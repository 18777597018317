import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../react-web-ui/components/Tooltip';
import { ReactComponent as Money } from '../../assets/images/icons/Money.svg';
import { ReactComponent as Tick } from '../../assets/images/icons/Tick.svg';
import { ReactComponent as X } from '../../assets/images/icons/X-red.svg';
import CountryContext from '../../react-web-ui/contexts/CountryContext';

/**
* Partners section
* @returns {*}
* @constructor
*/
export default function FeaturesTable({
  pricingBlocks,
  rows,
  hasPricingRange,
  pricePeriod,
  setPricePeriod,
}) {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);

  return (
    <table className="pricing-table">
      <thead>
        <tr>
          <th>
            {hasPricingRange ? (
              <div className="pricing-period">
                <Money className="icon" />
                <button
                  type="button"
                  className={`toggle-status ${pricePeriod.pro === 'YEARLY' ? 'active' : ''}`}
                  onClick={() => setPricePeriod({
                    pro: pricePeriod.pro === 'MONTHLY' ? 'YEARLY' : 'MONTHLY',
                    premium: pricePeriod.premium === 'MONTHLY' ? 'YEARLY' : 'MONTHLY',
                  })}
                >
                  <span className="seating-option">{t('selfOnboarding:monthly')}</span>
                  <span className="seating-option">{t('selfOnboarding:yearly')}</span>
                </button>
              </div>
            ) : ' '}
          </th>
          {pricingBlocks.map((block, index) => (
            <React.Fragment key={block.title}>
              {index > 0 && (
                <th style={{ width: '20px' }}>{' '}</th>
              )}
              <th className="pricing-header">
                <h3>{block.title}</h3>
                <p>
                  {block.title === 'lite'
                    ? block.price
                    : `${block.price} / ${t('selfOnboarding:month')}`}
                </p>
                {(pricePeriod.pro === 'YEARLY' || pricePeriod.premium === 'YEARLY') && block.title !== 'lite' && (
                  <p className="price-label">
                    {t('selfOnboarding:save_value', {
                      value: `${(t(`selfOnboarding:${block.title}_price_${countryContext.country.name}_MONTHLY`).replace(/\D/g, '') - block.price.replace(/\D/g, '')) * 12}${block.price.replace(/[^a-zA-Z]+/g, '')}`,
                    })}
                  </p>
                )}
              </th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.title}>
            <td className="pricing-option">
              <div className="flex-between">
                {row.title}
                {row.additional && (
                  <Tooltip
                    title={row.additional}
                    position="left"
                  >
                    <span className="btn-show-additional">?</span>
                  </Tooltip>
                )}
              </div>
            </td>
            <td className="pricing-option">
              {row.pricingBlockFeatures[0] === 'tick' ? (
                <Tick className="icon" />
              ) : (
                <X className="icon" />
              )}
            </td>
            <td className="pricing-option" />
            <td className="pricing-option">
              {row.pricingBlockFeatures[1] === 'tick' ? (
                <Tick className="icon" />
              ) : (
                <X className="icon" />
              )}
            </td>
            <td className="pricing-option" />
            <td className="pricing-option">
              {row.pricingBlockFeatures[2] === 'tick' ? (
                <Tick className="icon" />
              ) : (
                <X className="icon" />
              )}
            </td>
          </tr>
        ))}
        <tr>
          <td style={{ border: 0 }}>{t('selfOnboarding:pricing_vat')}</td>
          {pricingBlocks.map((block, index) => (
            <React.Fragment key={block.title}>
              {index > 0 && (
                <td style={{ width: '20px', border: 0 }}>{' '}</td>
              )}
              <td style={{ border: 0 }}>
                {(pricePeriod.pro === 'YEARLY' || pricePeriod.premium === 'YEARLY') && block.title !== 'lite' && (
                  <p>
                    {t('selfOnboarding:total_yearly', {
                      total: `${block.price.replace(/\D/g, '') * 12}${block.price.replace(/[^a-zA-Z]+/g, '')}`,
                      value: `${(t(`selfOnboarding:${block.title}_price_${countryContext.country.name}_MONTHLY`).replace(/\D/g, '') - block.price.replace(/\D/g, '')) * 12}${block.price.replace(/[^a-zA-Z]+/g, '')}`,
                    })}
                  </p>
                )}
              </td>
            </React.Fragment>
          ))}
        </tr>
      </tbody>
    </table>
  );
}

FeaturesTable.propTypes = {
  pricingBlocks: PropTypes.oneOfType([PropTypes.array]).isRequired,
  rows: PropTypes.oneOfType([PropTypes.array]).isRequired,
  hasPricingRange: PropTypes.bool,
  pricePeriod: PropTypes.string,
  setPricePeriod: PropTypes.func,
};

FeaturesTable.defaultProps = {
  hasPricingRange: false,
  pricePeriod: 'MONTHLY',
  setPricePeriod: null,
};
