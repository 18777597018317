import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/pl';
import { DayPickerRangeController } from 'react-dates';

import WindowContext from '../../react-web-ui/contexts/WindowContext';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';

/**
* Calendar
* @returns {*}
* @constructor
*/
export default function Calendar({
  weekStart,
  datesChangeHandler,
  focusChangeHandler,
  focused,
  start,
  end,
  disableDays,
}) {
  const DEKSTOP_CONTENT_LOADER = 700;
  const windowContext = useContext(WindowContext);
  return (
    <DayPickerRangeController
      firstDayOfWeek={weekStart}
      numberOfMonths={
        // eslint-disable-next-line
        windowContext.currentWidth < DEKSTOP_CONTENT_LOADER
          ? 1
          // eslint-disable-next-line
          : windowContext.currentWidth < 1024
            ? 2
            : windowContext.currentWidth < 1370
              ? 1
              : 2
      }
      navPrev={(
        <button className="calendar-prev" type="button">
          <ArrowDown />
        </button>
      )}
      navNext={(
        <button className="calendar-next" type="button">
          <ArrowDown />
        </button>
      )}
      orientation={
        windowContext.currentWidth >= DEKSTOP_CONTENT_LOADER
          ? 'horizontal'
          : 'vertical'
      }
      minimumNights={0}
      isDayBlocked={(day) => disableDays.some((item) => day.weekday() === item)}
      noBorder
      hideKeyboardShortcutsPanel
      onDatesChange={datesChangeHandler}
      onFocusChange={focusChangeHandler}
      focusedInput={focused}
      startDate={start}
      endDate={end}
      isOutsideRange={(day) => day.isBefore(moment().startOf('day'))}
    />
  );
}

Calendar.propTypes = {
  weekStart: PropTypes.number,
  datesChangeHandler: PropTypes.func.isRequired,
  focusChangeHandler: PropTypes.func.isRequired,
  focused: PropTypes.string.isRequired,
  start: PropTypes.instanceOf(moment),
  end: PropTypes.instanceOf(moment),
  disableDays: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

Calendar.defaultProps = {
  weekStart: 1,
  start: null,
  end: null,
};
