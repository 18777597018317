import { toast } from 'react-toastify';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import RestaurantContext from '../contexts/RestaurantContext';
import RequestService from '../networking/RequestService';
import useBeforeUnload from './useBeforeUnload';

const useSpecialEvents = (setLoading = false) => {
  const { t } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  const handlePublish = (id, userId) => {
    setLoading(true);
    startListening();
    (new RequestService('manager/special-events/publish'))
      .setParams({
        user_id: userId,
        special_event_id: id,
      })
      .send()
      .then(() => {
        toast.success(t('success:publish_event_success'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_publish_event'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  const handleUnpublish = (id, userId) => {
    setLoading(true);
    startListening();
    (new RequestService('manager/special-events/unpublish'))
      .setParams({
        user_id: userId,
        special_event_id: id,
      })
      .send()
      .then(() => {
        toast.success(t('success:unpublish_event_success'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_unpublish_event'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  const handleDelete = (id, userId) => {
    setLoading(true);
    startListening();
    (new RequestService('manager/special-events/remove'))
      .setParams({
        user_id: userId,
        special_event_id: id,
      })
      .send()
      .then(() => {
        toast.success(t('success:event_remove_success'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_remove_event'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  const handleChefImgUpload = (restaurantId, userId, e) => {
    setLoading(true);
    startListening();
    if (e.target.files.length) {
      (new RequestService('manager/places/chef'))
        .forFileUpload()
        .setParams({
          place_id: restaurantId,
          user_id: userId,
          image: e.target.files[0],
        })
        .send()
        .then((response) => {
          restaurantContext.setRestaurant(response.data);
          toast.success(t('success:chef_img_upload_success'));
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:could_not_upload_image'));
        })
        .finally(() => {
          setLoading(false);
          endListening();
        });
    }
  };

  const createSets = (inputList, locale, editing) => {
    const sets = [];
    inputList.forEach(({
      setId,
      menuNameEn,
      menuPriceNoWine,
      menuPriceWithWine,
      menuNameTranslation,
      menuItems,
    }) => {
      const set = {
        name: menuNameEn,
        price: menuPriceNoWine,
        drinks_price: menuPriceWithWine,
        translations: [{
          locale,
          name: menuNameTranslation,
        }],
      };
      if (editing) {
        set.id = setId;
      }

      const items = [];
      menuItems.forEach(({
        itemId,
        itemNameEn,
        itemDescriptionEn,
        itemNameTranslation,
        itemDescriptionTranslation,
      }, index) => {
        const newMenuItems = {
          name: itemNameEn,
          description: itemDescriptionEn,
          position: index,
          translations: [{
            locale,
            name: itemNameTranslation,
            description: itemDescriptionTranslation,
          }],
        };
        if (editing && itemId && itemId.length > 10) {
          newMenuItems.id = itemId;
        }
        items.push(newMenuItems);
      });

      set.items = items;

      sets.push(set);
    });

    return sets;
  };

  const createSetsInputs = (event, locale) => {
    const newInputList = [];
    event._sets.forEach((set) => {
      const menuItems = [];
      set._items.forEach((item) => {
        menuItems.push({
          itemId: item.id,
          itemNameEn: item.name,
          itemNameTranslation: item.translations[locale] && item.translations[locale].name,
          itemDescriptionEn: item.description,
          itemDescriptionTranslation: (
            item.translations[locale] && item.translations[locale].description
          ),
        });
      });
      newInputList.push({
        setId: set._id,
        menuNameEn: set._name,
        menuNameTranslation: set._translations[locale] && set._translations[locale].name,
        menuPriceNoWine: set._price,
        menuPriceWithWine: set._drinksPrice,
        menuItems,
      });
    });
    return newInputList;
  };

  const checkFields = (inputList) => {
    let submitCheck = true;

    const currentErrors = JSON.parse(JSON.stringify(inputList));
    inputList.forEach(({
      menuNameEn,
      menuNameTranslation,
      menuPriceNoWine,
      menuItems,
    }, index) => {
      currentErrors[index].menuNameEn = [];
      currentErrors[index].menuNameTranslation = [];
      currentErrors[index].menuPriceNoWine = [];
      currentErrors[index].menuPriceWithWine = [];
      if (!menuNameEn || menuNameEn.trim() === '') {
        currentErrors[index].menuNameEn = [t('forms:error_empty')];
        submitCheck = false;
      }
      if (!menuNameTranslation || menuNameTranslation.trim() === '') {
        currentErrors[index].menuNameTranslation = [t('forms:error_empty')];
        submitCheck = false;
      }
      if (!menuPriceNoWine) {
        currentErrors[index].menuPriceNoWine = [t('forms:error_empty')];
        submitCheck = false;
      }
      menuItems.forEach(({
        itemNameEn,
        itemNameTranslation,
      }, i) => {
        currentErrors[index].menuItems[i].itemNameEn = [];
        currentErrors[index].menuItems[i].itemDescriptionEn = [];
        currentErrors[index].menuItems[i].itemNameTranslation = [];
        currentErrors[index].menuItems[i].itemDescriptionTranslation = [];
        if (!itemNameEn || itemNameEn.trim() === '') {
          currentErrors[index].menuItems[i].itemNameEn = [t('forms:error_empty')];
          submitCheck = false;
        }
        if (!itemNameTranslation || itemNameTranslation.trim() === '') {
          currentErrors[index].menuItems[i].itemNameTranslation = [t('forms:error_empty')];
          submitCheck = false;
        }
      });
    });

    return {
      submitCheck,
      currentErrors,
    };
  };

  return {
    handlePublish,
    handleUnpublish,
    handleDelete,
    handleChefImgUpload,
    createSets,
    checkFields,
    createSetsInputs,
  };
};

export default useSpecialEvents;
