import React from 'react';
import PropTypes from 'prop-types';

/**
* Day select
* @param selectedDays
* @param setSelectedDays
* @returns {*}
* @constructor
*/
export default function DaySelect({
  selectedDays,
  setSelectedDays,
}) {
  return (
    <div className="group select-value-block">
      <div className="value-select-wrapper">
        {selectedDays.map(({ name, index, selected }) => (
          <button
            key={name}
            className={`value-select ${selected ? 'selected' : ''}`}
            type="button"
            onClick={() => {
              const newSelectedDays = [...selectedDays];
              newSelectedDays[index].selected = !selected;
              setSelectedDays(newSelectedDays);
            }}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
}

DaySelect.propTypes = {
  selectedDays: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  setSelectedDays: PropTypes.func.isRequired,
};
