import PropTypes from 'prop-types';
import React from 'react';
import StarRatings from 'react-star-ratings';

/**
 * Create rating element
 * @param stars
 * @returns {*}
 * @constructor
 */
export default function Rating({ stars }) {
  return (
    <StarRatings
      rating={stars}
      starDimension="22px"
      starSpacing="2px"
      starRatedColor="#2f4858"
      starHoverColor="#2f4858"
      starEmptyColor="#E2E2EA"
      svgIconViewBox="0 0 20 22"
      svgIconPath="M9.092 1.144c.13-.285.392-.465.677-.465.285 0 .547.18.677.465l2.428 5.436a.764.764 0 0 0 .568.456l5.431.872c.283.043.52.263.61.567a.894.894 0 0 1-.192.855l-3.928 4.232a.887.887 0 0 0-.217.739l.926 5.975a.872.872 0 0 1-.3.823.7.7 0 0 1-.795.064L10.12 18.34a.687.687 0 0 0-.703 0L4.56 21.162a.699.699 0 0 1-.795-.063.872.872 0 0 1-.3-.823l.927-5.975a.888.888 0 0 0-.217-.74l-3.93-4.23a.895.895 0 0 1-.19-.857c.09-.303.327-.523.61-.566l5.43-.872a.765.765 0 0 0 .569-.457z"
    />
  );
}

Rating.propTypes = {
  /** Number of filled stars. */
  stars: PropTypes.number,
};

Rating.defaultProps = {
  stars: 0,
};
