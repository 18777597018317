import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import InfoItem from '../../../react-web-ui/components/RestaurantInfo/InfoItem';
import { ReactComponent as MapIcon } from '../../../react-web-ui/assets/images/icons/Map.svg';
import { ReactComponent as Edit } from '../../../assets/images/icons/Edit.svg';
import FormElement from '../../../react-web-ui/components/FormElement';

export default function LocationInfoItem({
  locale,
  currentRestaurantInfo,
  setCurrentRestaurantInfo,
  disabled,
}) {
  const [edit, setEdit] = useState(false);
  const [defaultText, setDefaultText] = useState(currentRestaurantInfo._address);
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);

  useEffect(() => {
    if (i18n.language === 'en' || i18n.language === 'en-GB') {
      setDefaultText(currentRestaurantInfo._address);
    } else {
      setDefaultText(currentRestaurantInfo._translations[locale].address);
    }
  }, [
    i18n.language,
    currentRestaurantInfo._address,
    currentRestaurantInfo._translations[locale].address,
  ]);

  return (
    <div className={`tour-address editable-info-item ${edit ? 'editing' : ''}`}>
      <InfoItem
        icon={<MapIcon className="icon" />}
        title={(
          <>
            {`${t('pageRestaurantProfile:location')} `}
            <a
              href={`https://www.google.bg/maps/place/${currentRestaurantInfo._lat},${currentRestaurantInfo._lng}`}
              rel="noopener noreferrer"
              target="_blank"
              className="accent-text"
            >
              {`(${t('pageRestaurantProfile:see_map')})`}
            </a>
          </>
        )}
        text={edit ? (
          <>
            <FormElement
              id="latitude"
              elementType="input"
              inputType="number"
              placeholder={t('pageRestaurantProfile:latitude')}
              value={currentRestaurantInfo._lat}
              changed={(e) => {
                const copyRestaurantInfo = { ...currentRestaurantInfo };
                copyRestaurantInfo._lat = e.target.value;
                setCurrentRestaurantInfo(copyRestaurantInfo);
              }}
              disabled={disabled}
            />
            <FormElement
              id="longitude"
              elementType="input"
              inputType="number"
              placeholder={t('pageRestaurantProfile:longitude')}
              value={currentRestaurantInfo._lng}
              changed={(e) => {
                const copyRestaurantInfo = { ...currentRestaurantInfo };
                copyRestaurantInfo._lng = e.target.value;
                setCurrentRestaurantInfo(copyRestaurantInfo);
              }}
              disabled={disabled}
            />
            <div
              className={`
                invertible-fields
                ${(i18n.language === 'bg' || i18n.language === 'pl') ? 'inverted' : ''}
              `}
            >
              <FormElement
                className="invertible-field"
                id="addressEn"
                elementType="input"
                placeholder={t('forms:placeholder_default')}
                value={currentRestaurantInfo._address}
                changed={(e) => {
                  const copyRestaurantInfo = { ...currentRestaurantInfo };
                  copyRestaurantInfo._address = e.target.value;
                  setCurrentRestaurantInfo(copyRestaurantInfo);
                }}
                disabled={disabled}
              />
              <FormElement
                className="invertible-field"
                id="addressTranslation"
                elementType="input"
                placeholder={t(`forms:placeholder_translation_${countryContext.country.name}`)}
                value={currentRestaurantInfo._translations[locale].address}
                changed={(e) => {
                  const copyRestaurantInfo = { ...currentRestaurantInfo };
                  copyRestaurantInfo._translations[locale].address = e.target.value;
                  setCurrentRestaurantInfo(copyRestaurantInfo);
                }}
                disabled={disabled}
              />
            </div>
            <FormElement
              id="postalCode"
              elementType="input"
              placeholder={t('forms:billing_postal_code')}
              value={currentRestaurantInfo._postalCode}
              changed={(e) => {
                const copyRestaurantInfo = { ...currentRestaurantInfo };
                copyRestaurantInfo._postalCode = e.target.value;
                setCurrentRestaurantInfo(copyRestaurantInfo);
              }}
              disabled={disabled}
            />
            <button
              className="save-btn"
              type="button"
              onClick={() => setEdit(!edit)}
            >
              {t('save')}
            </button>
          </>
        ) : defaultText || t('pageRestaurantProfile:add_address')}
      />
      <button
        className="edit-btn"
        type="button"
        onClick={() => setEdit(!edit)}
      >
        <Edit className="icon" />
      </button>
    </div>
  );
}

LocationInfoItem.propTypes = {
  locale: PropTypes.string,
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
  setCurrentRestaurantInfo: PropTypes.func,
  disabled: PropTypes.bool,
};

LocationInfoItem.defaultProps = {
  locale: 'en',
  currentRestaurantInfo: {},
  setCurrentRestaurantInfo: null,
  disabled: false,
};
