import React, {
  useContext,
  Suspense,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CountryContext from '../../contexts/CountryContext';
import UserContext from '../../contexts/UserContext';
import MenuNav from './MenuNav';
import LanguageSwitcher from './LanguageSwitcher';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import ButtonLink from '../ButtonLink';
import { ReactComponent as Download } from '../../assets/images/icons/Download.svg';

/**
 * Main navigation component: includes LanguageSwitcher.
 * @returns {*}
 * @constructor
 */
export default function Header({
  className,
  mainLinks,
  secondaryLinks,
  navOpen,
  setNavOpen,
  authButtons,
  authPopup,
}) {
  const countryContext = useContext(CountryContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <header className={`page-header ${className}`}>
      <div className="inner-wrapper">
        <Link className="logo" to="/">
          <Logo />
        </Link>
        <ButtonLink
          text={(
            <>
              <Download className="icon" />
              {t('navigation:host_app')}
            </>
          )}
          classes="btn-accent btn-outline btn-download-host"
          href="/monetize/dineout-host"
        />
        <nav className="header-right" role="navigation">
          <Suspense fallback={<div />}>
            <MenuNav
              mainLinks={mainLinks}
              secondaryLinks={secondaryLinks}
              navOpen={navOpen}
            />
          </Suspense>
          <div className="auth-block">
            {authButtons}
          </div>
          <LanguageSwitcher locales={['en', countryContext.country.code.toLowerCase()]} />
          <button
            type="button"
            className={`mobile-menu-link ${navOpen ? 'open' : ''}`}
            onClick={() => {
              setNavOpen(!navOpen);
            }}
          >
            <span>Menu</span>
          </button>
        </nav>
      </div>
      {authPopup}
    </header>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  mainLinks: PropTypes.element,
  secondaryLinks: PropTypes.element,
  navOpen: PropTypes.bool,
  setNavOpen: PropTypes.func,
  authButtons: PropTypes.element,
  authPopup: PropTypes.element,
};

Header.defaultProps = {
  className: '',
  mainLinks: null,
  secondaryLinks: null,
  navOpen: false,
  setNavOpen: null,
  authButtons: null,
  authPopup: null,
};
