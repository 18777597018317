import moment from 'moment';
import Gift from './Gift';

import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import SpecialEventForBooking from './SpecialEventForBooking';
import SpecialOfferClient from './SpecialOfferClient';
import Table from './Table';

export default class BookingManager extends Resource {
  static collectionKey = 'bookings';

  constructor(
    id,
    dt,
    people,
    pnr,
    status,
    os,
    type,
    seatedOn,
    completedOn,
    contactName,
    contactPhone,
    contactEmail,
    notes,
    resolution,
    seen,
    tables,
    gift,
    specialEvent,
    guest,
    specialOffer,
    createdAt,
    updatedAt,
    amountDue,
  ) {
    super(id);
    this._dt = dt;
    this._people = people;
    this._pnr = pnr;
    this._status = status;
    this._os = os;
    this._type = type;
    this._seatedOn = seatedOn;
    this._completedOn = completedOn;
    this._contactName = contactName;
    this._contactPhone = contactPhone;
    this._contactEmail = contactEmail;
    this._notes = notes;
    this._resolution = resolution;
    this._seen = seen;
    this._tables = tables;
    this._gift = gift;
    this._specialEvent = specialEvent;
    this._guest = guest;
    this._specialOffer = specialOffer;
    this._createdAt = createdAt;
    this._updatedAt = updatedAt;
    this._amountDue = amountDue;
  }

  get id() {
    return this._id;
  }

  get dt() {
    return this._dt;
  }

  get people() {
    return this._people;
  }

  get pnr() {
    return this._pnr;
  }

  get contactName() {
    return this._contactName;
  }

  get contactEmail() {
    return this._contactEmail;
  }

  get contactPhone() {
    return this._contactPhone;
  }

  get date() {
    const newDate = moment.unix(this._dt);
    return newDate.format('DD.MM.YYYY');
  }

  get time() {
    const newTime = moment.unix(this._dt).utc();
    return newTime.format('HH:mm');
  }

  get status() {
    return this._status;
  }

  get rateFood() {
    return this._rateFood;
  }

  get rateService() {
    return this._rateService;
  }

  get rateAmbience() {
    return this._rateAmbience;
  }

  get isLate() {
    return this._isLate;
  }

  get type() {
    return this._type;
  }

  get notes() {
    return this._notes;
  }

  get createdAt() {
    return this._createdAt;
  }

  get seatedOn() {
    return this._seatedOn;
  }

  get completedOn() {
    return this._completedOn;
  }

  get resolution() {
    return this._resolution;
  }

  get voucher() {
    return this._voucher;
  }

  get gift() {
    return this._gift;
  }

  get points() {
    return this._points;
  }

  get seen() {
    return this._seen;
  }

  get os() {
    return this._os;
  }

  get place() {
    return this._place;
  }

  get tables() {
    return this._tables;
  }

  get specialEvent() {
    return this._specialEvent;
  }

  get discount() {
    return this._discount;
  }

  get specialOffer() {
    return this._specialOffer;
  }

  get users() {
    return this._users;
  }

  get owner() {
    return this._owner;
  }

  get guest() {
    return this._guest;
  }

  get updatedAt() {
    return this._updatedAt;
  }

  get amountDue() {
    return this._amountDue;
  }

  static fromAPI(object) {
    return new BookingManager(
      object.id,
      object.dt,
      object.people,
      object.pnr,
      object.status,
      object.os,
      object.type,
      object.seated_on,
      object.completed_on,
      object.contact_name,
      object.contact_phone,
      object.contact_email,
      object.notes,
      object.resolution,
      object.seen,
      object.tables ? new ResourceCollection(Table).make(object) : [],
      object.gift ? Gift.fromAPI(object.gift) : null,
      object.special_event
        ? SpecialEventForBooking.fromAPI(object.special_event)
        : null,
      object.guest,
      object.special_offer
        ? SpecialOfferClient.fromAPI(object.special_offer)
        : null,
      object.created_at,
      object.updated_at,
      object.amount_due,
    );
  }
}
