import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    firstNameEmpty: i18n.t('forms:first_name_error_empty'),
    lastNameEmpty: i18n.t('forms:last_name_error_empty'),
    emailEmpty: i18n.t('forms:email_error_empty'),
    emailInvalid: i18n.t('forms:email_error_invalid'),
    phoneEmpty: i18n.t('forms:phone_error_empty'),
    phoneInvalid: i18n.t('forms:phone_error_invalid'),
    passwordRequirements: i18n.t('forms:password_requirements'),
    repeatPasswordEmpty: i18n.t('forms:repeat_password_error_empty'),
    repeatPasswordDifferent: i18n.t('forms:repeat_password_error_not_same'),
    errorEmpty: i18n.t('forms:error_empty'),
  };

  // First Name
  errors.first_name = [];
  if (!values.first_name || values.first_name.trim() === '') {
    errors.first_name.push(errorTexts.firstNameEmpty);
  }

  // Last Name
  errors.last_name = [];
  if (!values.last_name || values.last_name.trim() === '') {
    errors.last_name.push(errorTexts.lastNameEmpty);
  }

  // Phone
  errors.phone = [];
  const telRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

  if (!values.phone || values.phone.trim() === '') {
    errors.phone.push(errorTexts.errorEmpty);
  }

  if (values.phone && !telRegex.test(values.phone)) {
    errors.phone.push(errorTexts.phoneInvalid);
  }

  // Email
  errors.email = [];
  if (!values.email || values.email.trim() === '') {
    errors.email.push(errorTexts.emailEmpty);
  }

  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email.push(errorTexts.emailInvalid);
  }

  // Password
  const mediumRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  errors.new_password = [];

  if (values.new_password && !mediumRegex.test(values.new_password)) {
    errors.new_password.push(errorTexts.passwordRequirements);
  }

  // Repeat Password
  errors.repeat_new_password = [];
  if (values.new_password !== values.repeat_new_password) {
    errors.repeat_new_password.push(errorTexts.repeatPasswordDifferent);
  }

  return errors;
};

export default validate;
