const convertHours = (hours) => {
  const times = [];
  let timesGroup = [];
  for (let i = 0; i < hours.length; i += 1) {
    if (hours[i].selected) {
      timesGroup.push(hours[i]);
      if (i === hours.length - 1) {
        times.push({
          start: timesGroup[0].hour,
          end: timesGroup[timesGroup.length - 1].hour,
        });
        timesGroup = [];
      }
    } else if (timesGroup.length > 0) {
      times.push({
        start: timesGroup[0].hour,
        end: timesGroup[timesGroup.length - 1].hour,
      });
      timesGroup = [];
    }
  }
  return times;
};

export default convertHours;
