/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createApi } from 'unsplash-js';
import { useTranslation } from 'react-i18next';

import RequestService from '../../../networking/RequestService';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import {
  restaurantImage,
  chefImage,
  eventImage,
  specialOfferImg,
} from '../../../react-web-ui/utilities/images';
import FormElement from '../../../react-web-ui/components/FormElement';
import filterNone from '../../../assets/images/filters/none.png';
import filterBlur from '../../../assets/images/filters/blur.png';
import filterBrightness from '../../../assets/images/filters/brightness.png';
import filterContrast from '../../../assets/images/filters/contrast.png';
import filterGrayscale from '../../../assets/images/filters/grayscale.png';
import filterHueRotate from '../../../assets/images/filters/hue-rotate.png';
import filterInvert from '../../../assets/images/filters/invert.png';
import filterOpacity from '../../../assets/images/filters/opacity.png';
import filterSepia from '../../../assets/images/filters/sepia.png';
import filterSaturation from '../../../assets/images/filters/saturation.png';

const unsplash = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH,
});
export default function BackgroundElement({
  background,
  setBackground,
  intensity,
  setIntensity,
  filter,
  setFilter,
}) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const userContext = React.useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);
  const [searchedImages, setSearchedImages] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [allImages, setAllImages] = useState({});

  const searchPhotos = () => {
    unsplash.search
      .getPhotos({
        query,
        perPage: 30,
      })
      .then((json) => {
        setSearchedImages(json.response.results);
      });
  };

  const selectOptions = [
    {
      value: 'none',
      text: t('pagePostCreator:filter_none'),
      img: filterNone,
      intensity: 50,
    },
    {
      value: 'blur',
      text: t('pagePostCreator:filter_blur'),
      img: filterBlur,
      intensity: 50,
    },
    {
      value: 'brightness',
      text: t('pagePostCreator:filter_brightness'),
      img: filterBrightness,
      intensity: 50,
    },
    {
      value: 'contrast',
      text: t('pagePostCreator:filter_contrast'),
      img: filterContrast,
      intensity: 70,
    },
    {
      value: 'grayscale',
      text: t('pagePostCreator:filter_grayscale'),
      img: filterGrayscale,
      intensity: 100,
    },
    {
      value: 'hue-rotate',
      text: t('pagePostCreator:filter_hue_rotate'),
      img: filterHueRotate,
      intensity: 50,
    },
    {
      value: 'invert',
      text: t('pagePostCreator:filter_invert'),
      img: filterInvert,
      intensity: 70,
    },
    {
      value: 'opacity',
      text: t('pagePostCreator:filter_opacity'),
      img: filterOpacity,
      intensity: 50,
    },
    {
      value: 'sepia',
      text: t('pagePostCreator:filter_sepia'),
      img: filterSepia,
      intensity: 50,
    },
    {
      value: 'saturation',
      text: t('pagePostCreator:filter_saturation'),
      img: filterSaturation,
      intensity: 20,
    },
  ];

  useEffect(() => {
    if (!restaurantContext.restaurantId || !userContext.user) return;

    (new RequestService('manager/gallery/all'))
      .setParams({
        user_id: userContext.user.id,
        place_id: restaurantContext.restaurantId,
      })
      .send()
      .then((response) => {
        const { data } = response;
        setAllImages(data);
      })
      .catch(() => null);
  }, [restaurantContext.restaurantId, userContext]);

  return (
    <>
      <h2 className="column-top">{t('pagePostCreator:background')}</h2>
      <div className="details-form">
        {background && (
          <>
            <span className="label">{t('pagePostCreator:filter')}</span>
            <div className="filter-wrapper">
              <button
                type="button"
                className="selected-filter"
                onClick={() => setOpenSelect(!openSelect)}
              >
                {selectOptions.filter((select) => select.value === filter)[0].text}
              </button>
              {openSelect && (
                <div className="filter-options">
                  {selectOptions.map((option) => (
                    <button
                      key={option.value}
                      type="button"
                      onClick={() => {
                        setFilter(option.value);
                        setOpenSelect(false);
                        setIntensity(option.intensity);
                      }}
                    >
                      <img src={option.img} alt="" />
                      {option.text}
                    </button>
                  ))}
                </div>
              )}
            </div>
            {filter !== 'none' && (
              <div className="range-slider">
                {t('pagePostCreator:intensity')}
                <input
                  id="intensity"
                  type="range"
                  value={intensity}
                  min="0"
                  max="100"
                  step="1"
                  onChange={(e) => setIntensity(e.target.value)}
                />
                {`${intensity}%`}
              </div>
            )}
          </>
        )}
        <form
          action="#"
          onSubmit={(e) => {
            e.preventDefault();
            searchPhotos();
          }}
        >
          <FormElement
            id="query"
            elementType="input"
            placeholder={t('pagePostCreator:search_in_images')}
            value={query}
            changed={(e) => setQuery(e.target.value)}
          />
        </form>
        <div className="choose-from-images">
          {searchedImages.length > 0 ? (
            <>
              {searchedImages.map((image) => (
                <button
                  key={image.id}
                  type="button"
                  onClick={() => {
                    setBackground(image.urls.full);
                    unsplash.photos.trackDownload({
                      downloadLocation: image.links.download_location,
                    });
                  }}
                >
                  <img src={image.urls.small} alt="" />
                  <p className="unsplash-credit">
                    {t('pagePostCreator:photo_by')}
                    {' '}
                    <a target="_blank" rel="noreferrer" href={`${image.user.links.html}?utm_source=dineout.bg&utm_medium=referral`}>{`${image.user.first_name} ${image.user.last_name ? image.user.last_name : ''}`}</a>
                    {' '}
                    {t('pagePostCreator:on')}
                    {' '}
                    <a target="_blank" rel="noreferrer" href="https://unsplash.com/?utm_source=dineout.bge&utm_medium=referral">Unsplash</a>
                  </p>
                </button>
              ))}
            </>
          ) : (
            <>
              {allImages.place && allImages.place.map((image) => (
                <button
                  key={image}
                  type="button"
                  onClick={() => setBackground(
                    restaurantImage(
                      restaurantContext.restaurantId,
                      image,
                      2000,
                      2000,
                    ),
                  )}
                >
                  <img
                    src={restaurantImage(
                      restaurantContext.restaurantId,
                      image,
                      250,
                      160,
                    )}
                    alt=""
                  />
                </button>
              ))}
              {allImages.special_events
              && allImages.special_events.map(({ special_event_id, image }) => (
                <button
                  key={special_event_id}
                  type="button"
                  onClick={() => setBackground(
                    eventImage(
                      special_event_id,
                      image,
                      2000,
                      2000,
                    ),
                  )}
                >
                  <img
                    src={eventImage(
                      special_event_id,
                      image,
                      250,
                      160,
                    )}
                    alt=""
                  />
                </button>
              ))}
              {allImages.special_offers
              && allImages.special_offers.map(({ special_offer_id, image }) => (
                <button
                  key={special_offer_id}
                  type="button"
                  onClick={() => setBackground(
                    specialOfferImg(
                      special_offer_id,
                      image,
                      2000,
                      2000,
                    ),
                  )}
                >
                  <img
                    src={specialOfferImg(
                      special_offer_id,
                      image,
                      250,
                      160,
                    )}
                    alt=""
                  />
                </button>
              ))}
              {allImages.logo && (
                <button
                  type="button"
                  onClick={() => setBackground(
                    restaurantImage(
                      restaurantContext.restaurantId,
                      allImages.logo,
                      2000,
                      2000,
                    ),
                  )}
                >
                  <img
                    src={restaurantImage(
                      restaurantContext.restaurantId,
                      allImages.logo,
                      250,
                      160,
                    )}
                    alt=""
                  />
                </button>
              )}
              {allImages.chef_thumb && (
                <button
                  type="button"
                  onClick={() => setBackground(
                    chefImage(
                      restaurantContext.restaurantId,
                      allImages.chef_thumb,
                      2000,
                      2000,
                    ),
                  )}
                >
                  <img
                    src={chefImage(
                      restaurantContext.restaurantId,
                      allImages.chef_thumb,
                      250,
                      160,
                    )}
                    alt=""
                  />
                </button>
              )}
            </>
          )}
        </div>
        <label className="upload-file btn btn-accent" htmlFor="avatar-image">
          {t('pagePostCreator:upload_image')}
          <input
            type="file"
            id="avatar-image"
            accept="image/*"
            onChange={(event) => {
              setBackground(URL.createObjectURL(event.target.files[0]));
            }}
          />
        </label>
      </div>
    </>
  );
}

BackgroundElement.propTypes = {
  background: PropTypes.string.isRequired,
  setBackground: PropTypes.func.isRequired,
  intensity: PropTypes.number.isRequired,
  setIntensity: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};
