import { useTranslation } from 'react-i18next';

const useBeforeUnload = () => {
  const { t } = useTranslation();

  const handleLeavePage = (e) => {
    const confirmationMessage = t('changes_may_not_be_saved');
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  const startListening = () => {
    window.addEventListener('beforeunload', handleLeavePage);
  };

  const endListening = () => {
    window.removeEventListener('beforeunload', handleLeavePage);
  };

  return {
    startListening,
    endListening,
  };
};

export default useBeforeUnload;
