import common from './common.json';
import forms from './forms.json';
import navigation from './navigation.json';
import walktrough from './walktrough.json';
import pageHome from './pageHome.json';
import pageRestaurantsListing from './pageRestaurantsListing.json';
import pageSpecialOffers from './pageSpecialOffers.json';
import pageEvents from './pageEvents.json';
import pageTastingMenus from './pageTastingMenus.json';
import pageRestaurantProfile from './pageRestaurantProfile.json';
import pageShifts from './pageShifts.json';
import pageMenu from './pageMenu.json';
import pageReviews from './pageReviews.json';
import pageBilling from './pageBilling.json';
import pageContact from './pageContact.json';
import pageEmployees from './pageEmployees.json';
import pageStatistics from './pageStatistics.json';
import pageFloorPlan from './pageFloorPlan.json';
import pageWidget from './pageWidget.json';
import pageEmailBranding from './pageEmailBranding.json';
import pageCombinations from './pageCombinations.json';
import pageBookings from './pageBookings.json';
import pageSeatingAreas from './pageSeatingAreas.json';
import selfOnboarding from './selfOnboarding.json';
import pageSubscriptions from './pageSubscriptions.json';
import pageSettings from './pageSettings.json';
import footer from './footer.json';
import apiErrors from './apiErrors.json';
import success from './success.json';
import verifyOwnership from './verifyOwnership.json';
import pageHost from './pageHost.json';
import pagePostCreator from './pagePostCreator.json';
import pageGuests from './pageGuests.json';

export default {
  common,
  forms,
  navigation,
  walktrough,
  pageHome,
  pageRestaurantsListing,
  pageSpecialOffers,
  pageEvents,
  pageTastingMenus,
  pageRestaurantProfile,
  pageShifts,
  pageFloorPlan,
  pageMenu,
  pageReviews,
  pageBilling,
  pageContact,
  pageEmployees,
  pageStatistics,
  pageWidget,
  pageEmailBranding,
  pageCombinations,
  pageBookings,
  pageSeatingAreas,
  selfOnboarding,
  pageSubscriptions,
  pageSettings,
  footer,
  apiErrors,
  success,
  verifyOwnership,
  pageHost,
  pagePostCreator,
  pageGuests,
};
