import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { restaurantImage } from '../../react-web-ui/utilities/images';
import RestaurantContext from '../../contexts/RestaurantContext';
import Place from '../../networking/resources/Place';
import Rating from '../../react-web-ui/components/Rating';
import RestaurantBlock from '../../react-web-ui/components/RestaurantBlock';
import PlaceManager from '../../networking/resources/PlaceManager';

/**
* Restaurant
* @param restaurantInfo
* @returns {*}
* @constructor
*/
export default function Restaurant({ restaurantInfo }) {
  const { t } = useTranslation();
  const history = useHistory();
  const restaurantContext = useContext(RestaurantContext);

  return (
    <RestaurantBlock
      onClick={() => {
        restaurantContext.setRestaurant(restaurantInfo);
        restaurantContext.setRestaurantId(restaurantInfo.id);
        if (restaurantInfo.subscriptions.length > 0) {
          const activeSubscription = restaurantInfo.subscriptions.find(
            (subscription) => subscription.status === 'active'
              || (subscription.status === 'trialing'),
          );
          restaurantContext.setPlan(activeSubscription?.plan || 'false');
        } else {
          restaurantContext.setPlan('false');
        }
        history.push('/manage/restaurant-profile');
      }}
      className="clickable"
      imageBlock={(
        <div className="img-wrapper">
          {restaurantInfo.thumb ? (
            <img
              src={restaurantImage(restaurantInfo.id, restaurantInfo.thumb, 577, 420)}
              alt={restaurantInfo.name}
            />
          ) : (
            <span className="no-img" />
          )}
        </div>
      )}
      title={(
        <>{restaurantInfo.name}</>
      )}
      address={`${restaurantInfo.address ? `${restaurantInfo.address}, ` : ''}${restaurantInfo.neighbourhood.name}`}
      cuisines={restaurantInfo.cuisinesText}
      rating={(
        <Rating stars={restaurantInfo.rateStars} />
      )}
      button={restaurantInfo.id === restaurantContext.restaurantId ? (
        <span className="btn btn-accent btn-outline">
          {t('selected')}
        </span>
      ) : (
        <span className="btn btn-accent">
          {t('manage')}
        </span>
      )}
    />
  );
}

Restaurant.propTypes = {
  restaurantInfo: PropTypes.oneOfType(
    [PropTypes.instanceOf(Place), PropTypes.instanceOf(PlaceManager)],
  ).isRequired,
};
