import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
* PickHourBlock
* @param restaurantInfo
* @returns {*}
* @constructor
*/
export default function PickHourBlock({
  lunchHours,
  setLunchHours,
  dinnerHours,
  setDinnerHours,
}) {
  const { t } = useTranslation();

  const changeLunchHours = (bool) => {
    const newLunchHours = [...lunchHours];
    for (let i = 0; i < newLunchHours.length; i += 1) {
      newLunchHours[i].selected = bool;
    }
    setLunchHours(newLunchHours);
  };

  const changeDinnerHours = (bool) => {
    const newDinnerHours = [...dinnerHours];
    for (let i = 0; i < newDinnerHours.length; i += 1) {
      newDinnerHours[i].selected = bool;
    }
    setDinnerHours(newDinnerHours);
  };

  return (
    <div className="tour-hour pick-hour">
      <div className="flex-between">
        <h3>{t('lunch')}</h3>
        {lunchHours.every((hour) => hour.selected === true) ? (
          <button
            type="button"
            className="clear-all-hours"
            onClick={() => changeLunchHours(false)}
          >
            {t('clear')}
          </button>
        ) : (
          <button
            type="button"
            className="select-all-hours"
            onClick={() => changeLunchHours(true)}
          >
            {t('select_all')}
          </button>
        )}
      </div>
      <div className="hours-wrapper">
        {lunchHours.map(({ hour, selected }) => (
          <button
            type="button"
            key={hour}
            className={selected ? 'selected-hour' : ''}
            onClick={(event) => {
              const newLunchHours = [...lunchHours];
              for (let i = 0; i < newLunchHours.length; i += 1) {
                if (newLunchHours[i].hour === event.target.textContent) {
                  newLunchHours[i].selected = !newLunchHours[i].selected;
                }
              }
              setLunchHours(newLunchHours);
            }}
          >
            {hour}
          </button>
        ))}
      </div>
      <div className="flex-between">
        <h3>{t('dinner')}</h3>
        {dinnerHours.every((hour) => hour.selected === true) ? (
          <button
            type="button"
            className="clear-all-hours"
            onClick={() => changeDinnerHours(false)}
          >
            {t('clear')}
          </button>
        ) : (
          <button
            type="button"
            className="select-all-hours"
            onClick={() => changeDinnerHours(true)}
          >
            {t('select_all')}
          </button>
        )}
      </div>
      <div className="hours-wrapper">
        {dinnerHours.map(({ hour, selected }) => (
          <button
            type="button"
            key={hour}
            className={selected ? 'selected-hour' : ''}
            onClick={(event) => {
              const newDinnerHours = [...dinnerHours];
              for (let i = 0; i < newDinnerHours.length; i += 1) {
                if (newDinnerHours[i].hour === event.target.textContent) {
                  newDinnerHours[i].selected = !newDinnerHours[i].selected;
                }
              }
              setDinnerHours(newDinnerHours);
            }}
          >
            {hour}
          </button>
        ))}
      </div>
    </div>
  );
}

PickHourBlock.propTypes = {
  lunchHours: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setLunchHours: PropTypes.func.isRequired,
  dinnerHours: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setDinnerHours: PropTypes.func.isRequired,
};
