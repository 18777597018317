import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ElementsContext from '../../../contexts/ElementsContext';
import { initialIconTextOptions } from '../../../utilities/constants';
import { ReactComponent as Calendar } from '../../../assets/images/icons/Calendar.svg';
import { ReactComponent as Clock } from '../../../assets/images/icons/Clock.svg';
import { ReactComponent as Cuisine } from '../../../assets/images/icons/Cuisine.svg';
import { ReactComponent as Map } from '../../../assets/images/icons/Map.svg';
import { ReactComponent as Delete } from '../../../assets/images/icons/Delete.svg';

import { randomId, sortElements, updateCanvasElements } from '../helper';
import FormElement from '../../../react-web-ui/components/FormElement';

export default function IconTextElement() {
  const { t } = useTranslation();
  const {
    canvasElements,
    setCanvasElements,
    selectedElement,
    setSelectedElement,
  } = useContext(ElementsContext);
  const [selectedIcon, setSelectedIcon] = useState('clock');
  const [showIcons, setShowIcons] = useState(false);

  const iconSelect = [
    {
      value: 'calendar',
      icon: <Calendar className="icon" />,
    },
    {
      value: 'clock',
      icon: <Clock className="icon" />,
    },
    {
      value: 'cuisine',
      icon: <Cuisine className="icon" />,
    },
    {
      value: 'map',
      icon: <Map className="icon" />,
    },
  ];

  useEffect(() => {
    if (canvasElements.length === 0 || selectedElement === null || typeof selectedElement === 'undefined') return;
    setSelectedIcon(selectedElement.icon);
  }, [selectedElement, canvasElements]);

  return (
    <>
      <div className="column-top">
        <h2>{t('pagePostCreator:icon_with_text')}</h2>
        <button
          type="button"
          onClick={() => {
            const id = randomId();
            const newElement = {
              ...initialIconTextOptions,
              id,
            };
            const sortedElements = sortElements([
              ...canvasElements,
              { ...newElement },
            ]);
            setCanvasElements(sortedElements);
            setSelectedElement(sortedElements.find((element) => element.id === id));
          }}
          className="btn btn-accent btn-add-element"
        >
          {t('pagePostCreator:add_element')}
        </button>
      </div>
      {selectedElement && (
        <div className="details-form">
          <div className="lg-two-cols">
            <div className="col">
              <span className="label">{t('pagePostCreator:icon')}</span>
              <button
                type="button"
                className="selected-icon"
                onClick={() => setShowIcons(!showIcons)}
              >
                {iconSelect.find((select) => select.value === selectedIcon).icon}
              </button>
              {showIcons && (
                <div className="icon-select">
                  {iconSelect.map((select) => (
                    <button
                      type="button"
                      key={select.value}
                      className={selectedIcon === select.value ? 'selected' : ''}
                      onClick={() => {
                        setShowIcons(false);
                        setSelectedIcon(select.value);
                        setCanvasElements(updateCanvasElements(
                          canvasElements,
                          selectedElement,
                          'icon',
                          select.value,
                        ));
                      }}
                    >
                      {select.icon}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <FormElement
              className="col"
              id="text-size"
              elementType="input"
              inputType="number"
              placeholder={t('pagePostCreator:font_size')}
              label={t('pagePostCreator:font_size')}
              value={selectedElement?.fontSize}
              changed={(e) => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'fontSize',
                  e.target.value,
                ));
              }}
            />
          </div>
          <div className="color-form-group">
            <span className="label">{t('pagePostCreator:text_color')}</span>
            <div className="input-wrapper">
              <input
                type="color"
                id="text-color"
                name="text-color"
                value={selectedElement?.color}
                onChange={(e) => {
                  setCanvasElements(updateCanvasElements(
                    canvasElements,
                    selectedElement,
                    'color',
                    e.target.value,
                  ));
                }}
              />
            </div>
          </div>
          <div className="color-form-group">
            <span className="label">{t('pagePostCreator:icon_color')}</span>
            <div className="input-wrapper">
              <input
                type="color"
                id="icon-color"
                name="icon-color"
                value={selectedElement?.iconColor}
                onChange={(e) => {
                  setCanvasElements(updateCanvasElements(
                    canvasElements,
                    selectedElement,
                    'iconColor',
                    e.target.value,
                  ));
                }}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              const currentCanvasElements = canvasElements.filter(
                (element) => (element.id !== selectedElement.id),
              );
              let lastIndex = null;
              currentCanvasElements.forEach((element, index) => {
                if (element.type === 'icon-with-text') {
                  lastIndex = index;
                }
              });
              setSelectedElement(currentCanvasElements[lastIndex]);
              setCanvasElements(currentCanvasElements);
            }}
            className="btn-link btn-delete-element"
          >
            <Delete className="icon" />
            {t('pagePostCreator:remove_icon_text_element')}
          </button>
        </div>
      )}
    </>
  );
}
