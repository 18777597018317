import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonLink from '../../react-web-ui/components/ButtonLink';

/**
 * OfferTypeBlock
 * @param title
 * @param text
 * @param link
 * @param icon
 * @returns {*}
 * @constructor
 */
export default function OfferTypeBlock({
  title,
  text,
  link,
  icon,
  onMouseEnter,
}) {
  const { t } = useTranslation();

  return (
    <div className="offer-type-block" onFocus={onMouseEnter} onMouseOver={onMouseEnter}>
      <div className="text-wrapper">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <ButtonLink
        href={link}
        classes="btn-accent btn-outline"
        text={t('select_and_continue')}
      />
      {icon}
    </div>
  );
}

OfferTypeBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.element.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
};
