import React, { useEffect, useContext, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  useLocation, useHistory, Link, Prompt,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import WindowContext from '../../react-web-ui/contexts/WindowContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import Canvas from '../../components/FloorPlan/Canvas';
import Element from '../../components/FloorPlan/Element';
import useSalons from '../../hooks/useSalons';
import ItemTypes from '../../components/FloorPlan/ItemTypes';
import ElementDetails from '../../components/FloorPlan/ElementDetails';
import { ReactComponent as Bar } from '../../assets/images/icons/Bar.svg';
import { ReactComponent as Kitchen } from '../../assets/images/icons/Kitchen.svg';
import { ReactComponent as Exit } from '../../assets/images/icons/Exit.svg';
import { ReactComponent as WC } from '../../assets/images/icons/WC.svg';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';
import Button from '../../react-web-ui/components/Button';
import RequestService from '../../networking/RequestService';
import Spinner from '../../react-web-ui/components/Spinner';
import useExitPrompt from '../../hooks/useExitPrompt';
import { findElementSize } from '../../components/FloorPlan/helpers';

export default function FloorPlan() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useExitPrompt(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [minCanvasSizes, setMinCanvasSizes] = useState({
    width: 0,
    height: 0,
  });
  const [zoom, setZoom] = useState({
    scale: 1,
    step: 0,
  });
  const { isDesktop } = useContext(WindowContext);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const salons = useSalons(userContext.user, restaurantContext.restaurantId);
  const { hash } = useLocation();
  const history = useHistory();

  useEffect(() => {
    document.querySelector('body').classList.add('hide-header');
    return () => {
      document.querySelector('body').classList.remove('hide-header');
    };
  }, []);

  const selectSalonByHash = () => {
    const salonId = hash.replace('#', '');
    const currentSalon = salons.all.filter((salon) => salon.id === salonId)[0];
    currentSalon.elements.forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.size = findElementSize(element.type, element.width, element.height, element.rotation);
    });
    salons.select(currentSalon);
    setOpenSelect(false);
  };

  const deselectElement = () => {
    if (salons.selectedElements.length > 0) {
      const selectedElementId = salons.selectedElements[0];
      salons.elements.deselect(selectedElementId);
    }
  };

  useEffect(() => {
    if (salons.all.length === 0 || salons.selectedSalon) return;
    selectSalonByHash();
  }, [salons]);

  useEffect(() => {
    if (salons.all.length === 0) return;
    selectSalonByHash();
    deselectElement();
  }, [hash]);

  const updateFloorPlan = () => {
    setLoading(true);
    if (userContext.user) {
      const requestParams = {
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
        salon_id: salons.selectedSalon.id,
        elements: salons.selectedSalon.elements.map((element) => ({
          id: element.exists ? element.id : null,
          exists: element.exists,
          name: element.name,
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height,
          min_capacity: element.minCapacity,
          max_capacity: element.maxCapacity,
          type: element.type,
          bookable: element.bookable,
          rotation: element.rotation || 0,
          resizable: element.resizable,
        })),
      };
      (new RequestService('manager/floorplan/save'))
        .setParams(requestParams)
        .send()
        .then(() => {
          toast.success(t('success:save_floorplan_success'));
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('pageFloorPlan:save_floorplan_fail'));
        })
        .finally(() => {
          setLoading(false);
          setChanged(false);
        });
    }
  };

  useEffect(() => () => {
    setChanged(false);
  }, []);

  useEffect(() => {
    if (!salons.selectedSalon) return;
    const allX = [];
    const allY = [];
    salons.selectedSalon.elements.forEach((element) => {
      allX.push(element.x + element.width);
      allY.push(element.y + element.height);
    });
    setMinCanvasSizes({
      width: Math.max(...allX),
      height: Math.max(...allY),
    });
  }, [salons.selectedSalon]);

  return (
    <div className="full-width-inner-wrapper">
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      <div className="floorplan-top">
        <div className="selected-salon-wrapper">
          <div className="selected-salon">
            <h2>{salons.selectedSalon && salons.selectedSalon.name}</h2>
            {salons.all.length > 1 && (
              <button
                type="button"
                className={`arrow ${openSelect ? 'rotate' : ''}`}
                onClick={() => setOpenSelect(!openSelect)}
              >
                <ArrowDown />
              </button>
            )}
          </div>
          {openSelect && (
            <div className="other-salons">
              <ul>
                {salons.all.filter((salon) => salon.id !== hash.replace('#', '')).map((salon) => (
                  <li key={salon.id}>
                    <Link to={`/manage/floor-plan#${salon.id}`}>
                      {salon.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="buttons">
          <button
            type="button"
            className="btn-link btn-discard"
            onClick={() => history.push('/manage/seating-areas')}
          >
            {changed ? t('discard') : t('go_back')}
          </button>
          {isDesktop && (
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('save_changes')}
              classes={`btn-discount ${loading ? 'loading-btn' : ''}`}
              disabled={loading || !changed}
              onClick={() => {
                confirmAlert({
                  // eslint-disable-next-line react/prop-types
                  customUI: ({ onClose }) => (
                    <div className="react-confirm-alert-body">
                      <h2 className="section-title">{t('pageFloorPlan:are_you_sure_update_floorplan')}</h2>
                      <button
                        type="button"
                        onClick={() => {
                          updateFloorPlan();
                          onClose();
                        }}
                      >
                        {t('yes')}
                      </button>
                      <button
                        type="button"
                        onClick={onClose}
                      >
                        {t('no')}
                      </button>
                    </div>
                  ),
                });
              }}
            />
          )}
        </div>
      </div>
      {salons.selectedSalon ? (
        <>
          {isDesktop ? (
            <DndProvider backend={HTML5Backend}>
              <div className="floorplan-content">
                <div
                  className="left-floorplan-column"
                  role="button"
                  tabIndex="0"
                  onClick={() => deselectElement()}
                  onKeyPress={() => deselectElement()}
                >
                  <h2 className="column-top">
                    {t('pageFloorPlan:table_elements_label')}
                  </h2>
                  <div className="elements-wrapper">
                    <Element
                      type={ItemTypes.SQUARE.name}
                      width={ItemTypes.SQUARE.width}
                      height={ItemTypes.SQUARE.height}
                      minCapacity={ItemTypes.SQUARE.minCapacity}
                      maxCapacity={ItemTypes.SQUARE.maxCapacity}
                    />
                    <Element
                      type={ItemTypes.CIRCLE.name}
                      width={ItemTypes.CIRCLE.width}
                      height={ItemTypes.CIRCLE.height}
                      minCapacity={ItemTypes.CIRCLE.minCapacity}
                      maxCapacity={ItemTypes.CIRCLE.maxCapacity}
                    />
                    <Element
                      type={ItemTypes.RECTANGLE.name}
                      width={ItemTypes.RECTANGLE.width}
                      height={ItemTypes.RECTANGLE.height}
                      minCapacity={ItemTypes.RECTANGLE.minCapacity}
                      maxCapacity={ItemTypes.RECTANGLE.maxCapacity}
                    />
                    <Element
                      type={ItemTypes.RECTANGLE.name}
                      width={ItemTypes.RECTANGLE.width}
                      height={ItemTypes.RECTANGLE.height}
                      minCapacity={ItemTypes.RECTANGLE.minCapacity}
                      maxCapacity={ItemTypes.RECTANGLE.maxCapacity}
                      rotation={90}
                    />
                    <Element
                      type={ItemTypes.BAR.name}
                      width={ItemTypes.BAR.width}
                      height={ItemTypes.BAR.height}
                      icon={<Bar className="icon" />}
                      name={t(`pageFloorPlan:${ItemTypes.BAR.name}`)}
                      resizable
                    />
                    <Element
                      type={ItemTypes.KITCHEN.name}
                      width={ItemTypes.KITCHEN.width}
                      height={ItemTypes.KITCHEN.height}
                      icon={<Kitchen className="icon" />}
                      name={t(`pageFloorPlan:${ItemTypes.KITCHEN.name}`)}
                      resizable
                    />
                    <Element
                      type={ItemTypes.ENTRANCE.name}
                      width={ItemTypes.ENTRANCE.width}
                      height={ItemTypes.ENTRANCE.height}
                      icon={<Exit className="icon" />}
                      name={t(`pageFloorPlan:${ItemTypes.ENTRANCE.name}`)}
                    />
                    <Element
                      type={ItemTypes.WC.name}
                      width={ItemTypes.WC.width}
                      height={ItemTypes.WC.height}
                      icon={<WC className="icon" />}
                      name={t(`pageFloorPlan:${ItemTypes.WC.name}`)}
                    />
                  </div>
                </div>
                <div className="center-floorplan-column">
                  <div className="column-top">
                    <span className="current-elements">
                      {`${t('pageSeatingAreas:tables')}: `}
                      {`(${salons.selectedSalon.elements.filter((element) => (
                        element.type === ItemTypes.SQUARE.name
                        || element.type === ItemTypes.CIRCLE.name
                        || element.type === ItemTypes.RECTANGLE.name
                      )).length})`}
                    </span>
                    <span className="zoom-options">
                      {t('pageFloorPlan:zoom')}
                      <span className="zoom-scale">
                        {zoom.scale}
                      </span>
                      <button
                        type="button"
                        onClick={() => setZoom({
                          scale: parseFloat((zoom.scale - 0.2).toFixed(2)),
                          step: zoom.step - 1,
                        })}
                        className="zoom-out"
                        disabled={zoom.scale === 0.2}
                      >
                        -
                      </button>
                      <button
                        type="button"
                        onClick={() => setZoom({
                          scale: parseFloat((zoom.scale + 0.2).toFixed(2)),
                          step: zoom.step + 1,
                        })}
                        className="zoom-in"
                        disabled={zoom.scale === 2}
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <Canvas
                    setChanged={setChanged}
                    changed={changed}
                    salons={salons}
                    elements={salons.selectedSalon.elements}
                    static={salons.selectedSalon.static}
                    resizable={salons.selectedSalon.resizable}
                    zoom={zoom}
                    minCanvasSizes={minCanvasSizes}
                  />
                </div>
                <div className="right-floorplan-column">
                  {salons.selectedElements[0] && salons.selectedSalon.elements && (
                    <ElementDetails
                      setChanged={setChanged}
                      salons={salons}
                      element={salons.selectedSalon.elements.find((element) => (
                        element.id === salons.selectedElements[0]
                      ))}
                      onDeleteClicked={(elementId) => {
                        salons.elements.remove(elementId);
                        setChanged(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </DndProvider>
          ) : (
            <h2>{t('pageFloorPlan:mobile_message')}</h2>
          )}
        </>
      ) : t('loading')}
    </div>
  );
}
