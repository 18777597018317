const ItemTypes = {
  SQUARE: {
    name: 'SQUARE',
    width: 64,
    height: 64,
    minCapacity: 2,
    maxCapacity: 4,
  },
  CIRCLE: {
    name: 'CIRCLE',
    width: 64,
    height: 64,
    minCapacity: 4,
    maxCapacity: 8,
  },
  RECTANGLE: {
    name: 'RECTANGLE',
    width: 90,
    height: 64,
    minCapacity: 6,
    maxCapacity: 8,
  },
  BAR: {
    name: 'BAR',
    width: 74,
    height: 52,
  },
  KITCHEN: {
    name: 'KITCHEN',
    width: 74,
    height: 52,
  },
  ENTRANCE: {
    name: 'ENTRANCE',
    width: 52,
    height: 52,
  },
  WC: {
    name: 'WC',
    width: 52,
    height: 52,
  },
};

export default ItemTypes;
