import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CountryContext from '../../../react-web-ui/contexts/CountryContext';

export default function Services({
  specialOffers,
  tasting,
  event,
  slug,
}) {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);

  if (!countryContext.country) return null;

  return (
    <div className="services">
      {specialOffers.length > 0 && (
        <div className="discount-offers">
          <a
            href={`https://dineout.${countryContext.country.code.toLowerCase()}/special-offers/${slug}`}
            className="btn btn-outline btn-discount"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="special-offers"
          >
            {t('pageWidget:special_offers')}
          </a>
        </div>
      )}
      {tasting.length > 0 && (
        <div className="tasting-offers">
          <a
            href={`https://dineout.${countryContext.country.code.toLowerCase()}/book-tasting/${slug}`}
            className="btn btn-outline btn-tasting"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="tasting-menus"
          >
            {t('pageWidget:book_tasting')}
          </a>
        </div>
      )}
      {event.length > 0 && (
        <div className="event-offers">
          <a
            href={`https://dineout.${countryContext.country.code.toLowerCase()}/events/${slug}`}
            className="btn btn-outline btn-event"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="special-events"
          >
            {t('pageWidget:book_event')}
          </a>
        </div>
      )}
    </div>
  );
}

Services.propTypes = {
  specialOffers: PropTypes.oneOfType([PropTypes.array]),
  tasting: PropTypes.oneOfType([PropTypes.array]),
  event: PropTypes.oneOfType([PropTypes.array]),
  slug: PropTypes.string,
};

Services.defaultProps = {
  specialOffers: [],
  tasting: [],
  event: [],
  slug: '',
};
