import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
* Value select
* @param label
* @param inputLabel
* @param values
* @param minValue
* @param unit
* @param amount
* @param setAmount
* @returns {*}
* @constructor
*/
export default function ValueSelect({
  label,
  inputLabel,
  values,
  minValue,
  unit,
  amount,
  setAmount,
}) {
  const [amountInput, setAmountInput] = useState('');

  return (
    <div className="group select-value-block">
      <p className="label">{label}</p>
      <div className="value-select-wrapper">
        {values.map((value) => (
          <button
            key={value}
            className={`value-select ${amount === value && 'selected'}`}
            type="button"
            onClick={() => setAmount(value)}
          >
            {`${value}${unit}`}
          </button>
        ))}
        <div className={`value-select form-group ${amount === amountInput && 'selected'}`}>
          <input
            placeholder={inputLabel}
            type="number"
            min={minValue}
            value={amountInput}
            onChange={(e) => {
              setAmountInput(e.target.value);
              setAmount(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

ValueSelect.propTypes = {
  label: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([PropTypes.array]).isRequired,
  minValue: PropTypes.number,
  unit: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setAmount: PropTypes.func.isRequired,
};

ValueSelect.defaultProps = {
  minValue: 5,
};
