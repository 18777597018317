import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';

import ItemTypes from './ItemTypes';
import { randomId } from '../../utilities/constants';
import { checkBounds, getElementOnCanvasSize, scaledCorrections } from './helpers';
import { ReactComponent as Resize } from '../../assets/images/icons/Resize.svg';
import { ReactComponent as Bar } from '../../assets/images/icons/Bar.svg';
import { ReactComponent as Kitchen } from '../../assets/images/icons/Kitchen.svg';
import { ReactComponent as Exit } from '../../assets/images/icons/Exit.svg';
import { ReactComponent as WC } from '../../assets/images/icons/WC.svg';

const Canvas = ({
  salons,
  elements,
  setChanged,
  zoom,
  minCanvasSizes,
  staticElements,
  onClick,
  highlight,
}) => {
  const { t } = useTranslation();
  const gridRef = useRef();
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
  });

  // eslint-disable-next-line no-unused-vars
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [
      ItemTypes.SQUARE.name,
      ItemTypes.CIRCLE.name,
      ItemTypes.RECTANGLE.name,
      ItemTypes.ENTRANCE.name,
      ItemTypes.WC.name,
      ItemTypes.BAR.name,
      ItemTypes.KITCHEN.name,
    ],
    drop(item, monitor) {
      const offset = monitor.getClientOffset();
      const cursorX = monitor.getInitialClientOffset().x - monitor.getInitialSourceClientOffset().x;
      const cursorY = monitor.getInitialClientOffset().y - monitor.getInitialSourceClientOffset().y;
      const elementCanvasSize = getElementOnCanvasSize(item.width, item.height);
      const { currentX, currentY } = checkBounds(
        offset.x - gridRef.current.offsetLeft - cursorX,
        offset.y - gridRef.current.offsetTop - cursorY,
      );
      const bookable = item.type === ItemTypes.SQUARE.name
        || item.type === ItemTypes.CIRCLE.name
        || item.type === ItemTypes.RECTANGLE.name;
      const newId = randomId();
      salons.elements.create({
        ...item,
        ...elementCanvasSize,
        x: (currentX + gridRef.current.scrollLeft) / zoom.scale,
        y: (currentY + gridRef.current.scrollTop) / zoom.scale,
        exists: false,
        id: newId,
        name: item.name || t('pageFloorPlan:table_name_placeholder'),
        bookable,
      });
      setChanged(true);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // console.log(highlight.tables.filter((id) => id).includes(element.id));

  useEffect(() => {
    const {
      marginLeftCorrection,
      marginTopCorrection,
    } = scaledCorrections(
      gridRef.current.clientWidth,
      gridRef.current.clientHeight,
      minCanvasSizes.width,
      minCanvasSizes.height,
      zoom,
    );
    setPosition({
      top: (10 * zoom.step) / zoom.scale,
      left: (10 * zoom.step) / zoom.scale,
      marginLeft: marginLeftCorrection,
      marginTop: marginTopCorrection,
    });
    setContainerSize({
      width: 100 / zoom.scale,
      height: 100 / zoom.scale,
    });
  }, [zoom]);

  return (
    <div
      className="canvas-container"
      ref={gridRef}
    >
      <div
        id="floorplan-grid"
        className="floorplan-grid"
        ref={drop}
        style={{
          transform: `scale(${zoom.scale})`,
          top: `${position.top}%`,
          left: `${position.left}%`,
          width: `${containerSize.width}%`,
          height: `${containerSize.height}%`,
          minWidth: `${minCanvasSizes.width}px`,
          minHeight: `${minCanvasSizes.height}px`,
          marginLeft: `${position.marginLeft}px`,
          marginTop: `${position.marginTop}px`,
        }}
      >
        {elements.map((element) => {
          let activeElement = salons.selectedElements.includes(element.id);
          if (highlight && highlight.tables) {
            activeElement = highlight.tables.map(({ id }) => id).includes(element.id);
          }
          return (
            <Rnd
              key={element.id}
              disableDragging={staticElements}
              enableResizing={{
                top: staticElements ? !staticElements : element.resizable,
                right: staticElements ? !staticElements : element.resizable,
                bottom: staticElements ? !staticElements : element.resizable,
                left: staticElements ? !staticElements : element.resizable,
                topRight: staticElements ? !staticElements : element.resizable,
                bottomRight: staticElements ? !staticElements : element.resizable,
                bottomLeft: staticElements ? !staticElements : element.resizable,
                topLeft: staticElements ? !staticElements : element.resizable,
              }}
              scale={zoom.scale}
              className={`
                ${activeElement ? 'active-wrapper' : ''}
                ${element.type === ItemTypes.BAR.name
                || element.type === ItemTypes.KITCHEN.name
                || element.type === ItemTypes.ENTRANCE.name
                || element.type === ItemTypes.WC.name ? 'lower-z' : ''}
              `}
              size={{ width: element.width, height: element.height }}
              position={{ x: element.x, y: element.y }}
              onDragStop={(e, d) => {
                const { currentX, currentY } = checkBounds(d.x, d.y);
                salons.elements.update({
                  ...element,
                  x: currentX,
                  y: currentY,
                });
                if (element.x !== currentX || element.y !== currentY) {
                  setChanged(true);
                }
              }}
              // eslint-disable-next-line no-shadow
              onResizeStop={(e, direction, ref, delta, position) => {
                const resizedWidth = parseInt(ref.style.width.replace('px', ''), 10);
                const resizedHeight = parseInt(ref.style.height.replace('px', ''), 10);
                const { currentX, currentY } = checkBounds(position.x, position.y);
                let widthCorrection = 0;
                let heightCorrection = 0;
                if (position.x < 0) {
                  widthCorrection = position.x;
                }
                if (position.y < 0) {
                  heightCorrection = position.y;
                }
                salons.elements.update({
                  ...element,
                  width: resizedWidth + widthCorrection,
                  height: resizedHeight + heightCorrection,
                  x: position.x < 0 ? currentX : position.x,
                  y: position.y < 0 ? currentY : position.y,
                });
                setChanged(true);
              }}
              onClick={() => onClick(element.id)}
            >
              <div
                className={`
                    canvas-element
                    ${activeElement ? 'active' : ''}
                    size-${element.size}
                    ${element.type === ItemTypes.BAR.name || element.type === ItemTypes.KITCHEN.name ? 'bordered-medium' : ''}
                    ${element.type === ItemTypes.ENTRANCE.name || element.type === ItemTypes.WC.name ? 'bordered-light' : ''}
                  `}
              >
                {element.resizable && (<Resize className="resize-icon" />)}
                <span className="title">
                  {element.type === ItemTypes.BAR.name && (<Bar className="icon" />)}
                  {element.type === ItemTypes.KITCHEN.name && (<Kitchen className="icon" />)}
                  {element.type === ItemTypes.ENTRANCE.name && (<Exit className="icon" />)}
                  {element.type === ItemTypes.WC.name && (<WC className="icon" />)}
                  {element.type === ItemTypes.BAR.name
                      || element.type === ItemTypes.KITCHEN.name
                      || element.type === ItemTypes.ENTRANCE.name
                      || element.type === ItemTypes.WC.name ? (
                      t(`pageFloorPlan:${element.type}`)
                    ) : element.name}
                </span>
                {element.minCapacity && element.minCapacity !== 0 && element.maxCapacity !== 0 ? (
                  <div
                    className={`
                    table-element element-${element.type.toLowerCase()}
                    ${element.bookable ? '' : 'not-bookable'}
                    `}
                  >
                    <div className="table-capacity">
                      <strong>
                        Min
                        <br />
                        <span>{element.minCapacity}</span>
                      </strong>
                      <strong>
                        Max
                        <br />
                        <span>{element.maxCapacity}</span>
                      </strong>
                    </div>
                  </div>
                ) : null}
              </div>
            </Rnd>
          );
        })}
      </div>
    </div>
  );
};

Canvas.propTypes = {
  salons: PropTypes.oneOfType([PropTypes.object]).isRequired,
  elements: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setChanged: PropTypes.func.isRequired,
  zoom: PropTypes.oneOfType([PropTypes.object]).isRequired,
  minCanvasSizes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  highlight: PropTypes.oneOfType([PropTypes.object]),
  staticElements: PropTypes.bool,
  onClick: PropTypes.func,
};

Canvas.defaultProps = {
  staticElements: false,
  onClick: () => {},
  highlight: {},
};

export default Canvas;
