import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import Button from '../../react-web-ui/components/Button';
import { ReactComponent as Tick } from '../../assets/images/icons/Tick.svg';
import { ReactComponent as Plus } from '../../assets/images/icons/PlusOnly.svg';
import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import SubscriptionForm from '../../components/SubscriptionForm/SubscriptionForm';
import useFeatures from '../../hooks/useFeatures';
import Spinner from '../../react-web-ui/components/Spinner';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Subscriptions() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const countryContext = useContext(CountryContext);
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);
  const [subscription, setSubscription] = useState(null);
  const [enterBillingInfo, setEnterBillingInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const {
    pricingBlocks,
    pricePeriod,
    setPricePeriod,
  } = useFeatures();

  const handleSubscription = (plan) => {
    const params = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      plan,
      price: pricePeriod[plan.toLowerCase()],
    };
    setLoading(true);

    if (plan === 'FREE') {
      params.billing_address = {
        country: countryContext.country.code,
      };
      params.price = 'MONTHLY';
    }

    (new RequestService('manager/subscription/subscribe'))
      .setParams(params)
      .send()
      .then(() => {
        restaurantContext.setPlan(plan);
        setTimeout(() => {
          toast.success(t('success:subscribe_success'));
          history.push('/manage/restaurant-profile');
        }, 200);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_subscribe'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const freeSubscription = () => {
    const params = {
      user_id: userContext.user.id,
      billing_address: {
        country: countryContext.country.code,
      },
    };
    setLoading(true);

    (new RequestService('manager/users/update'))
      .setParams(params)
      .send()
      .then((response) => {
        userContext.setUser(response.data);
        handleSubscription('FREE');
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_update_user'));
      });
  };

  useEffect(() => {
    if (!userContext.user) return;

    (new RequestService('manager/subscription/setup-intent'))
      .setParams({
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setClientSecret(response.data.intent.client_secret);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
      });
  }, [userContext.user]);

  useEffect(() => {
    if (location.hash === '') setEnterBillingInfo(false);
    if (location.hash !== '' && !enterBillingInfo) history.push('/manage/subscriptions');
  }, [location, enterBillingInfo]);

  useEffect(() => {
    if (!restaurantContext.restaurant?.subscriptions) return;
    setPricePeriod({
      ...pricePeriod,
      [restaurantContext.restaurant?.subscriptions[0]?.plan.toLowerCase()]:
        restaurantContext.restaurant?.subscriptions[0]?.price,
    });
  }, [restaurantContext.restaurant]);

  const trialSubscriptions = restaurantContext.restaurant?.subscriptions.filter(
    (sub) => sub.trialEndsAt,
  ) || 0;

  return (
    <div className="inner-wrapper">
      <div id="pricing-plan" className="section">
        {enterBillingInfo ? (
          <>
            {clientSecret && (
              <Elements
                stripe={stripePromise}
                locale={i18n.language}
                options={{
                  clientSecret,
                }}
              >
                <h2 className="section-title">
                  <strong>{t('pageBilling:title')}</strong>
                </h2>
                <div className="grid bigger-gap">
                  <div className="grid-col-4">
                    <div className="pricing-block vertical">
                      <span className="ribbon-above">
                        {t('pageSubscriptions:youre_unlocking')}
                      </span>
                      <div className="shadow-block">
                        <header className="pricing-header">
                          <h3>{subscription}</h3>
                          <div>
                            <p className="plan-price">
                              {pricingBlocks.filter(
                                (block) => block.value === subscription,
                              )[0].price}
                              <small>{t('selfOnboarding:month')}</small>
                            </p>
                            {pricePeriod[subscription.toLowerCase()] === 'MONTHLY' ? (
                              <p className="billing-type">{t('pageSubscriptions:billed_monthly')}</p>
                            ) : (
                              <p className="billing-type">{t('pageSubscriptions:billed_annually')}</p>
                            )}
                          </div>
                        </header>
                        <div className="pricing-content">
                          {pricingBlocks.map((block, index) => block.value === subscription && (
                            <React.Fragment key={block.value}>
                              {block.value === 'PRO' && (
                                <div className="pricing-option">
                                  <Tick className="icon" />
                                  <p>
                                    {t('pageSubscriptions:all')}
                                    {' '}
                                    <strong className="text-accent">LITE</strong>
                                    {' '}
                                    {t('pageSubscriptions:features')}
                                  </p>
                                </div>
                              )}
                              {block.value === 'PREMIUM' && (
                                <div className="pricing-option">
                                  <Tick className="icon" />
                                  <p>
                                    {t('pageSubscriptions:all')}
                                    {' '}
                                    <strong className="text-accent">PRO</strong>
                                    {' '}
                                    {t('pageSubscriptions:features')}
                                  </p>
                                </div>
                              )}
                              {block.features.map((feature) => (
                                <div key={feature} className="pricing-option">
                                  {block.value === 'FREE' ? (
                                    <Tick className="icon" />
                                  ) : (
                                    <Plus className="icon" />
                                  )}
                                  <p>{feature}</p>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                        <footer>
                          <div className="flex-between align-center">
                            <h4>{t('pageSubscriptions:sub_total')}</h4>
                            <h4 className="text-accent">
                              {pricePeriod[subscription.toLowerCase()] === 'MONTHLY' ? (
                                parseInt(
                                  pricingBlocks.filter(
                                    (block) => block.value === subscription,
                                  )[0].price,
                                  10,
                                )
                              ) : (
                                parseInt(
                                  pricingBlocks.filter(
                                    (block) => block.value === subscription,
                                  )[0].price,
                                  10,
                                ) * 12
                              )}
                              {pricingBlocks.filter(
                                (block) => block.value === subscription,
                              )[0].price.replace(/[0-9]/g, '')}
                            </h4>
                          </div>
                          <div className="flex-between align-center">
                            <p>{t('pageSubscriptions:billing_vat')}</p>
                            <p>
                              {`${process.env.REACT_APP_STRIPE_TAX_VALUE}%`}
                            </p>
                          </div>
                          <div className="flex-between align-center">
                            <h4>{t('pageSubscriptions:total')}</h4>
                            <p className="plan-price text-accent">
                              {pricePeriod[subscription.toLowerCase()] === 'MONTHLY' ? (
                                parseFloat(parseInt(
                                  pricingBlocks.filter(
                                    (block) => block.value === subscription,
                                  )[0].price,
                                  10,
                                )
                                + parseInt(
                                  pricingBlocks.filter(
                                    (block) => block.value === subscription,
                                  )[0].price,
                                  10,
                                ) * (process.env.REACT_APP_STRIPE_TAX_VALUE / 100)).toFixed(2)
                              ) : (
                                parseFloat(parseInt(
                                  pricingBlocks.filter(
                                    (block) => block.value === subscription,
                                  )[0].price,
                                  10,
                                ) * 12
                                + parseInt(
                                  pricingBlocks.filter(
                                    (block) => block.value === subscription,
                                  )[0].price,
                                  10,
                                ) * 12 * (process.env.REACT_APP_STRIPE_TAX_VALUE / 100)).toFixed(2)
                              )}
                              {pricingBlocks.filter(
                                (block) => block.value === subscription,
                              )[0].price.replace(/[0-9]/g, '')}
                            </p>
                          </div>
                          {trialSubscriptions.length === 0 && (
                            <p className="text-gray">
                              <Trans i18nKey="pageSubscriptions:amount_charged" count={moment().add(30, 'days').format('DD.MM.YYYY')}>
                                Тhe amount  you will be charged after your trial ends on
                                {' '}
                                <strong>{moment().add(30, 'days').format('DD.MM.YYYY')}</strong>
                                , unless you cancel before that.
                              </Trans>
                            </p>
                          )}
                        </footer>
                      </div>
                    </div>
                    {trialSubscriptions.length === 0 && (
                      <p className="free-trial-text">
                        <Trans i18nKey="pageSubscriptions:free_trial">
                          Your plan comes with
                          {' '}
                          <strong className="text-accent">30 day</strong>
                          {' '}
                          free trial.
                        </Trans>
                      </p>
                    )}
                  </div>
                  <div className="grid-col-4">
                    <SubscriptionForm
                      clientSecret={clientSecret}
                      plan={subscription}
                      price={pricePeriod[subscription.toLowerCase()]}
                    />
                  </div>

                </div>
              </Elements>
            )}
          </>
        ) : (
          <>
            <div className="subscriptions-title">
              <h2 className="section-title">
                <strong>
                  {t('pageSubscriptions:choose_a_plan', { restaurant: restaurantContext.restaurant?.name })}
                </strong>
              </h2>
            </div>
            {pricingBlocks.map((block, index) => (
              <div key={block.title} className={`pricing-block horizontal plan-${block.value.toLowerCase()}`}>
                {(restaurantContext.restaurant?.subscriptions[0]?.status === 'active'
                || restaurantContext.restaurant?.subscriptions[0]?.status === 'trialing')
                && (restaurantContext.restaurant?.subscriptions[0]?.plan
                === block.value) ? (
                  <span className="ribbon-above">
                    {t('pageSubscriptions:current_plan')}
                  </span>
                  ) : block.value === 'PRO' && (
                    <span className="ribbon-above">
                      {t('pageSubscriptions:most_popular')}
                    </span>
                  )}
                <div className="shadow-block">
                  <header className="pricing-header">
                    <h3>{block.title}</h3>
                  </header>
                  <div className="pricing-content">
                    {block.value === 'PRO' && (
                      <div className="pricing-option">
                        <Tick className="icon" />
                        <p>
                          {t('pageSubscriptions:all')}
                          {' '}
                          <strong className="text-accent">LITE</strong>
                          {' '}
                          {t('pageSubscriptions:features')}
                        </p>
                      </div>
                    )}
                    {block.value === 'PREMIUM' && (
                      <div className="pricing-option">
                        <Tick className="icon" />
                        <p>
                          {t('pageSubscriptions:all')}
                          {' '}
                          <strong className="text-accent">PRO</strong>
                          {' '}
                          {t('pageSubscriptions:features')}
                        </p>
                      </div>
                    )}
                    {block.features.map((feature) => (
                      <div key={feature} className="pricing-option">
                        {block.value === 'FREE' ? (
                          <Tick className="icon" />
                        ) : (
                          <Plus className="icon" />
                        )}
                        <p>{feature}</p>
                      </div>
                    ))}
                  </div>
                  <footer>
                    {block.value !== 'FREE' && (
                      <button
                        type="button"
                        className={`toggle-status toggle-${block.value.toLowerCase()} ${pricePeriod[block.value.toLowerCase()] !== 'MONTHLY' ? 'active' : ''}`}
                        onClick={() => setPricePeriod({
                          ...pricePeriod,
                          [block.value.toLowerCase()]: pricePeriod[block.value.toLowerCase()] === 'MONTHLY' ? 'YEARLY' : 'MONTHLY',
                        })}
                      >
                        <span className="seating-option">{t('selfOnboarding:monthly')}</span>
                        <span className="seating-option">{t('selfOnboarding:yearly')}</span>
                      </button>
                    )}
                    <p className="plan-price">
                      {block.price}
                      {block.value !== 'FREE' && (<small>{t('selfOnboarding:month')}</small>)}
                    </p>
                    <Button
                      text={loading ? (
                        <>
                          <Spinner />
                          {t('loading')}
                        </>
                      ) : (
                        <>
                          {
                            block.value === 'FREE'
                            || trialSubscriptions.length > 0
                              ? t('select_and_continue')
                              : t('pageSubscriptions:try_for_free')
                          }
                        </>
                      )}
                      classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
                      onClick={() => {
                        setSubscription(block.value);
                        if (block.value === 'PRO' || block.value === 'PREMIUM') {
                          history.push(`#billing-${block.value.toLocaleLowerCase()}`);
                          setEnterBillingInfo(true);
                        } else if (block.value === 'FREE') {
                          freeSubscription();
                        }
                      }}
                      disabled={
                        ((restaurantContext.restaurant?.subscriptions[0]?.status === 'active'
                          || restaurantContext.restaurant?.subscriptions[0]?.status === 'trialing')
                          && (restaurantContext.restaurant?.subscriptions[0]?.plan === block.value)
                          && (restaurantContext.restaurant?.subscriptions[0]?.price
                          === pricePeriod[block.value.toLowerCase()]))
                        || loading
                      }
                    />
                  </footer>
                </div>
              </div>
            ))}
            {trialSubscriptions.length === 0 && (
              <p>{t('pageSubscriptions:all_plans_trial')}</p>
            )}
            <p>{t('selfOnboarding:pricing_vat')}</p>
          </>
        )}
      </div>
    </div>
  );
}
