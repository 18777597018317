import React from 'react';
import { useTranslation } from 'react-i18next';

import heroImg from '../assets/images/hero-image-3.jpg';
import grayC from '../assets/images/gray-c.svg';
import illustration1 from '../assets/images/illustrations/illustration1.svg';
import illustration2 from '../assets/images/illustrations/illustration2.svg';
import illustration3 from '../assets/images/illustrations/illustration3.svg';
import illustration4 from '../assets/images/illustrations/illustration4.svg';
import PartnersSectionSlider from '../components/PartnersSectionSlider';
import ButtonLink from '../react-web-ui/components/ButtonLink';

export default function WhatWeServe() {
  const { t } = useTranslation();

  return (
    <>
      <div className="hero-section">
        <img src={heroImg} alt="" />
        <div className="inner-wrapper">
          <div className="hero-section-content">
            <h2>{t('selfOnboarding:flexible_solutions')}</h2>
            <p>{t('selfOnboarding:flexible_solutions_text')}</p>
            <ButtonLink
              text={t('selfOnboarding:start_now')}
              classes="btn-accent"
              href="/join"
            />
          </div>
        </div>
      </div>
      <div className="big-inner-wrapper">
        <PartnersSectionSlider />
      </div>
      <div className="inner-wrapper alternating-blocks-wrapper">
        <div className="alternating-blocks">
          <div className="img-wrapper with-circle">
            <div className="c-shape-wrapper">
              <img src={grayC} alt="" />
            </div>
            <img className="illustration" src={illustration1} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_1_title')}</h2>
            <h3>{t('selfOnboarding:section_1_sub_title')}</h3>
            <p>{t('selfOnboarding:section_1_text')}</p>
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper with-circle">
            <div className="c-shape-wrapper" style={{ transform: 'rotate(225deg)' }}>
              <img src={grayC} alt="" />
            </div>
            <img className="illustration" src={illustration2} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_2_title')}</h2>
            <h3>{t('selfOnboarding:section_2_sub_title')}</h3>
            <p>{t('selfOnboarding:section_2_text')}</p>
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper with-circle">
            <div className="c-shape-wrapper" style={{ transform: 'rotate(41deg)' }}>
              <img src={grayC} alt="" />
            </div>
            <img className="illustration" src={illustration3} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_3_title')}</h2>
            <h3>{t('selfOnboarding:section_3_sub_title')}</h3>
            <p>{t('selfOnboarding:section_3_text')}</p>
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper with-circle">
            <div className="c-shape-wrapper" style={{ transform: 'rotate(291deg)' }}>
              <img src={grayC} alt="" />
            </div>
            <img className="illustration" src={illustration4} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_4_title')}</h2>
            <h3>{t('selfOnboarding:section_4_sub_title')}</h3>
            <p>{t('selfOnboarding:section_4_text')}</p>
          </div>
        </div>
      </div>
    </>
  );
}
