import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import UserContext from '../../react-web-ui/contexts/UserContext';
import RequestService from '../../networking/RequestService';
import RestaurantContext from '../../contexts/RestaurantContext';
import PageHeading from '../../react-web-ui/components/PageHeading';
import GuestsTable from '../../components/GuestsTable';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import Checkbox from '../../react-web-ui/components/FormElement/Checkbox';

let getRequest = null;

export default function Guests() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);
  const [guests, setGuests] = useState([]);
  const [addGuestsToList, setAddGuestsToList] = useState(
    restaurantContext.restaurant?.saveGuestsAutomatically,
  );
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  useEffect(() => {
    if (!userContext.user || !restaurantContext.restaurantId) return;
    startListening();
    (new RequestService('host/guests/index'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setGuests(response.data);
      })
      .catch((err) => {
        if (err.key) toast.error(t(`apiErrors:${err.key}`));
        else toast.error(t('apiErrors:unexpected_error'));
      })
      .finally(() => {
        endListening();
      });
  }, [t, userContext.user, restaurantContext.restaurantId]);

  useEffect(() => {
    if (!restaurantContext.restaurant) return;
    setAddGuestsToList(restaurantContext.restaurant?.saveGuestsAutomatically);
  }, [restaurantContext.restaurant]);

  useEffect(() => {
    if (!restaurantContext.restaurant) return;
    if (addGuestsToList === restaurantContext.restaurant?.saveGuestsAutomatically) return;
    if (getRequest !== null) {
      getRequest.source.cancel();
    }
    setTimeout(() => {
      startListening();
      getRequest = (new RequestService('manager/guests/settings'))
        .setParams({
          place_id: restaurantContext.restaurantId,
          user_id: userContext.user.id,
          save_guests_automatically: addGuestsToList,
        });

      getRequest.send()
        .then((response) => {
          restaurantContext.setRestaurant(response.data);
        })
        .catch((err) => {
          if (err.key) toast.error(t(`apiErrors:${err.key}`));
          else toast.error(t('apiErrors:unexpected_error'));
        })
        .finally(() => {
          endListening();
        });
    }, 500);
  }, [addGuestsToList]);

  const exportGuests = () => {
    if (!userContext.user || !restaurantContext.restaurantId) return;
    startListening();
    (new RequestService('manager/guests/export'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then(() => {
        toast.success(t('pageGuests:export_to_email', { email: userContext.user.email }));
      })
      .catch((err) => {
        if (err.key) toast.error(t(`apiErrors:${err.key}`));
        else toast.error(t('apiErrors:unexpected_error'));
      })
      .finally(() => {
        endListening();
      });
  };

  return (
    <div className="inner-wrapper">
      <PageHeading
        classes="page-heading-guests"
        title={(
          <>
            {t('pageGuests:title')}
            <button
              type="button"
              className="btn-link"
              onClick={exportGuests}
            >
              {t('pageGuests:export')}
            </button>
          </>
        )}
        content={(
          <></>
        )}
      />
      <Checkbox
        id="add_guests_to_list"
        text={t('pageGuests:add_guests_to_list')}
        value={`${addGuestsToList}`}
        checked={addGuestsToList}
        check={() => setAddGuestsToList(!addGuestsToList)}
        className="guest-list-checkbox"
      />
      <GuestsTable
        guests={guests}
      />
    </div>
  );
}
