import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useOutsideClick from '../../../react-web-ui/hooks/useOutsideClick';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import RestaurantContext from '../../../contexts/RestaurantContext';

export default function DropDownBox({ links, clickedLink, setActive }) {
  const { t } = useTranslation();
  const ref = useRef();
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);

  useOutsideClick(ref, () => {
    setActive(0);
  });

  return (
    <ul ref={ref} className="dd-box">
      {links.map((link) => (
        <li key={link.name}>
          {link.type === 'external' ? (
            <a href={link.href}>
              {link.name}
            </a>
          ) : (
            <>
              {link.type === 'logout' ? (
                <button
                  type="button"
                  className="reset-btn"
                  onClick={() => {
                    userContext.setUser(null);
                    userContext.setUserId(null);
                    restaurantContext.setRestaurant(null);
                    restaurantContext.setRestaurantId(null);
                    toast.success(t('success:logout_success'));
                    clickedLink();
                  }}
                >
                  {t('logout')}
                </button>
              ) : (
                <NavLink
                  to={link.href}
                  onClick={clickedLink}
                >
                  {link.name}
                </NavLink>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

DropDownBox.propTypes = {
  links: PropTypes.oneOfType([PropTypes.array]).isRequired,
  /** onClick function for the links */
  clickedLink: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
};
