/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { checkVAT, countries } from 'jsvat/lib/commonjs';

import validate from './validation';
import illustration6 from '../../assets/images/illustrations/illustration6.svg';
import Checkbox from '../../react-web-ui/components/FormElement/Checkbox';
import useForm from '../../react-web-ui/hooks/useForm';
import Button from '../../react-web-ui/components/Button';
import Spinner from '../../react-web-ui/components/Spinner';
import FormElement from '../../react-web-ui/components/FormElement';
import countriesSelect from '../../utilities/countries';
import RequestService from '../../networking/RequestService';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import CountryContext from '../../react-web-ui/contexts/CountryContext';

export default function Terms() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const countryContext = useContext(CountryContext);
  const [vatText, setVatText] = useState('');

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    setLoading(true);
    const params = {
      place_id: restaurantContext.restaurantId,
      user_id: userContext.user.id,
      bank_details: {
        beneficiary: values.beneficiary,
        iban: values.iban,
        bic: values.swift,
      },
      invoice_details: {
        company_name: values.billing_company_name,
        country: values.billing_country,
        city: values.billing_city,
        address_line_1: values.billing_line1,
        address_line_2: values.billing_line2,
        vat_id: values.billing_vat,
      },
    };
    (new RequestService('manager/places/enable-gift-cards'))
      .setParams(params)
      .send()
      .then((response) => {
        restaurantContext.setRestaurant(response.data);
        toast.success(t('success:accepted_giftcards_success'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_accept_giftcards'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!restaurantContext.restaurant || !restaurantContext.restaurant.payoutDetails) return;
    if (!restaurantContext.restaurant.payoutDetails.bankDetails
      || !restaurantContext.restaurant.payoutDetails.invoiceDetails) return;
    setValues({
      beneficiary: restaurantContext.restaurant.payoutDetails.bankDetails.beneficiary,
      iban: restaurantContext.restaurant.payoutDetails.bankDetails.iban,
      swift: restaurantContext.restaurant.payoutDetails.bankDetails.bic,
      billing_company_name: restaurantContext.restaurant.payoutDetails.invoiceDetails.companyName,
      billing_country: restaurantContext.restaurant.payoutDetails.invoiceDetails.country,
      billing_city: restaurantContext.restaurant.payoutDetails.invoiceDetails.city,
      billing_line1: restaurantContext.restaurant.payoutDetails.invoiceDetails.addressLine1,
      billing_line2: restaurantContext.restaurant.payoutDetails.invoiceDetails.addressLine2,
      billing_vat: restaurantContext.restaurant.payoutDetails.invoiceDetails.vatId,
    });

    if (restaurantContext.restaurant.payoutDetails.invoiceDetails.vatId) {
      setVatText(restaurantContext.restaurant.payoutDetails.invoiceDetails.vatId);
    }
  }, [restaurantContext.restaurant]);

  useEffect(() => {
    if (!countryContext.country.code || vatText !== '') return;
    setVatText(countryContext.country.code);
  }, [countryContext.country.code]);

  useEffect(() => {
    if (vatText === '') return;
    setValues({
      ...values,
      billing_vat: vatText,
    });
    if (!checkVAT(vatText, countries).isValid) {
      setValues({
        ...values,
        billing_vat: 'invalid',
      });
    }
  }, [vatText]);

  return (
    <div className="inner-wrapper">
      <div className="terms-heading">
        {i18n.language === 'en' && (
          <div className="text-wrapper">
            <h2>GIFT CARDS</h2>
            <p>At Dineout we sell our own gift cards which are widely popular among HRs of businesses of varying sizes and types. Our team works with partnering restaurants to create monthly promotions so that users of gift cards have additional reasons to use them at a specific place. Through our corporate network every month we reach thousands of avid restaurant goers and promote your restaurant among them for free.</p>
            <p>We strongly encourage you to benefit from accepting the Dineout Gift Cards.</p>
          </div>
        )}
        {i18n.language === 'pl' && (
          <div className="text-wrapper">
            <h2>Karty Podarunkowe:</h2>
            <p>W Dineout sprzedajemy nasze własne karty podarunkowe, które cieszą się dużą popularnością wśród HR-owców firm różnej wielkości i typu. Nasz zespół współpracuje z partnerskimi restauracjami tworząc comiesięczne promocje, tak aby posiadacze kart podarunkowych mieli dodatkowe powody do wykorzystania ich w polecanym przez nas miejscu. Poprzez naszą sieć korporacyjną co miesiąc docieramy do tysięcy zapalonych bywalców restauracji i promujemy wśród nich Państwa restaurację zupełnie za darmo.</p>
            <p>Gorąco zachęcamy do akceptacji i honorowania naszych Kart Podarunkowych Dineout w waszej restauracji.</p>
          </div>
        )}
        {i18n.language === 'bg' && (
          <div className="text-wrapper">
            <h2>ГИФТ КАРТИ </h2>
            <p>Dineout предлага собствени ваучери за подарък, които имат огромна популярност сред HR-и на компании от различни сектори. Екипът ни непрестанно разработва промоции с партньори, приемащи ваучери за подарък, за да могат именно техните обекти да бъдат предпочетени от потребителите. Без допълнително заплащане, ежемесечно достигате до широката ни мрежа от корпоративни клиенти.</p>
            <p>Горещо препоръчваме на бизнеса Ви да започне да печели, приемайки Dineout ваучерите за подарък.</p>
          </div>
        )}
        <div className="img-wrapper giftcards-img">
          <img src={illustration6} alt="" />
        </div>
      </div>
      {i18n.language === 'en' && (
        <div className="terms-content">
          <h3>Terms and Conditions</h3>
          <p>By clicking the continue button below you are agreeing to the following terms and conditions:</p>
          <h4>1. Gift Cards</h4>
          <p>
            1.1. All Gift Cards issued by Dineout to users are electronic, and contain the following requisites:  value in local currency,  a unique 9 (nine) symbol code and a validity date.
            <br />
            1.2. Dineout Gift Cards can only be applied in reservations generated through our platforms - website and user apps.
            <br />
            1.3. Dineout agrees to honour and pay to you the value of the gift card, less our commission, as described in section 2 below, for any and all reservations with gift cards originating from our platform -website and user apps.
            <br />
            1.4 You understand and agree that you will be notified for all reservations involving Dineout Gift Cards through the Dineout Host or Dineout Host Lite apps and email. Please, ensure that you have downloaded and set up the respective table management app (Dineout Host/ Dineout Host Lite) and provided an email address for reservation confirmations.
            <br />
            1.5. You agree to accept all reservations with Gift Cards and apply it at face value towards the bill of the user. Should the bill exceed the value of the Gift Card, the user must pay the exceeding amount directly to you. Should the Gift Card value exceed the bill, then you are not required to refund the difference to the user.
            <br />
            1.6. You agree to invoice Dineout for the value of the Gift Card.
            <br />
            1.7. You understand and agree that in cases where users request an invoice from you they must only receive an invoice for the amount they pay directly to you.

          </p>
          <h4>2. Commission and payment</h4>
          <p>
            2.1. The commission to Dineout is 10% (ten per cent) of the value of each Gift Card.
            <br />
            2.2 The commission is due only for completed reservations, i.e. reservations honoured by the users.
            <br />
            2.3.  The total value of the order for a Gift Card is transferred by Dineout to your bank account no later than 5 (working days) days after the completed reservation with the applied Gift Card.
            <br />
            2.4 Dineout’s commission is invoiced to you at the end of the calendar month. An invoice will be sent to your email address.
            <br />
            2.5 The commission is deducted by the total value of the order for a Gift Cards.
            <br />
            2.6. Dineout agrees to cover the bank transfer fees.
            <br />
            2.7 You are obliged to pay the invoice within 14 days from the invoice date.
            <br />
            2.8. You may contact your account manager or our support team with any questions or concerns you might have to resolve a specific issue.

          </p>
          <h4>3. Cancelations</h4>
          <p>
            3.1. If you cancel a reservation with an applied Gift Card that is already paid for by the user - 100 % of the price is refunded to the user.
            <br />
            3.2 In case the user cancels a reservation with an applied Gift Card, before the date and time of the booking - - 100 % of the price is refunded to the user.

          </p>
          <h4>
            4. Changes to the terms and conditions

          </h4>
          <p>
            From time to time Dineout may, at its sole discretion, change, modify or update the current terms and conditions to reflect regulatory, security or other market changes. You will be notified via email of any changes to these terms and conditions at least 30 days prior to these changes taking effect.
          </p>
          <h4>5. Termination of this agreement</h4>
          <p>
            5.1. You may withdraw your consent to accept gift cards with a 30 days written notice sent by email to your account manager or to
            {' '}
            <a href="mailto:hello@dineout.pl">hello@dineout.pl</a>
            . You agree to honour any reservations during the termination notice period. Dineout agrees to pay you all the amounts due for reservations with Gift Cards during the termination period.
            <br />
            5.2. Dineout may terminate this agreement with a 30 day written notice sent to the email address provided by you during the registration process. You agree to honour any reservations during the termination notice period. Dineout agrees to pay you all the amounts due for reservations with Gift Cards during the termination period.

          </p>
        </div>
      )}
      {i18n.language === 'pl' && (
        <div className="terms-content">
          <h3>Regulamin korzystania</h3>
          <h4>Definicje</h4>
          <p>
            1.Platforma – oprogramowanie oferowane przez Dineout Poland w którego skład wchodzą; aplikacja Dineout Poland, Dineout Host, Dineout Host Lite oraz portal dla managerów: restaurant.dineout.pl. Platforma ta wspiera procesy sprzedaży kart podarunkowych, menu degustacyjnych, organizacji wydarzeń, rejestracji uczestnictwa oraz sprzedaży biletów przez Klienta.
            <br />
            2.Klient - Podmiot, który spełnia warunki Regulaminu i dokonał akceptacji Regulaminu. Klientami są osoby zarządzające restauracją, mający prawo do jej reprezentowania.
            <br />
            3. Użytkownik - użytkownicy indywidualni oraz użytkownicy biznesowi, którzy korzystają z usług oferowanych przez Dineout, osoby korzystające z aplikacji Dineout Poland.

          </p>
          <h3>Warunki korzystania:</h3>
          <p>
            Klikając poniższy przycisk Kontynuuj, wyrażasz zgodę na następujące warunki:
          </p>
          <h4>1. Karty podarunkowe</h4>
          <p>
            1.1. Wszystkie Karty Podarunkowe wysyłane użytkownikom przez Dineout są elektroniczne i zawierają następujące wymagania: wartość w lokalnej walucie, unikalny 9 (dziewięciocyfrowy) kod i datę ważności.
            <br />
            1.2. Karty podarunkowe Dineout można wykorzystać tylko dokonując rezerwacji za pośrednictwem platformy Dineout, aktywując kartę w systemie i dodając kod karty do rezerwacji.
            <br />
            1.3. Użytkownik rozumie i zgadza się, że będzie powiadamiany o wszystkich rezerwacjach dotyczących Kart podarunkowych Dineout za pośrednictwem aplikacji i poczty e-mail Dineout Host lub Dineout Host Lite. Klient ma obowiązek upewnic się, że pobrał i skonfigurował  aplikację do zarządzania stolikami (Dineout Host/Dineout Host Lite) oraz podał aktualny adres e-mail do potwierdzeń rezerwacji.
            <br />
            1.4. Klient  zgadza się przyjąć wszystkie rezerwacje otrzymywane od Dineout, honorując Karty Podarunkowe.
            <br />
            1.5. Klient rozumie i zgadza się, że w przypadkach, gdy użytkownicy żądają faktury od Klienta, muszą otrzymać fakturę tylko na kwotę, którą płacą bezpośrednio Klientowi.

          </p>
          <h4>2. Prowizja i rozliczanie kart podarunkowych</h4>
          <p>
            2.1. Prowizja dla Dineout wynosi 10% (dziesięć procent) od wartości nominalnej każdej Karty Podarunkowej.
            <br />
            2.2. Prowizja jest naliczana tylko za zrealizowane rezerwacje ( odwołane rezerwacje nie są wliczane).

          </p>
          <h4>3. Odwołanie rezerwacji z kartą podarunkową</h4>
          <p>
            3.1. W przypadku anulowania rezerwacji z kartą podarunkową  przez Klienta, użytkownikowi zwracana jest cała wartość karty podarunkowej na konto Dineout.
            <br />
            3.2. W przypadku anulowania przez Użytkownika rezerwacji z zastosowaną Kartą Podarunkową, przed datą i godziną dokonania rezerwacji, Wartość Karty jest zwracan na konto użytkownika.
            <br />
            3.3. W przypadku nieodwołania rezerwacji z zastosowaną kartą podarunkową przez użytkownika rezerwacja jest traktowana jako zrealizowana.

          </p>
          <h4>
            4. Zmiany w regulaminie
          </h4>
          <p>
            Od czasu do czasu firma Dineout może, według własnego uznania, zmieniać, modyfikować lub aktualizować aktualne warunki w celu odzwierciedlenia zmian regulacyjnych, bezpieczeństwa lub innych zmian rynkowych. Klient zostanie powiadomiony drogą mailową o wszelkich zmianach w niniejszych warunkach co najmniej 30 dni przed wejściem tych zmian w życie.

          </p>
          <h4>
            5. Rozwiązanie niniejszej umowy
          </h4>
          <p>
            5.1. Klient może rozwiązać umowę z Dineout Poland poprzez wysłanie oświadczenia o rozwiązaniu umowy na adres
            {' '}
            <a href="mailto:hello@dineout.pl">hello@dineout.pl</a>
            {' '}
            lub pocztą tradycyjną na adres Dineout Poland, ul. Koszykowa 61, 00-667 Warszawa.
            <br />
            5.2. Adres e-mail z którego wysyłana jest prośba musi być taki sam jak ten, który został użyty do rejestracji konta klienta na platformie Dineout.
            <br />
            5.3. W przypadku Klientów, usunięcie Konta nie jest możliwe w czasie od momentu aktywacji karty podarunkowej w restauracji Klienta w systemie Dineout do zakończenia rezerwacji z kartą podarunkową i rozliczenia wszelkich płatności związanych z kartami podarunkowymi.
            <br />
            5.4. Klient zgadza się honorować wszelkie rezerwacje z kartą podarunkową w okresie wypowiedzenia. Dineout zobowiązuje się zapłacić Klientowi wszystkie kwoty należne za rezerwacje z kartą podarunkową w okresie wypowiedzenia.
            <br />
            5.5. Z uwzględnieniem warunków określonych w punkcie
            {' '}
            <strong>5.3. oraz 5.4.</strong>
            {' '}
            umowa ulega rozwiązaniu po upływie 30 dni od zakończenia rozliczenia kart podarunkowych lub dostarczeniu dokumentu obejmującego oświadczenie o rozwiązaniu umowy.
            <br />
            5.6. Z ważnych przyczyn, w szczególności gdy Klient, po uprzednim wezwaniu przez Dineout Poland nie spełnia swoich zobowiązań umownych wobec platformy, umowa może zostać rozwiązana przez Dineout Poland za wypowiedzeniem, z zachowaniem siedmiodniowego terminu wypowiedzenia.
            <br />
            5.7. Dineout Poland ma prawo do rozwiązania umowy z Klientem ze skutkiem natychmiastowym lub do zablokowania Konta w przypadku naruszenia istotnych postanowień niniejszego Regulaminu.

          </p>
        </div>
      )}
      {i18n.language === 'bg' && (
        <div className="terms-content">
          <h3>Условия за използване</h3>
          <h4>1. Ваучери за подарък</h4>
          <p>
            1.1. Всички ваучери за подарък, издадени от Dineout на потребители са електронни и съдържат следните реквизити: сума в местна валута, уникален код от 9 (девет) символа, дата на валидност.
            <br />
            1.2. Ваучерите за подарък могат да се прилагат само към резервации, направени в системата на Dineout - уебсайт и потребителски приложения.
            <br />
            1.3. Dineout се съгласява да заплати стойността на всеки ваучер за подарък, след приспадане на комисионна, описана в глава 2, използван в системата на Dineout - уебсайт и потребителски приложения.
            <br />
            1.4. Съгласявате се да бъдете информирани за резервации с ваучери за подарък чрез Dineout Host, Dineout Host Lite и имейл. Уверете се, че имате инсталирано и настроено съответното приложение (Dineout Host или Dineout Host Lite), както и че сте предоставили валиден имейл адрес в резервационната система на Dineout.
            <br />
            1.5. Съгласявате се да уважите резервации с приложен ваучер за подарък и да приложите номиналната му стойност като отстъпка към сметката на потребителя. В случаите, когато сметката е с по-висока стойност от номиналната стойност на ваучера за подарък, потребителят заплаща разликата директно на Вас. Когато номиналната стойност на ваучера за подарък е по-голяма от сметката, не сте задължени да върнете разликата на потребителя.
            <br />
            1.6. Съгласявате се да издадете фактура на Dineout за стойността на ваучера за подарък.
            <br />
            1.7. Разбирате и се съгласявате, че ако потребител изиска фактура, е необходимо да предоставите такава само за сумата, платена директно към Вас.

          </p>
          <h4>
            2. Комисионна и заплащане
          </h4>
          <p>
            2.1. Комисионната на Dineout възлиза на 10% (десет процента) от стойността на всяка гифт карта.
            <br />
            2.2. Комисионната е дължима единствено за реално осъществени резервации от страна на потребителите.
            <br />
            2.3. Крайната сума за всяка гифт карта се изплаща по Ваша банкова сметка не по-късно от 5 (пет) дни от датата на резервацията.
            <br />
            2.4. Дайнаут издава една фактура в последния ден от месеца. Фактурата се изпраща на имейл ви.
            <br />
            2.5. Комисионната на Dineout във всяка фактура включва всички резервации с гифт карти през сайта и приложенията ни в съответния месец.
            <br />
            2.6. Dineout се съгласява да покрие транзакционните такси за преводите.
            <br />
            2.7. Вие се съгласявате да платите фактурата в  срок от 14 дни от датата на фактурата.
            <br />
            2.8. Можете да се свържете с контактното си лице или отдела ни за поддръжка за разрешаване на конкретен въпрос.

          </p>
          <h4>
            3. Анулации
          </h4>
          <p>
            3.1. В случай, че Вие анулирате резервация с приложен ваучер за подарък, не получавате възнаграждение за нея.
            <br />
            3.2. В случай, че потребител анулира резервация с приложен ваучер за подарък, преди настъпване на началния ѝ час, не получавате възнаграждение за нея.

          </p>
          <h4>
            4. Промени по условията за използване

          </h4>
          <p>
            Dineout запазва правото си да променя, обновява или модифицира настоящите условия за използване, така че да отразява пазарни, регулаторни или свързани със сигурността промени. За такива промени ще бъдете информирани по имейл, не по-малко от 30 (тридесет) дни, преди влизането им в сила.

          </p>
          <h4>5. Прекратяване на настоящото споразумение</h4>
          <p>
            5.1. Можете да оттеглите съгласието си да приемате резервации с ваучер за подарък, с 30 -дневно писмено предизвестие, изпратено по имейл до вашия контакт в Dineout или на
            {' '}
            <a href="mailto:hello@dineout.bg">hello@dineout.bg</a>
            . Вие се съгласявате да уважавате всички резервации, направени през периода на предизвестие. Dineout се съгласява да заплати всички дължими суми за резервации, направени през периода на предизвестие.
            <br />
            5.2. Dineout може да прекрати това споразумение с 30 -дневно писмено предизвестие на имейл адреса, предоставен от Вас, по време на процеса на регистрация. Вие се съгласявате да уважавате всички резерви, постъпили по време на периода на предизвестието. Dineout се съгласява да заплати всички дължими суми за резервации, направени през периода на предизвестие.

          </p>
        </div>
      )}
      {restaurantContext.restaurant
      && !restaurantContext.restaurant.acceptsGiftCards
      && (
        <>
          <Checkbox
            id="accept_terms"
            text={t('forms:agree_to_gift_cards')}
            value={`${values.accept_terms}`}
            checked={values.accept_terms}
            check={handleChange}
            errors={errors.accept_terms}
          />
          <div className="grid bigger-gap stripe-form">
            <div className="grid-col-5">
              <h2 className="text-accent">{t('forms:bank_details')}</h2>
              <FormElement
                id="beneficiary"
                elementType="input"
                placeholder={t('forms:beneficiary')}
                label={t('forms:beneficiary')}
                value={values.beneficiary || ''}
                errors={errors.beneficiary}
                changed={handleChange}
              />
              <FormElement
                id="iban"
                elementType="input"
                placeholder={t('forms:iban')}
                label={t('forms:iban')}
                value={values.iban || ''}
                errors={errors.iban}
                changed={handleChange}
              />
              <FormElement
                id="swift"
                elementType="input"
                placeholder={t('forms:swift')}
                label={t('forms:swift')}
                value={values.swift || ''}
                errors={errors.swift}
                changed={handleChange}
              />
            </div>
            <div className="grid-col-5">
              <h2 className="text-accent">{t('forms:invoice_details')}</h2>
              <FormElement
                id="billing_company_name"
                elementType="input"
                placeholder={t('forms:selfOnboarding')}
                label={t('forms:selfOnboarding')}
                value={values.billing_company_name || ''}
                errors={errors.billing_company_name}
                changed={handleChange}
              />
              <FormElement
                id="billing_country"
                elementType="select"
                placeholder={t('forms:billing_country')}
                label={t('forms:billing_country')}
                value={values.billing_country || ''}
                errors={errors.billing_country}
                changed={handleChange}
                selectOptions={[
                  {
                    value: '',
                    text: t('forms:billing_country'),
                  },
                  ...countriesSelect,
                ]}
              />
              <FormElement
                id="billing_city"
                elementType="input"
                placeholder={t('forms:restaurant_city')}
                label={t('forms:restaurant_city')}
                value={values.billing_city || ''}
                errors={errors.billing_city}
                changed={handleChange}
              />
              <FormElement
                id="billing_line1"
                elementType="input"
                placeholder={t('forms:billing_line1')}
                label={t('forms:billing_line1')}
                value={values.billing_line1 || ''}
                errors={errors.billing_line1}
                changed={handleChange}
              />
              <FormElement
                id="billing_line2"
                elementType="input"
                placeholder={t('forms:billing_line2')}
                label={t('forms:billing_line2')}
                value={values.billing_line2 || ''}
                errors={errors.billing_line2}
                changed={handleChange}
              />
              <FormElement
                id="billing_vat"
                elementType="input"
                placeholder={t('pageSubscriptions:billing_vat')}
                label={`${t('pageSubscriptions:billing_vat')} (${t('pageSubscriptions:billing_vat_type')})`}
                value={vatText}
                errors={errors.billing_vat}
                changed={(e) => setVatText(e.target.value)}
              />
            </div>
            <div className="grid-col-10">
              <Button
                text={loading ? (
                  <>
                    <Spinner />
                    {t('loading')}
                  </>
                ) : t('selfOnboarding:start_now')}
                classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
                disabled={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
