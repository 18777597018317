import Resource from './Resource';
import Salon from './Salon';

export const TABLE_SQUARE = 'SQUARE';
export const TABLE_CIRCLE = 'CIRCLE';
export const TABLE_RECTANGLE = 'RECTANGLE';

export default class Table extends Resource {
  static collectionKey = 'tables';

  constructor(
    id,
    name,
    people,
    salon,
    x,
    y,
    minCapacity,
    maxCapacity,
    exists,
    type,
    bookable,
    bookingsCnt,
    rotation,
  ) {
    super(id);
    this._name = name;
    this._people = people;
    this._salon = salon;
    this._svg_x = x;
    this._svg_y = y;
    this._min_capacity = minCapacity;
    this._max_capacity = maxCapacity;
    this._exists = exists;
    this._type = type;
    this._bookable = bookable;
    this._bookings_cnt = bookingsCnt;
    this._rotation = rotation;
  }

  get name() {
    return this._name;
  }

  get people() {
    return this._people;
  }

  get salon() {
    return this._salon;
  }

  get x() {
    return this._svg_x;
  }

  get y() {
    return this._svg_y;
  }

  get minCapacity() {
    return this._min_capacity;
  }

  get maxCapacity() {
    return this._max_capacity;
  }

  get exists() {
    return this._exists;
  }

  get type() {
    return this._type;
  }

  get bookable() {
    return this._bookable;
  }

  get bookingsCnt() {
    return this._bookings_cnt;
  }

  get rotation() {
    return this._rotation;
  }

  static fromAPI(object) {
    return new Table(
      object.id,
      object.name,
      object.people,
      Salon.fromAPI(object.salon),
      object.x,
      object.y,
      object.min_capacity,
      object.max_capacity,
      object.exists,
      object.type,
      object.bookable,
      object.bookings_cnt,
      object.rotation,
    );
  }

  plain() {
    return {
      id: this.id,
      name: this.name,
      minCapacity: this.minCapacity,
      maxCapacity: this.maxCapacity,
      type: this.type,
      exists: this.exists,
      rotation: this.rotation,
      bookable: this.bookable,
      bookingsCnt: this.bookingsCnt,
      x: this.x,
      y: this.y,
      salon: this.salon.plain(),
    };
  }
}
