import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Tray } from '../../assets/images/icons/Cuisine.svg';

/**
 * Tasting Menu Block
 * @param currency
 * @param big
 * @param restaurantImage
 * @param chefImage
 * @param chefName
 * @param restaurantName
 * @param sets
 * @param buttons
 * @returns {*}
 * @constructor
 */
export default function TastingMenuBlock({
  currency,
  big,
  restaurantImage,
  chefImage,
  chefName,
  restaurantName,
  sets,
  buttons,
}) {
  const { t } = useTranslation();
  const [active, setActive] = useState(0);

  return (
    <div className={`restaurant-block tasting-menu-block ${big ? 'big' : ''}`}>
      <div className="img-wrapper">
        {restaurantImage}
        {chefName && (
          <div className={`chef ${chefImage ? 'with-img' : ''}`}>
            {chefImage}
            <span className="chef-name">{chefName}</span>
          </div>
        )}
      </div>
      <div className="text-wrapper">
        <h2>{`${t('pageTastingMenus:tasting_menu_at')} ${restaurantName}:`}</h2>
        <div className="menu-tabs">
          {sets.map((set, index) => (
            <button
              key={`${set.id}-button`}
              type="button"
              className={`${active === index ? 'active' : ''}`}
              onClick={() => setActive(index)}
            >
              <Tray className="icon" />
              {set.name}
            </button>
          ))}
        </div>
        <div className="menu-content">
          {sets.map((set, index) => (
            <div
              key={`${set.id}-menu`}
              className={`menu-text ${active === index ? 'active' : ''}`}
            >
              {set.items.map((menuItem) => (
                <div key={menuItem.id}>
                  <p key={menuItem.id} className="menu-item">
                    <strong>{menuItem.name}</strong>
                    {menuItem.description && <br />}
                    {menuItem.description}
                  </p>
                </div>
              ))}
              <p className="menu-price">{`${set.price} ${currency}`}</p>
            </div>
          ))}
        </div>
        {buttons}
      </div>
    </div>
  );
}

TastingMenuBlock.propTypes = {
  big: PropTypes.bool,
  restaurantImage: PropTypes.element,
  chefImage: PropTypes.element,
  chefName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  restaurantName: PropTypes.string,
  sets: PropTypes.oneOfType([PropTypes.array]),
  buttons: PropTypes.element,
  currency: PropTypes.string.isRequired,
};

TastingMenuBlock.defaultProps = {
  big: false,
  restaurantImage: null,
  chefImage: null,
  chefName: '',
  restaurantName: '',
  sets: [],
  buttons: null,
};
