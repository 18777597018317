import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import WindowContext from '../../../react-web-ui/contexts/WindowContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import DropDownBox from '../DropDownBox';
import ButtonLink from '../../../react-web-ui/components/ButtonLink';
import { ReactComponent as Download } from '../../../assets/images/icons/Download.svg';

export default function MainNav({ clickedLink }) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { isDesktop } = useContext(WindowContext);
  const [active, setActive] = useState(0);

  const clickedDropdown = (index) => {
    setActive(index);
  };

  const clickedSubLink = () => {
    clickedLink();
    setActive(0);
  };

  return (
    <>
      {userContext.isLoggedIn ? (
        <>
          <li>
            <NavLink
              to="/manage"
              className="dd-btn"
              onClick={(e) => {
                e.preventDefault();
                clickedDropdown(active === 1 ? 0 : 1);
              }}
            >
              {t('navigation:nav_manage')}
            </NavLink>
            {active === 1 && (
              <DropDownBox
                links={[
                  {
                    name: t('navigation:nav_restaurant_profile'),
                    href: '/manage/restaurant-profile',
                  },
                  {
                    name: t('navigation:nav_menu'),
                    href: '/manage/menu',
                  },
                  {
                    name: t('navigation:nav_seating_areas'),
                    href: '/manage/seating-areas',
                  },
                  {
                    name: t('navigation:nav_shifts'),
                    href: '/manage/shifts',
                  },
                  {
                    name: t('navigation:nav_employees'),
                    href: '/manage/employees',
                  },
                  {
                    name: t('navigation:nav_bookings'),
                    href: '/manage/bookings',
                  },
                  {
                    name: t('navigation:nav_subscriptions'),
                    href: '/manage/subscriptions',
                  },
                ]}
                clickedLink={clickedSubLink}
                setActive={setActive}
              />
            )}
          </li>
          <li>
            <NavLink
              to="/grow"
              className="dd-btn"
              onClick={(e) => {
                e.preventDefault();
                clickedDropdown(active === 2 ? 0 : 2);
              }}
            >
              {t('navigation:nav_grow')}
            </NavLink>
            {active === 2 && (
              <DropDownBox
                links={[
                  {
                    name: t('navigation:nav_stats'),
                    href: '/grow/stats',
                  },
                  {
                    name: t('navigation:nav_guests'),
                    href: '/grow/guests',
                  },
                  {
                    name: t('navigation:nav_reviews'),
                    href: '/grow/reviews',
                  },
                  {
                    name: t('navigation:nav_widget'),
                    href: '/grow/widget',
                  },
                  {
                    name: t('navigation:nav_email_branding'),
                    href: '/grow/email-branding',
                  },
                  {
                    name: t('navigation:nav_post_creator'),
                    href: '/grow/post-creator',
                  },
                ]}
                clickedLink={clickedSubLink}
                setActive={setActive}
              />
            )}
          </li>
          <li>
            <NavLink
              to="/monetize"
              className="dd-btn"
              onClick={(e) => {
                e.preventDefault();
                clickedDropdown(active === 3 ? 0 : 3);
              }}
            >
              {t('navigation:nav_monetize')}
            </NavLink>
            {active === 3 && (
              <DropDownBox
                links={[
                  {
                    name: t('navigation:nav_special_offers'),
                    href: '/monetize/special-offers',
                  },
                  {
                    name: t('navigation:nav_events'),
                    href: '/monetize/events',
                  },
                  {
                    name: t('navigation:nav_tasting_menus'),
                    href: '/monetize/tasting-menus',
                  },
                  {
                    name: t('navigation:nav_gift_cards'),
                    href: '/monetize/gift-cards',
                  },
                  {
                    name: t('navigation:nav_payments'),
                    href: '/monetize/payments',
                  },
                ]}
                clickedLink={clickedSubLink}
                setActive={setActive}
              />
            )}
          </li>
          <li>
            <NavLink
              to="/account"
              className="dd-btn"
              onClick={(e) => {
                e.preventDefault();
                clickedDropdown(active === 4 ? 0 : 4);
              }}
            >
              {t('navigation:nav_account')}
            </NavLink>
            {active === 4 && (
              <DropDownBox
                links={[
                  {
                    name: t('navigation:nav_settings'),
                    href: '/account/settings',
                  },
                  {
                    name: t('navigation:nav_contact_dineout'),
                    href: '/account/contact-dineout',
                  },
                  // {
                  //   name: t('navigation:nav_help'),
                  //   href: '/account/help',
                  //   type: 'external',
                  // },
                  {
                    name: t('navigation:nav_logout'),
                    href: '/account/logout',
                    type: 'logout',
                  },
                  // {
                  //   name: t('navigation:nav_billing'),
                  //   href: '/administration/billing',
                  // },
                ]}
                clickedLink={clickedSubLink}
                setActive={setActive}
              />
            )}
          </li>
          {!isDesktop && (
            <li>
              <ButtonLink
                text={(
                  <>
                    <Download className="icon" />
                    {t('navigation:host_app')}
                  </>
                )}
                classes="btn-accent btn-outline btn-download-host"
                href="/monetize/dineout-host"
              />
            </li>
          )}
        </>
      ) : (
        <>
          <li>
            <NavLink
              to="/"
              exact
              onClick={clickedLink}
            >
              {t('navigation:what_you_get')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/what-you-pay"
              onClick={clickedLink}
            >
              {t('navigation:what_you_pay')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/who-we-help"
              onClick={clickedLink}
            >
              {t('navigation:who_we_help')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/join"
              onClick={clickedLink}
            >
              {t('navigation:nav_join_now')}
            </NavLink>
          </li>
          {!isDesktop && (
            <li>
              <ButtonLink
                text={(
                  <>
                    <Download className="icon" />
                    {t('navigation:host_app')}
                  </>
                )}
                classes="btn-accent btn-outline btn-download-host"
                href="/monetize/dineout-host"
              />
            </li>
          )}
        </>
      )}
    </>
  );
}

MainNav.propTypes = {
  /** onClick function for the links */
  clickedLink: PropTypes.func.isRequired,
};
