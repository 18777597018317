/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  useStripe, useElements, PaymentElement,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { checkVAT, countries } from 'jsvat/lib/commonjs';

import useForm from '../../react-web-ui/hooks/useForm';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';
import UserContext from '../../react-web-ui/contexts/UserContext';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import validate from './validation';
import RequestService from '../../networking/RequestService';
import countriesSelect from '../../utilities/countries';
import Spinner from '../../react-web-ui/components/Spinner';
import CustomPhoneInput from '../CustomPhoneInput';
import stripeImg from '../../assets/images/stripe.png';
import Loader from '../../assets/images/loader.gif';

const appearance = {
  variables: {
    colorDanger: '#d64e2f',
    borderRadius: '2px',
  },
  rules: {
    '.Tab': {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
      padding: '8px 12px',
      border: 'none',
      fontWeight: '400',
    },
    '.Tab:hover': {
      border: 'none',
      boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.08), 0px 3px 7px rgba(18, 42, 66, 0.04)',
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      backgroundColor: '#FFAE42',
      color: '#FFFFFF',
      border: 'none',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
    },
    '.TabIcon--selected': {
      fill: '#FFFFFF',
    },
    '.Label': {
      color: '#adadad',
      fontSize: '15px',
    },
    '.Input': {
      boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
      border: 'none',
      borderBottom: '1px solid #2e2e2e',
      height: '36px',
    },
    '.Input:focus': {
      outline: 'none',
    },
    '.Text': {
      color: '#7180c4',
    },
  },
};

export default function SubscriptionForm({
  clientSecret,
  plan,
  price,
}) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const userContext = useContext(UserContext);
  const countryContext = useContext(CountryContext);
  const [loading, setLoading] = useState(false);
  const [vatText, setVatText] = useState('');
  const [elementsLoaded, setElementsLoaded] = useState(false);

  const {
    handleChange,
    handleSubmit,
    setValues,
    setIsSubmitting,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  // console.log(checkVAT('ATU12011204', countries));

  async function confirmStripe() {
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // return_url: `https://restaurant.dineout.com/manage/subscribe?plan=${plan}&price=${price}`,
        return_url: process.env.REACT_APP_API_URL === 'http://localhost/v2/'
          ? `https://restaurant.dineout.com/manage/subscribe?plan=${plan}&price=${price}`
          : `https://${process.env.REACT_APP_DOMAIN}/manage/subscribe?plan=${plan}&price=${price}`,
      },
    });
  }

  function submit() {
    if (!stripe || !elements || !vatText) return;

    setLoading(true);

    const billingDetails = {
      address: {
        city: values.billing_city,
        country: values.billing_country,
        line1: values.billing_line1,
        line2: values.billing_line2,
        postal_code: values.billing_postal_code,
        state: values.billing_state,
      },
      email: values.billing_email,
      name: values.billing_full_name,
      phone: values.phone,
    };

    const params = {
      user_id: userContext.user.id,
      billing_address: billingDetails.address,
      tax_id: {
        type: 'eu_vat',
        value: values.billing_vat,
      },
    };

    (new RequestService('manager/users/update'))
      .setParams(params)
      .send()
      .then((response) => {
        userContext.setUser(response.data);
        confirmStripe();
      })
      .catch((er) => {
        if (er.key) toast.error(t(`apiErrors:${er.key}`));
        else toast.error(t('apiErrors:could_not_update_user'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!countryContext.country.code || vatText !== '') return;
    setVatText(countryContext.country.code);
  }, [countryContext.country.code]);

  useEffect(() => {
    if (!userContext.user) return;

    setValues({
      ...values,
      billing_full_name: `${userContext.user.firstName} ${userContext.user.lastName}`,
      billing_email: userContext.user.email,
      phone: userContext.user.phone,
      billing_country: userContext.user.billingAddress?.country || userContext.user.country.code,
      billing_city: userContext.user.billingAddress?.city,
      billing_line1: userContext.user.billingAddress?.line1,
      billing_line2: userContext.user.billingAddress?.line2,
      // eslint-disable-next-line camelcase
      billing_postal_code: userContext.user.billingAddress?.postal_code,
      billing_state: userContext.user.billingAddress?.state,
      billing_vat: userContext.user.taxId?.value,
    });

    if (userContext.user.taxId) {
      setVatText(userContext.user.taxId?.value);
    }
  }, [userContext.user]);

  useEffect(() => {
    if (vatText === '') return;
    setIsSubmitting(false);
    setValues({
      ...values,
      billing_vat: vatText,
    });
    if (!checkVAT(vatText, countries).isValid) {
      setValues({
        ...values,
        billing_vat: 'invalid',
      });
    }
  }, [vatText]);

  const options = {
    clientSecret,
    // appearance,
  };

  return (
    <div className="stripe-form">
      {elementsLoaded ? (
        <>
          <h2 className="text-accent">
            {t('pageSubscriptions:payment_method')}
          </h2>
          <h3 className="section-sub-title flex align-center">
            <small>{t('pageEmailBranding:powered_by')}</small>
            <img className="stripe-img" src={stripeImg} alt="" />
          </h3>
        </>
      ) : (
        <div className="loader-wrapper"><img src={Loader} alt="" /></div>
      )}
      <PaymentElement
        options={options}
        onReady={() => setElementsLoaded(true)}
      />
      {elementsLoaded && (
        <>
          <small>{t('pageSubscriptions:dineut_does_not_keep_card_info')}</small>
          <h2 className="text-accent">{t('pageSubscriptions:billing_address')}</h2>
          <FormElement
            id="billing_full_name"
            elementType="input"
            placeholder={t('forms:full_name')}
            label={t('forms:full_name')}
            value={values.billing_full_name || ''}
            errors={errors.billing_full_name}
            changed={handleChange}
          />
          <FormElement
            id="billing_email"
            elementType="input"
            placeholder={t('forms:email')}
            label={t('forms:email')}
            value={values.billing_email || ''}
            errors={errors.billing_email}
            changed={handleChange}
          />
          <CustomPhoneInput
            values={values}
            setValues={setValues}
            errors={errors.phone}
          />
          <FormElement
            id="billing_country"
            elementType="select"
            placeholder={t('forms:billing_country')}
            label={t('forms:billing_country')}
            value={values.billing_country || ''}
            errors={errors.billing_country}
            changed={handleChange}
            selectOptions={[
              {
                value: '',
                text: t('forms:billing_country'),
              },
              ...countriesSelect,
            ]}
          />
          <FormElement
            id="billing_city"
            elementType="input"
            placeholder={t('forms:restaurant_city')}
            label={t('forms:restaurant_city')}
            value={values.billing_city || ''}
            errors={errors.billing_city}
            changed={handleChange}
          />
          <FormElement
            id="billing_line1"
            elementType="input"
            placeholder={t('forms:billing_line1')}
            label={t('forms:billing_line1')}
            value={values.billing_line1 || ''}
            errors={errors.billing_line1}
            changed={handleChange}
          />
          <FormElement
            id="billing_line2"
            elementType="input"
            placeholder={t('forms:billing_line2')}
            label={t('forms:billing_line2')}
            value={values.billing_line2 || ''}
            errors={errors.billing_line2}
            changed={handleChange}
          />
          <FormElement
            id="billing_postal_code"
            elementType="input"
            placeholder={t('forms:billing_postal_code')}
            label={t('forms:billing_postal_code')}
            value={values.billing_postal_code || ''}
            errors={errors.billing_postal_code}
            changed={handleChange}
          />
          <FormElement
            id="billing_state"
            elementType="input"
            placeholder={t('forms:billing_state')}
            label={t('forms:billing_state')}
            value={values.billing_state || ''}
            errors={errors.billing_state}
            changed={handleChange}
          />
          <FormElement
            id="billing_vat"
            elementType="input"
            placeholder={t('pageSubscriptions:billing_vat')}
            label={`${t('pageSubscriptions:billing_vat')} (${t('pageSubscriptions:billing_vat_type')})`}
            value={vatText}
            errors={errors.billing_vat}
            changed={(e) => setVatText(e.target.value)}
          />
          <Button
            text={loading ? (
              <>
                <Spinner />
                {t('loading')}
              </>
            ) : t('forms:subscribe')}
            classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
            disabled={loading}
            onClick={handleSubmit}
          />
        </>
      )}
    </div>
  );
}

SubscriptionForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};
