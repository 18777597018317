import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import heroImg from '../assets/images/hero-image-1.jpg';
import WindowContext from '../react-web-ui/contexts/WindowContext';
import FeaturesTable from '../components/FeaturesTable';
import { ReactComponent as Tick } from '../assets/images/icons/Tick.svg';
import { ReactComponent as X } from '../assets/images/icons/X-red.svg';
import { ReactComponent as Money } from '../assets/images/icons/Money.svg';
import ButtonLink from '../react-web-ui/components/ButtonLink';
import UserContext from '../react-web-ui/contexts/UserContext';
import useFeatures from '../hooks/useFeatures';
import Tooltip from '../react-web-ui/components/Tooltip';
import CountryContext from '../react-web-ui/contexts/CountryContext';

export default function WhatYouPay() {
  const { t } = useTranslation();
  const { isDesktop } = useContext(WindowContext);
  const [showFeatures, setShowFeatures] = useState(false);
  const userContext = useContext(UserContext);
  const countryContext = useContext(CountryContext);
  const {
    rows,
    pricingBlocks,
    pricePeriod,
    setPricePeriod,
  } = useFeatures();

  return (
    <>
      <div className="hero-section">
        <img src={heroImg} alt="" />
        <div className="inner-wrapper">
          <div className="hero-section-content">
            <h2>{t('selfOnboarding:what_you_pay')}</h2>
            <p>{t('selfOnboarding:choose_plan')}</p>
            {!userContext.isLoggedIn && (
              <ButtonLink
                text={t('selfOnboarding:start_now')}
                classes="btn-accent"
                href="/join"
              />
            )}
          </div>
        </div>
      </div>
      <div className="inner-wrapper">
        <div id="pricing-plan" className="section">
          {!isDesktop ? (
            <>
              <div className="pricing-period">
                <Money className="icon" />
                <button
                  type="button"
                  className={`toggle-status ${pricePeriod.pro !== 'MONTHLY' ? 'active' : ''}`}
                  onClick={() => setPricePeriod({
                    pro: pricePeriod.pro === 'MONTHLY' ? 'YEARLY' : 'MONTHLY',
                    premium: pricePeriod.premium === 'MONTHLY' ? 'YEARLY' : 'MONTHLY',
                  })}
                >
                  <span className="seating-option">{t('selfOnboarding:monthly')}</span>
                  <span className="seating-option">{t('selfOnboarding:yearly')}</span>
                </button>
              </div>
              <div className="pricing-blocks">
                {pricingBlocks.map((block, index) => (
                  <React.Fragment key={block.title}>
                    <div className="pricing-block-column">
                      <header className="pricing-header">
                        <h3>{block.title}</h3>
                        <p>
                          {block.title === 'lite'
                            ? block.price
                            : `${block.price} / ${t('selfOnboarding:month')}`}
                        </p>
                        {(pricePeriod.pro === 'YEARLY' || pricePeriod.premium === 'YEARLY') && block.title !== 'lite' && (
                          <p className="price-label">
                            {t('selfOnboarding:save_value', {
                              value: `${(t(`selfOnboarding:${block.title}_price_${countryContext.country.name}_MONTHLY`).replace(/\D/g, '') - block.price.replace(/\D/g, '')) * 12}${block.price.replace(/[^a-zA-Z]+/g, '')}`,
                            })}
                          </p>
                        )}
                      </header>
                      <div className="pricing-content">
                        {rows.slice(0, 5).map((row) => (
                          <div key={row.title} className="pricing-option">
                            <p>{row.title}</p>
                            {row.pricingBlockFeatures[index] === 'tick' ? (
                              <Tick className="icon" />
                            ) : (
                              <X className="icon" />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    {(pricePeriod.pro === 'YEARLY' || pricePeriod.premium === 'YEARLY') && block.title !== 'lite' && (
                      <p>
                        {t('selfOnboarding:total_yearly', {
                          total: `${block.price.replace(/\D/g, '') * 12}${block.price.replace(/[^a-zA-Z]+/g, '')}`,
                          value: `${(t(`selfOnboarding:${block.title}_price_${countryContext.country.name}_MONTHLY`).replace(/\D/g, '') - block.price.replace(/\D/g, '')) * 12}${block.price.replace(/[^a-zA-Z]+/g, '')}`,
                        })}
                      </p>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <p>{t('selfOnboarding:pricing_vat')}</p>
              <div className="compare-pricings">
                <h2>{t('selfOnboarding:compare_all_features')}</h2>
                {rows.map((row) => (
                  <div key={row.title} className="compare-row">
                    <header>
                      {row.title}
                      <Tooltip
                        title={row.additional}
                        position="left"
                      >
                        <span className="btn-show-additional">?</span>
                      </Tooltip>
                    </header>
                    <div className="pricing-option">
                      <h3>{pricingBlocks[0].title}</h3>
                      <p>
                        {row.pricingBlockFeatures[0] === 'tick' ? (
                          <Tick className="icon" />
                        ) : (
                          <X className="icon" />
                        )}
                      </p>
                    </div>
                    <div className="pricing-option">
                      <h3>{pricingBlocks[1].title}</h3>
                      <p>
                        {row.pricingBlockFeatures[1] === 'tick' ? (
                          <Tick className="icon" />
                        ) : (
                          <X className="icon" />
                        )}
                      </p>
                    </div>
                    <div className="pricing-option">
                      <h3>{pricingBlocks[2].title}</h3>
                      <p>
                        {row.pricingBlockFeatures[2] === 'tick' ? (
                          <Tick className="icon" />
                        ) : (
                          <X className="icon" />
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <FeaturesTable
                pricingBlocks={pricingBlocks}
                rows={rows.slice(0, 5)}
                hasPricingRange
                pricePeriod={pricePeriod}
                setPricePeriod={setPricePeriod}
                setSubscription={() => {}}
                hiddenButtons
              />
              <button
                type="button"
                className="btn-link btn-show-all-features"
                onClick={() => setShowFeatures(!showFeatures)}
              >
                {!showFeatures
                  ? `↓ ${t('selfOnboarding:compare_all_features')} ↓`
                  : `↑ ${t('selfOnboarding:compare_all_features')} ↑`}
              </button>
              {showFeatures && (
                <FeaturesTable
                  pricingBlocks={pricingBlocks}
                  pricePeriod={pricePeriod}
                  rows={rows}
                  setSubscription={() => {}}
                  hiddenButtons
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
