import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';

export default function ComboItemsForm({
  inputList,
  setInputList,
}) {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);

  const handleAddComboClick = () => {
    setInputList([...inputList, {
      special_offer_item_id: '',
      itemNameEn1: '',
      itemNameTranslation1: '',
    }]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = JSON.parse(JSON.stringify(inputList));
    list[index][name] = value;
    setInputList(list);
  };

  const translateText = (englishParam, englishValue, otherParam, otherValue, index) => {
    const list = JSON.parse(JSON.stringify(inputList));
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [englishValue];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [otherValue];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          list[index][otherParam] = translations[0].text;
        } else {
          list[index][englishParam] = translations[0].text;
        }
        setInputList(list);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  return (
    <>
      <div className="menu-items-section">
        <div className="menu-item-group">
          {inputList && inputList.map(({
            itemNameEn1,
            itemNameTranslation1,
          }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="grid" key={index}>
              <div className="grid-col-5">
                <FormElement
                  id={`itemNameEn1${index}`}
                  name="itemNameEn1"
                  elementType="input"
                  placeholder={t('pageSpecialOffers:enter_menu_item_en')}
                  value={itemNameEn1}
                  changed={(e) => handleInputChange(e, index)}
                />
              </div>
              <div className="grid-col-5">
                <FormElement
                  id={`itemNameTranslation1${index}`}
                  name="itemNameTranslation1"
                  elementType="input"
                  placeholder={t(`pageSpecialOffers:enter_menu_item_translation_${countryContext.country.name}`)}
                  value={itemNameTranslation1}
                  changed={(e) => handleInputChange(e, index)}
                />
              </div>
              <button
                type="button"
                className="translate-fields"
                onClick={() => translateText('itemNameEn1', itemNameEn1, 'itemNameTranslation1', itemNameTranslation1, index)}
              >
                {' '}
              </button>
            </div>
          ))}
        </div>

      </div>
      <Button
        text={t('pageSpecialOffers:add_combo_option')}
        classes="btn-accent btn-outline add-another-btn"
        onClick={handleAddComboClick}
      />
    </>
  );
}

ComboItemsForm.propTypes = {
  inputList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setInputList: PropTypes.func.isRequired,
};
