import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import FormElement from '../../react-web-ui/components/FormElement';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import MenuItem from '../../react-web-ui/components/RestaurantMenu/MenuItem';
import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';
import { ReactComponent as CloseIcon } from '../../react-web-ui/assets/images/icons/Close.svg';

/**
* Menu item block
* @param locale
* @param categories
* @param setCategories
* @param categoryId
* @param item
* @returns {*}
* @constructor
*/
export default function MenuItemBlock({
  locale,
  categories,
  setCategories,
  categoryId,
  item,
  disabled,
  setChanged,
  changed,
}) {
  const { t, i18n } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const countryContext = useContext(CountryContext);
  const [showTranslate, setShowTranslate] = useState(false);

  useEffect(() => {
    setCurrentItem({ ...item });
  }, [item]);

  const handleItemChange = (e, translation) => {
    const currentItemCopy = { ...currentItem };
    if (translation) {
      if (currentItemCopy.translations[locale]) {
        currentItemCopy.translations[locale][e.target.name] = e.target.value;
      } else {
        currentItemCopy.translations = {
          [locale]: {
            [e.target.name]: e.target.value,
          },
        };
      }
    } else {
      currentItemCopy[e.target.name] = e.target.value;
    }
    setCurrentItem(currentItemCopy);
  };

  const handleItemEdit = () => {
    const currentCategories = [...categories];
    for (let i = 0; i < currentCategories.length; i += 1) {
      if (categoryId === currentCategories[i].id) {
        for (let k = 0; k < currentCategories[i].items.length; k += 1) {
          if (currentCategories[i].items[k].id === currentItem.id) {
            currentCategories[i].items[k].name = currentItem.name;
            currentCategories[i].items[k].description = currentItem.description;
            currentCategories[i].items[k].price = currentItem.price;
            currentCategories[i].items[k].translations = {
              [locale]: {
                name: currentItem.translations[locale] ? currentItem.translations[locale].name : '',
                description: currentItem.translations[locale] ? currentItem.translations[locale].description : '',
              },
            };
          }
        }
      }
    }
    setCategories(currentCategories);
    setEdit(false);
  };

  const handleDelete = () => {
    const currentCategories = [...categories];
    for (let i = 0; i < currentCategories.length; i += 1) {
      if (categoryId === currentCategories[i].id) {
        currentCategories[i].items = currentCategories[i].items.filter(
          ({ id }) => id !== currentItem.id,
        );
      }
    }
    setCategories(currentCategories);
  };

  const translateNewItem = () => {
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [currentItem.name, currentItem.description];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [
        currentItem.translations[i18n.language].name,
        currentItem.translations[i18n.language].description,
      ];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          setCurrentItem({
            ...currentItem,
            translations: {
              [locale]: {
                locale,
                name: translations[0].text,
                description: translations[1]?.text,
              },
            },
          });
        } else {
          setCurrentItem({
            ...currentItem,
            name: translations[0].text,
            description: translations[1]?.text,
          });
        }
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  useEffect(() => {
    if (Object.keys(currentItem).length > 0 && Object.values(currentItem).some((x) => x !== '') && edit) {
      setChanged(true);
    } else if (!changed) {
      setChanged(false);
    }
  }, [currentItem, edit]);

  if (!currentItem.id) {
    return '';
  }

  return (
    <>
      {edit ? (
        <div className={`new-menu-item grid grid-col-10 ${showTranslate ? 'show-translate' : ''}`}>
          <div className="grid-col-5 invertible-field">
            <FormElement
              id={`menu_item_name_en_${currentItem.id}`}
              name="name"
              elementType="input"
              placeholder={t('forms:enter_course_name_en')}
              value={currentItem.name}
              changed={handleItemChange}
              disabled={disabled}
              onFocus={() => setShowTranslate(true)}
              onBlur={() => setShowTranslate(false)}
            />
            <FormElement
              id={`menu_item_desc_en_${currentItem.id}`}
              name="description"
              elementType="input"
              placeholder={t('forms:enter_course_description_en')}
              value={currentItem.description || ''}
              changed={handleItemChange}
              disabled={disabled}
              onFocus={() => setShowTranslate(true)}
              onBlur={() => setShowTranslate(false)}
            />
          </div>
          <div className="grid-col-5 invertible-field">
            <FormElement
              id={`menu_item_name_transaltion_${currentItem.id}`}
              name="name"
              elementType="input"
              placeholder={t(`forms:enter_course_name_translation_${countryContext.country.name}`)}
              value={
                currentItem.translations[locale]
                && currentItem.translations[locale].name
              }
              changed={(e) => handleItemChange(e, true)}
              disabled={disabled}
              onFocus={() => setShowTranslate(true)}
              onBlur={() => setShowTranslate(false)}
            />
            <FormElement
              id={`menu_item_desc_translation_${currentItem.id}`}
              name="description"
              elementType="input"
              placeholder={t(`forms:enter_course_description_translation_${countryContext.country.name}`)}
              value={
                currentItem.translations[locale]
                && currentItem.translations[locale].description
              }
              changed={(e) => handleItemChange(e, true)}
              disabled={disabled}
              onFocus={() => setShowTranslate(true)}
              onBlur={() => setShowTranslate(false)}
            />
          </div>
          <div className="price-col">
            <FormElement
              id={`menu_item_price_${currentItem.id}`}
              name="price"
              elementType="input"
              inputType="number"
              placeholder={t('price')}
              value={currentItem.price || ''}
              changed={handleItemChange}
              disabled={disabled}
            />
          </div>
          <button
            type="button"
            className="add-item-btn"
            onClick={handleItemEdit}
          >
            {t('save')}
          </button>
          <button
            type="button"
            className="translate-fields"
            onClick={() => translateNewItem()}
          >
            {' '}
          </button>
        </div>
      ) : (
        <div className="grid current-menu-item">
          <div className="grid-col-5 invertible-field">
            <MenuItem
              name={currentItem.name || ''}
              description={currentItem.description}
              price={currentItem.price || ''}
            />
          </div>
          <div className="grid-col-5 invertible-field">
            {currentItem.translations[locale] && (
              <MenuItem
                name={currentItem.translations[locale].name || ''}
                description={currentItem.translations[locale].description}
                price={currentItem.price || ''}
              />
            )}
          </div>
          <button
            className="delete-btn"
            type="button"
            onClick={() => {
              confirmAlert({
                // eslint-disable-next-line react/prop-types
                customUI: ({ onClose }) => (
                  <div className="react-confirm-alert-body">
                    <h2 className="section-title">{t('pageMenu:are_you_sure_delete')}</h2>
                    <button
                      type="button"
                      onClick={() => {
                        handleDelete();
                        onClose();
                      }}
                    >
                      {t('yes')}
                    </button>
                    <button
                      type="button"
                      onClick={onClose}
                    >
                      {t('no')}
                    </button>
                  </div>
                ),
              });
            }}
          >
            <CloseIcon className="icon" />
          </button>
          <button
            className="edit-btn"
            type="button"
            onClick={() => setEdit(true)}
          >
            <Edit className="icon" />
          </button>
        </div>
      )}
    </>
  );
}

MenuItemBlock.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array]).isRequired,
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setCategories: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setChanged: PropTypes.func,
  changed: PropTypes.bool,
};

MenuItemBlock.defaultProps = {
  disabled: false,
  setChanged: null,
  changed: false,
};
