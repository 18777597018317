/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import './i18n';

import './assets/styles/style.scss';
import WindowContext from './react-web-ui/contexts/WindowContext';
import CountryContext from './react-web-ui/contexts/CountryContext';
import Country from './networking/resources/Country';
import App from './root/App';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `restaurant@${process.env.REACT_APP_RELEASE_VERSION}`,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
});

const countries = (domain) => {
  switch (domain) {
    case 'restaurant.dineout.bg':
    case 'restaurant.dineout.com':
      return Country.fromAPI({
        id: '3691308f2a4c2f6983f2880d32e29c84',
        name: 'Bulgaria',
        currency: 'BGN',
        code: 'BG',
      });
    case 'restaurant.dineout.pl':
      return Country.fromAPI({
        id: '7e83de9a1f3a4d52effc3352b336f64f',
        name: 'Poland',
        currency: 'PLN',
        code: 'PL',
      });
    case 'restaurant.dineout.dev':
      return Country.fromAPI({
        id: '3691308f2a4c2f6983f2880d32e29c84',
        name: 'Poland',
        currency: 'PLN',
        code: 'PL',
      });
    case 'restaurant.staging.dineout.es':
    case 'restaurant.dineout.es':
      return Country.fromAPI({
        id: '8a15024c391d8a93371129285343f5b3',
        name: 'Spain',
        currency: 'EUR',
        code: 'es',
      });

    case 'restaurant.staging.dineout.ro':
    case 'restaurant.dineout.ro':
      return Country.fromAPI({
        id: '756073c0e71e9dba34afc37b9ecfc396',
        name: 'Romania',
        currency: 'LEI',
        code: 'ro',
      });

    default:
      return Country.fromAPI({
        id: '3691308f2a4c2f6983f2880d32e29c84',
        name: 'Bulgaria',
        currency: 'BGN',
        code: 'BG',
      });
  }
};

const rootElement = document.getElementById('root');

const RootApp = () => {
  const DESKTOP_WIDTH_THRESHOLD = 1025;
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  const updateCurrentWindowWidth = () => {
    setCurrentWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateCurrentWindowWidth);
    return () => window.removeEventListener('resize', updateCurrentWindowWidth);
  });

  return (
    <React.StrictMode>
      <WindowContext.Provider
        value={{
          currentWidth,
          isDesktop: currentWidth >= DESKTOP_WIDTH_THRESHOLD,
        }}
      >
        <CountryContext.Provider
          value={{
            country: countries(window.location.hostname),
          }}
        >
          <App />
        </CountryContext.Provider>
      </WindowContext.Provider>
    </React.StrictMode>
  );
};

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<RootApp />, rootElement);
} else {
  ReactDOM.render(<RootApp />, rootElement);
}
