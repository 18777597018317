import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormElement from '../../react-web-ui/components/FormElement';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import { ReactComponent as CloseIcon } from '../../react-web-ui/assets/images/icons/Close.svg';
import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';

export default function EditableText({
  deletable,
  name,
  placeholder,
  valueEn,
  valueTranslation,
  onChangeEn,
  onChangeTranslation,
  onDelete,
  textarea,
  onSave,
  disabled,
  translatable,
  translateText,
}) {
  const [edit, setEdit] = useState(false);
  const [defaultText, setDefaultText] = useState(valueEn);
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);

  useEffect(() => {
    if (i18n.language === 'en' || i18n.language === 'en-GB') {
      setDefaultText(valueEn);
    } else {
      setDefaultText(valueTranslation);
    }
  }, [i18n.language, valueEn, valueTranslation]);

  return (
    <span
      className={`
        editable-text 
        ${edit ? 'editing' : ''}
        ${deletable ? 'deletable' : ''}
      `}
    >
      {edit ? (
        <>
          <div
            className={`
              invertible-fields
              ${(i18n.language === 'bg' || i18n.language === 'pl') ? 'inverted' : ''}
            `}
          >
            <FormElement
              className="invertible-field"
              id={`${name}En`}
              elementType={textarea ? 'textarea' : 'input'}
              placeholder={t('forms:placeholder_default')}
              value={valueEn}
              changed={onChangeEn}
              disabled={disabled}
            />
            <FormElement
              className="invertible-field"
              id={`${name}Translation`}
              elementType={textarea ? 'textarea' : 'input'}
              placeholder={t(`forms:placeholder_translation_${countryContext.country.name}`)}
              value={valueTranslation}
              changed={onChangeTranslation}
              disabled={disabled}
            />
            {translatable && (
              <button
                type="button"
                className="translate-fields"
                onClick={() => translateText()}
              >
                {' '}
              </button>
            )}
          </div>
          <button
            className="save-btn"
            type="button"
            onClick={() => {
              setEdit(!edit);
              if (onSave) onSave();
            }}
          >
            {t('save')}
          </button>
        </>
      ) : defaultText || placeholder}
      <button
        className="edit-btn"
        type="button"
        onClick={() => setEdit(!edit)}
      >
        <Edit className="icon" />
      </button>
      {deletable && (
        <button
          className="delete-btn"
          type="button"
          onClick={onDelete}
        >
          <CloseIcon className="icon" />
        </button>
      )}
    </span>
  );
}

EditableText.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  valueEn: PropTypes.string,
  valueTranslation: PropTypes.string,
  onChangeEn: PropTypes.func,
  onChangeTranslation: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  textarea: PropTypes.bool,
  deletable: PropTypes.bool,
  disabled: PropTypes.bool,
  translatable: PropTypes.bool,
  translateText: PropTypes.func,
};

EditableText.defaultProps = {
  name: '',
  placeholder: '',
  valueEn: '',
  valueTranslation: '',
  onChangeEn: null,
  onChangeTranslation: null,
  onSave: null,
  onDelete: null,
  textarea: false,
  deletable: false,
  disabled: false,
  translatable: false,
  translateText: null,
};
