import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import FormElement from '../../react-web-ui/components/FormElement';

export default function MenuOrCategoryForm({
  setValues,
  values,
  errors,
  handleChange,
}) {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);

  const translateText = (english, other) => {
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [values[english]];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [values[other]];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          setValues({
            ...values,
            [other]: translations[0].text,
          });
        } else {
          setValues({
            ...values,
            [english]: translations[0].text,
          });
        }
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  return (
    <div className="grid">
      <div className="grid-col-5">
        <FormElement
          id="item_en"
          elementType="input"
          placeholder={t('pageSpecialOffers:menu_or_category_en')}
          value={values.item_en || ''}
          errors={errors.item_en}
          changed={handleChange}
        />
      </div>
      <div className="grid-col-5">
        <FormElement
          id="item_translation"
          elementType="input"
          placeholder={t(`pageSpecialOffers:menu_or_category_translation_${countryContext.country.name}`)}
          value={values.item_translation || ''}
          errors={errors.item_translation}
          changed={handleChange}
        />
      </div>
      <button
        type="button"
        className="translate-fields"
        onClick={() => translateText('item_en', 'item_translation')}
      >
        {' '}
      </button>
    </div>
  );
}

MenuOrCategoryForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleChange: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};
