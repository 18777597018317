import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    emailEmpty: i18n.t('forms:email_error_empty'),
    emailInvalid: i18n.t('forms:email_error_invalid'),
    passwordEmpty: i18n.t('forms:password_error_empty'),
  };

  // Email
  errors.email = [];
  if (!values.email || values.email.trim() === '') {
    errors.email.push(errorTexts.emailEmpty);
  }

  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email.push(errorTexts.emailInvalid);
  }

  // Password
  errors.password = [];
  if (!values.password || values.password.trim() === '') {
    errors.password.push(errorTexts.passwordEmpty);
  }

  return errors;
};

export default validate;
