import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import heroImg from '../assets/images/hero-image-2.jpg';
import ButtonLink from '../react-web-ui/components/ButtonLink';
import illustration5 from '../assets/images/illustrations/illustration5.svg';
import illustration6 from '../assets/images/illustrations/illustration6.svg';
import illustration7 from '../assets/images/illustrations/illustration7.svg';
import illustration8 from '../assets/images/illustrations/illustration8.svg';
import illustration9 from '../assets/images/illustrations/illustration9.svg';
import illustration10 from '../assets/images/illustrations/illustration10.svg';
import illustration11 from '../assets/images/illustrations/illustration11.svg';
import illustration12 from '../assets/images/illustrations/illustration12.svg';

export default function Landing() {
  const { t } = useTranslation();

  return (
    <>
      <div className="hero-section">
        <img src={heroImg} alt="" />
        <div className="inner-wrapper">
          <div className="hero-section-content">
            <h2>{t('selfOnboarding:manage_grow')}</h2>
            <p>{t('selfOnboarding:maximase_business')}</p>
            <ButtonLink
              text={t('selfOnboarding:start_now')}
              classes="btn-accent"
              href="/join"
            />
          </div>
        </div>
      </div>
      <div className="inner-wrapper alternating-blocks-wrapper">
        <div className="alternating-blocks">
          <div className="img-wrapper">
            <img src={illustration5} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_5_title')}</h2>
            <Trans i18nKey="selfOnboarding:section_5_text" />
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper">
            <img src={illustration6} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-giftcard">{t('selfOnboarding:section_6_title')}</h2>
            <p>{t('selfOnboarding:section_6_text')}</p>
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper">
            <img src={illustration7} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-tasting">{t('selfOnboarding:section_7_title')}</h2>
            <Trans i18nKey="selfOnboarding:section_7_text" />
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper">
            <img src={illustration8} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_8_title')}</h2>
            <p>
              <Trans i18nKey="selfOnboarding:section_8_text">
                Get visible to a large base of restaurant goers. Get a
                {' '}
                <strong>detailed profile</strong>
                {' '}
                of your restaurant including your
                {' '}
                <strong>up to date menu</strong>
                , photos, detailed description, etc.
                {' '}
                <strong>Easily manage the content your guests see.</strong>
              </Trans>
            </p>
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper">
            <img src={illustration9} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_9_title')}</h2>
            <Trans i18nKey="selfOnboarding:section_9_text" />
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper img-outside">
            <img src={illustration10} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_10_title')}</h2>
            <p>{t('selfOnboarding:section_10_text')}</p>
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper">
            <img src={illustration11} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_11_title')}</h2>
            <p>{t('selfOnboarding:section_11_text')}</p>
          </div>
        </div>
        <div className="alternating-blocks">
          <div className="img-wrapper">
            <img src={illustration12} alt="" />
          </div>
          <div className="text-wrapper">
            <h2 className="text-accent">{t('selfOnboarding:section_12_title')}</h2>
            <p>{t('selfOnboarding:section_12_text')}</p>
          </div>
        </div>
      </div>
    </>
  );
}
