import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import InfoItem from '../../../react-web-ui/components/RestaurantInfo/InfoItem';
import FormElement from '../../../react-web-ui/components/FormElement';
import { ReactComponent as PriceRange } from '../../../react-web-ui/assets/images/icons/PriceRange.svg';
import { ReactComponent as Edit } from '../../../assets/images/icons/Edit.svg';

export default function PriceRangeInfoItem({
  currentRestaurantInfo,
  setCurrentRestaurantInfo,
  disabled,
  setChanged,
}) {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const countryContext = useContext(CountryContext);
  const priceRangeMin = currentRestaurantInfo._priceRange && currentRestaurantInfo._priceRange.min;
  const priceRangeMax = currentRestaurantInfo._priceRange && currentRestaurantInfo._priceRange.max;

  return (
    <div className={`editable-info-item ${edit ? 'editing' : ''}`}>
      <InfoItem
        icon={<PriceRange className="icon" />}
        title={t('pageRestaurantProfile:price_range')}
        text={edit ? (
          <>
            <FormElement
              id="min"
              elementType="input"
              inputType="number"
              placeholder={t('pageRestaurantProfile:min_price_range')}
              value={priceRangeMin}
              changed={(e) => {
                const copyRestaurantInfo = { ...currentRestaurantInfo };
                copyRestaurantInfo._priceRange.min = e.target.value;
                setCurrentRestaurantInfo(copyRestaurantInfo);
              }}
              disabled={disabled}
            />
            <FormElement
              id="max"
              elementType="input"
              inputType="number"
              placeholder={t('pageRestaurantProfile:max_price_range')}
              value={priceRangeMax}
              changed={(e) => {
                const copyRestaurantInfo = { ...currentRestaurantInfo };
                copyRestaurantInfo._priceRange.max = e.target.value;
                setCurrentRestaurantInfo(copyRestaurantInfo);
              }}
              disabled={disabled}
            />
            <button
              className="save-btn"
              type="button"
              onClick={() => {
                setEdit(!edit);
                setChanged(true);
              }}
            >
              {t('save')}
            </button>
          </>
        ) : `
            ${priceRangeMin} - ${priceRangeMax} 
            ${countryContext.country.currency}
            ${t('pageRestaurantProfile:per_person')}
          `}
      />
      <button
        className="edit-btn"
        type="button"
        onClick={() => setEdit(!edit)}
      >
        <Edit className="icon" />
      </button>
    </div>
  );
}

PriceRangeInfoItem.propTypes = {
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
  setCurrentRestaurantInfo: PropTypes.func,
  setChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

PriceRangeInfoItem.defaultProps = {
  currentRestaurantInfo: {},
  setCurrentRestaurantInfo: null,
  setChanged: null,
  disabled: false,
};
