import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import {
  SPECIAL_EVENT,
  DISCOUNT,
  PROMO_PRICE,
  COMBO_OFFER,
  FREE_ITEM,
  ENTERTAINMENT,
} from '../../../react-web-ui/utilities/constants';
import enumerateDaysBetweenDates from '../../../utilities/enumerateDaysBetweenDates';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import RequestService from '../../../networking/RequestService';
import Button from '../../../react-web-ui/components/Button';
import ValueSelect from '../../../react-web-ui/components/ValueSelect';
import useForm from '../../../react-web-ui/hooks/useForm';
import validate from './validation';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import DateDayPicker from '../../../components/DateDayPicker';
import PickHourBlock from '../../../components/PickHourBlock';
import convertHours from '../../../utilities/convertHours';
import Spinner from '../../../react-web-ui/components/Spinner';
import SpecialMenuTypeForm from '../../../components/SpecialMenuTypeForm';
import EntertainmentTypeForm from '../../../components/EntertainmentTypeForm';
import FreeItemForm from '../../../components/FreeItemForm';
import ConditionsForm from '../../../components/ConditionsForm';
import PromoForm from '../../../components/PromoForm';
import MenuOrCategoryForm from '../../../components/MenuOrCategoryForm';
import ComboItemsForm from '../../../components/ComboItemsForm';
import PercentOrPriceForm from '../../../components/PercentOrPriceForm';
import Tooltip from '../../../react-web-ui/components/Tooltip';
import useExitPrompt from '../../../hooks/useExitPrompt';

export default function AddSpecialOffer({ editing }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const countryContext = useContext(CountryContext);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const locale = countryContext.country.code.toLowerCase();
  const [percent, setPercent] = useState(10);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [hoursError, setHoursError] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [lunchHours, setLunchHours] = useState([]);
  const [dinnerHours, setDinnerHours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [specialOffer, setSpecialOffer] = useState({});
  const [offerType, setOfferType] = useState(JSON.parse(localStorage.getItem('currentType')) || '');
  const [status, setStatus] = useState(false);
  const [changed, setChanged] = useExitPrompt(false);
  const [inputList, setInputList] = useState([
    {
      special_offer_item_id: '',
      itemNameEn1: '',
      itemNameTranslation1: '',
    },
    {
      special_offer_item_id: '',
      itemNameEn1: '',
      itemNameTranslation1: '',
    },
  ]);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );
  const [run, setRun] = useState(false);
  // tour steps
  let steps = [
    {
      target: '.tour-fields',
      content: 'Use these fields to enter a name for your special menu and description containing its individual items. Use a separate line for each item, add prices if you wish. The in-field suggestions will help you determine which language to use - English or {country_language}.',
      disableBeacon: true,
    },
    {
      target: '.tour-date',
      content: 'Set a day or a period of validity, the dark green boxes show your selection. You can switch on and off individual days of the week should you wish to limit the availability of the special menu.',
    },
    {
      target: '.pick-hour',
      content: 'You can select individual hours to offer your special menu. Use the select all link to offer it during a whole shift. Grey boxes represent hours when it won\'t be available.',
    },
  ];

  if (offerType === DISCOUNT) {
    steps = [
      {
        target: '.select-value-block',
        content: 'This type of Special Offer is best to discount your whole menu. If you intend to discount a specific category in it, please, use Promo Price type. Now select a % or type a custom percentage. It will be shown to end-customers.',
        disableBeacon: true,
      },
      {
        target: '.invertible-grid',
        content: 'Clarify any conditions for the discount - like discount is applicable for food only. This information is visible to your guests. The in-field suggestions will help you determine which language to use - English or {country_language}.',
      },
      {
        target: '.tour-date',
        content: 'Set a day or a period of validity, the dark green boxes show the selection you\'ve made.You can switch on and off individual weekdays should you wish to limit the availability of the discount.',
      },
      {
        target: '.pick-hour',
        content: 'You can select individual hours to offer the discount. Use the select all link to offer it during a whole shift. Grey boxes represent hours when it won\'t be available.',
      },
    ];
  }
  if (offerType === PROMO_PRICE) {
    steps = [
      {
        target: '.tour-main-fields',
        content: 'Start by adding an appealing title to your promotion, be short and specific. Use the description field to add more details. Specify a menu item or a whole menu category. The in-field suggestions will help you determine which language to use - English or {country_language}.',
        disableBeacon: true,
      },
      {
        target: '.tour-percent',
        content: 'You can switch between two types of discount - percentage or discount to a specific price. In the second case you’ll enter the menu item’s price before and after the discount and both will be shown to the end-customers.',
      },
      {
        target: '.tour-date',
        content: 'Set a day or a period of validity, the dark green boxes show your selection. You can switch on and off individual days of the week should you wish to limit the availability of the discount.',
      },
      {
        target: '.pick-hour',
        content: 'You can select individual hours to offer the discount. Use the select all link to offer it during a whole shift. Grey boxes represent hours when it won\'t be available.',
      },
    ];
  }
  if (offerType === COMBO_OFFER) {
    steps = [
      {
        target: '.tour-main-fields',
        content: 'Add an appealing title and a more detailed description. The in-field suggestions will help you determine which language to use - English or {country_language}.',
        disableBeacon: true,
      },
      {
        target: '.menu-items-section',
        content: 'Add the items required for purchase in order for the guest to activate a combo price i.e. a medium pizza and a soft drink. ',
      },
      {
        target: '.tour-percent',
        content: 'You can choose to discount the combo by a specific percentage or show customers the total price of the combo before or after the discount.',
      },
      {
        target: '.tour-date-time',
        content: 'Select the period you’d like the combo offer to be valid for. You can exclude specific dates of the week. Lastly you need to specify at what times of the day it is valid.',
      },
    ];
  }
  if (offerType === FREE_ITEM) {
    steps = [
      {
        target: '.tour-main-fields',
        content: 'Specify the item you’d like to offer and add any conditions guests need to meet before receiving the compliment. The in-field suggestions will help you determine which language to use - English or {country_language}.',
        disableBeacon: true,
      },
      {
        target: '.tour-date-time',
        content: 'Select the period you’d like to offer a complimentary item. You can exclude specific weekdays within the period. Lastly you need to specify at what times of the day this offer is valid.',
      },
    ];
  }
  if (offerType === ENTERTAINMENT) {
    steps = [
      {
        target: '.tour-main-fields',
        content: 'Add a name and description detailing the entertainment provided at the restaurant Ex: live music, traditional dances, etc. The in-field suggestions will help you determine which language to use - English or {country_language}.',
        disableBeacon: true,
      },
      {
        target: '.tour-date-time',
        content: 'Select the date or period the entertainment is offered. You can exclude specific dates of the week. Lastly you need to specify at what times of the day it is offered.',
      },
    ];
  }

  function submit() {
    const dates = enumerateDaysBetweenDates(start, end, disableDays);
    const hours = [...lunchHours, ...dinnerHours];
    const selectedHours = hours.filter((hour) => hour.selected);
    setHoursError(selectedHours.length === 0);
    setDateError(dates.length === 0);
    if (selectedHours.length === 0 || dates.length === 0) return;
    setLoading(true);
    startListening();

    const times = convertHours(hours);
    const intervals = [];
    dates.forEach((date) => {
      intervals.push({
        date,
        times,
      });
    });

    let name = 'Отстъпка';

    if (locale === 'pl') {
      name = 'Zniżka';
    }

    const currentTranslations = {
      locale,
      name,
    };

    const AddSpecialOfferParams = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      special_offer_type: offerType,
      default_name: 'Discount',
      intervals,
    };

    if (offerType === SPECIAL_EVENT
      || offerType === ENTERTAINMENT
      || offerType === PROMO_PRICE
      || offerType === COMBO_OFFER) {
      AddSpecialOfferParams.default_name = values.name_en;
      AddSpecialOfferParams.default_description = values.description_en;
      currentTranslations.name = values.name_translation;
      currentTranslations.description = values.description_translation;
    }

    if (offerType === DISCOUNT || offerType === FREE_ITEM) {
      AddSpecialOfferParams.default_description = values.conditions_en;
      currentTranslations.description = values.conditions_translation;
    }

    if (offerType === DISCOUNT) {
      AddSpecialOfferParams.percentage = percent;
    }

    if (offerType === PROMO_PRICE || offerType === COMBO_OFFER) {
      if (values.percent) {
        AddSpecialOfferParams.percentage = values.percent;
      } else {
        AddSpecialOfferParams.price = values.standard_price;
        AddSpecialOfferParams.discounted_price = values.discount_price;
      }
    }

    if (offerType === FREE_ITEM || offerType === PROMO_PRICE) {
      const item = {
        name: values.item_en,
        translations: [{
          locale: countryContext.country.code.toLowerCase(),
          name: values.item_translation,
        }],
      };

      if (specialOffer._items && specialOffer._items.length > 0) {
        item.special_offer_item_id = specialOffer._items[0].id;
      }

      AddSpecialOfferParams.items = [item];
    }

    if (offerType === COMBO_OFFER) {
      const newList = [];
      inputList.forEach((input) => {
        const listItem = {
          name: input.itemNameEn1,
          translations: [{
            locale: countryContext.country.code.toLowerCase(),
            name: input.itemNameTranslation1,
          }],
        };
        if (input.special_offer_item_id !== '') {
          listItem.special_offer_item_id = input.special_offer_item_id;
        }
        newList.push(listItem);
      });
      AddSpecialOfferParams.items = newList;
    }

    AddSpecialOfferParams.translations = [currentTranslations];

    if (editing) {
      const offerId = location.pathname.split('/').pop();
      AddSpecialOfferParams.special_offer_id = offerId;
      AddSpecialOfferParams.status = specialOffer._status;
    }

    const endpoint = editing ? 'edit' : 'add';

    (new RequestService(`manager/special-offers/${endpoint}`))
      .setParams(AddSpecialOfferParams)
      .send()
      .then(() => {
        setChanged(false);
        toast.success(t(`success:${endpoint}_offer_success`));
        history.push('/monetize/special-offers');
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t(`apiErrors:could_not_${endpoint}_offer`));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  }

  const handleCancel = () => {
    if (editing) {
      history.push('/monetize/special-offers');
    } else {
      history.push('/monetize/special-offers/type');
    }
  };

  useEffect(() => {
    if (!editing || !userContext.user) return;
    const offerId = location.pathname.split('/').pop();
    (new RequestService('manager/special-offers/single'))
      .setParams({
        special_offer_id: offerId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setOfferType(response.data.type);
        const responseData = { ...response.data };
        if (!responseData._translations[locale]) {
          responseData._translations = {
            [locale]: {
              name: responseData._name,
              description: responseData._description,
            },
          };
        }
        for (let k = 0; k < responseData._items.length; k += 1) {
          if (!responseData._items[k].translations[locale]) {
            responseData._items[k].translations = {
              [locale]: {
                name: responseData._items[k].name,
                description: responseData._items[k].description,
              },
            };
          }
        }
        setSpecialOffer(responseData);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_offer'));
      });
  }, [editing, userContext.user, location.pathname]);

  useEffect(() => {
    if (!specialOffer._id) return;
    if (selectedDays.map(({ selected }) => selected).some((e) => !e)) return;
    if (lunchHours.map(({ selected }) => selected).some((e) => e)) return;
    if (dinnerHours.map(({ selected }) => selected).some((e) => e)) return;

    if (specialOffer._items.length > 1) {
      const newInputList = [];
      specialOffer._items.forEach((item) => {
        newInputList.push({
          special_offer_item_id: item.id,
          itemNameEn1: item.name,
          itemNameTranslation1: item.translations[locale] && item.translations[locale].name,
        });
      });
      setInputList(newInputList);
    }

    if (offerType === SPECIAL_EVENT
      || offerType === ENTERTAINMENT
      || offerType === PROMO_PRICE
      || offerType === COMBO_OFFER) {
      values.name_en = specialOffer._defaultName;
      values.description_en = specialOffer._defaultDescription;
      values.name_translation = specialOffer._translations[locale].name;
      values.description_translation = specialOffer._translations[locale].description;
    }

    if (offerType === DISCOUNT || offerType === FREE_ITEM) {
      values.conditions_en = specialOffer._defaultDescription;
      values.conditions_translation = specialOffer._translations[locale].description;
    }

    if (offerType === DISCOUNT) {
      setPercent(specialOffer._percentage);
    }

    if (offerType === PROMO_PRICE || offerType === COMBO_OFFER) {
      if (specialOffer._percentage) {
        values.percent = specialOffer._percentage;
      } else {
        values.standard_price = specialOffer._price;
        values.discount_price = specialOffer._discountedPrice;
        setStatus(true);
      }
    }

    if (offerType === FREE_ITEM || offerType === PROMO_PRICE) {
      values.item_en = specialOffer._items[0].name;
      values.item_translation = specialOffer._items[0].translations[locale].name;
    }

    const datesAll = specialOffer._intervals.map(({ date }) => date);
    const dates = [...new Set(datesAll)];
    setStart(moment(dates[0]));
    setEnd(moment(dates[dates.length - 1]));

    const daysAll = dates.map((date) => (
      moment(date).weekday()
    ));
    const days = [...new Set(daysAll)];
    const sortedDays = days.sort();

    const newSelectedDays = [...selectedDays];
    for (let i = 0; i < newSelectedDays.length; i += 1) {
      if (sortedDays.includes(newSelectedDays[i].index)) {
        newSelectedDays[i].selected = true;
      } else {
        newSelectedDays[i].selected = false;
      }
    }
    setSelectedDays(newSelectedDays);

    const startTimesAll = specialOffer._intervals.map((interval) => interval.start);
    const endTimesAll = specialOffer._intervals.map((interval) => interval.end);
    const startTimes = [...new Set(startTimesAll)];
    const endTimes = [...new Set(endTimesAll)];
    const times = [];

    for (let i = 0; i < startTimes.length; i += 1) {
      const startTime = moment(startTimes[i], 'HH:mm');
      const endTime = moment(endTimes[i], 'HH:mm');

      while (startTime.isBefore(endTime, 'HH:mm')) {
        times.push(startTime.format('HH:mm'));
        startTime.add(30, 'minutes');
      }
      times.push(endTime.format('HH:mm'));
    }

    const hours = [...lunchHours, ...dinnerHours];

    for (let i = 0; i < hours.length; i += 1) {
      if (times.includes(hours[i].hour)) {
        hours[i].selected = true;
      }
    }

    setLunchHours(hours.slice(0, 14));
    setDinnerHours(hours.slice(14));
  }, [specialOffer, countryContext.country.code, selectedDays, lunchHours, dinnerHours]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem(`${offerType}-tour-status`);
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    // eslint-disable-next-line no-shadow
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem(`${offerType}-tour-status`, 1);
    }
  };

  useEffect(() => {
    if (
      (Object.keys(values).length > 0 && Object.values(values).some((x) => x !== ''))
      || inputList.filter((list) => (list.itemNameEn1 !== '' || list.itemNameTranslation1 !== '')).length > 0
      || selectedDays.filter((day) => !day.selected).length > 0
      || lunchHours.filter((hour) => hour.selected).length > 0
      || dinnerHours.filter((hour) => hour.selected).length > 0
      || start
      || end
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [values, selectedDays, lunchHours, dinnerHours, start, end, inputList]);

  useEffect(() => () => {
    setChanged(false);
  }, []);

  return (
    <div className="inner-wrapper discount-page">
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      {offerType && (
        <>
          <JoyRide
            steps={steps}
            continuous
            showSkipButton
            showProgress
            disableScrolling
            spotlightClicks
            run={run}
            locale={{
              back: t('walktrough:back'),
              close: t('walktrough:close'),
              last: t('walktrough:last'),
              next: t('walktrough:next'),
              skip: t('walktrough:skip'),
            }}
            styles={{
              options: {
                primaryColor: '#ffae42',
              },
            }}
            callback={handleJoyrideCallback}
          />
          <div className="show-tips">
            <Tooltip
              title="show walktrough for current page"
              position="left"
            >
              <Button
                text="?"
                classes="btn-show-tips"
                onClick={() => setRun(true)}
              />
            </Tooltip>
          </div>
        </>
      )}
      <h2 className="section-title underlined">
        <strong>{t('pageSpecialOffers:add_discount_title', { type: t(`pageSpecialOffers:${offerType}`) })}</strong>
      </h2>
      <br />
      <div className="tour-fields">
        {offerType === DISCOUNT && (
        <div className="grid section">
          <div className="grid-col-5">
            <ValueSelect
              label={t('pageSpecialOffers:select_discount')}
              inputLabel={t('pageSpecialOffers:custom_discount')}
              values={[10, 20, 30, 40, 50]}
              unit="%"
              amount={percent}
              setAmount={setPercent}
            />
          </div>
        </div>
        )}
        <div
          className={`
          section
          invertible-grid
          ${(i18n.language === 'bg' || i18n.language === 'pl') ? 'inverted' : ''}
        `}
        >
          <div className="tour-main-fields">
            {offerType === SPECIAL_EVENT && (
              <>
                <p className="label">{t('pageSpecialOffers:special_menu')}</p>
                <SpecialMenuTypeForm
                  setValues={setValues}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                />
              </>
            )}
            {offerType === ENTERTAINMENT && (
              <>
                <p className="label">{t('pageSpecialOffers:entertainment')}</p>
                <EntertainmentTypeForm
                  setValues={setValues}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                />
              </>
            )}
            {offerType === FREE_ITEM && (
              <>
                <p className="label">{t('pageSpecialOffers:item')}</p>
                <FreeItemForm
                  setValues={setValues}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                />
              </>
            )}
            {(offerType === DISCOUNT || offerType === FREE_ITEM) && (
              <>
                <p className="label">{t('pageSpecialOffers:conditions')}</p>
                <ConditionsForm
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  handleChange={handleChange}
                />
              </>
            )}
            {offerType === PROMO_PRICE && (
              <p className="label">{t('pageSpecialOffers:promo_options')}</p>
            )}
            {offerType === COMBO_OFFER && (
              <p className="label">{t('pageSpecialOffers:offer_combo_price')}</p>
            )}
            {(offerType === PROMO_PRICE || offerType === COMBO_OFFER) && (
              <PromoForm
                setValues={setValues}
                values={values}
                errors={errors}
                handleChange={handleChange}
              />
            )}
            {offerType === PROMO_PRICE && (
              <MenuOrCategoryForm
                setValues={setValues}
                values={values}
                errors={errors}
                handleChange={handleChange}
              />
            )}
          </div>
          {offerType === COMBO_OFFER && (
            <ComboItemsForm
              inputList={inputList}
              setInputList={setInputList}
            />
          )}
          {(offerType === PROMO_PRICE || offerType === COMBO_OFFER) && (
            <PercentOrPriceForm
              values={values}
              errors={errors}
              handleChange={handleChange}
              status={status}
              setStatus={setStatus}
              conditionType={offerType === FREE_ITEM ? 'free_item' : 'default'}
            />
          )}
        </div>
      </div>
      <div className="tour-date-time section">
        <p className="label">
          {t('pageSpecialOffers:dates_times_validity')}
          {' '}
          <strong className="text-accent">
            {start ? start.format('DD/MM/YYYY') : t('start_date')}
          </strong>
          {' - '}
          <strong className="text-accent">
            {end ? end.format('DD/MM/YYYY') : t('end_date')}
          </strong>
        </p>
        <div className="grid">
          <div className="tour-date grid-col-5">
            {dateError && (<p className="error">{t('forms:please_choose_dates')}</p>)}
            <DateDayPicker
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              setLunchHours={setLunchHours}
              setDinnerHours={setDinnerHours}
              setDisableDays={setDisableDays}
              disableDays={disableDays}
              setStart={setStart}
              start={start}
              setEnd={setEnd}
              end={end}
            />
          </div>
          <div className="grid-col-5">
            {hoursError && (<p className="error">{t('forms:please_choose_hours')}</p>)}
            <PickHourBlock
              lunchHours={lunchHours}
              setLunchHours={setLunchHours}
              dinnerHours={dinnerHours}
              setDinnerHours={setDinnerHours}
            />
          </div>
        </div>
      </div>
      <div className="center-buttons">
        <Button
          text={t('cancel')}
          classes="btn-accent btn-outline"
          onClick={handleCancel}
        />
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('save')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

AddSpecialOffer.propTypes = {
  editing: PropTypes.bool,
};

AddSpecialOffer.defaultProps = {
  editing: false,
};
