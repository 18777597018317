/* eslint-disable no-param-reassign */
import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ElementsContext from '../../../contexts/ElementsContext';
import useDragResizing from '../../../hooks/useDragResizing';
import useCanvasImage from '../../../hooks/useCanvasImage';
import Calendar from '../../../assets/images/icons/Calendar.svg';
import Clock from '../../../assets/images/icons/Clock.svg';
import Cuisine from '../../../assets/images/icons/Cuisine.svg';
import Map from '../../../assets/images/icons/Map.svg';
import {
  centerImageInCanvas,
  drawRectangle,
  drawResizingCircles,
  resizeElement,
  checkBounds,
  printAtWordWrap,
  getCurrentIcon,
  hexToRgb,
  setFilter,
} from '../helper';
import Button from '../../../react-web-ui/components/Button';

const Canvas = ({
  width,
  height,
  repositioning,
  background,
  intensity,
  filter,
  onDoubleClick,
  focus,
  setFocus,
}) => {
  const { t } = useTranslation();
  const {
    canvasElements,
    setCanvasElements,
    selectedElement,
    setSelectedElement,
  } = useContext(ElementsContext);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const {
    resize,
    setResize,
    dragging,
    setDragging,
    canDrag,
    setCanDrag,
    startingCanvasSizes,
    selectedImage,
    setNewImagePosition,
    imagePosition,
    setCursorPositionImage,
    rectanglePosition,
    widthScaleCorrection,
    heightScaleCorrection,
    getImageStartingPosition,
  } = useCanvasImage(width, height, repositioning, background, canvasContainerRef);
  const {
    dragTopRight,
    dragBottomRight,
    dragTopLeft,
    dragBottomLeft,
    startingElement,
    elementDragging,
    setElementDragging,
    cursorPositionElement,
    setCursorPositionElement,
    resetShapeResizing,
    checkElementBounds,
    checkResizingOrDragging,
  } = useDragResizing();
  const [iconCalendar, setIconCalendar] = useState();
  const [iconClock, setIconClock] = useState();
  const [iconCuisine, setIconCuisine] = useState();
  const [iconMap, setIconMap] = useState();
  const [loadedChefImage, setLoadedChefImage] = useState();
  const [loadedLogoImage, setLoadedLogoImage] = useState();
  const inputRef = useRef();

  useEffect(() => {
    if (focus && inputRef && selectedElement) {
      if (selectedElement.type === 'text') {
        inputRef.current.focus();
        inputRef.current.selectionStart = selectedElement.text.length;
      }
    }
  }, [focus, selectedElement]);

  const getElementProperties = useCallback((element) => {
    let elementPositionX = element.x;
    let elementPositionY = element.y;
    let elementWidth = element.width;
    let elementHeight = element.height;

    if (repositioning) {
      elementPositionX = (element.x * widthScaleCorrection) + rectanglePosition.x;
      elementPositionY = (element.y * heightScaleCorrection) + rectanglePosition.y;
      elementWidth = element.width * widthScaleCorrection;
      elementHeight = element.height * heightScaleCorrection;
    }

    return {
      elementPositionX,
      elementPositionY,
      elementWidth,
      elementHeight,
    };
  }, [
    repositioning,
    widthScaleCorrection,
    heightScaleCorrection,
    rectanglePosition.x,
    rectanglePosition.y,
  ]);

  const drawBackgroundImage = useCallback((context) => {
    const {
      imageHeight,
      imageWidth,
    } = centerImageInCanvas(
      selectedImage,
      repositioning ? startingCanvasSizes.width : width,
      repositioning ? startingCanvasSizes.height : height,
      resize,
    );

    const {
      startingPostitionX,
      startingPostitionY,
    } = getImageStartingPosition(imageWidth, imageHeight);

    if (repositioning) {
      context.globalAlpha = 0.8;
    }
    setFilter(context, filter, intensity);
    context.drawImage(
      selectedImage,
      0,
      0,
      selectedImage.width,
      selectedImage.height,
      startingPostitionX,
      startingPostitionY,
      imageWidth,
      imageHeight,
    );
    context.filter = 'none';
    context.restore();
  }, [
    height,
    width,
    startingCanvasSizes.width,
    startingCanvasSizes.height,
    resize,
    selectedImage,
    repositioning,
    filter,
    intensity,
    getImageStartingPosition,
  ]);

  const drawShape = useCallback((context, element) => {
    const {
      elementPositionX,
      elementPositionY,
      elementWidth,
      elementHeight,
    } = getElementProperties(element);

    if (!element.blur) {
      context.fillStyle = element.backgroundColor;
    } else {
      context.filter = 'blur(20px)';
      context.drawImage(
        canvasRef.current,
        elementPositionX,
        elementPositionY,
        elementWidth,
        elementHeight,
        elementPositionX,
        elementPositionY,
        elementWidth,
        elementHeight,
      );
      context.filter = 'none';
      context.fillStyle = 'rgba(255,255,255,0.2)';
    }
    context.fillRect(elementPositionX, elementPositionY, elementWidth, elementHeight);
  }, [getElementProperties]);

  const drawText = useCallback((context, element) => {
    const {
      elementPositionX,
      elementPositionY,
    } = getElementProperties(element);
    const fontSize = repositioning ? element.fontSize * widthScaleCorrection : element.fontSize;
    const lineHeight = repositioning
      ? element.lineHeight * widthScaleCorrection
      : element.lineHeight;
    const elementWidth = repositioning ? element.width * widthScaleCorrection : element.width;

    context.font = `${element.fontStyle} ${element.fontWeight} ${fontSize}px ${element.fontFamily}`;
    context.fillStyle = element.color;

    if (!focus || selectedElement?.id !== element.id) {
      printAtWordWrap(
        context,
        element.text,
        elementPositionX,
        elementPositionY + parseInt(fontSize, 10),
        elementWidth,
        lineHeight,
        element.textDecoration,
        element.align,
      );
    }
  }, [
    getElementProperties,
    repositioning,
    widthScaleCorrection,
    focus,
    selectedElement,
  ]);

  const drawIconWithText = useCallback((context, element) => {
    const {
      elementPositionX,
      elementPositionY,
    } = getElementProperties(element);
    const fontSize = repositioning ? element.fontSize * widthScaleCorrection : element.fontSize;
    const elementWidth = repositioning ? element.width * widthScaleCorrection : element.width;

    context.font = `bold ${fontSize}px Montserrat`;
    context.fillStyle = element.color;
    const currentIcon = getCurrentIcon(element.icon, iconCalendar, iconClock, iconCuisine, iconMap);

    const rgbColor = hexToRgb(element.iconColor);
    const red = rgbColor[0];
    const green = rgbColor[1];
    const blue = rgbColor[2];

    const svgCanvas = document.createElement('canvas');
    const svgCtx = svgCanvas.getContext('2d');

    if (currentIcon) {
      svgCanvas.width = currentIcon.width;
      svgCanvas.height = currentIcon.height;
      // draw the actual svg image to temporary canvas
      svgCtx.drawImage(currentIcon, 0, 0);
      const svgData = svgCtx.getImageData(0, 0, svgCanvas.width, svgCanvas.height);
      const { data } = svgData;
      for (let i = 0; i < data.length; i += 4) {
        // check if pixel alpha value is not 0, then change the data
        if (data[i + 3] !== 0) {
          data[i] = red;
          data[i + 1] = green;
          data[i + 2] = blue;
        }
      }
      svgCtx.putImageData(svgData, 0, 0);

      context.drawImage(
        svgCanvas,
        elementPositionX - 10,
        elementPositionY - 8,
        fontSize * 1.6,
        fontSize * 1.6,
      );
    }
    if (!focus || selectedElement?.id !== element.id) {
      printAtWordWrap(
        context,
        element.text,
        elementPositionX + (fontSize * 1.4),
        elementPositionY + parseInt(fontSize, 10),
        elementWidth,
        fontSize,
        'none',
        'left',
      );
    }
  }, [
    getElementProperties,
    repositioning,
    widthScaleCorrection,
    iconCalendar,
    iconClock,
    iconCuisine,
    iconMap,
    focus,
    selectedElement,
  ]);

  const drawChefOrLogo = useCallback((context, element) => {
    const {
      elementPositionX,
      elementPositionY,
      elementWidth,
      elementHeight,
    } = getElementProperties(element);
    let imageToDraw = element.type === 'chef' ? loadedChefImage : loadedLogoImage;

    // console.log(element);
    // console.log(elementHeight);

    if (element.type === 'chef' && loadedChefImage) {
      imageToDraw = loadedChefImage;
    }

    if (element.type === 'logo' && loadedLogoImage) {
      imageToDraw = loadedLogoImage;
    }

    if (element.borderRadius) {
      const centerX = elementPositionX + (elementWidth / 2);
      const centerY = elementPositionY + (elementHeight / 2);
      context.save();
      context.beginPath();
      context.arc(centerX, centerY, elementWidth / 2, 0, Math.PI * 2, true);
      if (element.border) {
        context.strokeStyle = element.borderColor;
        context.lineWidth = 4;
        context.stroke();
      }
      context.closePath();
      context.clip();
    }

    if (imageToDraw) {
      context.drawImage(
        imageToDraw,
        elementPositionX,
        elementPositionY,
        elementWidth,
        elementHeight,
      );
    }
    context.restore();
  }, [
    getElementProperties,
    loadedChefImage,
    loadedLogoImage,
  ]);

  const drawSelection = useCallback((context, element) => {
    const {
      elementPositionX,
      elementPositionY,
      elementWidth,
      elementHeight,
    } = getElementProperties(element);
    if (!focus) {
      drawResizingCircles(context, elementPositionX, elementPositionY, elementWidth, elementHeight);
    }
    context.beginPath();
    context.lineWidth = '2';
    context.strokeStyle = '#4082A4';
    context.rect(elementPositionX, elementPositionY, elementWidth, elementHeight);
    context.stroke();
  }, [
    getElementProperties,
    focus,
  ]);

  const drawImage = useCallback(
    (context) => {
      drawBackgroundImage(context);
      if (repositioning) {
        drawRectangle(
          context,
          rectanglePosition.x,
          rectanglePosition.y,
          startingCanvasSizes.width,
          startingCanvasSizes.height,
        );
      }

      if (canvasElements.length > 0) {
        canvasElements.forEach((element) => {
          if (element.type === 'shape') drawShape(context, element);

          if (element.type === 'text') drawText(context, element);

          if (element.type === 'icon-with-text') drawIconWithText(context, element);

          if ((element.type === 'chef' || element.type === 'logo')) drawChefOrLogo(context, element);

          if (selectedElement && selectedElement.id === element.id) drawSelection(context, element);
        });
      }
    },
    [
      drawBackgroundImage,
      drawShape,
      drawText,
      drawIconWithText,
      drawChefOrLogo,
      drawSelection,
      rectanglePosition.x,
      rectanglePosition.y,
      repositioning,
      startingCanvasSizes.width,
      startingCanvasSizes.height,
      canvasElements,
      selectedElement,
    ],
  );

  const downloadCanvas = () => {
    const canvas = canvasRef.current;
    setSelectedElement(null);
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      link.download = 'post.png';
      document.body.appendChild(link);
      link.click();
    }, 100);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    contextRef.current = context;
  }, [width, height, repositioning]);

  useEffect(() => {
    if (!selectedImage) return;

    contextRef.current.clearRect(0, 0, width, height);
    drawImage(contextRef.current);
  }, [selectedImage, drawImage, width, height, startingCanvasSizes]);

  useEffect(() => {
    if (repositioning) {
      setSelectedElement(null);
    }
  }, [repositioning, setSelectedElement]);

  useEffect(() => {
    if (!repositioning) {
      setCanDrag(false);
    }
  }, [repositioning, setCanDrag]);

  useEffect(() => {
    if (!selectedElement) return;
    const img = new Image();
    const currentCanvasElements = [...canvasElements];
    img.src = selectedElement.img;
    img.onload = () => {
      currentCanvasElements.forEach((element) => {
        if (element.id === selectedElement.id && (element.type === 'chef' || element.type === 'logo')) {
          element.width = element.width || img.width;
          element.height = element.height || img.height;
        }
      });
      setCanvasElements(currentCanvasElements);
      if (selectedElement.type === 'chef') {
        setLoadedChefImage(img);
      } else {
        setLoadedLogoImage(img);
      }
    };
  }, [selectedElement?.img]);

  useEffect(() => {
    const img = new Image();
    const img2 = new Image();
    const img3 = new Image();
    const img4 = new Image();
    img.src = Calendar;
    img2.src = Clock;
    img3.src = Cuisine;
    img4.src = Map;

    img.onload = () => {
      setIconCalendar(img);
    };
    img2.onload = () => {
      setIconClock(img2);
    };
    img3.onload = () => {
      setIconCuisine(img3);
    };
    img4.onload = () => {
      setIconMap(img4);
    };
  }, []);

  return (
    <>
      {repositioning && (
        <div className="range-slider image-resize-slider">
          {t('pagePostCreator:resize')}
          <input
            id="resize"
            type="range"
            value={resize}
            min="1"
            max="2"
            step="0.01"
            onChange={(e) => setResize(e.target.value)}
          />
        </div>
      )}
      <div
        className={`canvas-width-wrapper ${canDrag ? 'can-drag' : ''}`}
        style={{
          width,
        }}
      >
        <div
          ref={canvasContainerRef}
          className="post-canvas-container"
          role="button"
          tabIndex={0}
          style={{
            paddingBottom: `${(height / width) * 100}%`,
          }}
          onMouseDown={(e) => {
            if (!repositioning) return;
            if (canDrag) {
              setDragging(true);
              const canvasPositionX = e.clientX - canvasContainerRef.current.offsetLeft;
              const canvasPositionY = (
                e.clientY
                - canvasContainerRef.current.offsetTop
                + document.documentElement.scrollTop
              );

              setCursorPositionImage({
                x: Math.ceil(canvasPositionX - imagePosition.left),
                y: Math.ceil(canvasPositionY - imagePosition.top),
              });
            }
          }}
          onMouseUp={() => setDragging(false)}
          onMouseMove={(e) => {
            if (!repositioning) return;
            const canvasPositionX = e.clientX - canvasContainerRef.current.offsetLeft;
            const canvasPositionY = (
              e.clientY - canvasContainerRef.current.offsetTop + document.documentElement.scrollTop
            );

            if (
              checkBounds(
                canvasPositionX,
                canvasPositionY,
                imagePosition.left,
                imagePosition.left + imagePosition.width,
                imagePosition.top,
                imagePosition.top + imagePosition.height,
              )
            ) {
              setCanDrag(true);
            } else {
              setCanDrag(false);
            }

            if (dragging) {
              setNewImagePosition({
                x: Math.ceil(canvasPositionX),
                y: Math.ceil(canvasPositionY),
              });
            }
          }}
        >
          {!background && (
            <p className="no-image">{t('pagePostCreator:select_image_from_library')}</p>
          )}
          <canvas
            ref={canvasRef}
            onMouseDown={(e) => {
              if (repositioning) return;
              const canvasPositionX = e.clientX - canvasContainerRef.current.offsetLeft;
              const canvasPositionY = (
                e.clientY
                - canvasContainerRef.current.offsetTop
                + document.documentElement.scrollTop
              );
              setCursorPositionElement({
                x: 0,
                y: 0,
              });
              let clickedElement = 0;
              let newSelectedElement = {};

              canvasElements.forEach((element) => {
                const elementX = element.x * widthScaleCorrection;
                const elementY = element.y * heightScaleCorrection;
                const elementWidth = (element.x + element.width) * widthScaleCorrection;
                const elementHeight = (element.y + element.height) * heightScaleCorrection;

                if (checkElementBounds(
                  canvasPositionX,
                  canvasPositionY,
                  element,
                  elementX,
                  elementY,
                  elementWidth,
                  elementHeight,
                )) {
                  clickedElement += 1;
                  newSelectedElement = checkElementBounds(
                    canvasPositionX,
                    canvasPositionY,
                    element,
                    elementX,
                    elementY,
                    elementWidth,
                    elementHeight,
                  );
                }

                checkResizingOrDragging(
                  canvasPositionX,
                  canvasPositionY,
                  element,
                  elementX,
                  elementY,
                  elementWidth,
                  elementHeight,
                );
              });

              if (clickedElement === 0) {
                setSelectedElement(null);
                setFocus(false);
              }

              if (focus) {
                if (
                  newSelectedElement.id === selectedElement?.id
                  && newSelectedElement.type === 'text'
                ) {
                  setFocus(Math.random());
                } else {
                  setFocus(false);
                }
              }
            }}
            onMouseUp={() => {
              setElementDragging(null);
              resetShapeResizing();
            }}
            onMouseMove={(e) => {
              if (repositioning || focus) return;
              if (
                elementDragging !== null
                || dragTopLeft.drag
                || dragTopRight.drag
                || dragBottomLeft.drag
                || dragBottomRight.drag
              ) {
                const canvasPositionX = e.clientX - canvasContainerRef.current.offsetLeft;
                const canvasPositionY = (e.clientY
                - canvasContainerRef.current.offsetTop
                + document.documentElement.scrollTop);
                const newPositionX = Math.ceil(
                  (canvasPositionX - cursorPositionElement.x) / widthScaleCorrection,
                );
                const newPositionY = Math.ceil(
                  (canvasPositionY - cursorPositionElement.y) / heightScaleCorrection,
                );
                const currentCanvasElements = [...canvasElements];
                currentCanvasElements.forEach((element) => {
                  if (element.id === selectedElement.id && (
                    element.id === dragTopLeft.index
                    || element.id === dragTopRight.index
                    || element.id === dragBottomLeft.index
                    || element.id === dragBottomRight.index
                  )) {
                    resizeElement(
                      element,
                      dragTopLeft.drag,
                      dragTopRight.drag,
                      dragBottomLeft.drag,
                      dragBottomRight.drag,
                      startingElement,
                      newPositionX,
                      newPositionY,
                    );
                  }
                  if (element.id === elementDragging) {
                    element.x = newPositionX;
                    element.y = newPositionY;
                  }
                });
                setCanvasElements(currentCanvasElements);
              }
            }}
            onDoubleClick={(e) => {
              if (repositioning) return;
              const canvasPositionX = e.clientX - canvasContainerRef.current.offsetLeft;
              const canvasPositionY = (
                e.clientY
                - canvasContainerRef.current.offsetTop
                + document.documentElement.scrollTop
              );

              let doubleClickOnElement = 0;

              canvasElements.forEach((element) => {
                const elementX = element.x * widthScaleCorrection;
                const elementY = element.y * heightScaleCorrection;
                const elementWidth = (element.x + element.width) * widthScaleCorrection;
                const elementHeight = (element.y + element.height) * heightScaleCorrection;

                if (checkElementBounds(
                  canvasPositionX,
                  canvasPositionY,
                  element,
                  elementX,
                  elementY,
                  elementWidth,
                  elementHeight,
                )) {
                  doubleClickOnElement += 1;
                }
              });
              onDoubleClick(doubleClickOnElement);
            }}
          />
          {focus && selectedElement && (
            <div
              className="editable-canvas-text"
              style={{
                top: selectedElement.y * heightScaleCorrection,
                left: selectedElement.x * widthScaleCorrection,
                width: selectedElement.width * widthScaleCorrection,
                height: selectedElement.height * heightScaleCorrection,
                paddingTop: (selectedElement.lineHeight / 4) * heightScaleCorrection,
                paddingLeft: selectedElement.type === 'icon-with-text' ? `${selectedElement.fontSize * widthScaleCorrection * 1.6 - 8}px` : '0',
              }}
            >
              <textarea
                ref={inputRef}
                type="text"
                id="text"
                name="text"
                value={selectedElement?.text}
                onChange={(e) => {
                  const currentCanvasElements = [...canvasElements];
                  currentCanvasElements.forEach((element) => {
                    if (element.id === selectedElement?.id) {
                      element.text = e.target.value;
                      if ((e.target.scrollHeight / heightScaleCorrection) > element.height) {
                        element.height = (e.target.scrollHeight / heightScaleCorrection) + 10;
                      }
                    }
                  });
                  setCanvasElements(currentCanvasElements);
                }}
                style={{
                  fontFamily: selectedElement.fontFamily,
                  fontSize: `${selectedElement.fontSize * widthScaleCorrection}px`,
                  lineHeight: `${selectedElement.lineHeight * heightScaleCorrection}px`,
                  fontWeight: selectedElement.fontWeight,
                  fontStyle: selectedElement.fontStyle,
                  textDecoration: selectedElement.textDecoration,
                  textAlign: selectedElement.align,
                }}
              />
            </div>
          )}
        </div>
      </div>
      {background && !repositioning && (
        <Button
          text={t('pagePostCreator:download')}
          classes="btn-accent btn-download"
          onClick={downloadCanvas}
        />
      )}
    </>
  );
};

Canvas.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  repositioning: PropTypes.bool,
  background: PropTypes.string,
  intensity: PropTypes.number,
  filter: PropTypes.string,
  onDoubleClick: PropTypes.func,
  focus: PropTypes.bool,
  setFocus: PropTypes.func,
};

Canvas.defaultProps = {
  repositioning: false,
  background: '',
  intensity: 50,
  filter: '',
  onDoubleClick: null,
  focus: false,
  setFocus: null,
};

export default Canvas;
