import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './lang/en';
import bg from './lang/bg';
import pl from './lang/pl';
import es from './lang/es';

const COUNTRIES = {
  'restaurant.dineout.bg': 'bg',
  'restaurant.dineout.pl': 'pl',
  'restaurant.dineout.es': 'es',
};

const allowedLanguages = ['en', 'bg', 'pl', 'es'];

let lng = COUNTRIES[process.env.REACT_APP_DOMAIN];

const storageLanguage = localStorage.getItem('i18nextLng');
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
  lng = storageLanguage;
}

const resources = {
  en,
  bg,
  pl,
  es,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng,
    defaultNS: 'common',
    resources,
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
