import React from 'react';
import PropTypes from 'prop-types';

/**
 * Used to display multiple content loaders of the same type.
 * @param ContentLoader
 * @param numberOfItems
 * @param containerStyle
 * @param containerClasses
 * @param itemStyle
 * @param itemClasses
 * @returns {*}
 * @constructor
 */
export default function CollectionLoader({
  contentLoader,
  numberOfItems,
  containerStyle,
  containerClasses,
  itemStyle,
  itemClasses,
}) {
  return (
    <div style={containerStyle} className={containerClasses}>
      {
        [...Array(numberOfItems)].map((e, i) => (
          <div key={i.toString()} style={itemStyle} className={itemClasses}>
            {contentLoader}
          </div>
        ))
      }
    </div>
  );
}

CollectionLoader.propTypes = {
  /**
   * Content loader to display.
   */
  contentLoader: PropTypes.node.isRequired,
  /**
   * Number of content loaders.
   */
  numberOfItems: PropTypes.number.isRequired,
  /**
   * Custom styles for the container of the Collection Loader.
   */
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * Custom classes for the container of the Collection Loader.
   */
  containerClasses: PropTypes.string,
  /**
   * Custom styles for the element wrapping each Content Loader.
   */
  itemStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * Custom classes for the element wrapping each Content Loader.
   */
  itemClasses: PropTypes.string,

};

CollectionLoader.defaultProps = {
  containerStyle: null,
  containerClasses: null,
  itemStyle: null,
  itemClasses: null,
};
