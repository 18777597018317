import React from 'react';
import PropTypes from 'prop-types';

/**
 * Restaurant info block
 * @param categorySelect
 * @param menu
 * @param legend
 * @param button
 * @returns {*}
 * @constructor
 */
export default function RestaurantMenu({
  categorySelect,
  menu,
  legend,
  button,
}) {
  return (
    <div className="restaurant-menu">
      {categorySelect}
      {menu}
      {legend}
      {button}
    </div>
  );
}

RestaurantMenu.propTypes = {
  categorySelect: PropTypes.element,
  menu: PropTypes.element.isRequired,
  legend: PropTypes.element,
  button: PropTypes.element,
};

RestaurantMenu.defaultProps = {
  categorySelect: null,
  legend: null,
  button: null,
};
