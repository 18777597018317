import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';

import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import ReviewItem from '../../react-web-ui/components/ReviewItem';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';
import { ReactComponent as Reply } from '../../assets/images/icons/Reply.svg';
import { ReactComponent as Logo } from '../../react-web-ui/assets/images/logo.svg';

export default function ReviewItemWrapper({ review, setLoading }) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [replyForm, setReplyForm] = useState(false);
  const [replyText, setReplyText] = useState('');

  const handleReplySubmit = () => {
    if (replyText.trim().length < 100) return;
    setLoading(true);
    (new RequestService('manager/reviews/reply'))
      .setParams({
        review_id: review.id,
        user_id: userContext.user.id,
        content: replyText,
      })
      .send()
      .then(() => {
        toast.success(t('success:reply_success'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_reply'));
      })
      .finally(() => {
        setLoading(false);
        setReplyForm(false);
      });
  };

  return (
    <>
      <ReviewItem
        id={review.id}
        avatar={review.avatar}
        username={review.reviewer}
        reviewNumber={review.authorReviews}
        date={moment(review.datetime).format('DD.MM.YYYY')}
        reviewText={review.text}
        reviewTip={review.tip}
      />
      <div className="reply-wrapper">
        <Logo />
        <button
          className="reply-btn"
          type="button"
          onClick={() => setReplyForm(!replyForm)}
        >
          <Reply className="icon" />
          {`${t('pageReviews:reply_to')} `}
          <strong>{review.reviewer}</strong>
        </button>
      </div>
      {replyForm && (
        <div className="reply-form">
          <FormElement
            className="gray-bg"
            elementType="textarea"
            placeholder={t('pageReviews:reply_here')}
            value={replyText}
            changed={(e) => setReplyText(e.target.value)}
          />
          <div className="reply-text-symbols">
            {t('pageReviews:review_text_should_be_100_symbols')}
            {replyText.trim().length > 0 && (
              <span className={`${replyText.trim().length < 100 ? 'error' : ''}`}>
                {`${t('pageReviews:current_symbols')} ${replyText.trim().length}`}
              </span>
            )}
          </div>
          <Button
            text={t('pageReviews:post_reply')}
            classes="btn-accent"
            onClick={handleReplySubmit}
          />
        </div>
      )}
      {review.replies.length > 0 && (
        review.replies.map((reply) => (
          <ReviewItem
            key={reply.id}
            id={reply.id}
            className="reply-item"
            username={t('pageReviews:restaurant_reply')}
            date={reply.submittedOn.toLocaleDateString('bg', {
              year: 'numeric', month: 'numeric', day: 'numeric',
            })}
            reviewText={reply.content}
          />
        ))
      )}
    </>
  );
}

ReviewItemWrapper.propTypes = {
  review: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setLoading: PropTypes.func.isRequired,
};
