import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker } from '@react-google-maps/api';

import CountryContext from '../../contexts/CountryContext';
import { ReactComponent as MapIcon } from '../../assets/images/icons/Map.svg';
import { ReactComponent as Clock } from '../../assets/images/icons/Clock.svg';
import { ReactComponent as Company } from '../../assets/images/icons/Company.svg';
import { ReactComponent as PhoneRing } from '../../assets/images/icons/PhoneRing.svg';

export default function ContactPage({ rightColumn }) {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);
  const [mapCenter, setMapCenter] = useState({
    lat: 42.677926,
    lng: 23.322176,
  });

  const options = {
    streetViewControl: false,
    scrollwheel: false,
    mapTypeControl: false,
  };

  useEffect(() => {
    if (countryContext.country.code === 'BG') {
      setMapCenter({
        lat: 42.677926,
        lng: 23.322176,
      });
    }
    if (countryContext.country.code === 'PL') {
      setMapCenter({
        lat: 52.2315259,
        lng: 21.016595,
      });
    }
  }, [countryContext.country]);

  return (
    <div className="inner-wrapper">
      <h1 className="section-title underlined">
        <strong>{t('pageContact:title')}</strong>
      </h1>
      <div className="column-layout clear-both">
        <div className="main-column">
          <div className="bordered-info-block contact-block">
            <div className="lg-two-cols">
              <div className="col contact-info-block">
                <Company className="icon" />
                <h3 className="text-gray">{t('pageContact:company')}</h3>
                <p>
                  {t(`pageContact:company_text_${countryContext.country.name}`)}
                </p>
              </div>
              <div className="col contact-info-block">
                <PhoneRing className="icon" />
                <h3 className="text-gray">{t('pageContact:phone')}</h3>
                <p>
                  {t(`pageContact:phone_text_${countryContext.country.name}`)}
                </p>
              </div>
              <div className="col contact-info-block">
                <MapIcon className="icon" />
                <h3 className="text-gray">{t('pageContact:location')}</h3>
                <p>
                  {t(`pageContact:address_text_${countryContext.country.name}`)}
                </p>
              </div>
              <div className="col contact-info-block">
                <Clock className="icon" />
                <h3 className="text-gray">{t('pageContact:work_hours')}</h3>
                <p>
                  {t(`pageContact:work_hours_text_${countryContext.country.name}`)}
                </p>
              </div>
            </div>
            <div id="contact-map">
              <GoogleMap
                id="googlemap"
                zoom={15}
                center={mapCenter}
                options={options}
                clickableIcons={false}
              >
                <Marker position={{ lat: mapCenter.lat, lng: mapCenter.lng }} />
              </GoogleMap>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="bordered-info-block">
            {rightColumn}
          </div>
        </div>
      </div>
    </div>
  );
}

ContactPage.propTypes = {
  rightColumn: PropTypes.element.isRequired,
};
