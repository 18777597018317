import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import useForm from '../../react-web-ui/hooks/useForm';
import validate from './validation';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';
import Checkbox from '../../react-web-ui/components/FormElement/Checkbox';
import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import Spinner from '../../react-web-ui/components/Spinner';
import CustomPhoneInput from '../../components/CustomPhoneInput';

export default function Settings() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState('');

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    const params = {
      user_id: userContext.user.id,
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      is_subscribed: values.accept_promo_newsletter,
    };

    if (values.new_password) {
      params.password = values.new_password;
      params.password_confirmation = values.repeat_new_password;
      params.password_v2 = true;
    }

    setLoading(true);
    (new RequestService('manager/users/update'))
      .setParams(params)
      .send()
      .then((response) => {
        toast.success(t('success:user_update_success'));
        userContext.setUser(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_update_user'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!userContext.user) return;

    setValues({
      email: userContext.user.email,
      first_name: userContext.user.firstName,
      last_name: userContext.user.lastName,
      phone: userContext.user.phone || '',
      accept_promo_newsletter: userContext.user.marketing?.isSubscribed,
    });

    const portalParams = {
      user_id: userContext.user.id,
      redirect_to: window.location.origin,
    };
    (new RequestService('manager/subscription/portal'))
      .setParams(portalParams)
      .send()
      .then((response) => {
        setLink(response.data.url);
      })
      .catch(() => null);
  }, [userContext.user]);

  return (
    <div className="inner-wrapper">
      <div className="grid bigger-gap">
        <div className="grid-col-4">
          <h2 className="section-title">
            <strong>{t('pageSettings:personal_info_title')}</strong>
          </h2>
          <form action="" noValidate>
            <FormElement
              id="email"
              elementType="input"
              inputType="email"
              placeholder={t('forms:email')}
              value={values.email || ''}
              errors={errors.email}
              changed={handleChange}
              disabled
            />
            <FormElement
              id="first_name"
              elementType="input"
              placeholder={t('forms:first_name')}
              value={values.first_name || ''}
              errors={errors.first_name}
              changed={handleChange}
            />
            <FormElement
              id="last_name"
              elementType="input"
              placeholder={t('forms:last_name')}
              value={values.last_name || ''}
              errors={errors.last_name}
              changed={handleChange}
            />
            <div className="phone-wrapper">
              <CustomPhoneInput
                values={values}
                setValues={setValues}
                errors={errors.phone}
                noLabel
              />
              {!userContext.user?.phoneVerifiedAt && userContext.user?.phone !== null && (
                <>
                  <Link to="/account/verify-phone" className="btn-link">
                    {t('verify')}
                  </Link>
                  <p className="important">!</p>
                </>
              )}
            </div>
            <FormElement
              id="new_password"
              elementType="input"
              inputType="password"
              placeholder={t('forms:new_password')}
              value={values.new_password || ''}
              errors={errors.new_password}
              changed={handleChange}
            />
            <FormElement
              id="repeat_new_password"
              elementType="input"
              inputType="password"
              placeholder={t('forms:repeat_new_password')}
              value={values.repeat_new_password || ''}
              errors={errors.repeat_new_password}
              changed={handleChange}
            />
            <Checkbox
              id="accept_promo_newsletter"
              text={(
                <span>
                  {t('selfOnboarding:accept_promo')}
                </span>
              )}
              value={`${values.accept_promo_newsletter}`}
              checked={values.accept_promo_newsletter}
              check={handleChange}
              errors={errors.accept_promo_newsletter}
            />
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('save')}
              classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
              disabled={loading}
              onClick={handleSubmit}
            />
          </form>
        </div>
        <div className="grid-col-3">
          <h2 className="section-title">
            <strong>{t('pageSettings:billing')}</strong>
          </h2>
          <div className="billing-links">
            <a href={link} target="_blank" rel="noreferrer">{t('pageSettings:manage_subscriptions')}</a>
            <a href={link} target="_blank" rel="noreferrer">{t('pageSettings:update_billing_info')}</a>
            <a href={link} target="_blank" rel="noreferrer">{t('pageSettings:invoices')}</a>
            <a href={link} target="_blank" rel="noreferrer">{t('pageSettings:cancel_subscription')}</a>
          </div>
        </div>
      </div>
    </div>
  );
}
