import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';

import ItemTypes from './ItemTypes';

const Element = ({
  type,
  width,
  height,
  minCapacity,
  maxCapacity,
  name,
  resizable,
  rotation,
  icon,
}) => {
  const [{ isDragging, dragPreview }, drag] = useDrag({
    item: {
      type,
      width: rotation === 90 ? height : width,
      height: rotation === 90 ? width : height,
      resizable,
      name,
      minCapacity,
      maxCapacity,
      size: 'M',
      rotation,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag(),
      didDrop: monitor.didDrop(),
    }),
  });

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div
        style={{
          width: rotation === 90 ? height : width,
          height: rotation === 90 ? width : height,
        }}
        className={`
          element element-${type.toLowerCase()}
          ${type === ItemTypes.BAR.name || type === ItemTypes.KITCHEN.name ? 'bordered-medium' : ''}
          ${type === ItemTypes.ENTRANCE.name || type === ItemTypes.WC.name ? 'bordered-light' : ''}
        `}
        ref={drag}
      >
        {icon}
        {name}
      </div>
    </div>
  );
};

Element.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  minCapacity: PropTypes.number,
  maxCapacity: PropTypes.number,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  resizable: PropTypes.bool,
  rotation: PropTypes.number,
  icon: PropTypes.element,
};

Element.defaultProps = {
  name: '',
  minCapacity: 0,
  maxCapacity: 0,
  rotation: 0,
  resizable: false,
  icon: null,
};

export default Element;
