import PropTypes from 'prop-types';
import React from 'react';

/**
 * Menu item
 * @param icon
 * @param name
 * @param price
 * @param description
 * @param allergens
 * @returns {*}
 * @constructor
 */
export default function MenuItem({
  icon,
  name,
  price,
  description,
  allergens,
}) {
  return (
    <div className="menu-item">
      <div className="text">
        <p>
          {icon}
          {name}
          {allergens && <sup>{allergens}</sup>}
        </p>
        {description && <small>{description}</small>}
      </div>
      <div className="price">{price}</div>
    </div>
  );
}

MenuItem.propTypes = {
  /** Name of menu item. */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /** Price of menu item. */
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]).isRequired,
  /** Description of menu item. */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Allergen numbers of menu item. */
  allergens: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** icon element */
  icon: PropTypes.element,
};

MenuItem.defaultProps = {
  description: '',
  allergens: '',
  icon: null,
};
