import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    errorEmpty: i18n.t('forms:error_empty'),
  };

  // Restaurant Name
  errors.restaurant_name = [];
  if (!values.restaurant_name || values.restaurant_name.trim() === '') {
    errors.restaurant_name.push(errorTexts.errorEmpty);
  }

  // Cuisines
  errors.cuisines_select = [];
  if (!values.cuisines_select || values.cuisines_select.trim() === '') {
    errors.cuisines_select.push(errorTexts.errorEmpty);
  }

  // Restaurant Address
  errors.restaurant_address = [];
  if (!values.restaurant_address || values.restaurant_address.trim() === '') {
    errors.restaurant_address.push(errorTexts.errorEmpty);
  }

  // Restaurant City
  errors.restaurant_city = [];
  if (!values.restaurant_city || values.restaurant_city.trim() === '') {
    errors.restaurant_city.push(errorTexts.errorEmpty);
  }

  // restaurant_neighborhood
  errors.restaurant_neighborhood = [];
  if (!values.restaurant_neighborhood || values.restaurant_neighborhood.trim() === '') {
    errors.restaurant_neighborhood.push(errorTexts.errorEmpty);
  }
  // restaurant_post_code
  errors.restaurant_post_code = [];
  if (!values.restaurant_post_code || values.restaurant_post_code.trim() === '') {
    errors.restaurant_post_code.push(errorTexts.errorEmpty);
  }

  return errors;
};

export default validate;
