import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import JoyRide, { STATUS } from 'react-joyride';

import RequestService from '../../networking/RequestService';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import ReviewCircleRating from '../../react-web-ui/components/ReviewCircleRating';
import Rating from '../../react-web-ui/components/Rating';
import ReviewItemWrapper from '../../components/ReviewItemWrapper';
import ReviewItem from '../../react-web-ui/components/ReviewItem';
import { ReactComponent as Logo } from '../../react-web-ui/assets/images/logo.svg';
import { ReactComponent as Google } from '../../assets/images/google.svg';
import { ReactComponent as Facebook } from '../../assets/images/facebook.svg';
import { ReactComponent as Tripadvisor } from '../../assets/images/tripadvisor.svg';
import ReviewsLinks from '../../components/ReviewsLinks';
import Button from '../../react-web-ui/components/Button';
import Tooltip from '../../react-web-ui/components/Tooltip';

export default function Reviews() {
  const { t } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [restaurantReviews, setRestaurantReviews] = useState([]);
  const [restaurantRatings, setRestaurantRatings] = useState([]);
  const [thirdPartyReviews, setThirdPartyReviews] = useState([]);
  const [allRestaurantReviews, setAllRestaurantReviews] = useState([]);
  const [starRatings, setStarRatings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const stars = [5, 4, 3, 2, 1];

  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: '.tour-links',
      content: 'This is the central place to keep up with all the important reviews and your restaurant’s online reputation. Start by correctly your restaurant’s name as it appears in Google maps and select it from the dropdown. Then provide links to your Facebook page and TripAdvisor profile. Make sure to click SAVE. Your reviews from third-party sources will be updated once a day. Your Dineout reviews will appear here instantly.',
      disableBeacon: true,
    },
    {
      target: '.tour-ratings',
      content: 'You can see a detailed rating for your restaurant on Dineout.',
    },
  ];

  useEffect(() => {
    if (!userContext.user || !restaurantContext.restaurantId) return;
    (new RequestService('client/reviews/place'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        sort: 'submitted',
      })
      .send()
      .then((response) => {
        setRestaurantReviews(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_reviews'));
      });
    (new RequestService('client/places/ratings'))
      .setParams({
        place_id: restaurantContext.restaurantId,
      })
      .send()
      .then((response) => {
        setRestaurantRatings(response.data.ratings);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_ratings'));
      });
    if (userContext.user) {
      (new RequestService('manager/reviews/third-party'))
        .setParams({
          place_id: restaurantContext.restaurantId,
          user_id: userContext.user.id,
        })
        .send()
        .then((response) => {
          setThirdPartyReviews(response.data);
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:could_not_get_third_party_reviews'));
        });
    }
  }, [restaurantContext.restaurantId, userContext.user, loading]);

  useEffect(() => {
    if (restaurantContext.restaurant !== null) {
      setInitialFormValues({
        facebook_link: restaurantContext.restaurant.facebookPageLink,
        tripadvisor_link: restaurantContext.restaurant.tripadvisorPageLink,
      });
    } else {
      setInitialFormValues({});
    }
  }, [restaurantContext.restaurant]);

  useEffect(() => {
    if (restaurantRatings.length === 0) return;
    const foodRatings = restaurantRatings[0].food[0].ratings_summary;
    const serviceRatings = restaurantRatings[1].service[0].ratings_summary;
    const ambienceRatings = restaurantRatings[2].ambience[0].ratings_summary;
    const starRatingsCurrent = {};

    [5, 4, 3, 2, 1].forEach((star) => {
      const foodRatingStar = foodRatings.filter(
        (summary) => summary.rating === star,
      );
      const serviceRatingStar = serviceRatings.filter(
        (summary) => summary.rating === star,
      );
      const ambienceRatingStar = ambienceRatings.filter(
        (summary) => summary.rating === star,
      );
      starRatingsCurrent[star] = {
        food: foodRatingStar[0] ? foodRatingStar[0].ratings_count : 0,
        service: serviceRatingStar[0] ? serviceRatingStar[0].ratings_count : 0,
        ambience: ambienceRatingStar[0] ? ambienceRatingStar[0].ratings_count : 0,
      };
    });
    setStarRatings(starRatingsCurrent);
  }, [restaurantRatings]);

  useEffect(() => {
    const allReviews = [];
    const newArray = [
      ...restaurantReviews,
      ...thirdPartyReviews,
    ];
    newArray.forEach((review) => {
      let type = 'dineout';
      if (review.source) {
        type = review.source.toLowerCase();
      }
      allReviews.push({
        id: review.id,
        reviewer: review.authorName || review.reviewer,
        datetime: review.submittedOn || review.datetime,
        avatar: review.authorThumb || review.reviewer_avatar || review.avatar || null,
        authorReviews: review.authorReviews || null,
        title: review.title || null,
        text: review.content || review.text || '',
        tip: review.tip || null,
        rating: review.rating || null,
        recommends: review.recommends_rating || null,
        replies: review.replies || null,
        url: review.url || null,
        type,
      });
    });
    const newAllReviews = allReviews.sort((a, b) => moment(b.datetime).format('YYYYMMDD') - moment(a.datetime).format('YYYYMMDD'));
    setAllRestaurantReviews(newAllReviews);
  }, [restaurantReviews, thirdPartyReviews]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('reviews-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('reviews-tour-status', 1);
    }
  };

  return (
    <div className="inner-wrapper ratings-page">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <ReviewsLinks
        loading={loading}
        setLoading={setLoading}
        initialValues={initialFormValues}
      />
      <h4 className="text-center text-tasting">{t('pageReviews:third_party_reviews_info')}</h4>
      <div className="column-layout">
        <div className="main-column">
          {allRestaurantReviews.map((review, index) => {
            if (review.replies) {
              return (
                <ReviewItemWrapper
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${review.id}${index}`}
                  review={review}
                  setLoading={setLoading}
                />
              );
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${review.id}${index}`} className={`outside-review ${review.type}-review`}>
                <ReviewItem
                  id={review.id}
                  username={review.reviewer}
                  avatar={review.avatar}
                  date={moment(review.datetime).format('DD.MM.YYYY')}
                  reviewText={(
                    <>
                      {review.rating && <Rating stars={parseFloat(review.rating)} />}
                      {review.title && <h3>{review.title}</h3>}
                      <p>
                        {review.text}
                      </p>
                    </>
                  )}
                />
                <div className="reply-wrapper">
                  {review.type === 'google' && <Google />}
                  {review.type === 'facebook' && <Facebook />}
                  {review.type === 'tripadvisor' && <Tripadvisor />}
                  <a className="btn btn-accent" href={review.url} target="_blank" rel="noreferrer">
                    {`${t('pageReviews:reply_at')} `}
                    {review.type}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="tour-ratings right-column">
          <Logo className="site-logo" />
          <div className="rating-info bordered-info-block">
            <div className="total-rating">
              <h3>{t('pageReviews:total_rating')}</h3>
              {restaurantContext.restaurant && (
                <div>
                  <Rating
                    stars={restaurantContext.restaurant.rateStars}
                  />
                  <small>{`(${restaurantContext.restaurant.rateCount} ${t('pageReviews:ratings')})`}</small>
                </div>
              )}
            </div>
            {restaurantContext.restaurant && (
              <div className="circles-wrapper">
                <ReviewCircleRating
                  title={t('pageReviews:food')}
                  rating={restaurantContext.restaurant.rateFood}
                />
                <ReviewCircleRating
                  title={t('pageReviews:service')}
                  rating={restaurantContext.restaurant.rateService}
                />
                <ReviewCircleRating
                  title={t('pageReviews:ambience')}
                  rating={restaurantContext.restaurant.rateAmbience}
                />
              </div>
            )}
          </div>
          <div className="bordered-info-block">
            <div className="rating-summary-header">
              <h3>{t('pageReviews:rating_summary')}</h3>
              <p>{t('pageReviews:rating_summary_text')}</p>
            </div>
            {starRatings && (
              <table className="rating-summary">
                <thead>
                  <tr>
                    <th>{t('pageReviews:rating')}</th>
                    <th>{t('pageReviews:food')}</th>
                    <th>{t('pageReviews:service')}</th>
                    <th>{t('pageReviews:ambience')}</th>
                  </tr>
                </thead>
                <tbody>
                  {stars.map((star) => (
                    <tr key={star}>
                      <td><Rating stars={star} /></td>
                      <td>
                        {starRatings[star].food}
                      </td>
                      <td>
                        {starRatings[star].service}
                      </td>
                      <td>
                        {starRatings[star].ambience}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {/* <img className="site-logo" src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg" alt="Tripadvisor" />
          <div className="bordered-info-block"></div>
          <img src="https://www.google.co.uk/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png" alt="" className="site-logo" />
          <div className="bordered-info-block"></div>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Facebook_Logo_%282019%29.svg/1280px-Facebook_Logo_%282019%29.svg.png" alt="" className="site-logo"/>
          <div className="bordered-info-block"></div> */}
        </div>
      </div>
    </div>
  );
}
