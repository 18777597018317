import React, { useState, useContext, useEffect } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import { initialInputList, initialEventSpecificFields } from './constants';
import enumerateDaysBetweenDates from '../../../utilities/enumerateDaysBetweenDates';
import useSpecialEvents from '../../../hooks/useSpecialEvents';
import convertHours from '../../../utilities/convertHours';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import RequestService from '../../../networking/RequestService';
import Button from '../../../react-web-ui/components/Button';
import PickHourBlock from '../../../components/PickHourBlock';
import EventMenuSection from '../../../components/EventMenuSection';
import DateDayPicker from '../../../components/DateDayPicker';
import FormElement from '../../../react-web-ui/components/FormElement';
import DatePicker from '../../../components/DatePicker';
import Spinner from '../../../react-web-ui/components/Spinner';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import Tooltip from '../../../react-web-ui/components/Tooltip';
import useExitPrompt from '../../../hooks/useExitPrompt';

export default function AddEvent({ editing, copy }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const countryContext = useContext(CountryContext);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const locale = countryContext.country.code.toLowerCase();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [hoursError, setHoursError] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [lunchHours, setLunchHours] = useState([]);
  const [dinnerHours, setDinnerHours] = useState([]);
  const [event, setEvent] = useState({});
  const [errors, setErrors] = useState([]);
  const [prebookTime, setPrebookTime] = useState();
  const [allocations, setAllocations] = useState(null);
  const [lastSaleDate, setLastSaleDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useExitPrompt(false);
  const [eventSpecificFields, setEventSpecificFields] = useState({ ...initialEventSpecificFields });
  const [eventSpecificErrors, setEventSpecificErrors] = useState({
    eventNameEn: [],
    eventNameTranslation: [],
    eventDescriptionEn: [],
    eventDescriptionTranslation: [],
  });
  const [inputList, setInputList] = useState(
    [JSON.parse(JSON.stringify(initialInputList))],
  );
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  const {
    createSets,
    checkFields,
    createSetsInputs,
  } = useSpecialEvents();

  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: '.tour-main-fields',
      content: 'This section will help you sell your event. Add a distinctive name and a short paragraph to describe it. The language of the in-field suggestion will help you decide which language to use - English or {country_language}',
      disableBeacon: true,
    },
    {
      target: '.tour-menu-options',
      content: 'Your culinary event needs at least one menu set. The set can contain as many courses as you need and it needs an appealing name. Versions in both languages are required.',
    },
    {
      target: '.tour-price',
      content: 'Enter the price for the first set, excluding drinks.',
    },
    {
      target: '.tour-drinks',
      content: 'You can offer a drinks pairing for this specific set at an additional price (optional)',
    },
    {
      target: '.tour-add-menu',
      content: 'You can add as menu sets as you need, for example one vegetarian, one poultry, and one fish set. Each set has an individual price and an option to add drinks pairing at a separate price.',
    },
    {
      target: '.tour-date',
      content: 'Select a single date for one off events. You can select longer periods for reappearing events.',
    },
    {
      target: '.tour-hour',
      content: 'Indicate the start time of your event.',
    },
    {
      target: '.tour-prebook',
      content: 'You can set a prebook time, specific for this event. It’s the minimum time you require before a reservation for the event. You can also specify a last sale date if your event requires a lot of preparation. Lastly you can limit the number of covers for this event sold by Dineout.',
    },
  ];

  const handleCancel = () => {
    history.push('/monetize/events');
  };

  const checkEventSpecificFields = () => {
    let submitCheck = true;
    const currentErrors = JSON.parse(JSON.stringify(eventSpecificErrors));
    if (!eventSpecificFields.eventNameEn || eventSpecificFields.eventNameEn.trim() === '') {
      currentErrors.eventNameEn = [t('forms:error_empty')];
      submitCheck = false;
    }
    if (!eventSpecificFields.eventNameTranslation || eventSpecificFields.eventNameTranslation.trim() === '') {
      currentErrors.eventNameTranslation = [t('forms:error_empty')];
      submitCheck = false;
    }
    if (!eventSpecificFields.eventDescriptionEn || eventSpecificFields.eventDescriptionEn.trim() === '') {
      currentErrors.eventDescriptionEn = [t('forms:error_empty')];
      submitCheck = false;
    }
    if (!eventSpecificFields.eventDescriptionTranslation || eventSpecificFields.eventDescriptionTranslation.trim() === '') {
      currentErrors.eventDescriptionTranslation = [t('forms:error_empty')];
      submitCheck = false;
    }
    setEventSpecificErrors(currentErrors);
    return submitCheck;
  };

  const handleSpecificInputChange = (e) => {
    const { name, value } = e.target;
    const list = JSON.parse(JSON.stringify(eventSpecificFields));
    const currentErrors = JSON.parse(JSON.stringify(eventSpecificErrors));
    list[name] = value;
    if (value && value.trim() !== '') {
      currentErrors[name] = [];
    }
    setEventSpecificFields(list);
    setEventSpecificErrors(currentErrors);
  };

  const handleSubmit = () => {
    const dates = enumerateDaysBetweenDates(start, end, disableDays);
    const hours = [...lunchHours, ...dinnerHours];
    const selectedHours = hours.filter((hour) => hour.selected);
    const intervals = [];
    const {
      currentErrors,
      submitCheck,
    } = checkFields(inputList);
    setChanged(false);

    const submitCheckSpecificFields = checkEventSpecificFields();

    setHoursError(selectedHours.length === 0);
    setDateError(dates.length === 0);
    setErrors(currentErrors);
    if (!submitCheck || !submitCheckSpecificFields) return;
    if (selectedHours.length === 0 || dates.length === 0) return;
    setLoading(true);
    startListening();
    const times = convertHours(hours);
    dates.forEach((date) => {
      intervals.push({
        date,
        times,
      });
    });

    const addEventParams = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      name: eventSpecificFields.eventNameEn,
      description: eventSpecificFields.eventDescriptionEn,
      intervals,
      feature_sets: 0,
      show_in_listings: 1,
      allocation: allocations,
      translations: [{
        locale,
        name: eventSpecificFields.eventNameTranslation,
        description: eventSpecificFields.eventDescriptionTranslation,
      }],
    };

    if (editing || copy) {
      const eventId = location.pathname.split('/').pop();
      addEventParams.special_event_id = eventId;
    }

    if (lastSaleDate) {
      addEventParams.last_sale_date = lastSaleDate.format('YYYY-MM-DD');
    }

    if (prebookTime) {
      addEventParams.prebook = moment.duration(prebookTime.format('HH:mm')).asSeconds();
    }

    addEventParams.sets = createSets(inputList, locale, editing || copy);
    const endpoint = editing ? 'edit' : 'add';

    (new RequestService(`manager/special-events/${endpoint}`))
      .setParams(addEventParams)
      .send()
      .then(() => {
        handleCancel();
        toast.success(t(`success:${endpoint}_event_success`));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t(`apiErrors:could_not_${endpoint}_event`));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  useEffect(() => {
    if ((!editing && !copy) || !userContext.user) return;
    const eventId = location.pathname.split('/').pop();
    (new RequestService('manager/special-events/single'))
      .setParams({
        special_event_id: eventId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        const responseData = { ...response.data };
        if (!responseData._translations[locale]) {
          responseData._translations = {
            [locale]: {
              name: responseData._name,
              description: responseData._description,
            },
          };
        }
        for (let i = 0; i < responseData._sets.length; i += 1) {
          if (!responseData._sets[i]._translations[locale]) {
            responseData._sets[i]._translations = {
              [locale]: {
                name: responseData._sets[i]._name,
              },
            };
          }
          for (let k = 0; k < responseData._sets[i]._items.length; k += 1) {
            if (!responseData._sets[i]._items[k].translations[locale]) {
              responseData._sets[i]._items[k].translations = {
                [locale]: {
                  name: responseData._sets[i]._items[k].name,
                  description: responseData._sets[i]._items[k].description,
                },
              };
            }
          }
        }
        setEvent(responseData);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_event'));
      });
  }, [editing, copy, userContext.user, location.pathname]);

  useEffect(() => {
    if (!event._id) return;
    if (selectedDays.map(({ selected }) => selected).some((e) => !e)) return;
    if (lunchHours.map(({ selected }) => selected).some((e) => e)) return;
    if (dinnerHours.map(({ selected }) => selected).some((e) => e)) return;

    setEventSpecificFields({
      eventNameEn: event._name,
      eventNameTranslation: event._translations[locale].name,
      eventDescriptionEn: event._description,
      eventDescriptionTranslation: event._translations[locale].description,
    });
    setInputList(createSetsInputs(event, locale));

    const datesAll = event._intervals.map(({ date }) => date);
    const dates = [...new Set(datesAll)];
    setStart(moment(dates[0]));
    setEnd(moment(dates[dates.length - 1]));

    const daysAll = dates.map((date) => (
      moment(date).weekday()
    ));
    const days = [...new Set(daysAll)];
    const sortedDays = days.sort();

    const newSelectedDays = [...selectedDays];
    for (let i = 0; i < newSelectedDays.length; i += 1) {
      if (sortedDays.includes(newSelectedDays[i].index)) {
        newSelectedDays[i].selected = true;
      } else {
        newSelectedDays[i].selected = false;
      }
    }
    setSelectedDays(newSelectedDays);

    const startTimesAll = event._intervals.map((interval) => interval.start);
    const endTimesAll = event._intervals.map((interval) => interval.end);
    const startTimes = [...new Set(startTimesAll)];
    const endTimes = [...new Set(endTimesAll)];
    const times = [];

    for (let i = 0; i < startTimes.length; i += 1) {
      const startTime = moment(startTimes[i], 'HH:mm');
      const endTime = moment(endTimes[i], 'HH:mm');

      while (startTime.isBefore(endTime, 'HH:mm')) {
        times.push(startTime.format('HH:mm'));
        startTime.add(30, 'minutes');
      }
      times.push(endTime.format('HH:mm'));
    }

    const hours = [...lunchHours, ...dinnerHours];

    for (let i = 0; i < hours.length; i += 1) {
      if (times.includes(hours[i].hour)) {
        hours[i].selected = true;
      }
    }

    setLunchHours(hours.slice(0, 14));
    setDinnerHours(hours.slice(14));

    if (event._prebook) {
      setPrebookTime(moment().startOf('day').seconds(event._prebook));
    }
    if (event._lastSaleDate) {
      setLastSaleDate(moment(event._lastSaleDate));
    }
    if (event._allocation) {
      setAllocations(event._allocation);
    }
  }, [event, countryContext.country.code, selectedDays, lunchHours, dinnerHours, createSetsInputs]);

  const translateText = (english, other) => {
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [eventSpecificFields[english]];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [eventSpecificFields[other]];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          setEventSpecificFields({
            ...eventSpecificFields,
            [other]: translations[0].text,
          });
        } else {
          setEventSpecificFields({
            ...eventSpecificFields,
            [english]: translations[0].text,
          });
        }
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('events-add-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('events-add-tour-status', 1);
    }
  };

  useEffect(() => {
    const inputListChanged = inputList.filter((list) => (
      list.menuNameEn !== ''
      || list.menuNameTranslation !== ''
      || list.menuPriceNoWine !== ''
      || list.menuPriceWithWine !== ''
      || list.menuItems.filter((items) => (
        items.itemDescriptionEn !== ''
        || items.itemDescriptionTranslation !== ''
        || items.itemNameTranslation !== ''
        || items.itemNameEn !== ''
      )).length > 0
    )).length;
    if (
      (Object.keys(eventSpecificFields).length > 0 && Object.values(eventSpecificFields).some((x) => x !== ''))
      || inputListChanged > 0
      || selectedDays.filter((day) => !day.selected).length > 0
      || lunchHours.filter((hour) => hour.selected).length > 0
      || dinnerHours.filter((hour) => hour.selected).length > 0
      || start
      || end
      || prebookTime
      || lastSaleDate
      || allocations
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [
    eventSpecificFields,
    selectedDays,
    lunchHours,
    dinnerHours,
    start,
    end,
    inputList,
    prebookTime,
    lastSaleDate,
    allocations,
  ]);

  useEffect(() => () => {
    setChanged(false);
  }, []);

  return (
    <div className="inner-wrapper event-page">
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <h2 className="section-title underlined">
        <strong>
          {editing ? t('pageEvents:editing_event') : t('pageEvents:adding_event')}
        </strong>
      </h2>
      <p className="label">{t('pageEvents:your_event')}</p>
      <div
        className={`tour-main-fields
        clear-both
        invertible-grid 
        ${(i18n.language === 'bg' || i18n.language === 'pl') ? 'inverted' : ''}
        `}
      >
        <div className="grid">
          <div className="grid-col-5">
            <FormElement
              id="eventNameEn"
              elementType="input"
              placeholder={t('pageEvents:enter_event_name_en')}
              value={eventSpecificFields.eventNameEn}
              errors={eventSpecificErrors.eventNameEn}
              changed={handleSpecificInputChange}
            />
          </div>
          <div className="grid-col-5">
            <FormElement
              id="eventNameTranslation"
              elementType="input"
              placeholder={t(`pageEvents:enter_event_name_translation_${countryContext.country.name}`)}
              value={eventSpecificFields.eventNameTranslation}
              errors={eventSpecificErrors.eventNameTranslation}
              changed={handleSpecificInputChange}
            />
          </div>
          <button
            type="button"
            className="translate-fields"
            onClick={() => translateText('eventNameEn', 'eventNameTranslation')}
          >
            {' '}
          </button>
        </div>
        <div className="grid">
          <div className="grid-col-5">
            <FormElement
              id="eventDescriptionEn"
              elementType="textarea"
              placeholder={t('pageEvents:enter_event_description_en')}
              value={eventSpecificFields.eventDescriptionEn}
              errors={eventSpecificErrors.eventDescriptionEn}
              changed={handleSpecificInputChange}
            />
          </div>
          <div className="grid-col-5">
            <FormElement
              id="eventDescriptionTranslation"
              elementType="textarea"
              placeholder={t(`pageEvents:enter_event_description_translation_${countryContext.country.name}`)}
              value={eventSpecificFields.eventDescriptionTranslation}
              errors={eventSpecificErrors.eventDescriptionTranslation}
              changed={handleSpecificInputChange}
            />
          </div>
          <button
            type="button"
            className="translate-fields"
            onClick={() => translateText('eventDescriptionEn', 'eventDescriptionTranslation')}
          >
            {' '}
          </button>
        </div>
      </div>
      <EventMenuSection
        inputList={inputList}
        setInputList={setInputList}
        errors={errors}
        setErrors={setErrors}
        sectionTitle={t('pageEvents:your_options')}
        events
      />
      <div className="section">
        <p className="label">
          {t('dates_times_availability')}
          {' '}
          <strong className="text-accent">
            {start ? start.format('DD/MM/YYYY') : t('start_date')}
          </strong>
          {' - '}
          <strong className="text-accent">
            {end ? end.format('DD/MM/YYYY') : t('end_date')}
          </strong>
        </p>
        <div className="grid">
          <div className="tour-date grid-col-5">
            {dateError && (<p className="error">{t('forms:please_choose_dates')}</p>)}
            <DateDayPicker
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              setLunchHours={setLunchHours}
              setDinnerHours={setDinnerHours}
              setDisableDays={setDisableDays}
              disableDays={disableDays}
              setStart={setStart}
              start={start}
              setEnd={setEnd}
              end={end}
            />
          </div>
          <div className="grid-col-5">
            {hoursError && (<p className="error">{t('forms:please_choose_hours')}</p>)}
            <PickHourBlock
              lunchHours={lunchHours}
              setLunchHours={setLunchHours}
              dinnerHours={dinnerHours}
              setDinnerHours={setDinnerHours}
            />
            <div className="tour-prebook form-group">
              <TimePicker
                placeholder={t('prebook_time')}
                showSecond={false}
                minuteStep={15}
                popupClassName="time-picker-custom time-picker-event"
                defaultOpenValue={moment().startOf('day')}
                value={prebookTime}
                onChange={(value) => setPrebookTime(value)}
              />
              <DatePicker
                placeholder={t('pageEvents:last_sale_date')}
                date={lastSaleDate}
                start={moment()}
                end={end}
                handleDate={(e) => setLastSaleDate(e)}
              />
              <FormElement
                id="allocations"
                elementType="input"
                inputType="number"
                placeholder={t('pageEvents:allocation')}
                value={allocations || ''}
                changed={(e) => setAllocations(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="center-buttons">
        <Button
          text={t('cancel')}
          classes="btn-accent btn-outline"
          onClick={handleCancel}
        />
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('save')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

AddEvent.propTypes = {
  editing: PropTypes.bool,
  copy: PropTypes.bool,
};

AddEvent.defaultProps = {
  editing: false,
  copy: false,
};
