import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CountryContext from '../react-web-ui/contexts/CountryContext';

const useFeatures = () => {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);
  const [pricePeriod, setPricePeriod] = useState({
    pro: 'YEARLY',
    premium: 'YEARLY',
  });

  const rows = [
    {
      title: t('selfOnboarding:row_1'),
      additional: t('selfOnboarding:row_1_additional'),
      pricingBlockFeatures: [
        'tick',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_2'),
      additional: t('selfOnboarding:row_2_additional'),
      pricingBlockFeatures: [
        'tick',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_3'),
      additional: t('selfOnboarding:row_3_additional'),
      pricingBlockFeatures: [
        'tick',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_4'),
      additional: t('selfOnboarding:row_4_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_32'),
      additional: t('selfOnboarding:row_32_additional'),
      pricingBlockFeatures: [
        'x',
        'x',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_11'),
      additional: t('selfOnboarding:row_11_additional'),
      pricingBlockFeatures: [
        'tick',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_12'),
      additional: t('selfOnboarding:row_12_additional'),
      pricingBlockFeatures: [
        'tick',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_17'),
      additional: t('selfOnboarding:row_17_additional'),
      pricingBlockFeatures: [
        'tick',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_24'),
      additional: t('selfOnboarding:row_24_additional'),
      pricingBlockFeatures: [
        'tick',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_5'),
      additional: t('selfOnboarding:row_5_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_14'),
      additional: t('selfOnboarding:row_14_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_15'),
      additional: t('selfOnboarding:row_15_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_16'),
      additional: t('selfOnboarding:row_16_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_19'),
      additional: t('selfOnboarding:row_19_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_21'),
      additional: t('selfOnboarding:row_21_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_22'),
      additional: t('selfOnboarding:row_22_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_23'),
      additional: t('selfOnboarding:row_23_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_26'),
      additional: t('selfOnboarding:row_26_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_27'),
      additional: t('selfOnboarding:row_27_additional'),
      pricingBlockFeatures: [
        'x',
        'tick',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_28'),
      additional: t('selfOnboarding:row_28_additional'),
      pricingBlockFeatures: [
        'x',
        'x',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_29'),
      additional: t('selfOnboarding:row_29_additional'),
      pricingBlockFeatures: [
        'x',
        'x',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_30'),
      additional: t('selfOnboarding:row_30_additional'),
      pricingBlockFeatures: [
        'x',
        'x',
        'tick',
      ],
    },
    {
      title: t('selfOnboarding:row_33'),
      additional: t('selfOnboarding:row_33_additional'),
      pricingBlockFeatures: [
        'x',
        'x',
        'tick',
      ],
    },
  ];

  const pricingBlocks = [
    {
      title: t('selfOnboarding:lite'),
      value: 'FREE',
      price: t('selfOnboarding:lite_price'),
      features: [
        t('selfOnboarding:row_1'),
        t('selfOnboarding:contact_guests'),
        t('selfOnboarding:row_24'),
        t('selfOnboarding:detailed_online_profile'),
        t('selfOnboarding:row_11_additional'),
        t('selfOnboarding:useful_app_suggestions '),
        t('selfOnboarding:guestbook_with_history'),
      ],
    },
    {
      title: t('selfOnboarding:pro'),
      value: 'PRO',
      pricePeriod: pricePeriod.pro,
      price: `${t(`selfOnboarding:pro_price_${countryContext.country.name}_${pricePeriod.pro}`)}`,
      features: [
        t('selfOnboarding:social_media_post'),
        t('selfOnboarding:interactive_floorplan'),
        t('selfOnboarding:manage_reviews'),
        t('selfOnboarding:customisable_confirmations'),
        t('selfOnboarding:row_26'),
      ],
    },
    {
      title: t('selfOnboarding:premium'),
      value: 'PREMIUM',
      pricePeriod: pricePeriod.premium,
      price: `${t(`selfOnboarding:premium_price_${countryContext.country.name}_${pricePeriod.premium}`)}`,
      features: [
        t('selfOnboarding:online_deposits_payments'),
        t('selfOnboarding:row_28'),
        t('selfOnboarding:row_30'),
        t('selfOnboarding:row_29'),
      ],
    },
  ];

  return {
    rows,
    pricingBlocks,
    pricePeriod,
    setPricePeriod,
  };
};

export default useFeatures;
