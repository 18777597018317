import { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import RequestService from '../../networking/RequestService';

const convertToChuncks = (array) => {
  const chunks = [];
  const chunk = 2;
  for (let i = 0; i < array.length; i += chunk) {
    chunks.push(array.slice(i, i + chunk));
  }
  return chunks;
};

const useWorkingHours = (restaurant) => {
  const { t } = useTranslation();
  const [workingHours, setWorkingHours] = useState([]);
  const days = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];

  useEffect(() => {
    if (!restaurant) return;
    if (!restaurant.shifts) return;
    const shifts = [];
    for (let i = 0; i < 7; i += 1) {
      const filteredDays = restaurant.shifts.filter(({ day }) => day === i);
      const newDays = [];
      for (let k = 0; k < filteredDays.length; k += 1) {
        newDays.push([
          filteredDays[k].start,
          filteredDays[k].end,
        ]);
      }
      shifts.push(newDays);
    }
    shifts.push(shifts.shift());
    const workHours = [];
    shifts.forEach((shift) => {
      const flat = shift.reduce((a, b) => a.concat(b), []);
      const removedDuplicates = flat.filter((a, _, aa) => aa.indexOf(a) === aa.lastIndexOf(a));
      workHours.push(convertToChuncks(removedDuplicates));
    });
    const currentWorkingHours = [];
    let startDay = '';
    days.forEach((day, index) => {
      if (workHours[index].length === 0) {
        currentWorkingHours.push(`${day}: ${t('closed')}`);
      } else {
        const hours = [];
        const hoursNext = [];
        workHours[index].forEach((hour) => {
          hours.push(`${moment.unix(hour[0]).utc().format('HH:mm')} - ${moment.unix(hour[1]).utc().format('HH:mm')}`);
        });
        if (index + 1 < days.length) {
          workHours[index + 1].forEach((hour) => {
            hoursNext.push(`${moment.unix(hour[0]).utc().format('HH:mm')} - ${moment.unix(hour[1]).utc().format('HH:mm')}`);
          });
        }

        if (hoursNext.join('; ') === hours.join('; ')) {
          if (startDay.length === 0) startDay = day;
        } else {
          currentWorkingHours.push(`${startDay}${startDay ? '- ' : ''} ${day}: ${hours.join('; ')}`);
          startDay = '';
        }
      }
    });
    setWorkingHours(currentWorkingHours);
  }, [restaurant, t]);

  return [workingHours];
};

export default useWorkingHours;
