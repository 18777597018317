import React, {
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import UserContext from '../../react-web-ui/contexts/UserContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import { ReactComponent as Restaurant } from '../../assets/images/icons/Restaurant.svg';

/**
 * StickyInfo
 * @returns {*}
 * @constructor
 */
export default function StickyInfo() {
  const { t } = useTranslation();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);

  return (
    <>
      {userContext.isLoggedIn
      && (location.pathname !== '/')
      && (location.pathname !== '/restaurants-listing')
      && (location.pathname !== '/manage/floor-plan')
      && (location.pathname !== '/manage/table-combinations')
      && (location.pathname !== '/what-you-pay')
      && (location.pathname !== '/monetize/dineout-host')
      && (
        <div className="sticky-info">
          <div className="inner-wrapper sticky-info-wrapper flex-between align-center">
            <div className="sticky-info-left flex align-center">
              <Restaurant className="icon" />
              {restaurantContext.restaurant && (
                <h1 className="restaurant-name">
                  {restaurantContext.restaurant.name}
                  <small className="text-gray">
                    {` (${restaurantContext.plan === 'false' ? t('pageSubscriptions:no_plan') : restaurantContext.plan})`}
                  </small>
                </h1>
              )}
              <NavLink to="/restaurants-listing" className="btn-link switch-link">
                {t('navigation:nav_switch_restaurant')}
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
