/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import MenuItemBlock from '../MenuItemBlock';

/**
* Category block
* @param category
* @returns {*}
* @constructor
*/
export default function CategoryBlock({
  locale,
  category,
  categories,
  setCategories,
  loading,
  setChanged,
  changed,
}) {
  const [items, setItems] = useState(category.items);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    const currentCategories = [...categories];
    for (let i = 0; i < currentCategories.length; i += 1) {
      if (category.id === currentCategories[i].id) {
        currentCategories[i].items = itemsNew;
      }
    }
    setCategories(currentCategories);
    setItems(itemsNew);
  };

  useEffect(() => {
    setItems(category.items);
  }, [category]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            className="current-menu-items-wrapper"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    className={`draggable-item ${snapshot.isDragging ? 'dragged' : ''}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <MenuItemBlock
                      locale={locale}
                      key={item.id}
                      categories={categories}
                      setCategories={setCategories}
                      categoryId={category.id}
                      item={item}
                      disabled={loading}
                      setChanged={setChanged}
                      changed={changed}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

CategoryBlock.propTypes = {
  category: PropTypes.oneOfType([PropTypes.object]).isRequired,
  categories: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setCategories: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  setChanged: PropTypes.func,
  changed: PropTypes.bool,
};

CategoryBlock.defaultProps = {
  loading: false,
  setChanged: null,
  changed: false,
};
