import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ButtonLink from '../../react-web-ui/components/ButtonLink';
import EventBlock from '../../react-web-ui/components/EventBlock';

/**
* Add event block
* @param restaurantName
* @returns {*}
* @constructor
*/
export default function AddEventBlock({ restaurantName }) {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <EventBlock
      big
      eventImage={(
        <span className="no-img" />
      )}
      eventName={t('pageEvents:your_event')}
      date={t('start_date')}
      time={t('pageSpecialOffers:times')}
      restaurantName={restaurantName}
      price={t('price')}
      buttons={(
        <ButtonLink
          href={`${location.pathname}/add`}
          text={t('pageEvents:add_an_event')}
          classes="btn-accent"
        />
      )}
    />
  );
}

AddEventBlock.propTypes = {
  restaurantName: PropTypes.string.isRequired,
};
