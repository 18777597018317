/* eslint-disable no-nested-ternary */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/pl';

import { specialOfferImg } from '../../react-web-ui/utilities/images';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import PlaceManager from '../../networking/resources/PlaceManager';
import RestaurantBlock from '../../react-web-ui/components/RestaurantBlock';
import SpecialOffer from '../../networking/resources/SpecialOffer';
import { ReactComponent as Clock } from '../../react-web-ui/assets/images/icons/Clock.svg';
import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';

/**
* SpecialOfferBlock
* @param restaurantInfo
* @returns {*}
* @constructor
*/
export default function SpecialOfferBlock({
  restaurantInfo,
  offer,
  buttons,
  removeBtn,
  handleImgUpload,
}) {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);
  const locale = countryContext.country.code.toLowerCase();
  const datesAll = offer.intervals.map((item) => item.date);
  const dates = [...new Set(datesAll)];
  const startDate = moment(datesAll[0]).format('DD MMMM YYYY');
  const endDate = moment(datesAll[datesAll.length - 1]).format('DD MMMM YYYY');
  const startTimesAll = offer.intervals.map((item) => (
    moment(item.start, 'HH:mm:ss').format('HH:mm')
  ));
  const endTimesAll = offer.intervals.map((item) => (
    moment(item.end, 'HH:mm:ss').format('HH:mm')
  ));
  const startTimes = [...new Set(startTimesAll)];
  const endTimes = [...new Set(endTimesAll)];
  const daysAll = dates.map((date) => (
    moment(date).weekday()
  ));
  const days = [...new Set(daysAll)];
  const daysOfWeek = moment.weekdays();
  daysOfWeek.push(daysOfWeek.shift());
  const sortedDays = days.sort();

  moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  }, [i18n.language]);

  return (
    <div>
      <h3 className="section-sub-title discount-validity">
        <strong>{t('pageSpecialOffers:dates')}</strong>
        {`${startDate} ${t('until')} ${endDate}`}
        <br />
        <div>
          <strong>{t('pageSpecialOffers:days')}</strong>
          {sortedDays.map((day) => (
            <span key={day} className="discount-days">
              {daysOfWeek[day]}
            </span>
          ))}
        </div>
      </h3>

      <RestaurantBlock
        className="special-offer-block"
        imageBlock={offer.thumb ? (
          <div className="img-wrapper">
            <img
              src={specialOfferImg(offer.id, offer.thumb, 577, 420)}
              alt={restaurantInfo.name}
            />
            <label className="add-img edit-img" htmlFor={`edit-button-${offer.id}`}>
              <Edit className="icon" />
              <input
                type="file"
                id={`edit-button-${offer.id}`}
                hidden
                onChange={(e) => handleImgUpload(offer.id, e)}
              />
            </label>
          </div>
        ) : (
          <div className="img-wrapper">
            <label className="add-img" htmlFor={`upload-button-${offer.id}`}>
              {t('forms:add_photo')}
              <input
                type="file"
                id={`upload-button-${offer.id}`}
                hidden
                onChange={(e) => handleImgUpload(offer.id, e)}
              />
            </label>
          </div>
        )}
        title={(
          <>
            <span className="validity">
              <Clock className="icon" />
              {startTimes.map((time, index) => (
                <span key={time} className="discount-hours">
                  {`(${time} - ${endTimes[index]}) `}
                </span>
              ))}
            </span>
            {i18n.language === 'en' || i18n.language === 'en-GB' ? (
              offer.defaultName
            ) : (
              offer.translations[locale] ? offer.translations[locale].name : offer.defaultName
            )}
          </>
        )}
        cuisines={
          i18n.language === 'en' || i18n.language === 'en-GB' ? (
            restaurantInfo.name
          ) : (
            restaurantInfo.translations[locale]
              ? restaurantInfo.translations[locale].name
              : restaurantInfo.name
          )
        }
        button={buttons}
      />
      {removeBtn}
    </div>
  );
}

SpecialOfferBlock.propTypes = {
  restaurantInfo: PropTypes.instanceOf(PlaceManager).isRequired,
  offer: PropTypes.instanceOf(SpecialOffer).isRequired,
  buttons: PropTypes.element.isRequired,
  removeBtn: PropTypes.element.isRequired,
  handleImgUpload: PropTypes.func.isRequired,
};
