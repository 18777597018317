import React from 'react';
import PropTypes from 'prop-types';

/**
 * Counter
 * @param count
 * @param clickedMinus
 * @param clickedPlus
 * @param disabledPlus
 * @returns {*}
 * @constructor
 */

export default function Counter({
  count,
  clickedMinus,
  clickedPlus,
  disabledPlus,
}) {
  return (
    <div className="counter">
      <button
        type="button"
        onClick={clickedMinus}
        disabled={count === 0}
      >
        -
      </button>
      <p className="count">{count}</p>
      <button
        type="button"
        onClick={clickedPlus}
        disabled={disabledPlus}
      >
        +
      </button>
    </div>
  );
}

Counter.propTypes = {
  /** Count number. */
  count: PropTypes.number.isRequired,
  /**
   * Function to be executed onClick of minus button.
   */
  clickedMinus: PropTypes.func.isRequired,
  /**
   * Function to be executed onClick of plus button.
   */
  clickedPlus: PropTypes.func.isRequired,
  disabledPlus: PropTypes.bool,
};

Counter.defaultProps = {
  disabledPlus: false,
};
