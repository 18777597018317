import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import WindowContext from '../../../contexts/WindowContext';

/**
 * Restaurant Images Gallery
 * @param slider
 * @param controls
 * @param thumbs
 * @returns {*}
 * @constructor
 */
export default function ImagesCarousel({
  slider,
  controls,
  thumbs,
}) {
  const { isDesktop } = useContext(WindowContext);

  return (
    <>
      <div className="restaurant-images">
        {slider}
        {isDesktop && (
          <>{controls}</>
        )}
      </div>
      {thumbs}
    </>
  );
}

ImagesCarousel.propTypes = {
  slider: PropTypes.element,
  controls: PropTypes.element,
  thumbs: PropTypes.element,
};

ImagesCarousel.defaultProps = {
  slider: null,
  controls: null,
  thumbs: null,
};
