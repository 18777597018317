import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ElementsContext from '../../../contexts/ElementsContext';
import { ReactComponent as ElementBg } from '../../../assets/images/icons/ElementBg.svg';
import { ReactComponent as ElementChef } from '../../../assets/images/icons/ElementChef.svg';
import { ReactComponent as ElementIconText } from '../../../assets/images/icons/ElementIconText.svg';
import { ReactComponent as ElementLogo } from '../../../assets/images/icons/ElementLogo.svg';
import { ReactComponent as ElementShape } from '../../../assets/images/icons/ElementShape.svg';
import { ReactComponent as ElementText } from '../../../assets/images/icons/ElementText.svg';

export default function CanvasElementTypes() {
  const elementsContext = useContext(ElementsContext);
  const { t } = useTranslation();

  const elementTypes = [
    {
      type: 'background',
      title: t('pagePostCreator:background'),
      icon: <ElementBg className="icon" />,
    },
    {
      type: 'shape',
      title: t('pagePostCreator:shape'),
      icon: <ElementShape className="icon" />,
    },
    {
      type: 'text',
      title: t('pagePostCreator:text'),
      icon: <ElementText className="icon" />,
    },
    {
      type: 'icon-with-text',
      title: t('pagePostCreator:icon_with_text'),
      icon: <ElementIconText className="icon" />,
    },
    {
      type: 'chef',
      title: t('pagePostCreator:chef'),
      icon: <ElementChef className="icon" />,
    },
    {
      type: 'logo',
      title: t('pagePostCreator:logo'),
      icon: <ElementLogo className="icon" />,
    },
  ];

  return (
    <div className="post-elements-wrapper">
      {elementTypes.map(({ type, title, icon }) => (
        <button
          key={type}
          type="button"
          className={`element-button ${elementsContext.elementType === type ? 'selected' : ''}`}
          onClick={() => {
            elementsContext.setElementType(type);
            elementsContext.setSelectedElement(null);
          }}
        >
          <span>{icon}</span>
          {title}
        </button>
      ))}
    </div>
  );
}
