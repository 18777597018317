import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useForm from '../../react-web-ui/hooks/useForm';
import validate from './validation';
import PageHeading from '../../react-web-ui/components/PageHeading';
import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import Spinner from '../../react-web-ui/components/Spinner';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';

export default function BookingNotifications() {
  const { t, i18n } = useTranslation();
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);
  const [loading, setLoading] = useState(false);

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    const params = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      notifications_locale: values.language_select,
      contact_phone_number: values.phone,
      contact_email: values.email,
    };

    setLoading(true);
    (new RequestService('manager/places/notifications'))
      .setParams(params)
      .send()
      .then((response) => {
        toast.success(t('success:notification_info_updated_success'));
        restaurantContext.setRestaurant(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_update_notifications_info'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!restaurantContext.restaurant) return;

    setValues({
      ...values,
      language_select: restaurantContext.restaurant.notificationsLocale || i18n.language,
      email: restaurantContext.restaurant.contactEmail,
      phone: restaurantContext.restaurant.contactPhoneNumber,
    });
  }, [restaurantContext.restaurant]);

  return (
    <div className="inner-wrapper">
      <PageHeading
        classes="page-heading-bookings"
        title={t('pageBookings:notifications_settings')}
        content={(
          <p>
            {t('pageBookings:configure_email')}
          </p>
        )}
      />
      <div className="grid" style={{ columnGap: 100 }}>
        <div className="grid-col-5">
          <form action="" noValidate>
            <FormElement
              id="phone"
              elementType="input"
              placeholder={t('forms:phone')}
              label={t('forms:phone')}
              value={values.phone || ''}
              errors={errors.phone}
              changed={handleChange}
            />
            <FormElement
              id="email"
              elementType="input"
              inputType="email"
              placeholder={t('forms:email')}
              label={t('forms:email')}
              value={values.email || ''}
              errors={errors.email}
              changed={handleChange}
            />
            <FormElement
              id="language_select"
              elementType="select"
              placeholder={t('pageBookings:preferred_language')}
              label={t('pageBookings:preferred_language')}
              value={values.language_select || ''}
              errors={errors.language_select}
              changed={handleChange}
              selectOptions={[
                {
                  value: 'en',
                  text: t('forms:placeholder_default'),
                },
                {
                  value: 'bg',
                  text: t('forms:placeholder_translation_Bulgaria'),
                },
                {
                  value: 'pl',
                  text: t('forms:placeholder_translation_Poland'),
                },
              ]}
            />
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('save')}
              classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
              disabled={loading}
              onClick={handleSubmit}
            />
          </form>
        </div>
        <div className="grid-col-5">
          <h2 className="sextion-title">
            {t('pageBookings:example_sms')}
          </h2>
          <div className="example-sms">
            <p>
              {values.language_select === 'en' && (
                'New booking John Doe - Guests: 2 on 2021-04-03 at 20:30, phone no: 001 455 666.'
              )}
              {values.language_select === 'bg' && (
                'Нова резервация - Иван Иванов (гости: 3) за дата: от <час>, телефон: 09876543210'
              )}
              {values.language_select === 'pl' && (
                'Nowa rezerwacja - Jane Doe (Goście: 3) w dniu: 27.07.2021 o godz. 19:30, Nr kom: 602409361.'
              )}
            </p>
          </div>
          <h2 className="sextion-title">
            {t('pageBookings:example_email')}
          </h2>
          <div className="example-email">
            <p>
              {values.language_select === 'en' && (
                `"Restaurant - Your restaurant
                Name - Jane Doe
                Date - 02.08.2021
                Time - 20:30
                Guests - 2
                Phone - 09876543210
                Notes - None selected
                Salon - Garden"
                `
              )}
              {values.language_select === 'bg' && (
                `"Ресторант - Вашият ресторант
                Гост - Иван Иванов
                Дата - 02.08.2021
                Час - 20:30
                Гости в резервацията - 2
                Телефон - 09876543210
                Бележки - Няма допълнителни бележки
                Предпочитан салон - Градина"
                `
              )}
              {values.language_select === 'pl' && (
                `"Restauracja - Twoja restauracjia
                Imię i Nazwisko - Jane Doe
                Data - 02.08.2021
                Godzina - 20:30
                Goście - 2
                Telefon - 09876543210
                Uwagi - Brak uwag
                Sala - Ogród"
                `
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
