import React, {
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import UserContext from '../../react-web-ui/contexts/UserContext';
import Header from '../../react-web-ui/components/Header';
import Login from '../../react-web-ui/components/Login';
import MainNav from './MainNav';
import Modal from '../Modal';
import { ReactComponent as Info } from '../../assets/images/icons/Info.svg';

/**
 * Main navigation component: includes LanguageSwitcher.
 * @returns {*}
 * @constructor
 */
export default function HeaderWrapper() {
  const { t } = useTranslation();
  const [navOpen, setNavOpen] = useState(false);
  const userContext = useContext(UserContext);

  return (
    <>
      {process.env.REACT_APP_RELEASE_VERSION !== 'production' && (
        <div className="info-wrapper">
          <div className="inner-wrapper">
            <Info />
            <h3>
              This is not on production.
            </h3>
          </div>
        </div>
      )}
      <Header
        className={userContext.isLoggedIn ? '' : 'not-logged'}
        mainLinks={(
          <MainNav
            clickedLink={() => setNavOpen(!navOpen)}
          />
        )}
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        authButtons={!userContext.isLoggedIn ? (
          <button
            type="button"
            className="btn btn-accent btn-outline"
            onClick={() => userContext.setAuthPopup(true)}
          >
            {t('login')}
          </button>
        ) : null}
        authPopup={userContext.authPopup ? (
          <Modal
            title={t('login')}
            setShowPopup={() => userContext.setAuthPopup(false)}
          >
            <Login endpoint="manager/users/signin" type="manager" />
          </Modal>
        ) : null}
      />
    </>
  );
}
