import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import FormElement from '../../../react-web-ui/components/FormElement';
import Button from '../../../react-web-ui/components/Button';
import useForm from '../../../react-web-ui/hooks/useForm';
import validate from './validation';
import Checkbox from '../../../react-web-ui/components/FormElement/Checkbox';

export default function BookingFormFields() {
  const { t } = useTranslation();

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    toast.success(t('success:booking_success'));
  }

  return (
    <>
      <section className="bookings-info-section">
        <h3 className="section-sub-title">{t('pageWidget:aditional_requests')}</h3>
        <FormElement
          id="notes_book"
          elementType="input"
          placeholder={t('pageWidget:notes')}
          value={values.notes_book || ''}
          errors={errors.notes_book}
          changed={handleChange}
        />
      </section>
      <section className="bookings-info-section">
        <h3 className="section-sub-title">{t('pageWidget:personal_information')}</h3>
        <div className="two-columns-form-group">
          <FormElement
            id="first_name_book"
            elementType="input"
            placeholder={t('forms:first_name')}
            value={values.first_name_book || ''}
            errors={errors.first_name_book}
            changed={handleChange}
          />
          <FormElement
            id="last_name_book"
            elementType="input"
            placeholder={t('forms:last_name')}
            value={values.last_name_book || ''}
            errors={errors.last_name_book}
            changed={handleChange}
          />
          <FormElement
            id="email_book"
            elementType="input"
            inputType="email"
            placeholder={t('forms:email')}
            value={values.email_book || ''}
            errors={errors.email_book}
            changed={handleChange}
          />
          <FormElement
            id="phone_book"
            elementType="input"
            placeholder={t('forms:phone')}
            value={values.phone_book || ''}
            errors={errors.phone_book}
            changed={handleChange}
          />
        </div>
        <Checkbox
          id="accept_terms_book"
          text={(
            <span>
              {t('pageWidget:read_and_accept')}
              <a
                href={`https://${process.env.REACT_APP_DOMAIN}/terms-of-service`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('pageWidget:terms_and_conditions')}
              </a>
            </span>
          )}
          value={`${values.accept_terms_book}`}
          checked={values.accept_terms_book}
          check={handleChange}
          errors={errors.accept_terms_book}
          testId="checkbox"
        />
      </section>
      <Button
        text={t('pageWidget:book_now')}
        classes="btn-accent"
        onClick={handleSubmit}
      />
    </>
  );
}
