import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import StillTasting from '../../assets/images/still-tasting.svg';
import FormElement from '../../react-web-ui/components/FormElement';
import MenuItem from '../../react-web-ui/components/RestaurantMenu/MenuItem';

export default function MenuSection({
  restaurantId,
  name,
}) {
  const { t } = useTranslation();
  const [restaurantMenu, setRestaurantMenu] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState('');
  let categories;

  if (restaurantMenu) {
    categories = restaurantMenu.map((menu) => ({
      value: menu.name,
      text: menu.name,
    }));
  }

  useEffect(() => {
    const requestMenuItems = new RequestService('client/menu/get');

    requestMenuItems.setParams({
      place_id: restaurantId,
    })
      .send()
      .then((response) => {
        setRestaurantMenu(response.data);
        setSelectedMenu(response.data[0]?.name);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_menu'));
      });
  }, [restaurantId]);

  return (
    <section data-testid="menu-section" className="section">
      <h2 className="section-title">
        <strong>{name}</strong>
        {` ${t('pageRestaurantProfile:section_menu_title')} `}
      </h2>
      {restaurantMenu.length > 0 ? (
        <div data-testid="restaurant-menu" className="restaurant-menu">
          <FormElement
            id="menu-select"
            elementType="select"
            placeholder="Choose one"
            value={selectedMenu}
            changed={(e) => setSelectedMenu(e.target.value)}
            selectOptions={categories}
          />
          {restaurantMenu.map((category) => {
            if (selectedMenu === category.name) {
              return (
                <div key={category.name} className="menu-items-wrapper">
                  {category.items.map((item) => (
                    <MenuItem
                      key={item.id}
                      name={item.name}
                      description={item.description}
                      price={item.price}
                    />
                  ))}
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : (
        <div className="no-menu">
          <img src={StillTasting} alt="" />
          {t('pageRestaurantProfile:still_tasting')}
        </div>
      )}
    </section>
  );
}

MenuSection.propTypes = {
  restaurantId: PropTypes.string,
  name: PropTypes.string,
};

MenuSection.defaultProps = {
  restaurantId: '',
  name: '',
};
