import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';
import { Prompt } from 'react-router-dom';

import RestaurantContext from '../../contexts/RestaurantContext';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RequestService from '../../networking/RequestService';
import PageHeading from '../../react-web-ui/components/PageHeading';
import PillButton from '../../components/PillButton';
import RestaurantMenu from '../../react-web-ui/components/RestaurantMenu';
import Button from '../../react-web-ui/components/Button';
import AddMenuItemBlock from '../../components/AddMenuItemBlock';

import Spinner from '../../react-web-ui/components/Spinner';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import CategoryBlock from '../../components/CategoryBlock';
import Tooltip from '../../react-web-ui/components/Tooltip';
import useExitPrompt from '../../hooks/useExitPrompt';

export default function Menu() {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);
  const locale = countryContext.country.code.toLowerCase();
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);
  const [categories, setCategories] = useState([]);
  const [categoriesInitial, setCategoriesInitial] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [restaurantMenu, setRestaurantMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useExitPrompt(false);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'Your a la cart menu is the section your users spend most time on. It’s important to take the tame and keep it up to date.',
      disableBeacon: true,
      placement: 'center',
    },
    {
      target: '.tour-categories',
      content: 'Start by selecting the categories of your menu. You can suggest a new menu category by contacting your Dineout rep.',
    },
    {
      target: '.new-menu-item',
      content: 'Now write a name for your first dish and optionally a description. The language in the suggestion text will guide you which language should go where. Enter a price and use “.” should you need a decimal point. ',
    },
    {
      target: '.add-item-btn',
      content: 'Hit SAVE and a new set of lines will be added. Leave it empty if you are already done with the category. ',
    },
    {
      target: '.tour-sticky-buttons',
      content: 'Repeat for all Manu categories. Click the orange SAVE button to save your whole menu.',
    },
  ];

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('menu-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('menu-tour-status', 1);
    }
  };

  // Get restaurant menu
  useEffect(() => {
    if (!userContext.user || !restaurantContext.restaurantId) return;
    (new RequestService('manager/menu/get'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setRestaurantMenu(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_menu'));
      });
  }, [t, restaurantContext.restaurantId, userContext.user]);

  // Get all categories
  useEffect(() => {
    if (!restaurantContext.restaurantId || !userContext.user) return;
    (new RequestService('manager/menu/categories'))
      .setParams({
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        const newCategories = [];
        response.data.categories.forEach((category) => {
          newCategories.push({
            id: category.id,
            name: category.name,
            selected: false,
            items: [],
            translations: category.translations,
          });
        });
        setCategories(newCategories);
        setCategoriesInitial(JSON.stringify(newCategories));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_categories'));
      });
  }, [t, userContext.user, restaurantContext.restaurantId]);

  useEffect(() => {
    const selected = categories.filter((category) => category.selected);
    setSelectedCategories(JSON.parse(JSON.stringify(selected)));
  }, [categories]);

  // Select categories if the restaurant has a menu
  useEffect(() => {
    if (restaurantMenu.length === 0 || categories.length === 0) return;
    if (categories.map(({ selected }) => selected).some((item) => item)) return;

    const currentCategories = JSON.parse(JSON.stringify(categories));
    for (let i = 0; i < currentCategories.length; i += 1) {
      for (let k = 0; k < restaurantMenu.length; k += 1) {
        if (restaurantMenu[k].id === currentCategories[i].id) {
          currentCategories[i].selected = true;
          currentCategories[i].items = restaurantMenu[k].items;
          currentCategories[i].translations = restaurantMenu[k].translations;
        }
      }
    }
    setCategories(currentCategories);
    setCategoriesInitial(JSON.stringify(currentCategories));
  }, [restaurantMenu, categories]);

  const handleSaveMenu = () => {
    const convertedCategories = [];
    setLoading(true);
    startListening();

    categories.forEach((category) => {
      const menuItems = [];
      if (!category.selected) {
        convertedCategories.push({
          menu_category_id: category.id,
          menu_items: menuItems,
        });
      } else {
        category.items.forEach((item, index) => {
          const menuItemsParams = {
            default_name: item.name,
            default_description: item.description,
            default_price: item.price,
            position: index,
          };
          if (item.id.length > 10) {
            menuItemsParams.menu_item_id = item.id;
          }
          if (item.translations[locale]) {
            menuItemsParams.translations = [
              {
                locale,
                name: item.translations[locale].name,
                description: item.translations[locale].description,
              },
            ];
          }
          menuItems.push(menuItemsParams);
        });
        convertedCategories.push({
          menu_category_id: category.id,
          menu_items: menuItems,
        });
      }
    });

    const saveMenuParams = {
      place_id: restaurantContext.restaurantId,
      user_id: userContext.user.id,
      categories: convertedCategories,
    };

    (new RequestService('manager/menu/edit'))
      .setParams(saveMenuParams)
      .send()
      .then(() => {
        toast.success(t('success:menu_edit_success'));
        setChanged(false);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_edit_menu'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  useEffect(() => {
    if (JSON.stringify(categories) !== categoriesInitial) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [categoriesInitial, categories]);

  useEffect(() => () => {
    setChanged(false);
  }, []);

  return (
    <div className="inner-wrapper">
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <PageHeading
        classes="page-heading-menu"
        title={t('pageMenu:title')}
        content={(
          <br />
        )}
      />
      <section className="tour-categories section">
        <h2 className="section-title underlined">
          <strong>{t('pageMenu:manage_categories')}</strong>
        </h2>
        {categories.map((category) => {
          let { name } = category;
          if (i18n.language === 'en' || i18n.language === 'en-GB') {
            name = category.name;
          } else if (category.translations[locale]) {
            name = category.translations[locale].name;
          } else {
            name = category.name;
          }

          return (
            <PillButton
              key={category.id}
              text={name}
              className={category.selected ? 'active' : ''}
              onClick={() => {
                const currentCategories = JSON.parse(JSON.stringify(categories));
                for (let i = 0; i < currentCategories.length; i += 1) {
                  if (category.name === currentCategories[i].name) {
                    currentCategories[i].selected = !category.selected;
                  }
                }
                setCategories(currentCategories);
              }}
            />
          );
        })}
      </section>
      <section className="section">
        <h2 className="section-title underlined">
          <strong>{t('pageMenu:add_menu_items')}</strong>
        </h2>
        <h2 className="section-title">
          <strong>{restaurantContext.restaurant && restaurantContext.restaurant.name}</strong>
          {` ${t('pageRestaurantProfile:section_menu_title')} `}
        </h2>
        <div className="tour-menu-items restaurant-menu-edit">
          <RestaurantMenu
            menu={(
              <div
                id="menu-block"
                className={`
                  invertible-fields
                  ${(i18n.language === 'bg' || i18n.language === 'pl') ? 'inverted' : ''}
                `}
              >
                {selectedCategories.map((category) => (
                  <React.Fragment key={category.id}>
                    <div className="grid category-name-grid">
                      <div className="grid-col-5 invertible-field">
                        <h3>{category.name}</h3>
                      </div>
                      <div className="grid-col-5 invertible-field">
                        <h3>{category.translations[locale] ? category.translations[locale]?.name : '_'}</h3>
                      </div>
                    </div>
                    <CategoryBlock
                      locale={locale}
                      category={category}
                      categories={categories}
                      setCategories={setCategories}
                      loading={loading}
                      setChanged={setChanged}
                      changed={changed}
                    />
                    <AddMenuItemBlock
                      locale={locale}
                      category={category}
                      categories={categories}
                      setCategories={setCategories}
                      disabled={loading}
                      setChanged={setChanged}
                      changed={changed}
                    />
                  </React.Fragment>
                ))}
              </div>
            )}
          />
        </div>
        <div className="tour-sticky-buttons center-buttons">
          <Button
            text={loading ? (
              <>
                <Spinner />
                {t('loading')}
              </>
            ) : t('save')}
            classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
            disabled={loading}
            onClick={handleSaveMenu}
          />
        </div>
      </section>
    </div>
  );
}
