export default class Provider {
  restaurant() {
    throw new Error(
      `${this.constructor.name} doesn't implement restaurant() (－‸ლ)`,
    );
  }

  chef() {
    throw new Error(
      `${this.constructor.name} doesn't implement chef() (－‸ლ)`,
    );
  }

  city() {
    throw new Error(
      `${this.constructor.name} doesn't implement city() (－‸ლ)`,
    );
  }

  specialEvent() {
    throw new Error(
      `${this.constructor.name} doesn't implement specialEvent() (－‸ლ)`,
    );
  }

  specialOffer() {
    throw new Error(
      `${this.constructor.name} doesn't implement specialOffer() (－‸ლ)`,
    );
  }

  collection() {
    throw new Error(
      `${this.constructor.name} doesn't implement collection() (－‸ლ)`,
    );
  }
}
