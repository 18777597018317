import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MD5 } from 'crypto-js';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import useForm from '../../react-web-ui/hooks/useForm';
import validate from './validation';
import FormElement from '../../react-web-ui/components/FormElement';
import SaveOrDiscard from '../SaveOrDiscard';

/**
* EditEmployeeForm
* @returns {*}
* @constructor
*/
export default function EditEmployeeForm({
  item,
  index,
  itemsEditing,
  setItemsEditing,
  setLoading,
  setShowAdd,
  showSuccess,
  setShowSuccess,
}) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const currentSuccess = [...showSuccess];
  const currentEditing = [...itemsEditing];

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  const changeEditing = (bool) => {
    currentEditing.forEach((current, i) => {
      if (index === i) {
        currentEditing[i] = bool;
      } else {
        currentEditing[i] = false;
      }
    });
    setItemsEditing(currentEditing);
  };

  const changeSuccess = (bool) => {
    currentSuccess.forEach((current, i) => {
      if (index === i) {
        currentSuccess[i] = bool;
      } else {
        currentSuccess[i] = false;
      }
    });
    setShowSuccess(currentSuccess);
  };

  function submit() {
    changeEditing(false);
    setLoading(true);
    (new RequestService('manager/hosts/update'))
      .setParams({
        user_id: userContext.user.id,
        host_id: item.id,
        first_name: item.firstName,
        last_name: item.lastName,
        password: MD5(values.new_password).toString(),
      })
      .send()
      .then(() => {
        changeSuccess(true);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_update_host'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <SaveOrDiscard
        handleSubmit={() => {
          handleSubmit();
          setShowAdd(true);
        }}
        check={Object.keys(values).length > 0 && Object.values(values).some((x) => x !== '')}
        onClick={() => {
          changeEditing(false);
          setShowAdd(true);
        }}
      />
      <div className="lg-two-cols">
        <FormElement
          className="col"
          id="new_password"
          elementType="input"
          inputType="password"
          placeholder={t('forms:new_password')}
          value={values.new_password || ''}
          errors={errors.new_password}
          changed={handleChange}
        />
        <FormElement
          className="col"
          id="repeat_new_password"
          elementType="input"
          inputType="password"
          placeholder={t('forms:repeat_new_password')}
          value={values.repeat_new_password || ''}
          errors={errors.repeat_new_password}
          changed={handleChange}
        />
      </div>
    </>
  );
}

EditEmployeeForm.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  itemsEditing: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setItemsEditing: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setShowAdd: PropTypes.func.isRequired,
  showSuccess: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setShowSuccess: PropTypes.func.isRequired,
};
