import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Tray } from '../../assets/images/icons/Cuisine.svg';
import { ReactComponent as Calendar } from '../../assets/images/icons/Calendar.svg';
import { ReactComponent as PriceRange } from '../../assets/images/icons/PriceRange.svg';
import { ReactComponent as Clock } from '../../assets/images/icons/Clock.svg';

/**
 * Event Block
 * @param currency
 * @param className
 * @param big
 * @param eventImage
 * @param lastChance
 * @param eventName
 * @param date
 * @param time
 * @param restaurantName
 * @param price
 * @param description
 * @param sets
 * @param buttons
 * @param infoOnly
 * @returns {*}
 * @constructor
 */
export default function EventBlock({
  currency,
  className,
  big,
  eventImage,
  lastChance,
  eventName,
  date,
  time,
  restaurantName,
  price,
  description,
  sets,
  buttons,
  infoOnly,
}) {
  const [active, setActive] = useState(0);
  return (
    <div className={`restaurant-block event-block ${className} ${big ? 'big' : ''}`}>
      <div className="img-wrapper">
        {eventImage}
        {lastChance}
      </div>
      <div className="text-wrapper">
        <h2>{eventName}</h2>
        <div className="event-info">
          <span>
            <Calendar className="icon" />
            {date}
          </span>
          <span>
            <Clock className="icon" />
            {time}
          </span>
          <span>
            <Tray className="icon" />
            {restaurantName}
          </span>
          <span>
            <PriceRange className="icon" />
            {price}
          </span>
        </div>
        {infoOnly && (
          <>
            <div className="menu-tabs">
              {sets.map((set, index) => (
                <button
                  key={`${set.id}-button`}
                  type="button"
                  className={`${active === index ? 'active' : ''}`}
                  onClick={() => setActive(index)}
                >
                  <Tray className="icon" />
                  {set.name}
                </button>
              ))}
            </div>
            <div className="menu-content">
              {sets.map((set, index) => (
                <div
                  key={`${set.id}-menu`}
                  className={`menu-text ${active === index ? 'active' : ''}`}
                >
                  {set.items.map((menuItem) => (
                    <p key={menuItem.id} className="menu-item">
                      <strong>{menuItem.name}</strong>
                      {menuItem.description && <br />}
                      {menuItem.description}
                    </p>
                  ))}
                  <p className="menu-price">{`${set.price} ${currency}`}</p>
                </div>
              ))}
            </div>
          </>
        )}
        <div className="text-content event-text">
          {description}
        </div>
        {buttons}
      </div>
    </div>
  );
}

EventBlock.propTypes = {
  className: PropTypes.string,
  big: PropTypes.bool,
  eventImage: PropTypes.element,
  lastChance: PropTypes.element,
  eventName: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  restaurantName: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.string,
  sets: PropTypes.oneOfType([PropTypes.array]),
  buttons: PropTypes.element,
  infoOnly: PropTypes.bool,
  currency: PropTypes.string,
};

EventBlock.defaultProps = {
  className: '',
  big: false,
  eventImage: null,
  lastChance: null,
  eventName: '',
  date: '',
  time: '',
  restaurantName: '',
  price: '',
  description: '',
  sets: [],
  buttons: null,
  infoOnly: false,
  currency: 'PLN',
};
