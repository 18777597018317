import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Plus } from '../../assets/images/icons/Plus.svg';

/**
* AddNewShadowBlock
* @returns {*}
* @constructor
*/
export default function AddNewShadowBlock({
  title,
  fields,
  adding,
  setAdding,
}) {
  return adding ? (
    <div className="shadow-block add-new-shadow">
      {fields}
    </div>
  ) : (
    <button
      className="shadow-block add-new-shadow"
      type="button"
      onClick={() => setAdding(true)}
    >
      <header>
        <h3 className="name">{title}</h3>
      </header>
      <Plus className="icon-plus" />
    </button>
  );
}

AddNewShadowBlock.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.element.isRequired,
  adding: PropTypes.bool.isRequired,
  setAdding: PropTypes.func.isRequired,
};
