import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import RequestService from '../../networking/RequestService';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import ShadowBlock from '../../components/ShadowBlock';
import AddNewShadowBlock from '../../components/AddNewShadowBlock';
import Button from '../../react-web-ui/components/Button';
import Tooltip from '../../react-web-ui/components/Tooltip';
import AddEmployeeForm from '../../components/AddEmployeeForm';
import EmployeeFooter from '../../components/EmployeeFooter';
import EditEmployeeForm from '../../components/EditEmployeeForm';

export default function Employees() {
  const { t } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [showSuccess, setShowSuccess] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [itemsEditing, setItemsEditing] = useState([]);
  const [showAdd, setShowAdd] = useState(true);
  const [adding, setAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'Manage your host accounts here. You can add a new host, change the password of an existing account or disable it should you revoke their access to Dineout Host.',
      disableBeacon: true,
      placement: 'center',
    },
  ];

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('employees-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('employees-tour-status', 1);
    }
  };

  useEffect(() => {
    if (!userContext.user || loading || !restaurantContext.restaurantId) return;
    (new RequestService('manager/hosts/index'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setEmployees(response.data);
        const editing = [];
        response.data.forEach(() => {
          editing.push(false);
        });
        setItemsEditing(editing);
        setTimeout(() => {
          setShowSuccess(editing);
        }, 2000);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_hosts'));
      });
  }, [userContext.user, restaurantContext.restaurantId, loading]);

  useEffect(() => {
    if (showSuccess.filter((item) => item).length > 0) {
      const currentSuccess = [];
      showSuccess.forEach(() => {
        currentSuccess.push(false);
      });
      setTimeout(() => {
        setShowSuccess(currentSuccess);
      }, 2000);
    }
  }, [showSuccess]);

  return (
    <div className="inner-wrapper">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <h1 className="section-title underlined">
        <strong>{t('pageEmployees:title')}</strong>
      </h1>
      <div className="shadow-grid">
        {employees.map((employee, index) => (
          <ShadowBlock
            key={employee.id}
            item={employee}
            name={`${employee.firstName} ${employee.lastName}`}
            subtitle={employee.email}
            loading={loading}
            setShowAdd={setShowAdd}
            setLoading={setLoading}
            index={index}
            itemsEditing={itemsEditing}
            setItemsEditing={setItemsEditing}
            successMessage={t('pageEmployees:host_updated')}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
            footer={(
              <EmployeeFooter
                employee={employee}
                itemsEditing={itemsEditing}
                index={index}
                showSuccess={showSuccess}
                setShowSuccess={setShowSuccess}
              />
            )}
            editingForm={(
              <EditEmployeeForm
                item={employee}
                itemsEditing={itemsEditing}
                setItemsEditing={setItemsEditing}
                index={index}
                showSuccess={showSuccess}
                setShowSuccess={setShowSuccess}
                setShowAdd={setShowAdd}
                setLoading={setLoading}
              />
            )}
          />
        ))}
        {showAdd && (
          <AddNewShadowBlock
            setLoading={setLoading}
            title={t('pageEmployees:new_host')}
            adding={adding}
            setAdding={setAdding}
            fields={(
              <AddEmployeeForm
                setLoading={setLoading}
                adding={adding}
                setAdding={setAdding}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}
