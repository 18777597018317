/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { checkVAT, countries } from 'jsvat/lib/commonjs';

import validate from './validation';
import illustration12 from '../../assets/images/illustrations/illustration12-blue.svg';
import Checkbox from '../../react-web-ui/components/FormElement/Checkbox';
import useForm from '../../react-web-ui/hooks/useForm';
import Button from '../../react-web-ui/components/Button';
import Spinner from '../../react-web-ui/components/Spinner';
import FormElement from '../../react-web-ui/components/FormElement';
import countriesSelect from '../../utilities/countries';
import RequestService from '../../networking/RequestService';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import CountryContext from '../../react-web-ui/contexts/CountryContext';

export default function Terms() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const countryContext = useContext(CountryContext);
  const [vatText, setVatText] = useState('');

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    setLoading(true);
    const params = {
      place_id: restaurantContext.restaurantId,
      user_id: userContext.user.id,
      bank_details: {
        beneficiary: values.beneficiary,
        iban: values.iban,
        bic: values.swift,
      },
      invoice_details: {
        company_name: values.billing_company_name,
        country: values.billing_country,
        city: values.billing_city,
        address_line_1: values.billing_line1,
        address_line_2: values.billing_line2,
        vat_id: values.billing_vat,
      },
    };
    (new RequestService('manager/places/enable-dining-events'))
      .setParams(params)
      .send()
      .then((response) => {
        restaurantContext.setRestaurant(response.data);
        toast.success(t('success:accepted_events_success'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_accept_events'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!restaurantContext.restaurant || !restaurantContext.restaurant.payoutDetails) return;
    if (!restaurantContext.restaurant.payoutDetails.bankDetails
      || !restaurantContext.restaurant.payoutDetails.invoiceDetails) return;
    setValues({
      beneficiary: restaurantContext.restaurant.payoutDetails.bankDetails.beneficiary,
      iban: restaurantContext.restaurant.payoutDetails.bankDetails.iban,
      swift: restaurantContext.restaurant.payoutDetails.bankDetails.bic,
      billing_company_name: restaurantContext.restaurant.payoutDetails.invoiceDetails.companyName,
      billing_country: restaurantContext.restaurant.payoutDetails.invoiceDetails.country,
      billing_city: restaurantContext.restaurant.payoutDetails.invoiceDetails.city,
      billing_line1: restaurantContext.restaurant.payoutDetails.invoiceDetails.addressLine1,
      billing_line2: restaurantContext.restaurant.payoutDetails.invoiceDetails.addressLine2,
      billing_vat: restaurantContext.restaurant.payoutDetails.invoiceDetails.vatId,
      accept_terms: false,
    });

    if (restaurantContext.restaurant.payoutDetails.invoiceDetails.vatId) {
      setVatText(restaurantContext.restaurant.payoutDetails.invoiceDetails.vatId);
    }
  }, [restaurantContext.restaurant]);

  useEffect(() => {
    if (!countryContext.country.code || vatText !== '') return;
    setVatText(countryContext.country.code);
  }, [countryContext.country.code]);

  useEffect(() => {
    if (vatText === '') return;
    setValues({
      ...values,
      billing_vat: vatText,
    });
    if (!checkVAT(vatText, countries).isValid) {
      setValues({
        ...values,
        billing_vat: 'invalid',
      });
    }
  }, [vatText]);

  return (
    <div className="inner-wrapper">
      <div className="terms-heading">
        {i18n.language === 'en' && (
          <div className="text-wrapper">
            <h2>DINING EVENTS</h2>
            <p>Dineout promotes our partner restaurants&apos; dining events using various marketing channels, including direct marketing to our users as well as paid advertising. We encourage users to book and prepay dining events in advance to minimize no shows and to ensure optimal capacity utilization of your restaurant.  </p>
            <p>
              We do not have a fee for promoting your dining event but only charge a commission for dining events that were actually sold through our platform.
            </p>
            <p>In order for you to start promoting and selling your dining events through Dineout you need to first accept our terms and conditions. </p>
          </div>
        )}
        {i18n.language === 'pl' && (
          <div className="text-wrapper">
            <h2>WYDARZENIA KULINARNE</h2>
            <p>Dineout promuje wydarzenia kulinarne naszych partnerskich restauracji korzystając z wielu kanałów marketingowych, w tym marketingu bezpośredniego skierowanego do naszych użytkowników oraz reklamy płatnej. Zachęcamy użytkowników do rezerwacji wizyt z wyprzedzeniem, dokonując wcześniej przedpłaty. Pozwala to na  zminimalizowanie nieodwołanych wizyt i zapewnia optymalne wykorzystanie pojemności restauracji.</p>
            <p>
              Nie pobieramy opłaty za promocję Twojego wydarzenia kulinarnego, a jedynie pobieramy prowizję od faktycznej sprzedaży wydarzenia za pośrednictwem naszej platformy.
            </p>
            <p>Aby rozpocząć promocję i sprzedaż wydarzeń za pośrednictwem Dineout, musisz najpierw zaakceptować nasze warunki.</p>
          </div>
        )}
        {i18n.language === 'bg' && (
          <div className="text-wrapper">
            <h2>Кулинарни събития</h2>
            <p>Dineout промотира събитията на партниращи ресторанти в множество канали, част от тях включват директен маркетинг до потребителите ни, както и платени реклами в интернет. Насърчаваме своите потребители да запазват и предплащат събития, така че пропуснатите резервации да се сведат до минимум, а също така да имате възможност да оптимизирате капацитета на ресторанта си.</p>
            <p>
              Не заплащате за промотиране на събитието, а единствено комисионна за продадените през системата ни куверти.
            </p>
            <p>За да започнете, трябва единствено да се запознаете с условията за използване, поместени по-долу.</p>
          </div>
        )}
        <div className="img-wrapper events-img">
          <img src={illustration12} alt="" />
        </div>
      </div>
      {i18n.language === 'en' && (
        <div className="terms-content">
          <h3>Terms and Conditions</h3>
          <p>By clicking the continue button below you are agreeing to the following terms and conditions:</p>
          <h4>1. Dining Events</h4>
          <p>
            1.1. You are granted the right to create and manage Dining Events in your account and to promote and sell them through the Dineout platform.
            <br />
            1.2. You agree to pay Dineout a commission, as described in section 2 below, for each successful sale by Dineout of a Dining Event through Dineout’s website and apps.
            <br />
            1.3. You remain the sole owner of and are responsible for the content created for your restaurant on our platforms.
            <br />
            1.4. You agree that you are responsible for fulfilling each order placed through our website and apps in the quality, quantity and at the price described by you in your Dining Events.
            <br />
            1.5. You agree that the prices of Dining Events you publish on our platform will  not be higher than the prices of the same items when purchased directly at your restaurant.
            <br />
            1.6. You understand and agree that you will be notified for all reservations for Dining Events through Dineout Host, Dineout Host Lite and email only.
            <br />
            1.7. You give your consent to Dineout to use the content you create for promotional purposes.
            <br />
            1.8. You, at your own discretion, set the price of the Dining Events sold through our platform. For Dining Events you also determine the number of covers to be sold by Dineout, the last sale date, and the minimum reservation time before an event.
            <br />
            1.9. Dineout promotes the created Dining Events through our website, user apps as well other advertising channels we may deem appropriate at no cost to you.
            <br />
            1.10. All sales of Dining Events take place on Dineout’s website and user apps. Payments are processed securely by a third party.
            <br />
            1.11. You agree that you may not hold Dineout responsible for any direct or indirect losses you may incur as a result of users of our platforms  failing to honor their reservations.
            <br />
            1.12. You may contact your account manager or our support team with any questions or concerns you might have to resolve a specific issue.
          </p>
          <h4>
            2. Commission and payment
          </h4>
          <p>
            2.1. The commission to Dineout is 10% (ten per cent) of the value of each order of a Dining Event. In the case of booking a Dining Event  using a gift card, the commission is charged only on the value of the event.
            <br />
            2.2 The commission is due only for completed reservations, i.e. reservations honoured by the users.
            <br />
            2.3.  The total value of the order for a Dining Event is transferred by Dineout to your bank account no later than 5 (working days) days after the Event.
            <br />
            2.4 Dineout’s commission is invoiced to you at the end of the calendar month. An invoice will be sent to your email address.
            <br />
            2.5 The commission is deducted by the total value of the order for a Dining Event.
            <br />
            2.6. Dineout agrees to cover the bank transfer fees.
            <br />
            2.7 You are obliged to pay the invoice within 14 days from the invoice date.
            <br />
            2.8. You may contact your account manager or our support team with any questions or concerns you might have to resolve a specific issue.
          </p>
          <h4>3. Cancelations</h4>
          <p>
            3.1. If you cancel a reservation for a Dining Event that is already paid for by the user - 100 % of the price is refunded to the user.
            <br />
            3.2. In case the user cancels a reservation for a Dining Event, user pays the fee as follows:
            <br />
            3.2.1 Reservations cancelled 72 hours prior to the reservation’s date and time -  100% of price refunded to the user.
            <br />
            3.2.2 Reservations cancelled 24 hours prior to the reservation’s date and time - 50% of the price set by the Restaurant refunded to the user. You keep 50% of the price set by you, after deduction of Dineout’s commission.
            <br />
            3.2.3. Reservations cancelled less than 24 hours prior to the reservation’s date and time - You keep 100% of the price set by you, after deduction of Dineout’s commission.
          </p>
          <h4>4. Changes to the terms and conditions</h4>
          <p>From time to time Dineout may, at its sole discretion, change, modify or update the current terms and conditions to reflect regulatory, security or other market changes. You will be notified via email of any changes to these terms and conditions at least 30 days prior to these changes taking effect.</p>
          <h4>
            5. Termination of this agreement
          </h4>
          <p>
            5.1. You may withdraw your consent to offer Dining Events with a 30 days written notice sent by email to your account manager or to
            {' '}
            <a href="mailto:hello@dineout.pl">hello@dineout.pl</a>
            . You agree to honour any reservations during the termination notice period. Dineout agrees to pay you all the amounts due for reservations for Dining Events during the termination period.
            <br />
            5.2. Dineout may terminate this agreement with a 30 day written notice sent to the email address provided by you during the registration process. You agree to honour any reservations during the termination notice period. Dineout agrees to pay you all the amounts due for reservations for Dining Events during the termination period.
          </p>
        </div>
      )}
      {i18n.language === 'pl' && (
        <div className="terms-content">
          <h3>Regulamin korzystania</h3>
          <h4>Definicje</h4>
          <p>
            1.Platforma – oprogramowanie oferowane przez Dineout Poland w którego skład wchodzą; aplikacja Dineout Poland, Dineout Host, Dineout Host Lite oraz portal dla managerów: restaurant.dineout.pl. Platforma ta wspiera procesy sprzedaży kart podarunkowych, menu degustacyjnych, organizacji wydarzeń, rejestracji uczestnictwa oraz sprzedaży biletów przez Klienta.
            <br />
            2.Klient - Podmiot, który spełnia warunki Regulaminu i dokonał akceptacji Regulaminu. Klientami są osoby zarządzające restauracją, mający prawo do jej reprezentowania.
            <br />
            3. Użytkownik - użytkownicy indywidualni oraz użytkownicy biznesowi, którzy korzystają z usług oferowanych przez Dineout, osoby korzystające z aplikacji Dineout Poland.
            <br />
            3.Wydarzenie – zorganizowane przez Klienta przedsięwzięcie w szczególności wydarzenia kulinarne, eventy, imprezy, koncerty, festiwale i inne;  Wydarzenie na stronie Dineout musi być odpłatne.
            <br />
            4.Bilet – dokument w formie elektronicznej zakupiony przy wykorzystaniu serwisu dineout.pl potwierdzający uprawnienie do wzięcia udziału w Wydarzeniu organizowanym przez Klienta.
          </p>
          <h3>Warunki korzystania</h3>
          <p>
            Klikając poniższy przycisk Kontynuuj, wyrażasz zgodę na następujące warunki:
          </p>
          <h4>1. Wydarzenia kulinarne</h4>
          <p>
            1.1. Jako Klientowi przysługuje Ci prawo do tworzenia i zarządzania wydarzeniami kulinarnymi na swoim koncie Dineout oraz do promowania i sprzedawania wydarzeń za pośrednictwem platformy Dineout.
            <br />
            1.2. Wyrażasz zgodę na zapłatę Dineout prowizji w wysokości 10% od wartości sprzedanego biletu na wydarzenie kulinarne, za pośrednictwem witryny internetowej i aplikacji Dineout.
            <br />
            1.3. Pozostajesz jedynym właścicielem i odpowiadasz za treści tworzone dla Twojej restauracji na naszych platformach.
            <br />
            1.4. Zgadzasz się, że jesteś odpowiedzialny za realizację każdego zamówienia złożonego za pośrednictwem naszej strony internetowej i aplikacji z zachowaniem  jakości, ilości i ceny opisanej przez Ciebie w Twoich wydarzeniach.
            <br />
            1.5. Zgadzasz się, że ceny biletów na Wydarzenia, które publikujesz na naszej platformie, nie będą wyższe niż ceny tych samych ofert kupowanych bezpośrednio w Twojej restauracji lub przez strony trzecie.
            <br />
            1.6. Rozumiesz i zgadzasz się, że będziesz powiadamiany o wszystkich rezerwacjach na Wydarzenia  wyłącznie za pośrednictwem Dineout Host, Dineout Host Lite i poczty elektronicznej.
            <br />
            1.7. Wyrażasz zgodę na wykorzystanie przez Dineout tworzonych przez Ciebie treści do celów promocyjnych.
            <br />
            1.8. Klient, według własnego uznania ustala cenę biletów na Wydarzenia sprzedawane za pośrednictwem naszej platformy.  Klient określa również liczbę biletów na wydarzenie , które przeznacza do sprzedaży przez Dineout,  datę ostatniego dnia sprzedaży oraz minimalny czas na złożenie rezerwacji przed terminem wydarzenia.
            <br />
            1.9. Dineout promuje utworzone Wydarzenia za pośrednictwem naszej strony internetowej, aplikacji Dineout, jak również innych kanałów promocji, które możemy uznać za stosowne bez ponoszenia kosztów dodatkowych przez Klienta.
            <br />
            1.10. Cała sprzedaż biletów na wydarzenia odbywa się na stronie internetowej Dineout oraz w aplikacji dla użytkowników.
            {' '}
            <strong>Płatności są przetwarzane w bezpieczny sposób przez stronę trzecią.</strong>
            <br />
            1.11. Klient zgadza się, że nie może pociągnąć Dineout do odpowiedzialności za jakiekolwiek bezpośrednie lub pośrednie straty, które może ponieść w wyniku działań użytkowników naszej platformy.
          </p>
          <h4>
            2. Prowizja i płatności
          </h4>
          <p>
            2.1. Prowizja dla Dineout wynosi 10% (dziesięć procent) od wartości każdego zakupionego biletu na wydarzenie kulinarne.
            <br />
            2.2. Prowizja należna jest wyłącznie za dokonane rezerwacje, czyli rezerwacje opłacone i zrealizowane przez użytkowników.
            <br />
            2.3. Prowizja jest naliczana od łącznej wartości sprzedaży biletów na wydarzenie.
            <br />
            2.4. Po zrealizowanym wydarzeniu kulinarnymi Dineout przelewa na konto restauratora równowartość wszystkich  wykupionych biletów, nie później niż 5 dni roboczych od zakończenia wydarzenia.
            <br />
            2.5. Na koniec miesiąca Dineout wystawia fakturę na 10% wartości wszystkich biletów zakupionych przez Dineout  na wydarzenia, które odbyły się w danym miesiącu kalendarzowym.
            <br />
            2.6. Klient ma obowiązek opłacić fakturę w terminie 14 dni od daty jej przesłania na adres mailowy podany w systemie podczas rejestracji restauracji.
            <br />
            2.7. Dineout zobowiązuje się pokryć opłaty za przelew bankowy.

          </p>
          <h4>3. Rezygnacje</h4>
          <p>
            3.1. W przypadku gdy Klient anuluje rezerwację na Wydarzenie,  które użytkownik już opłacił, całość kwoty jest zwracana na konto użytkownika.
            <br />
            3.2. W przypadku, gdy użytkownik anuluje rezerwację na wydarzenie, ponosi on opłatę w następujący sposób:
            <br />
            3.2.1. Rezerwacje anulowane 72 godziny przed datą i godziną rezerwacji przez użytkownika - kwota w całości zwracana na konto użytkownika.
            <br />
            3.2.2. Rezerwacje anulowane 24 godziny przed datą i godziną rezerwacji - Użytkownikowi zwracane jest 50% wartości ceny biletu określonego przez Restaurację, po potrąceniu prowizji Dineout, jak określono w punkcie 2 powyżej.
            <br />
            3.2.3. Rezerwacje anulowane mniej niż 24 godziny przed datą i godziną rezerwacji -  Użytkownik traci prawo do zwrotów kosztów biletu.  Klient otrzymuje całość ustalonej ceny, po potrąceniu prowizji Dineout, jak określono w punkcie 2 powyżej.

          </p>
          <h4>4. Zmiany w regulaminie</h4>
          <p>Od czasu do czasu firma Dineout może, według własnego uznania, zmieniać, modyfikować lub aktualizować aktualne warunki w celu odzwierciedlenia zmian regulacyjnych, bezpieczeństwa lub innych zmian rynkowych. Zostaniesz powiadomiony drogą mailową o wszelkich zmianach w niniejszych warunkach co najmniej 30 dni przed wejściem tych zmian w życie.</p>
          <h4>
            5. Wypowiedzenie niniejszej umowy
          </h4>
          <p>
            5.1. Rozwiązanie niniejszej umowy.
            <br />
            5.2. Klient może rozwiązać umowę z Dineout Poland poprzez wysłanie oświadczenia o rozwiązaniu umowy na adres
            {' '}
            <a href="mailto:hello@dineout.pl">hello@dineout.pl</a>
            {' '}
            lub pocztą tradycyjną na adres Dineout Poland, ul. Koszykowa 61, 00-667 Warszawa.
            <br />
            5.3. Adres e-mail z którego wysyłana jest prośba musi być taki sam jak ten, który został użyty do rejestracji konta klienta na platformie Dineout.
            <br />
            5.4. W przypadku Klientów, usunięcie Konta nie jest możliwe w czasie od momentu aktywacji Wydarzenia i sprzedania co najmniej jednego biletu do zakończenia Wydarzenia i rozliczenia wszelkich płatności związanych z Wydarzeniem.
            <br />
            5.5. Klient zgadza się honorować wszelkie rezerwacje w okresie wypowiedzenia. Dineout zobowiązuje się zapłacić Klientowi wszystkie kwoty należne za rezerwacje na Wydarzenia w okresie wypowiedzenia.
            <br />
            5.6. Z uwzględnieniem warunków określonych w punkcie
            {' '}
            <strong>5.4. oraz 5.5.</strong>
            {' '}
            umowa ulega rozwiązaniu po upływie 30 dni od zakończenia rozliczenia wydarzenia lub dostarczeniu dokumentu obejmującego oświadczenie o rozwiązaniu umowy.
            <br />
            5.7. Z ważnych przyczyn, w szczególności gdy Klient, po uprzednim wezwaniu przez Dineout Poland nie spełnia swoich zobowiązań umownych wobec platformy,  umowa może zostać rozwiązana przez Dineout Poland za wypowiedzeniem, z zachowaniem siedmiodniowego terminu wypowiedzenia.
            <br />
            5.8. Dineout Poland ma prawo do rozwiązania umowy z Klientem ze skutkiem natychmiastowym lub do zablokowania Konta w przypadku naruszenia istotnych postanowień niniejszego Regulaminu.
          </p>
        </div>
      )}
      {i18n.language === 'bg' && (
        <div className="terms-content">
          <h3>Условия за използване</h3>
          <p>С настоящото се съгласявате на следните условия за използване:</p>
          <h4>1. Кулинарни събития</h4>
          <p>
            1.1. Получавате правото да създавате и управлявате Кулинарни събития, чрез личния си профил и да ги промотирате през системата на Dineout.
            <br />
            1.2. Съгласявате се да заплащате на Dineout комисионна за всяка продажба извършена от нас, както е упоменато в глава 2 на настоящия документ.
            <br />
            1.3. Създаденото съдържание, във връзка с Кулинарни събития, остава Ваша собственост и Вие носите отговорност за него.
            <br />
            1.4. Съгласявате се да уважите всяка поръчка за Кулинарно събитие, направена през Dineout в количество, с качество и на цената предварително определени от Вас за въпросното събитие.
            <br />
            1.5. Съгласявате се, че цените за Кулинарни събития, поместени на Dineout няма да са по-високи от цените им, при закупуване директно от Вас.
            <br />
            1.6. Съгласявате се и разбирате, че ще бъдете известявани за резервации за Кулинарни събития единствено чрез Dineout Host, Dineout Host LIte и имейл.
            <br />
            1.7. Предоставяте съгласието си Dineout да използва създаденото от Вас съдържание за промоционални цели.
            <br />
            1.8. Вие определяте цените на Кулинарни събития, предлагани през системата ни, също така избирате броя куверти, които могат да се продадат през Dineout, последна дата на продажба и минимално време за резервация преди събитието.
            <br />
            1.9. Dineout рекламира Кулинарните събития на нашия уебсайт, в приложенията ни, както и в други канали, избрани от нас, без да заплащате допълнително.
            <br />
            1.10. Продажбите се случват през уебсайта и в приложенията ни. Плащанията се осъществяват чрез трета страна.
            <br />
            1.11. Съгласявате се, че Dineout не носи отговорност за преки или непреки щети или пропуснати ползи, в резултат на неявяване на потребители.
            <br />
            1.12. Можете да се свържете със своето лице за контакт в Dineout или с отдела ни за поддръжка за разрешаване на конкретен въпрос.

          </p>
          <h4>
            2. Комисионна и заплащане
          </h4>
          <p>
            2.1. Комисионната на Dineout възлиза на 10% (десет процента) от стойността на всяка поръчка за Кулинарни събития. В случай, че за събитие има резервация с Дайнаут гифт карта, комисионната се начислява само върху общата стойност на събитието.
            <br />
            2.2. Комисионната е дължима единствено за реално осъществени резервации от страна на потребителите.
            <br />
            2.3. Крайната сума за всяка поръчка се изплаща по Ваша банкова сметка не по-късно от 5 (пет) дни от датата на събитието.
            <br />
            2.4. Дайнаут издава една фактура в последния ден от месеца. Фактурата се изпраща на имейл ви.
            <br />
            2.5. Комисионната на Dineout във всяка фактура включва всички продажби на събития през сайта и приложенията ни в съответния месец.
            <br />
            2.6. Dineout се съгласява да покрие транзакционните такси за превода.
            <br />
            2.7. Вие се съгласявате да платите фактурата в  срок от 14 дни от датата на фактурата.
            <br />
            2.8. Можете да се свържете с контактното си лице или отдела ни за поддръжка за разрешаване на конкретен въпрос.

          </p>
          <h4>3. Анулации</h4>
          <p>
            3.1. В случай, че анулирате вече платена от потребителя резервация за Кулинарно събитие, не получавате възнаграждение за нея.
            <br />
            3.2. В случай, че потребителят самостоятелно анулира резервация за Кулинарно събитие, се прилагат следните условия:
            <br />
            3.2.1. В случай, че потребителят анулира 72 (седемдесет и два) часа преди събитието, не получавате възнаграждение
            <br />
            3.2.3. В случай, че анулира до 24 (двадесет и четири) часа преди събитието, получавате 50% (петдесет процента) от заложената цена на куверта, след приспадане на комисионната на Dineout, описана в глава 2.
            <br />
            3.2.3. За резервации, анулирани по-малко от 24 (двадесет и четири) часа преди събитието, получавате 100% (сто процента) от определената цена за събитието, след приспадане на комисионната на Dineout, описана в глава 2.

          </p>
          <h4>4. Промени по условията за използване</h4>
          <p>Dineout запазва правото си да променя, обновява или модифицира настоящите условия за използване, така че да отразява пазарни, регулаторни или свързани със сигурността промени. За такива промени ще бъдете информирани по имейл, не по-малко от 30 (тридесет) дни, преди влизането им в сила.</p>
          <h4>
            5. Прекратяване на настоящото споразумение
          </h4>
          <p>
            5.1. Можете да оттеглите съгласието си да предлагате Кулинарни събития с 30 -дневно писмено предизвестие, изпратено по имейл до вашия контакт в Dineout или на
            {' '}
            <a href="mailto:hello@dineout.bg">hello@dineout.bg</a>
            . Вие се съгласявате да уважавате всички резервации, направени през периода на предизвестие. Dineout се съгласява да ви плати всички дължими суми за резервации, направени през периода на предизвестие.
            <br />
            5.2. Dineout може да прекрати това споразумение с 30 -дневно писмено предизвестие на имейл адреса, предоставен от Вас по време на процеса на регистрация. Вие се съгласявате да уважавате всички резерви, постъпили по време на периода на предизвестието. Dineout се съгласява да Ви плати всички дължими суми за резервации, направени през периода на предизвестие.
          </p>
        </div>
      )}
      {restaurantContext.restaurant
      && !restaurantContext.restaurant.areDiningEventsEnabled
      && (
        <>
          <Checkbox
            id="accept_terms"
            text={t('forms:agree_to_events')}
            value={`${values.accept_terms}`}
            checked={values.accept_terms}
            check={handleChange}
            errors={errors.accept_terms}
          />
          <div className="grid bigger-gap stripe-form">
            <div className="grid-col-5">
              <h2 className="text-accent">{t('forms:bank_details')}</h2>
              <FormElement
                id="beneficiary"
                elementType="input"
                placeholder={t('forms:beneficiary')}
                label={t('forms:beneficiary')}
                value={values.beneficiary || ''}
                errors={errors.beneficiary}
                changed={handleChange}
              />
              <FormElement
                id="iban"
                elementType="input"
                placeholder={t('forms:iban')}
                label={t('forms:iban')}
                value={values.iban || ''}
                errors={errors.iban}
                changed={handleChange}
              />
              <FormElement
                id="swift"
                elementType="input"
                placeholder={t('forms:swift')}
                label={t('forms:swift')}
                value={values.swift || ''}
                errors={errors.swift}
                changed={handleChange}
              />
            </div>
            <div className="grid-col-5">
              <h2 className="text-accent">{t('forms:invoice_details')}</h2>
              <FormElement
                id="billing_company_name"
                elementType="input"
                placeholder={t('forms:company')}
                label={t('forms:company')}
                value={values.billing_company_name || ''}
                errors={errors.billing_company_name}
                changed={handleChange}
              />
              <FormElement
                id="billing_country"
                elementType="select"
                placeholder={t('forms:billing_country')}
                label={t('forms:billing_country')}
                value={values.billing_country || ''}
                errors={errors.billing_country}
                changed={handleChange}
                selectOptions={[
                  {
                    value: '',
                    text: t('forms:billing_country'),
                  },
                  ...countriesSelect,
                ]}
              />
              <FormElement
                id="billing_city"
                elementType="input"
                placeholder={t('forms:restaurant_city')}
                label={t('forms:restaurant_city')}
                value={values.billing_city || ''}
                errors={errors.billing_city}
                changed={handleChange}
              />
              <FormElement
                id="billing_line1"
                elementType="input"
                placeholder={t('forms:billing_line1')}
                label={t('forms:billing_line1')}
                value={values.billing_line1 || ''}
                errors={errors.billing_line1}
                changed={handleChange}
              />
              <FormElement
                id="billing_line2"
                elementType="input"
                placeholder={t('forms:billing_line2')}
                label={t('forms:billing_line2')}
                value={values.billing_line2 || ''}
                errors={errors.billing_line2}
                changed={handleChange}
              />
              <FormElement
                id="billing_vat"
                elementType="input"
                placeholder={t('pageSubscriptions:billing_vat')}
                label={`${t('pageSubscriptions:billing_vat')} (${t('pageSubscriptions:billing_vat_type')})`}
                value={vatText}
                errors={errors.billing_vat}
                changed={(e) => setVatText(e.target.value)}
              />
            </div>
            <div className="grid-col-10">
              <Button
                text={loading ? (
                  <>
                    <Spinner />
                    {t('loading')}
                  </>
                ) : t('selfOnboarding:start_now')}
                classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
                disabled={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
