import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { oneOfType } from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';

import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';

function CombinationDetails({
  combination,
  onDeleteClicked,
  onSaveClicked,
  activeCombination,
  setCombinationChanged,
}) {
  const { t } = useTranslation();
  const [minCapacity, setMinCapacity] = useState(combination.minCapacity);
  const [maxCapacity, setMaxCapacity] = useState(combination.maxCapacity);
  const { tables, name } = combination;

  useEffect(() => {
    if (activeCombination === '') return;
    setMinCapacity(combination.minCapacity);
    setMaxCapacity(combination.maxCapacity);
  }, [activeCombination]);

  useEffect(() => {
    setMinCapacity(combination.minCapacity);
    setMaxCapacity(combination.maxCapacity);
  }, [combination.minCapacity, combination.maxCapacity]);

  useEffect(() => {
    if (activeCombination === ''
      || (parseInt(combination.minCapacity, 10) === parseInt(minCapacity, 10)
      && parseInt(combination.maxCapacity, 10) === parseInt(maxCapacity, 10))) {
      setCombinationChanged(false);
    } else {
      setCombinationChanged(true);
    }
  }, [minCapacity, maxCapacity]);

  return (
    <>
      <h2 className="column-top">
        {t('pageCombinations:combination_details_label')}
      </h2>
      <div className="details-form">
        {tables.length > 1 ? (
          <>
            <FormElement
              id="nameEn0"
              name="nameEn"
              elementType="input"
              placeholder={t('pageCombinations:combination_name')}
              value={name}
              label={t('pageCombinations:combination_name')}
              disabled
              changed={() => {}}
            />
            <div className="lg-two-cols">
              <FormElement
                id="minCapacity"
                name="nameEn"
                className="col"
                inputType="number"
                elementType="input"
                placeholder={t('pageFloorPlan:min_capacity')}
                value={minCapacity}
                changed={(event) => {
                  if (event.target.value > 0) {
                    setMinCapacity(event.target.value);
                  } else {
                    setMinCapacity(1);
                  }
                }}
                label={t('pageFloorPlan:min_capacity')}
              />
              <FormElement
                id="maxCapacity"
                elementType="input"
                className="col"
                inputType="number"
                placeholder={t('pageFloorPlan:max_capacity')}
                value={maxCapacity}
                changed={(event) => setMaxCapacity(event.target.value)}
                label={t('pageFloorPlan:max_capacity')}
              />
            </div>
            {activeCombination ? (
              <>
                <Button
                  text={t('update')}
                  classes="btn-accent"
                  onClick={() => {
                    onSaveClicked({
                      id: combination?.id,
                      name,
                      minCapacity,
                      maxCapacity,
                      tables,
                    });
                  }}
                />
                <button
                  type="button"
                  onClick={() => {
                    confirmAlert({
                      // eslint-disable-next-line react/prop-types
                      customUI: ({ onClose }) => (
                        <div className="react-confirm-alert-body">
                          <h2 className="section-title">{t('pageCombinations:are_you_sure_delete')}</h2>
                          <button
                            type="button"
                            onClick={() => {
                              onDeleteClicked(combination.id);
                              onClose();
                            }}
                          >
                            {t('yes')}
                          </button>
                          <button
                            type="button"
                            onClick={onClose}
                          >
                            {t('no')}
                          </button>
                        </div>
                      ),
                    });
                  }}
                  className="btn-link btn-delete"
                >
                  {t('delete')}
                </button>
              </>
            ) : (
              <Button
                text={t('add')}
                classes="btn-accent"
                onClick={() => {
                  onSaveClicked({
                    id: combination?.id,
                    name,
                    minCapacity,
                    maxCapacity,
                    tables,
                  });
                }}
              />
            )}
          </>
        ) : (
          t('pageCombinations:at_least_two_tables_warning')
        )}
      </div>
    </>
  );
}

CombinationDetails.propTypes = {
  combination: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    minCapacity: oneOfType([PropTypes.number, PropTypes.string]),
    maxCapacity: oneOfType([PropTypes.number, PropTypes.string]),
    tables: PropTypes.instanceOf(Array),
  }).isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  setCombinationChanged: PropTypes.func,
  activeCombination: PropTypes.string,
};

CombinationDetails.defaultProps = {
  activeCombination: '',
  setCombinationChanged: null,
};

export default CombinationDetails;
