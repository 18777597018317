import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    emailEmpty: i18n.t('forms:email_error_empty'),
    emailInvalid: i18n.t('forms:email_error_invalid'),
    phoneEmpty: i18n.t('forms:phone_error_empty'),
    phoneInvalid: i18n.t('forms:phone_error_invalid'),
  };

  // Phone
  errors.phone = [];
  const telRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

  if (!values.phone || values.phone.trim() === '') {
    errors.phone.push(errorTexts.errorEmpty);
  }

  if (values.phone && !telRegex.test(values.phone)) {
    errors.phone.push(errorTexts.phoneInvalid);
  }

  // Email
  errors.email = [];
  if (!values.email || values.email.trim() === '') {
    errors.email.push(errorTexts.emailEmpty);
  }

  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email.push(errorTexts.emailInvalid);
  }

  return errors;
};

export default validate;
