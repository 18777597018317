/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';
import ButtonLink from '../../react-web-ui/components/ButtonLink';

import useWorkingHours from '../../react-web-ui/hooks/useWorkingHours';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import RestaurantImages from '../../react-web-ui/components/RestaurantImages';
import Button from '../../react-web-ui/components/Button';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RequestService from '../../networking/RequestService';
import RestaurantInfo from '../../react-web-ui/components/RestaurantInfo';
import InfoItem from '../../react-web-ui/components/RestaurantInfo/InfoItem';
import RestaurantTitleWrapper from '../../components/RestaurantProfileComponents/RestaurantTitleWrapper';
import LocationInfoItem from '../../components/RestaurantProfileComponents/LocationInfoItem';
import PriceRangeInfoItem from '../../components/RestaurantProfileComponents/PriceRangeInfoItem';
import ChefNameInfoItem from '../../components/RestaurantProfileComponents/ChefNameInfoItem';
import CuisinesInfoItem from '../../components/RestaurantProfileComponents/CuisinesInfoItem';
import ImagesCarouselWrapper from '../../components/RestaurantProfileComponents/ImagesCarouselWrapper';
import RestaurantMenuPlaceholder from '../../components/RestaurantProfileComponents/RestaurantMenuPlaceholder';
import EditableText from '../../components/EditableText';
import AwardsBlock from '../../components/RestaurantProfileComponents/AwardsBlock';
import Spinner from '../../react-web-ui/components/Spinner';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import Tooltip from '../../react-web-ui/components/Tooltip';
import { ReactComponent as Clock } from '../../react-web-ui/assets/images/icons/Clock.svg';
import { ReactComponent as DineoutClub } from '../../react-web-ui/assets/images/icons/DineoutClub.svg';
import { ReactComponent as External } from '../../assets/images/icons/External.svg';
import { ReactComponent as CardPay } from '../../assets/images/icons/CardPay.svg';
import { ReactComponent as CardPayActive } from '../../assets/images/icons/CardPayActive.svg';
import { ReactComponent as Parking } from '../../assets/images/icons/Parking.svg';
import { ReactComponent as ParkingActive } from '../../assets/images/icons/ParkingActive.svg';
import { ReactComponent as PetFriendly } from '../../assets/images/icons/PetFriendly.svg';
import { ReactComponent as PetFriendlyActive } from '../../assets/images/icons/PetFriendlyActive.svg';
import { ReactComponent as Wifi } from '../../assets/images/icons/Wifi.svg';
import { ReactComponent as WifiActive } from '../../assets/images/icons/WifiActive.svg';
import { ReactComponent as KidsPlace } from '../../assets/images/icons/KidsPlace.svg';
import { ReactComponent as KidsPlaceActive } from '../../assets/images/icons/KidsPlaceActive.svg';
import useExitPrompt from '../../hooks/useExitPrompt';

export default function RestaurantProfile() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const countryContext = useContext(CountryContext);
  const locale = countryContext.country.code.toLowerCase();
  const [restaurantInfoInitial, setRestaurantInfoInitial] = useState(null);
  const [changed, setChanged] = useExitPrompt(false);
  const [restaurantInfo, setRestaurantInfo] = useState({});
  const [workingHours] = useWorkingHours(restaurantContext.restaurant || '');
  const [loading, setLoading] = useState(false);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'Your restaurant’s profile is the place to attract new customers. Keep it up to date and appealing in order to reach your goals.',
      disableBeacon: true,
      placement: 'center',
    },
    {
      target: '.tour-title',
      content: 'Hover over the different sections of the profile and click on the pencil that appears in order to edit them. Once ready - click on the save link. Make sure to write each element in both languages.',
    },
    {
      target: '.tour-address',
      content: 'An easy way to find the coordinates is to click on your restaurant in Google maps and see the link that appears in your browser.',
    },
    {
      target: '.tour-cuisines',
      content: 'You can select multiple cuisines but we suggest no more than 3.',
    },
    {
      target: '.tour-services',
      content: 'Toggle the services you offer on.',
    },
    {
      target: '.tour-gallery',
      content: 'Add new photos by clicking on the + button. We suggest you use clear images of your interior, then add some examples of your food. Upload images in landscape format. You can rearrange them, or delete individual photos. The first photo of the gallery will be your restaurant\'s profile picture.',
    },
    {
      target: '.tour-description',
      content: 'We suggest adding 3-5 paragraphs about the concept of the restaurant, the cuisine you offer and who’s your regular customer. Translate your description in both languages.',
    },
    {
      target: '.tour-rewards',
      content: 'Rewards: Add the most recent rewards or nominations your restaurant has. Make sure to use the precise title given by the rewards’ organisers.',
    },
  ];

  useEffect(() => {
    if (!restaurantContext.restaurantId || !userContext.user) return;
    (new RequestService('manager/places/show'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        const responseData = { ...response.data };
        if (!responseData._translations[locale]) {
          responseData._translations = {
            [locale]: {
              address: responseData._address,
              awards_list: responseData._awardsList,
              chef_name: responseData._chefName,
              description: responseData._description,
              name: responseData._name,
              subtitle: responseData._subtitle,
            },
          };
        }
        setRestaurantInfo(responseData);
        setRestaurantInfoInitial(JSON.stringify(responseData));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_place_details'));
      });
  }, [t, restaurantContext, userContext]);

  const handleCancel = () => {
    history.push('/restaurants-listing');
  };

  const handleSubmit = () => {
    const cuisines = restaurantInfo._cuisines.map((cuisine) => cuisine._id);
    let awards = [];
    setLoading(true);
    startListening();

    if (typeof restaurantInfo._awardsList === 'string' || restaurantInfo._awardsList instanceof String) {
      awards = restaurantInfo._awardsList.split(';');
    } else {
      awards = restaurantInfo._awardsList;
    }

    const updateParams = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      default_name: restaurantInfo._name,
      default_subtitle: restaurantInfo._subtitle,
      lat: parseFloat(restaurantInfo._lat),
      lng: parseFloat(restaurantInfo._lng),
      postal_code: restaurantInfo._postalCode,
      default_address: restaurantInfo._address,
      price_range_min: parseInt(restaurantInfo._priceRange.min, 10),
      price_range_max: parseInt(restaurantInfo._priceRange.max, 10),
      cuisines,
      default_chef_name: restaurantInfo._chefName,
      default_description: restaurantInfo._description,
      default_awards_list: awards || [],
      parking: restaurantInfo._parking,
      card_pay: restaurantInfo._cardPay,
      wifi: restaurantInfo._wifi,
      kids_place: restaurantInfo._kidsPlace,
      pet_friendly: restaurantInfo._petFriendly,
      translations: [
        {
          locale,
          address: restaurantInfo._translations[locale].address,
          name: restaurantInfo._translations[locale].name,
          subtitle: restaurantInfo._translations[locale].subtitle,
          description: restaurantInfo._translations[locale].description,
          awards_list: restaurantInfo._translations[locale].awards_list || [],
          chef_name: restaurantInfo._translations[locale].chef_name || [],
        },
      ],
    };

    (new RequestService('manager/places/change'))
      .setParams(updateParams)
      .send()
      .then(() => {
        toast.success(t('success:restaurant_profile_success'));
        setLoading(false);
        endListening();
        setChanged(false);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_update_restaurant_profile'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('profile-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('profile-tour-status', 1);
    }
  };

  const translateText = () => {
    const copyRestaurantInfo = { ...restaurantInfo };
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [restaurantInfo._description];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [restaurantInfo._translations[locale]?.description];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          copyRestaurantInfo._translations[locale].description = translations[0].text;
        } else {
          copyRestaurantInfo._description = translations[0].text;
        }
        setRestaurantInfo(copyRestaurantInfo);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  useEffect(() => {
    if (!restaurantInfo._id || !restaurantInfoInitial) return;
    if (Object.entries(restaurantInfo).toString()
      !== Object.entries(JSON.parse(restaurantInfoInitial)).toString()) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [restaurantInfo, restaurantInfoInitial]);

  useEffect(() => () => {
    setChanged(false);
  }, []);

  if (!restaurantInfo._id) return null;
  return (
    <div className="inner-wrapper" data-test-id={process.env.REACT_APP_API_URL === 'http://localhost/v2/' ? restaurantInfo._id : ''}>
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      {!restaurantInfo.isOwnershipVerified && (
        <div className="sticky-buttons">
          <ButtonLink
            text={t('publish')}
            classes="btn-discount"
            href="/verify-ownership"
          />
        </div>
      )}
      <h2 className="section-title underlined">
        <strong>
          {t('pageRestaurantProfile:edit_profile')}
        </strong>
      </h2>
      <header className="clear-both restaurant-header section grid">
        <div className="grid-col-4">
          <RestaurantInfo
            title={(
              <div className="tour-title">
                <RestaurantTitleWrapper
                  locale={locale}
                  currentRestaurantInfo={restaurantInfo}
                  setCurrentRestaurantInfo={setRestaurantInfo}
                  disabled={loading}
                />
              </div>
            )}
            location={(
              <LocationInfoItem
                locale={locale}
                currentRestaurantInfo={restaurantInfo}
                setCurrentRestaurantInfo={setRestaurantInfo}
                disabled={loading}
              />
            )}
            priceRange={(
              <PriceRangeInfoItem
                currentRestaurantInfo={restaurantInfo}
                setCurrentRestaurantInfo={setRestaurantInfo}
                disabled={loading}
                setChanged={setChanged}
              />
            )}
            cuisines={(
              <CuisinesInfoItem
                currentRestaurantInfo={restaurantInfo}
                setCurrentRestaurantInfo={setRestaurantInfo}
                disabled={loading}
              />
            )}
            chefName={(
              <ChefNameInfoItem
                locale={locale}
                currentRestaurantInfo={restaurantInfo}
                setCurrentRestaurantInfo={setRestaurantInfo}
                disabled={loading}
              />
            )}
            workingHours={(
              <InfoItem
                icon={<Clock className="icon" />}
                title={t('pageRestaurantProfile:accepts_bookings')}
                text={(
                  <>
                    {workingHours.map((hours) => (
                      <span key={hours} className="working-hours">{hours}</span>
                    ))}
                    <Link to="/manage/shifts" className="external-link text-accent">
                      <External className="icon" />
                      {t('pageRestaurantProfile:edit_shifts')}
                    </Link>
                  </>
                )}
              />
            )}
            dineoutClub={(
              <InfoItem
                icon={<DineoutClub className="icon" />}
                title={t('pageRestaurantProfile:part_of_dineout')}
                text={(
                  <>
                    {t('pageRestaurantProfile:part_of_dineout_get')}
                    <span className="text-accent">
                      {` ${t('pageRestaurantProfile:part_of_dineout_upto')} `}
                    </span>
                    {t('pageRestaurantProfile:part_of_dineout_desc')}
                  </>
                )}
              />
            )}
            button={(
              <div className="tour-services properties">
                <Tooltip
                  title={t(`pageRestaurantProfile:tooltip_parking${restaurantInfo._parking ? '' : '_unavailable'}`)}
                  position="right"
                >
                  <button
                    type="button"
                    onClick={() => {
                      const copyRestaurantInfo = { ...restaurantInfo };
                      copyRestaurantInfo._parking = !copyRestaurantInfo._parking;
                      setRestaurantInfo(copyRestaurantInfo);
                    }}
                  >
                    {restaurantInfo._parking ? (
                      <ParkingActive className="icon" />
                    ) : (
                      <Parking className="icon" />
                    )}
                  </button>
                </Tooltip>
                <Tooltip
                  title={t(`pageRestaurantProfile:tooltip_wifi${restaurantInfo._wifi ? '' : '_unavailable'}`)}
                  position="right"
                >
                  <button
                    type="button"
                    onClick={() => {
                      const copyRestaurantInfo = { ...restaurantInfo };
                      copyRestaurantInfo._wifi = !copyRestaurantInfo._wifi;
                      setRestaurantInfo(copyRestaurantInfo);
                    }}
                  >
                    {restaurantInfo._wifi ? (
                      <WifiActive className="icon" />
                    ) : (
                      <Wifi className="icon" />
                    )}
                  </button>
                </Tooltip>
                <Tooltip
                  title={t(`pageRestaurantProfile:tooltip_card_pay${restaurantInfo._cardPay ? '' : '_unavailable'}`)}
                  position="right"
                >
                  <button
                    type="button"
                    onClick={() => {
                      const copyRestaurantInfo = { ...restaurantInfo };
                      copyRestaurantInfo._cardPay = !copyRestaurantInfo._cardPay;
                      setRestaurantInfo(copyRestaurantInfo);
                    }}
                  >
                    {restaurantInfo._cardPay ? (
                      <CardPayActive className="icon" />
                    ) : (
                      <CardPay className="icon" />
                    )}
                  </button>
                </Tooltip>
                <Tooltip
                  title={t(`pageRestaurantProfile:tooltip_kids_place${restaurantInfo._kidsPlace ? '' : '_unavailable'}`)}
                  position="right"
                >
                  <button
                    type="button"
                    onClick={() => {
                      const copyRestaurantInfo = { ...restaurantInfo };
                      copyRestaurantInfo._kidsPlace = !copyRestaurantInfo._kidsPlace;
                      setRestaurantInfo(copyRestaurantInfo);
                    }}
                  >
                    {restaurantInfo._kidsPlace ? (
                      <KidsPlaceActive className="icon" />
                    ) : (
                      <KidsPlace className="icon" />
                    )}
                  </button>
                </Tooltip>
                <Tooltip
                  title={t(`pageRestaurantProfile:tooltip_pet_friendly${restaurantInfo._petFriendly ? '' : '_unavailable'}`)}
                  position="right"
                >
                  <button
                    type="button"
                    onClick={() => {
                      const copyRestaurantInfo = { ...restaurantInfo };
                      copyRestaurantInfo._petFriendly = !copyRestaurantInfo._petFriendly;
                      setRestaurantInfo(copyRestaurantInfo);
                    }}
                  >
                    {restaurantInfo._petFriendly ? (
                      <PetFriendlyActive className="icon" />
                    ) : (
                      <PetFriendly className="icon" />
                    )}
                  </button>
                </Tooltip>
              </div>
            )}
          />

        </div>
        <div className="tour-gallery grid-col-6">
          <RestaurantImages
            images={(
              <ImagesCarouselWrapper
                currentRestaurantInfo={restaurantInfo}
              />
            )}
          />

        </div>
      </header>
      <section className="section">
        <h2 className="section-title">
          <strong>
            {
              (i18n.language === 'bg' || i18n.language === 'pl')
                ? restaurantInfo._translations[locale]
                  ? restaurantInfo._translations[locale].name
                  : restaurantInfo._name
                : restaurantInfo._name
            }
          </strong>
          {` ${t('pageRestaurantProfile:section_menu_title')} `}
        </h2>
        <RestaurantMenuPlaceholder />
      </section>
      <section className="section">
        <h2 className="section-title">
          {`${t('pageRestaurantProfile:section_description_title')} `}
          <strong>
            {
              (i18n.language === 'bg' || i18n.language === 'pl')
                ? restaurantInfo._translations[locale]
                  ? restaurantInfo._translations[locale].name
                  : restaurantInfo._name
                : restaurantInfo._name
            }
          </strong>
        </h2>
        <div className="tour-description text-content restaurant-description">
          <EditableText
            name="restaurant_description"
            placeholder={t('pageRestaurantProfile:add_restaurant_description')}
            valueEn={restaurantInfo._description}
            valueTranslation={restaurantInfo._translations[locale] ? restaurantInfo._translations[locale].description : 'Add'}
            textarea
            disabled={loading}
            onChangeEn={(e) => {
              const copyRestaurantInfo = { ...restaurantInfo };
              copyRestaurantInfo._description = e.target.value;
              setRestaurantInfo(copyRestaurantInfo);
            }}
            onChangeTranslation={(e) => {
              const copyRestaurantInfo = { ...restaurantInfo };
              copyRestaurantInfo._translations[locale].description = e.target.value;
              setRestaurantInfo(copyRestaurantInfo);
            }}
            translatable
            translateText={() => translateText()}
          />
        </div>
      </section>
      <section className="tour-rewards section">
        <h2 className="section-title">
          {`${t('pageRestaurantProfile:section_awards_title')} `}
          <strong>
            {
              (i18n.language === 'bg' || i18n.language === 'pl')
                ? restaurantInfo._translations[locale]
                  ? restaurantInfo._translations[locale].name
                  : restaurantInfo._name
                : restaurantInfo._name
            }
          </strong>
        </h2>
        <AwardsBlock
          locale={locale}
          currentRestaurantInfo={restaurantInfo}
          setCurrentRestaurantInfo={setRestaurantInfo}
          disabled={loading}
          setChanged={setChanged}
        />
      </section>
      <div className="center-buttons">
        <Button
          text={t('cancel')}
          classes="btn-accent btn-outline"
          onClick={handleCancel}
        />
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('save')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
