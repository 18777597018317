import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    nameEmpty: i18n.t('forms:error_empty'),
  };

  // Name
  errors.salon_name = [];
  if (!values.salon_name || values.salon_name.trim() === '') {
    errors.salon_name.push(errorTexts.nameEmpty);
  }

  return errors;
};

export default validate;
