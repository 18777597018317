import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Review from '../../../networking/resources/Review';
import ReviewItem from '../../../react-web-ui/components/ReviewItem';

/**
 * Reviews block
 * @param restaurantReviews
 * @returns {*}
 * @constructor
 */
export default function Reviews({ restaurantReviews, slug }) {
  const { t } = useTranslation();

  return (
    <div className="reviews-wrapper">
      {restaurantReviews && (
        <>
          {restaurantReviews.slice(0, 3).map((review) => (
            <ReviewItem
              key={review.content}
              avatar={review.authorThumb}
              username={review.authorName}
              reviewNumber={review.authorReviews}
              date={review.submittedOn.toLocaleDateString('bg', {
                year: 'numeric', month: 'numeric', day: 'numeric',
              })}
              reviewText={review.content}
              reviewTip={review.tip}
            />
          ))}
          <a
            href={`https://${process.env.REACT_APP_DOMAIN}/restaurants/${slug}`}
            className="btn btn-accent btn-outline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('pageWidget:read_more_reviews')}
          </a>
        </>
      )}
    </div>
  );
}

Reviews.propTypes = {
  /** Restaurant reviews */
  restaurantReviews: PropTypes.oneOfType([
    PropTypes.instanceOf(Review),
    PropTypes.array,
  ]).isRequired,
  slug: PropTypes.string.isRequired,
};
