import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';

import MenuItem from '../../react-web-ui/components/RestaurantMenu/MenuItem';

function ChosenSet({ featureSets, chosenSet, currency }) {
  const [show, setShow] = useState(false);

  return (
    <div className={`${featureSets ? 'tasting-page' : 'event-page'} chosen-set`}>
      <button
        type="button"
        onClick={() => setShow(!show)}
      >
        <span>
          <span className={`text-accent arrow ${show ? 'rotate' : ''}`}>
            &raquo;
          </span>
          <strong className="quantity">{`${chosenSet.quantity}x`}</strong>
          {`${chosenSet.name}`}
        </span>
        <strong className="text-accent">
          {`${chosenSet.price}${currency}`}
        </strong>
      </button>
      {show && chosenSet.items.map((item) => (
        <MenuItem
          key={item.id}
          name={item.name}
          description={item.description}
        />
      ))}
    </div>
  );
}

ChosenSet.propTypes = {
  featureSets: PropTypes.bool.isRequired,
  chosenSet: PropTypes.oneOfType([PropTypes.object]).isRequired,
  currency: PropTypes.string.isRequired,
};

export default ChosenSet;
