import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/pl';
import { DateRangePicker } from 'react-dates';
import { toast } from 'react-toastify';
import MultiSelect from 'react-multi-select-component';
import { Link } from 'react-router-dom';

import FormElement from '../../react-web-ui/components/FormElement';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RequestService from '../../networking/RequestService';
import WindowContext from '../../react-web-ui/contexts/WindowContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import Spinner from '../../react-web-ui/components/Spinner';
import PageHeading from '../../react-web-ui/components/PageHeading';
import Button from '../../react-web-ui/components/Button';
import BookingsTable from '../../components/BookingsTable';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import { ReactComponent as Magnifier } from '../../assets/images/icons/Magnifier.svg';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';

export default function Bookings() {
  const { t, i18n } = useTranslation();
  const DEKSTOP_CONTENT_LOADER = 700;
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);
  const restaurantContext = useContext(RestaurantContext);
  const [focused, setFocused] = useState();
  const [start, setStart] = useState(moment(moment().startOf('week')).add(3, 'days'));
  const [end, setEnd] = useState(moment().endOf('week'));
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [selectedStatuses, setSelectedStatuses] = useState([
    { value: 'active', label: t('pageBookings:active') },
    { value: 'seated', label: t('pageBookings:seated') },
  ]);
  const [selectedTypes, setSelectedTypes] = useState([
    { value: 'app', label: t('pageBookings:app') },
    { value: 'widget', label: t('pageBookings:widget') },
    { value: 'walk_in', label: t('pageBookings:walk_in') },
    { value: 'internal', label: t('pageBookings:internal') },
  ]);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  // Change calendar locale
  moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  }, [i18n.language]);

  const datesChangeHandler = ({ startDate, endDate }) => {
    setStart(startDate);
    setEnd(endDate);
  };

  const focusChangeHandler = (focusedInput) => {
    setFocused(focusedInput);
  };

  const handleShowStats = () => {
    if (start === null || end === null) return;
    setLoading(true);
    startListening();
    const status = [];
    const type = [];

    selectedStatuses.forEach((selected) => {
      status.push(selected.value);
    });

    selectedTypes.forEach((selected) => {
      type.push(selected.value);
    });

    const params = {
      place_id: restaurantContext.restaurantId,
      user_id: userContext.user.id,
      from_dt: start.startOf('day').unix(),
      to_dt: end.endOf('day').unix(),
      sort_field: 'dt',
      sort_order: 'asc',
      type,
      status,
    };

    if (searchQuery !== '') {
      params.query = searchQuery;
    }

    (new RequestService('host/bookings/all'))
      .setParams(params)
      .send()
      .then((response) => {
        setBookings(response.data);
        setSelectedBooking(response.data[0]);
      })
      .catch((err) => {
        if (err.key) toast.error(t(`apiErrors:${err.key}`));
        else toast.error(t('apiErrors:could_not_get_bookings'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  useEffect(() => {
    if (!userContext.user || !restaurantContext.restaurantId) return;
    handleShowStats();
  }, [t, userContext.user, restaurantContext.restaurantId]);

  return (
    <div className="inner-wrapper">
      <PageHeading
        classes="page-heading-bookings"
        title={(
          <>
            {t('pageBookings:title')}
            <Link className="btn-link" to="/manage/booking-notifications">
              {t('pageBookings:notifications')}
            </Link>
          </>
        )}
        content={(
          <form className="bookings-fields">
            <div className="fields-wrapper">
              <FormElement
                icon={<Magnifier className="icon" />}
                id="search_query"
                className="search-query"
                elementType="input"
                placeholder={t('pageBookings:search_query')}
                value={searchQuery || ''}
                changed={(event) => setSearchQuery(event.target.value)}
              />
              <div className="pick-date pick-date-input">
                <strong>{t('pageBookings:period')}</strong>
                <DateRangePicker
                  isOutsideRange={() => false}
                  firstDayOfWeek={1}
                  numberOfMonths={
                    // eslint-disable-next-line
                    windowContext.currentWidth < DEKSTOP_CONTENT_LOADER
                      ? 1
                      : 2
                  }
                  navPrev={(
                    <button className="calendar-prev" type="button">
                      <ArrowDown />
                    </button>
                  )}
                  navNext={(
                    <button className="calendar-next" type="button">
                      <ArrowDown />
                    </button>
                  )}
                  orientation={
                    windowContext.currentWidth >= DEKSTOP_CONTENT_LOADER
                      ? 'horizontal'
                      : 'vertical'
                  }
                  minimumNights={0}
                  noBorder
                  hideKeyboardShortcutsPanel
                  onDatesChange={datesChangeHandler}
                  onFocusChange={focusChangeHandler}
                  focusedInput={focused}
                  startDate={start}
                  endDate={end}
                  startDateId="your_unique_start_date_id"
                  endDateId="your_unique_end_date_id"
                  startDatePlaceholderText={t('start_date')}
                  endDatePlaceholderText={t('end_date')}
                />
              </div>
              <div>
                <strong>{t('pageBookings:status')}</strong>
                <MultiSelect
                  id="status-select"
                  options={[
                    { value: 'active', label: t('pageBookings:active') },
                    { value: 'soft_cancelled', label: t('pageBookings:soft_cancelled') },
                    { value: 'hard_cancelled', label: t('pageBookings:hard_cancelled') },
                    { value: 'seated', label: t('pageBookings:seated') },
                    { value: 'completed', label: t('pageBookings:completed') },
                    { value: 'no_show', label: t('pageBookings:no_show') },
                    { value: 'cancelled', label: t('pageBookings:cancelled') },
                  ]}
                  value={selectedStatuses}
                  onChange={setSelectedStatuses}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: t('forms:select'),
                    allItemsAreSelected: t('pageBookings:all'),
                    selectAll: t('forms:select_all'),
                    search: t('forms:searh'),
                  }}
                />
              </div>
              <div>
                <strong>{t('pageBookings:type')}</strong>
                <MultiSelect
                  id="type-select"
                  options={[
                    { value: 'app', label: t('pageBookings:app') },
                    { value: 'widget', label: t('pageBookings:widget') },
                    { value: 'walk_in', label: t('pageBookings:walk_in') },
                    { value: 'internal', label: t('pageBookings:internal') },
                  ]}
                  value={selectedTypes}
                  onChange={setSelectedTypes}
                  labelledBy="Select"
                  overrideStrings={{
                    selectSomeItems: t('forms:select'),
                    allItemsAreSelected: t('pageBookings:all'),
                    selectAll: t('forms:select_all'),
                    search: t('forms:searh'),
                  }}
                />
              </div>
            </div>
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('show')}
              classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
              onClick={handleShowStats}
              disabled={loading}
            />
          </form>
        )}
      />
      <BookingsTable
        bookings={bookings}
        selectedBooking={selectedBooking}
        setSelectedBooking={setSelectedBooking}
      />
      <Button
        text={t('pageBookings:print')}
        classes="btn-accent btn-print"
        onClick={() => window.print()}
      />
    </div>
  );
}
