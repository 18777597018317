import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as MapIcon } from '../../assets/images/icons/Map.svg';
import { ReactComponent as Cuisine } from '../../assets/images/icons/Cuisine.svg';

/**
* Restaurant Block
* @returns {*}
* @constructor
*/
export default function RestaurantBlock({
  className,
  imageBlock,
  title,
  address,
  cuisines,
  rating,
  description,
  button,
  onClick,
}) {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
      className={`restaurant-block ${className}`}
    >
      {imageBlock}
      <div className="text-wrapper">
        <h2>
          {title}
        </h2>
        {description}
        {address && (
          <p>
            <MapIcon className="icon" />
            {address}
          </p>
        )}
        {cuisines !== '' && (
          <p>
            <Cuisine className="icon" />
            {cuisines}
          </p>
        )}
        {rating}
        {button}
      </div>
    </div>
  );
}

RestaurantBlock.propTypes = {
  className: PropTypes.string,
  imageBlock: PropTypes.element.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  address: PropTypes.string,
  cuisines: PropTypes.string,
  rating: PropTypes.element,
  button: PropTypes.element,
  description: PropTypes.element,
  onClick: PropTypes.func,
};

RestaurantBlock.defaultProps = {
  className: '',
  title: '',
  address: '',
  cuisines: '',
  rating: null,
  button: null,
  description: null,
  onClick: null,
};
