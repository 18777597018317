import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    conditionsEmpty: i18n.t('forms:conditions_error_empty'),
    empty: i18n.t('forms:error_empty'),
  };

  // Name EN
  errors.name_en = [];

  if (document.getElementsByName('name_en').length > 0
    && (!values.name_en || values.name_en.trim() === '')) {
    errors.name_en.push(errorTexts.empty);
  }

  // Name BG or PL
  errors.name_translation = [];
  if (document.getElementsByName('name_translation').length > 0
    && (!values.name_translation || values.name_translation.trim() === '')) {
    errors.name_translation.push(errorTexts.empty);
  }

  // Conditions EN
  errors.conditions_en = [];
  if (document.getElementsByName('conditions_en').length > 0
    && (!values.conditions_en || values.conditions_en.trim() === '')) {
    errors.conditions_en.push(errorTexts.conditionsEmpty);
  }

  // Conditions BG or PL
  errors.conditions_translation = [];
  if (document.getElementsByName('conditions_translation').length > 0
    && (!values.conditions_translation || values.conditions_translation.trim() === '')) {
    errors.conditions_translation.push(errorTexts.conditionsEmpty);
  }

  // Item EN
  errors.item_en = [];
  if (document.getElementsByName('item_en').length > 0
    && (!values.item_en || values.item_en.trim() === '')) {
    errors.item_en.push(errorTexts.empty);
  }

  // Item BG or PL
  errors.item_translation = [];
  if (document.getElementsByName('item_translation').length > 0
    && (!values.item_translation || values.item_translation.trim() === '')) {
    errors.item_translation.push(errorTexts.empty);
  }

  // Percent
  errors.percent = [];

  if (document.getElementsByName('percent').length > 0
    && (!values.percent || parseFloat(values.percent) <= 0)) {
    errors.percent.push(errorTexts.empty);
  }

  // Discounted price
  errors.discount_price = [];
  if (document.getElementsByName('discount_price').length > 0
    && (!values.discount_price || parseFloat(values.discount_price) <= 0)) {
    errors.discount_price.push(errorTexts.empty);
  }

  return errors;
};

export default validate;
