import Provider from './Provider';

export default class LocalProvider extends Provider {
  constructor() {
    super();
    this.url = process.env.REACT_APP_IMAGE_STORAGE_URL || 'http://localhost/storage/';
  }

  /**
   * Return url of the image requested for a restaurant in specified size.
   * @param placeId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  restaurant = (placeId, image, width, height) => `${this.url}/places/${placeId}/${image}`;

  /**
   * Return HTTPS url of the image requested for a chef in specified size.
   * @param placeId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  chef = (placeId, image, width, height) => `${this.url}/chefs/${placeId}/${image}`;

  /**
   * Return HTTPS url of the image requested for a city in specified size.
   * @param cityId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  city = (cityId, image, width, height) => `${this.url}/cities/${cityId}/${image}`;

  /**
   * Return HTTPS url of the image requested for an event.
   * @param eventId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  specialEvent = (eventId, image, width, height) => `${this.url}/special-events/${eventId}/${image}`;

  /**
   * Return HTTPS url of the image requested for a special offer.
   * @param offerId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  specialOffer = (offerId, image, width, height) => `${this.url}/special-offers/${offerId}/${image}`;
}
