/**
 * Constants used with localStorage.get()/localStorage.set()
 */
export const USER_ID_KEY = 'userId';
export const RESTAURANT_ID_KEY = 'restaurantId';
export const RESTAURANT_PLAN_KEY = 'restaurantPlan';

/**
 * Date & Time
 */
export const dateFormat = 'DD-MM-YYYY';
export const timeFormat = 'HH:mm';
export const dateTimeFormat = 'DD-MM-YYYY HH:mm';

/**
 * Random IDs used for creating tables on the floorplan.
 */
export const randomId = () => `_${Math.random().toString(36).substr(2, 9)}`;

export const initialTextOptions = {
  type: 'text',
  x: 10,
  y: 150,
  width: 250,
  height: 70,
  fontSize: 48,
  lineHeight: 48,
  color: '#000000',
  text: 'Type text',
  align: 'left',
  fontWeight: 'bold',
  fontStyle: 'normal',
  textDecoration: 'none',
  fontFamily: 'Montserrat',
};

export const initialIconTextOptions = {
  type: 'icon-with-text',
  x: 10,
  y: 250,
  width: 300,
  height: 80,
  fontSize: 48,
  color: '#000000',
  iconColor: '#000000',
  icon: 'clock',
  text: 'Type text',
  fontWeight: 'bold',
};

export const initialShapeOptions = {
  type: 'shape',
  x: 10,
  y: 10,
  width: 100,
  height: 100,
  backgroundColor: '#000000',
  blur: false,
};

export const initialImageOptions = {
  x: 10,
  y: 10,
  width: 0,
  height: 0,
};

export const postSizes = {
  instagramPost: {
    width: 1080,
    height: 1080,
  },
  instagramStory: {
    width: 1080,
    height: 1920,
  },
  facebookPost: {
    width: 1200,
    height: 630,
  },
};
