import { useState, useContext } from 'react';

import { checkBounds, checkCloseEnough } from '../components/PostCreatorComponents/helper';
import ElementsContext from '../contexts/ElementsContext';

const useDragResizing = () => {
  const elementsContext = useContext(ElementsContext);
  const [elementDragging, setElementDragging] = useState(null);
  const [dragTopRight, setDragTopRight] = useState({
    drag: false,
    index: null,
  });
  const [dragBottomRight, setDragBottomRight] = useState({
    drag: false,
    index: null,
  });
  const [dragTopLeft, setDragTopLeft] = useState({
    drag: false,
    index: null,
  });
  const [dragBottomLeft, setDragBottomLeft] = useState({
    drag: false,
    index: null,
  });
  const [startingElement, setStartingElement] = useState({
    width: 0,
    height: 0.0,
  });
  const [cursorPositionElement, setCursorPositionElement] = useState({
    x: 0,
    y: 0,
  });

  const resetShapeResizing = () => {
    setDragTopRight({
      drag: false,
      index: null,
    });
    setDragBottomRight({
      drag: false,
      index: null,
    });
    setDragTopLeft({
      drag: false,
      index: null,
    });
    setDragBottomLeft({
      drag: false,
      index: null,
    });
  };

  const checkElementBounds = (
    canvasPositionX,
    canvasPositionY,
    element,
    elementX,
    elementY,
    elementWidth,
    elementHeight,
  ) => {
    if (
      (checkCloseEnough(canvasPositionX, elementX)
        && checkCloseEnough(canvasPositionY, elementY))
      || (checkCloseEnough(canvasPositionX, elementWidth)
        && checkCloseEnough(canvasPositionY, elementY))
      || (checkCloseEnough(canvasPositionX, elementX)
        && checkCloseEnough(canvasPositionY, elementHeight))
      || (checkCloseEnough(canvasPositionX, elementWidth)
        && checkCloseEnough(canvasPositionY, elementHeight))
      || checkBounds(
        canvasPositionX,
        canvasPositionY,
        elementX,
        elementWidth,
        elementY,
        elementHeight,
      )
    ) {
      elementsContext.setSelectedElement(element);
      return element;
    }
    return null;
  };

  const checkResizingOrDragging = (
    canvasPositionX,
    canvasPositionY,
    element,
    elementX,
    elementY,
    elementWidth,
    elementHeight,
  ) => {
    if (
      checkCloseEnough(canvasPositionX, elementX)
      && checkCloseEnough(canvasPositionY, elementY)
    ) {
      setDragTopLeft({
        drag: true,
        index: element.id,
      });
      setStartingElement({ ...element });
    } else if (
      checkCloseEnough(canvasPositionX, elementWidth)
      && checkCloseEnough(canvasPositionY, elementY)
    ) {
      setDragTopRight({
        drag: true,
        index: element.id,
      });
      setStartingElement({ ...element });
    } else if (
      checkCloseEnough(canvasPositionX, elementX)
      && checkCloseEnough(canvasPositionY, elementHeight)
    ) {
      setDragBottomLeft({
        drag: true,
        index: element.id,
      });
      setStartingElement({ ...element });
    } else if (
      checkCloseEnough(canvasPositionX, elementWidth)
      && checkCloseEnough(canvasPositionY, elementHeight)
    ) {
      setDragBottomRight({
        drag: true,
        index: element.id,
      });
      setStartingElement({ ...element });
    } else if (
      checkBounds(
        canvasPositionX,
        canvasPositionY,
        elementX,
        elementWidth,
        elementY,
        elementHeight,
      )
    ) {
      if (elementsContext.selectedElement && elementsContext.selectedElement.id === element.id) {
        resetShapeResizing();
        setElementDragging(element.id);
        setCursorPositionElement({
          x: Math.ceil(canvasPositionX - elementX),
          y: Math.ceil(canvasPositionY - elementY),
        });
      }
    }
  };

  return {
    dragTopRight,
    dragBottomRight,
    dragTopLeft,
    dragBottomLeft,
    startingElement,
    elementDragging,
    setElementDragging,
    cursorPositionElement,
    setCursorPositionElement,
    resetShapeResizing,
    checkElementBounds,
    checkResizingOrDragging,
  };
};

export default useDragResizing;
