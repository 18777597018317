import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    emailEmpty: i18n.t('forms:email_error_empty'),
    emailInvalid: i18n.t('forms:email_error_invalid'),
  };

  // Email
  errors.reset_email = [];
  if (!values.reset_email || values.reset_email.trim() === '') {
    errors.reset_email.push(errorTexts.emailEmpty);
  }

  if (!/\S+@\S+\.\S+/.test(values.reset_email)) {
    errors.reset_email.push(errorTexts.emailInvalid);
  }

  return errors;
};

export default validate;
