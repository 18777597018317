import React, { useState, useContext, useEffect } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import { initialInputList } from '../Events/constants';
import enumerateDaysBetweenDates from '../../../utilities/enumerateDaysBetweenDates';
import useSpecialEvents from '../../../hooks/useSpecialEvents';
import convertHours from '../../../utilities/convertHours';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import RequestService from '../../../networking/RequestService';
import Button from '../../../react-web-ui/components/Button';
import PickHourBlock from '../../../components/PickHourBlock';
import EventMenuSection from '../../../components/EventMenuSection';
import DateDayPicker from '../../../components/DateDayPicker';
import Spinner from '../../../react-web-ui/components/Spinner';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import Tooltip from '../../../react-web-ui/components/Tooltip';
import useExitPrompt from '../../../hooks/useExitPrompt';

export default function AddTastingMenu({ editing }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const countryContext = useContext(CountryContext);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [hoursError, setHoursError] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [disableDays, setDisableDays] = useState([]);
  const [lunchHours, setLunchHours] = useState([]);
  const [dinnerHours, setDinnerHours] = useState([]);
  const [tastingMenu, setTastingMenu] = useState({});
  const [errors, setErrors] = useState([]);
  const [prebookTime, setPrebookTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useExitPrompt(false);
  const [inputList, setInputList] = useState(
    [JSON.parse(JSON.stringify(initialInputList))],
  );

  const {
    createSets,
    checkFields,
    createSetsInputs,
  } = useSpecialEvents();
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: '.tour-menu-name',
      content: 'You can now add a name for the whole menu',
      disableBeacon: true,
    },
    {
      target: '.tour-menu-fields',
      content: 'Add a course and an optional description in both languages. You can add as many courses as you need.',
    },
    {
      target: '.tour-add-course',
      content: 'Now add a price for the whole menu. Optionally you can add a price for the tаsting menu and wine pairing offer.',
    },
    {
      target: '.tour-add-menu',
      content: 'You can add more whole set menu options. It may be a good idea to add a vegetarian tasting menu for example.',
    },
    {
      target: '.tour-date',
      content: 'Now set the period you\'ll offer the tasting menu(s) and exclude any day of the week you\'d prefer not to offer it.',
    },
    {
      target: '.tour-hour',
      content: 'You can select individual hours to offer your tasting menu. Use the select all link to offer it during a whole shift. Grey boxes represent hours when it won\'t be available.',
    },
    {
      target: '.tour-prebook',
      content: 'Prebook time is the time you require before a guest can come and order your tasting menu. For example if you set one hour here a guest will need to book at least an hour in advance.',
    },
  ];

  const handleCancel = () => {
    history.push('/monetize/tasting-menus');
  };

  const handleSubmit = () => {
    const locale = countryContext.country.code.toLowerCase();
    const dates = enumerateDaysBetweenDates(start, end, disableDays);
    const hours = [...lunchHours, ...dinnerHours];
    const selectedHours = hours.filter((hour) => hour.selected);
    const intervals = [];
    const {
      currentErrors,
      submitCheck,
    } = checkFields(inputList);
    setChanged(false);

    setHoursError(selectedHours.length === 0);
    setDateError(dates.length === 0);
    setErrors(currentErrors);

    if (!submitCheck) return;
    if (selectedHours.length === 0 || dates.length === 0) return;
    setLoading(true);
    startListening();

    const times = convertHours(hours);
    dates.forEach((date) => {
      intervals.push({
        date,
        times,
      });
    });

    const addTastingMenuParams = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      name: 'Tasting menu',
      intervals,
      feature_sets: 1,
      show_in_listings: 0,
      translations: [{
        locale,
        name: 'Дегустационно меню',
      }],
    };

    if (editing) {
      const eventId = location.pathname.split('/').pop();
      addTastingMenuParams.special_event_id = eventId;
    }

    if (prebookTime) {
      addTastingMenuParams.prebook = moment.duration(prebookTime.format('HH:mm')).asSeconds();
    }

    addTastingMenuParams.sets = createSets(inputList, locale, editing);
    const endpoint = editing ? 'edit' : 'add';

    (new RequestService(`manager/special-events/${endpoint}`))
      .setParams(addTastingMenuParams)
      .send()
      .then(() => {
        handleCancel();
        toast.success(t(`success:${endpoint}_tasting_menu_success`));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t(`apiErrors:could_not_${endpoint}_tasting_menu`));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  useEffect(() => {
    if (!editing || !userContext.user) return;
    const eventId = location.pathname.split('/').pop();
    (new RequestService('manager/special-events/single'))
      .setParams({
        special_event_id: eventId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setTastingMenu(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_tasting_menu'));
      });
  }, [editing, userContext.user, location.pathname]);

  useEffect(() => {
    if (!tastingMenu.id) return;
    if (selectedDays.map(({ selected }) => selected).some((e) => !e)) return;
    if (lunchHours.map(({ selected }) => selected).some((e) => e)) return;
    if (dinnerHours.map(({ selected }) => selected).some((e) => e)) return;

    const locale = countryContext.country.code.toLowerCase();

    setInputList(createSetsInputs(tastingMenu, locale));

    const datesAll = tastingMenu.intervals.map(({ date }) => date);
    const dates = [...new Set(datesAll)];
    setStart(moment(dates[0]));
    setEnd(moment(dates[dates.length - 1]));

    const daysAll = dates.map((date) => (
      moment(date).weekday()
    ));
    const days = [...new Set(daysAll)];
    const sortedDays = days.sort();

    const newSelectedDays = [...selectedDays];
    for (let i = 0; i < newSelectedDays.length; i += 1) {
      if (sortedDays.includes(newSelectedDays[i].index)) {
        newSelectedDays[i].selected = true;
      } else {
        newSelectedDays[i].selected = false;
      }
    }
    setSelectedDays(newSelectedDays);

    const startTimesAll = tastingMenu.intervals.map((interval) => interval.start);
    const endTimesAll = tastingMenu.intervals.map((interval) => interval.end);
    const startTimes = [...new Set(startTimesAll)];
    const endTimes = [...new Set(endTimesAll)];
    const times = [];

    for (let i = 0; i < startTimes.length; i += 1) {
      const startTime = moment(startTimes[i], 'HH:mm');
      const endTime = moment(endTimes[i], 'HH:mm');

      while (startTime.isBefore(endTime, 'HH:mm')) {
        times.push(startTime.format('HH:mm'));
        startTime.add(30, 'minutes');
      }
      times.push(endTime.format('HH:mm'));
    }

    const hours = [...lunchHours, ...dinnerHours];

    for (let i = 0; i < hours.length; i += 1) {
      if (times.includes(hours[i].hour)) {
        hours[i].selected = true;
      }
    }

    setLunchHours(hours.slice(0, 14));
    setDinnerHours(hours.slice(14));

    if (tastingMenu.prebook) {
      setPrebookTime(moment().startOf('day').seconds(tastingMenu.prebook));
    }
  }, [
    tastingMenu,
    countryContext.country.code,
    selectedDays,
    lunchHours,
    dinnerHours,
    createSetsInputs,
  ]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('tasting-add-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('tasting-add-tour-status', 1);
    }
  };

  useEffect(() => {
    const inputListChanged = inputList.filter((list) => (
      list.menuNameEn !== ''
      || list.menuNameTranslation !== ''
      || list.menuPriceNoWine !== ''
      || list.menuPriceWithWine !== ''
      || list.menuItems.filter((items) => (
        items.itemDescriptionEn !== ''
        || items.itemDescriptionTranslation !== ''
        || items.itemNameTranslation !== ''
        || items.itemNameEn !== ''
      )).length > 0
    )).length;
    if (
      inputListChanged > 0
      || selectedDays.filter((day) => !day.selected).length > 0
      || lunchHours.filter((hour) => hour.selected).length > 0
      || dinnerHours.filter((hour) => hour.selected).length > 0
      || start
      || end
      || prebookTime
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [selectedDays, lunchHours, dinnerHours, start, end, inputList, prebookTime]);

  useEffect(() => () => {
    setChanged(false);
  }, []);

  return (
    <div className="inner-wrapper tasting-page">
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <h2 className="section-title underlined">
        <strong>
          {editing ? t('pageTastingMenus:editing_tasting_menu') : t('pageTastingMenus:adding_tasting_menu')}
        </strong>
      </h2>
      <EventMenuSection
        inputList={inputList}
        setInputList={setInputList}
        errors={errors}
        setErrors={setErrors}
        sectionTitle={t('pageTastingMenus:your_options')}
      />
      <div className="section">
        <p className="label">
          {t('dates_times_availability')}
          {' '}
          <strong className="text-accent">
            {start ? start.format('DD/MM/YYYY') : t('start_date')}
          </strong>
          {' - '}
          <strong className="text-accent">
            {end ? end.format('DD/MM/YYYY') : t('end_date')}
          </strong>
        </p>
        <div className="grid">
          <div className="tour-date grid-col-5">
            {dateError && (<p className="error">{t('forms:please_choose_dates')}</p>)}
            <DateDayPicker
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              setLunchHours={setLunchHours}
              setDinnerHours={setDinnerHours}
              setDisableDays={setDisableDays}
              disableDays={disableDays}
              setStart={setStart}
              start={start}
              setEnd={setEnd}
              end={end}
            />
          </div>
          <div className="grid-col-5">
            {hoursError && (<p className="error">{t('forms:please_choose_hours')}</p>)}
            <PickHourBlock
              lunchHours={lunchHours}
              setLunchHours={setLunchHours}
              dinnerHours={dinnerHours}
              setDinnerHours={setDinnerHours}
            />
            <div className="tour-prebook form-group">
              <TimePicker
                placeholder={t('prebook_time')}
                showSecond={false}
                minuteStep={15}
                popupClassName="time-picker-custom time-picker-tasting"
                defaultOpenValue={moment().startOf('day')}
                value={prebookTime}
                onChange={(value) => setPrebookTime(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="center-buttons">
        <Button
          text={t('cancel')}
          classes="btn-accent btn-outline"
          onClick={handleCancel}
        />
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('save')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

AddTastingMenu.propTypes = {
  editing: PropTypes.bool,
};

AddTastingMenu.defaultProps = {
  editing: false,
};
