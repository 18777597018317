import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../../../networking/RequestService';
import CountryContext from '../../../../react-web-ui/contexts/CountryContext';
import EditableText from '../../../EditableText';
import { ReactComponent as DineoutClub } from '../../../../react-web-ui/assets/images/icons/DineoutClub.svg';

export default function AwardItem({
  index,
  locale,
  en,
  translation,
  awardsEn,
  awardsTranslation,
  setAwardsEn,
  setAwardsTranslation,
  currentRestaurantInfo,
  setCurrentRestaurantInfo,
  disabled,
  setChanged,
}) {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);
  const [awardEn, setAwardEn] = useState(en);
  const [awardTranslation, setAwardTranslations] = useState(translation);

  useEffect(() => {
    setAwardTranslations(translation);
  }, [translation]);

  const handleDelete = (itemToDelete, ind) => {
    const copyCurrentRestaurantInfo = { ...currentRestaurantInfo };
    const newAwardsEn = [];
    const newAwardsTranslation = [];
    awardsEn.forEach((award, itemIndex) => {
      if (ind !== itemIndex) {
        newAwardsEn.push(award);
        newAwardsTranslation.push(awardsTranslation[itemIndex]);
      }
    });
    copyCurrentRestaurantInfo._awardsList = newAwardsEn;
    copyCurrentRestaurantInfo._translations[locale].awards_list = newAwardsTranslation;
    setAwardsEn(newAwardsEn);
    setAwardsTranslation(newAwardsTranslation);
    setCurrentRestaurantInfo(copyCurrentRestaurantInfo);
  };

  const translateText = () => {
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [awardEn];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [awardTranslation];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          setAwardTranslations(translations[0].text);
        } else {
          setAwardEn(translations[0].text);
        }
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  return (
    <>
      <DineoutClub className="icon" />
      <EditableText
        name={`editAward${index}`}
        deletable
        valueEn={awardEn}
        valueTranslation={awardTranslation}
        onChangeEn={(e) => {
          const copyAwardsEn = [...awardsEn];
          setAwardEn(e.target.value);
          if (copyAwardsEn[index] !== e.target.value) {
            setChanged(true);
          } else {
            setChanged(false);
          }
        }}
        onChangeTranslation={(e) => {
          const copyAwardsTranslation = [...awardsTranslation];
          setAwardTranslations(e.target.value);
          if (copyAwardsTranslation[index] !== e.target.value) {
            setChanged(true);
          } else {
            setChanged(false);
          }
        }}
        onSave={() => {
          const copyAwardsEn = [...awardsEn];
          const copyAwardsTranslation = [...awardsTranslation];
          const copyCurrentRestaurantInfo = { ...currentRestaurantInfo };

          copyAwardsEn[index] = awardEn;
          copyAwardsTranslation[index] = awardTranslation;
          copyCurrentRestaurantInfo._awardsList = copyAwardsEn;
          copyCurrentRestaurantInfo._translations[locale].awards_list = copyAwardsTranslation;
          setAwardsEn(copyAwardsEn);
          setAwardsTranslation(copyAwardsTranslation);
          setCurrentRestaurantInfo(copyCurrentRestaurantInfo);
        }}
        onDelete={() => {
          confirmAlert({
            // eslint-disable-next-line react/prop-types
            customUI: ({ onClose }) => (
              <div className="react-confirm-alert-body ratings-page">
                <h2 className="section-title">{t('pageRestaurantProfile:confirm_award_delete')}</h2>
                <button
                  type="button"
                  onClick={() => {
                    handleDelete(awardEn, index);
                    onClose();
                  }}
                >
                  {t('yes')}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                >
                  {t('no')}
                </button>
              </div>
            ),
          });
        }}
        disabled={disabled}
        translatable
        translateText={() => translateText()}
      />
    </>
  );
}

AwardItem.propTypes = {
  index: PropTypes.number.isRequired,
  locale: PropTypes.string,
  en: PropTypes.string,
  translation: PropTypes.string,
  awardsEn: PropTypes.oneOfType([PropTypes.array]),
  awardsTranslation: PropTypes.oneOfType([PropTypes.array]),
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
  setCurrentRestaurantInfo: PropTypes.func,
  setAwardsEn: PropTypes.func,
  setAwardsTranslation: PropTypes.func,
  setChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

AwardItem.defaultProps = {
  locale: 'en',
  en: '',
  translation: '',
  awardsEn: [],
  awardsTranslation: [],
  currentRestaurantInfo: {},
  setCurrentRestaurantInfo: null,
  setAwardsEn: null,
  setAwardsTranslation: null,
  setChanged: null,
  disabled: false,
};
