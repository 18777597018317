/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { restaurantImage } from '../../../react-web-ui/utilities/images';
import RequestService from '../../../networking/RequestService';
import ImagesCarousel from '../../../react-web-ui/components/RestaurantImages/ImagesCarousel';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import Button from '../../../react-web-ui/components/Button';
import Modal from '../../Modal';

const SortableItem = SortableElement(({
  id, name, value,
}) => (
  <img
    src={restaurantImage(id, value, 188, 106)}
    alt={name}
  />
));

const SortableList = SortableContainer(({ id, name, items }) => (
  <div className="reorder-images">
    {items.map((value, index) => (
      <SortableItem
        key={`item-${value}`}
        index={index}
        value={value}
        id={id}
        name={name}
      />
    ))}
  </div>
));

export default function ImagesCarouselWrapper({
  currentRestaurantInfo,
}) {
  const { t } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false);
  const refSlider = useRef();
  const refThumbnails = useRef();
  let allImages = [];
  const [imagesToReorder, setImagesToReorder] = useState([...currentRestaurantInfo._images]);
  if (currentRestaurantInfo._thumb) {
    allImages = [currentRestaurantInfo._thumb, ...currentRestaurantInfo._images];
  } else if (currentRestaurantInfo._images && currentRestaurantInfo._images.length > 0) {
    allImages = ['no-thumb', ...currentRestaurantInfo._images];
  }
  const settingsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    asNavFor: refThumbnails.current,
  };

  const settingsThumbnails = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: allImages.length >= 4 ? 4 : allImages.length,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    asNavFor: refSlider.current,
    focusOnSelect: true,
    className: 'gallery-thumbs',
  };

  const next = () => {
    refSlider.current.slickNext();
  };

  const previous = () => {
    refSlider.current.slickPrev();
  };

  const handleDelete = (image) => {
    (new RequestService('manager/gallery/remove'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
        image,
      })
      .send()
      .then((response) => {
        restaurantContext.setRestaurant(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_remove_image'));
      });
  };

  const handleMultipleImagesUpload = (e) => {
    if (e.target.files.length) {
      (new RequestService('manager/gallery/upload'))
        .forFileUpload()
        .setParams({
          place_id: restaurantContext.restaurantId,
          user_id: userContext.user.id,
          images: [...e.target.files],
        })
        .send()
        .then((response) => {
          restaurantContext.setRestaurant(response.data);
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:could_not_upload_images'));
        });
    }
  };

  const handleRearange = () => {
    setShowPopup(false);
    (new RequestService('manager/gallery/sort'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
        images: imagesToReorder,
      })
      .send()
      .then((response) => {
        restaurantContext.setRestaurant(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_sort_gallery'));
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImagesToReorder(arrayMove(imagesToReorder, oldIndex, newIndex));
  };

  return (
    <>
      {showPopup && (
        <Modal
          title={t('pageRestaurantProfile:reorder_images')}
          setShowPopup={setShowPopup}
        >
          <SortableList
            helperClass="sortable-helper"
            items={imagesToReorder}
            id={currentRestaurantInfo._id}
            name={currentRestaurantInfo._name}
            axis="xy"
            onSortEnd={onSortEnd}
          />
          <div className="reorder-btns-container">
            <Button
              text={t('cancel')}
              classes="btn-accent btn-outline"
              onClick={() => setShowPopup(false)}
            />
            <Button
              text={t('save')}
              classes="btn-accent"
              onClick={handleRearange}
            />
          </div>
        </Modal>
      )}
      <ImagesCarousel
        slider={allImages.length > 0 ? (
          <Slider ref={refSlider} {...settingsSlider}>
            {allImages.map((image) => (
              <React.Fragment
                key={`${image}-img`}
              >
                {image === 'no-thumb' ? (
                  <div className="slide-item no-img" />
                ) : (
                  <div
                    className="slide-item"
                    style={{
                      backgroundImage: `url("${restaurantImage(currentRestaurantInfo._id, image, 280, 200)}")`,
                      backgroundColor: 'rgb(255, 255, 255)',
                      backgroundSize: 0,
                    }}
                  >
                    <img
                      src={restaurantImage(currentRestaurantInfo._id, image, 768, 462)}
                      alt={currentRestaurantInfo._name}
                    />
                    <Button
                      text={t('pageRestaurantProfile:delete_image')}
                      classes="delete-img btn-accent"
                      onClick={() => {
                        confirmAlert({
                          // eslint-disable-next-line react/prop-types
                          customUI: ({ onClose }) => (
                            <div className="react-confirm-alert-body">
                              <h2 className="section-title">{t('pageRestaurantProfile:delete_image_confirm')}</h2>
                              <button
                                type="button"
                                onClick={() => {
                                  handleDelete(image);
                                  onClose();
                                }}
                              >
                                {t('yes')}
                              </button>
                              <button
                                type="button"
                                onClick={onClose}
                              >
                                {t('no')}
                              </button>
                            </div>
                          ),
                        });
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            ))}
          </Slider>
        ) : (
          <div className="no-img" />
        )}
        controls={allImages.length > 0 ? (
          <div className="custom-gallery-controls">
            <button
              className="btn-prev"
              type="button"
              onClick={previous}
            >
              Prev
            </button>
            <button
              className="btn-next"
              type="button"
              onClick={next}
            >
              Next
            </button>
          </div>
        ) : null}
        thumbs={(
          <div className="thumbs-container">
            {allImages.length > 0 ? (
              <Slider ref={refThumbnails} {...settingsThumbnails}>
                {allImages.map((image) => (
                  <div key={`${image}-thumb`}>
                    {image === 'no-thumb' ? (
                      <div className="no-img">
                        <label className="btn add-thumb-btn" htmlFor="upload-thumb-button">
                          {t('pageRestaurantProfile:add_restaurant_images')}
                          <input
                            type="file"
                            id="upload-thumb-button"
                            hidden
                            multiple
                            onChange={handleMultipleImagesUpload}
                          />
                        </label>
                      </div>
                    ) : (
                      <img
                        src={restaurantImage(currentRestaurantInfo._id, image, 768, 462)}
                        alt={currentRestaurantInfo._name}
                      />
                    )}
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="gallery-thumbs">
                <div className="slick-slide no-img">
                  <label className="btn add-thumb-btn" htmlFor="upload-thumb-button">
                    {t('pageRestaurantProfile:add_restaurant_images')}
                    <input
                      type="file"
                      id="upload-thumb-button"
                      hidden
                      multiple
                      onChange={handleMultipleImagesUpload}
                    />
                  </label>
                </div>
              </div>
            )}
            <label className="btn btn-accent add-img-btn" htmlFor="upload-button">
              +
              <input
                type="file"
                id="upload-button"
                hidden
                multiple
                onChange={handleMultipleImagesUpload}
              />
            </label>
          </div>
        )}
      />
      <button
        type="button"
        className="btn-link btn-reorder-images"
        onClick={() => {
          setShowPopup(true);
          setImagesToReorder(allImages);
        }}
      >
        {t('pageRestaurantProfile:reorder_gallery')}
      </button>
    </>
  );
}

ImagesCarouselWrapper.propTypes = {
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
};

ImagesCarouselWrapper.defaultProps = {
  currentRestaurantInfo: {},
};
