import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import RequestService from '../../../networking/RequestService';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import PageHeading from '../../../react-web-ui/components/PageHeading';
import ButtonLink from '../../../react-web-ui/components/ButtonLink';
import AddTastingMenuBlock from '../../../components/AddTastingMenuBlock';
import TastingMenuBlock from '../../../components/TastingMenuBlock';
import Button from '../../../react-web-ui/components/Button';
import useSpecialEvents from '../../../hooks/useSpecialEvents';
import Tooltip from '../../../react-web-ui/components/Tooltip';
import TermsTastingMenus from '../TermsTastingMenus';

export default function TastingMenusListing() {
  const { t } = useTranslation();
  const location = useLocation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [tastingMenus, setTastingMenus] = useState([]);
  const [tastingMenusActive, setTastingMenusActive] = useState([]);
  const [tastingMenusDraft, setTastingMenusDraft] = useState([]);
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: '.page-heading-tasting',
      content: 'Tasting menus showcase the best your chef can offer and can drive new customers and more revenue. Add your first tasting menu now.',
      disableBeacon: true,
    },
  ];

  const {
    handlePublish,
    handleUnpublish,
    handleDelete,
    handleChefImgUpload,
  } = useSpecialEvents(setLoading);

  useEffect(() => {
    if (!restaurantContext.restaurantId || !userContext.user || loading) return;
    setLoadingEvents(true);
    (new RequestService('manager/special-events/get'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        const responseData = response.data.filter((data) => data.featureSets);
        setTastingMenus(responseData);
        setTastingMenusActive(responseData.filter((item) => item.status === 'Active'));
        setTastingMenusDraft(responseData.filter((item) => item.status === 'Draft'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_tasting_menus'));
      })
      .finally(() => setLoadingEvents(false));
  }, [t, restaurantContext.restaurantId, userContext.user, loading]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('tasting-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('tasting-tour-status', 1);
    }
  };

  if (restaurantContext.restaurant) {
    if (!restaurantContext.restaurant.areTastingMenusEnabled) {
      return (
        <TermsTastingMenus />
      );
    }

    return (
      <div className="inner-wrapper tasting-page">
        <JoyRide
          steps={steps}
          continuous
          showSkipButton
          showProgress
          disableScrolling
          run={run}
          locale={{
            back: t('walktrough:back'),
            close: t('walktrough:close'),
            last: t('walktrough:last'),
            next: t('walktrough:next'),
            skip: t('walktrough:skip'),
          }}
          styles={{
            options: {
              primaryColor: '#ffae42',
            },
          }}
          callback={handleJoyrideCallback}
        />
        <div className="show-tips">
          <Tooltip
            title="show walktrough for current page"
            position="left"
          >
            <Button
              text="?"
              classes="btn-show-tips"
              onClick={() => setRun(true)}
            />
          </Tooltip>
        </div>
        <PageHeading
          classes="page-heading-tasting"
          title={t('pageTastingMenus:title')}
          content={(
            <>
              <Link className="btn-link" to="/monetize/terms-tasting-menus">
                <strong>{t('read_terms_add_conditions')}</strong>
              </Link>
              <ButtonLink
                href={`${location.pathname}/add`}
                text={t('pageTastingMenus:add_a_tasting_menu')}
                classes="btn-accent btn-outline"
              />
            </>
          )}
        />
        {tastingMenus.length === 0 && (
          <div className="tasting-menus-list-grid">
            <AddTastingMenuBlock
              restaurantInfo={restaurantContext.restaurant}
              handleChefImgUpload={
                (e) => handleChefImgUpload(restaurantContext.restaurantId, userContext.user.id, e)
              }
            />
          </div>
        )}
        {tastingMenusActive.length > 0 && (
          <>
            <h2 className="section-title underlined">
              <strong>{t('pageTastingMenus:active_tasting_menus')}</strong>
            </h2>
            <div className="tasting-menus-list-grid">
              {tastingMenusActive.map((tastingMenu) => (
                <TastingMenuBlock
                  key={tastingMenu.id}
                  menu={tastingMenu}
                  handleChefImgUpload={
                    (e) => handleChefImgUpload(
                      restaurantContext.restaurantId, userContext.user.id, e,
                    )
                  }
                  buttons={(
                    <div className="multiple-buttons">
                      <Button
                        text={t('unpublish')}
                        classes="btn-accent btn-outline right"
                        onClick={
                          () => handleUnpublish(tastingMenu.id, userContext.user.id, t('pageTastingMenus:active_bookings'))
                        }
                        disabled={loading || loadingEvents}
                      />
                    </div>
                  )}
                />
              ))}
            </div>
          </>
        )}
        {tastingMenusDraft.length > 0 && (
          <>
            <h2 className="section-title underlined">
              <strong>{t('pageTastingMenus:draft_tasting_menus')}</strong>
            </h2>
            <div className="tasting-menus-list-grid">
              {tastingMenusDraft.map((tastingMenu) => (
                <TastingMenuBlock
                  key={tastingMenu.id}
                  menu={tastingMenu}
                  handleChefImgUpload={
                    (e) => handleChefImgUpload(
                      restaurantContext.restaurantId, userContext.user.id, e,
                    )
                  }
                  buttons={(
                    <div className="multiple-buttons">
                      <Button
                        text={t('publish')}
                        classes="btn-accent"
                        onClick={() => {
                          confirmAlert({
                            // eslint-disable-next-line react/prop-types
                            customUI: ({ onClose }) => (
                              <div className="react-confirm-alert-body tasting-page">
                                <h2 className="section-title">{t('pageTastingMenus:are_you_sure_publish')}</h2>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handlePublish(tastingMenu.id, userContext.user.id);
                                    onClose();
                                  }}
                                >
                                  {t('yes')}
                                </button>
                                <button
                                  type="button"
                                  onClick={onClose}
                                >
                                  {t('no')}
                                </button>
                              </div>
                            ),
                          });
                        }}
                        disabled={loading || loadingEvents}
                      />
                      <ButtonLink
                        href={`${location.pathname}/edit/${tastingMenu.id}`}
                        text={t('edit')}
                        classes="btn-accent"
                        disabled={loading || loadingEvents}
                      />
                      <Button
                        text={t('delete')}
                        classes="btn-accent btn-outline right"
                        onClick={() => {
                          confirmAlert({
                            // eslint-disable-next-line react/prop-types
                            customUI: ({ onClose }) => (
                              <div className="react-confirm-alert-body tasting-page">
                                <h2 className="section-title">{t('pageTastingMenus:are_you_sure_delete')}</h2>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDelete(tastingMenu.id, userContext.user.id);
                                    onClose();
                                  }}
                                >
                                  {t('yes')}
                                </button>
                                <button
                                  type="button"
                                  onClick={onClose}
                                >
                                  {t('no')}
                                </button>
                              </div>
                            ),
                          });
                        }}
                        disabled={loading || loadingEvents}
                      />
                    </div>
                  )}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
  return ('');
}
