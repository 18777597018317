import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    firstNameEmpty: i18n.t('forms:first_name_error_empty'),
    lastNameEmpty: i18n.t('forms:last_name_error_empty'),
    emailEmpty: i18n.t('forms:email_error_empty'),
    emailInvalid: i18n.t('forms:email_error_invalid'),
    phoneEmpty: i18n.t('forms:phone_error_empty'),
    phoneInvalid: i18n.t('forms:phone_error_invalid'),
    checkTerms: i18n.t('forms:check_terms_error'),
  };

  // First Name
  errors.first_name_book = [];
  if (!values.first_name_book || values.first_name_book.trim() === '') {
    errors.first_name_book.push(errorTexts.firstNameEmpty);
  }

  // Last Name
  errors.last_name_book = [];
  if (!values.last_name_book || values.last_name_book.trim() === '') {
    errors.last_name_book.push(errorTexts.lastNameEmpty);
  }

  // Phone
  errors.phone_book = [];
  const telRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
  if (!values.phone_book || values.phone_book.trim() === '') {
    errors.phone_book.push(errorTexts.phoneEmpty);
  }

  if (!telRegex.test(values.phone_book)) {
    errors.phone_book.push(errorTexts.phoneInvalid);
  }

  // Email
  errors.email_book = [];
  if (!values.email_book || values.email_book.trim() === '') {
    errors.email_book.push(errorTexts.emailEmpty);
  }

  if (!/\S+@\S+\.\S+/.test(values.email_book)) {
    errors.email_book.push(errorTexts.emailInvalid);
  }

  // Terms
  errors.accept_terms_book = [];

  if (!values.accept_terms_book) {
    errors.accept_terms_book.push(errorTexts.checkTerms);
  }

  return errors;
};

export default validate;
