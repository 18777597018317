/* eslint-disable camelcase */
import NeighbourhoodManager from './NeighbourhoodManager';
import Resource from './Resource';

export default class Suggestion extends Resource {
  static collectionKey = 'suggestions';

  constructor(
    id,
    name,
    slug,
    address,
    tokens,
    neighbourhood,
  ) {
    super(id);
    this._name = name;
    this._slug = slug;
    this._address = address;
    this._tokens = tokens;
    this._neighbourhood = neighbourhood;
  }

  get name() {
    return this._name;
  }

  get address() {
    return this._address;
  }

  get slug() {
    return this._slug;
  }

  get tokens() {
    return this._tokens;
  }

  get neighbourhood() {
    return this._neighbourhood;
  }

  static fromAPI(object) {
    return new Suggestion(
      object.id,
      object.name,
      object.slug,
      object.address,
      object.tokens,
      NeighbourhoodManager.fromAPI(object.neighbourhood),
    );
  }
}
