import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MD5 } from 'crypto-js';
import JoyRide, { STATUS } from 'react-joyride';

import CountryContext from '../../react-web-ui/contexts/CountryContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import { ReactComponent as Clock } from '../../react-web-ui/assets/images/icons/Clock.svg';
import { ReactComponent as Email } from '../../assets/images/icons/Email.svg';
import { ReactComponent as Phone } from '../../assets/images/icons/Phone.svg';
import ContactPage from '../../react-web-ui/components/ContactPage';
import Button from '../../react-web-ui/components/Button';
import Tooltip from '../../react-web-ui/components/Tooltip';

export default function Contact() {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);
  const restaurantContext = useContext(RestaurantContext);
  const [accountManager, setAccountManager] = useState({});
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'Get in touch with your Dineout rep.',
      disableBeacon: true,
      placement: 'center',
    },
  ];

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('contact-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('contact-tour-status', 1);
    }
  };

  useEffect(() => {
    if (!restaurantContext.restaurant) return;
    setAccountManager(restaurantContext.restaurant._accountManager);
  }, [restaurantContext.restaurant]);

  return (
    <ContactPage
      rightColumn={(
        <>
          <JoyRide
            steps={steps}
            continuous
            showSkipButton
            spotlightClicks
            showProgress
            disableScrolling
            run={run}
            locale={{
              back: t('walktrough:back'),
              close: t('walktrough:close'),
              last: t('walktrough:last'),
              next: t('walktrough:next'),
              skip: t('walktrough:skip'),
            }}
            styles={{
              options: {
                primaryColor: '#ffae42',
              },
            }}
            callback={handleJoyrideCallback}
          />
          <div className="show-tips">
            <Tooltip
              title="show walktrough for current page"
              position="left"
            >
              <Button
                text="?"
                classes="btn-show-tips"
                onClick={() => setRun(true)}
              />
            </Tooltip>
          </div>
          <div className="account-manager-info">
            <h3><strong>{t('pageContact:your_account_manager')}</strong></h3>
            {accountManager && (
              <div className="flex flex-between">
                {accountManager.email && (
                  <img
                    className="account-manager-img"
                    src={`https://www.gravatar.com/avatar/${MD5(accountManager.email).toString()}`}
                    alt="manager thumb"
                  />
                )}
                <div className="text-wrapper">
                  <h3>{accountManager.name}</h3>
                  <p className="text-gray">{t('pageContact:account_manager')}</p>
                  <span className="account-manager-contact">
                    <Email className="icon" />
                    {accountManager.email}
                  </span>
                  <span className="account-manager-contact">
                    <Phone className="icon" />
                    {accountManager.phone}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="account-manager-work-hours contact-info-block">
            <Clock className="icon" />
            <h3 className="text-gray">{t('pageContact:work_hours')}</h3>
            <p>
              {t(`pageContact:work_hours_text_${countryContext.country.name}`)}
            </p>
          </div>
        </>
      )}
    />
  );
}
