import Place from './Place';
import Resource from './Resource';

export default class SpecialOfferClient extends Resource {
  static collectionKey = 'special_offers';

  constructor(
    id,
    slug,
    name,
    description,
    price,
    discountedPrice,
    status,
    percentage,
    intervals,
    thumb,
    items,
    place,
    type,
  ) {
    super(id);
    this._slug = slug;
    this._name = name;
    this._description = description;
    this._price = price;
    this._discountedPrice = discountedPrice;
    this._status = status;
    this._percentage = percentage;
    this._intervals = intervals;
    this._thumb = thumb;
    this._items = items;
    this._place = place;
    this._type = type;
  }

  get slug() {
    return this._slug;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get price() {
    return this._price;
  }

  get discountedPrice() {
    return this._discountedPrice;
  }

  get status() {
    return this._status;
  }

  get percentage() {
    return this._percentage;
  }

  get intervals() {
    return this._intervals;
  }

  get thumb() {
    return this._thumb;
  }

  get items() {
    return this._items;
  }

  get place() {
    return this._place;
  }

  get type() {
    return this._type;
  }

  static fromAPI(object) {
    return new SpecialOfferClient(
      object.id,
      object.slug,
      object.name,
      object.description,
      object.price,
      object.discounted_price,
      object.status,
      object.percentage,
      object.intervals,
      object.thumb,
      object.items,
      Place.fromAPI(object.place),
      object.type,
    );
  }

  static plain(object) {
    return {
      id: object.id,
      slug: object.slug,
      name: object.name,
      description: object.description,
      price: object.price,
      discountedPrice: object.discounted_price,
      status: object.status,
      percentage: object.percentage,
      intervals: object.intervals,
      thumb: object.thumb,
      items: object.items,
      place: Place.plain(object.place),
      type: object.type,
    };
  }
}
