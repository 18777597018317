import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonLink from '../react-web-ui/components/ButtonLink';
import Spaceship from '../assets/images/spaceship.svg';

export default function RestrictedPage() {
  const { t } = useTranslation();

  return (
    <div className="inner-wrapper">
      <div className="restricted-content">
        <img src={Spaceship} alt="" />
        <div className="hero-section-content">
          <h2>{t('feature_locked')}</h2>
          <p>{t('consider_upgrading')}</p>
          <ButtonLink
            text={t('see_plans')}
            classes="btn-accent"
            href="/manage/subscriptions"
          />
        </div>
      </div>
    </div>
  );
}
