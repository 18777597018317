import React, { useContext, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import WindowContext from '../../react-web-ui/contexts/WindowContext';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import logopl1 from '../../assets/images/partner-logos/pl/1.png';
import logopl2 from '../../assets/images/partner-logos/pl/2.png';
import logopl3 from '../../assets/images/partner-logos/pl/3.png';
import logopl4 from '../../assets/images/partner-logos/pl/4.png';
import logopl5 from '../../assets/images/partner-logos/pl/5.jpg';
import logopl6 from '../../assets/images/partner-logos/pl/6.jpg';
import logopl7 from '../../assets/images/partner-logos/pl/7.jpg';
import logopl8 from '../../assets/images/partner-logos/pl/8.jpg';
import logopl9 from '../../assets/images/partner-logos/pl/9.jpg';
import logopl10 from '../../assets/images/partner-logos/pl/10.png';
import logopl11 from '../../assets/images/partner-logos/pl/11.jpg';
import logopl12 from '../../assets/images/partner-logos/pl/12.jpg';
import logopl13 from '../../assets/images/partner-logos/pl/13.png';
import logopl14 from '../../assets/images/partner-logos/pl/14.png';
import logopl15 from '../../assets/images/partner-logos/pl/15.jpg';
import logopl16 from '../../assets/images/partner-logos/pl/16.png';
import logopl17 from '../../assets/images/partner-logos/pl/17.png';
import logopl18 from '../../assets/images/partner-logos/pl/18.png';
import logopl19 from '../../assets/images/partner-logos/pl/19.png';
import logopl20 from '../../assets/images/partner-logos/pl/20.jpg';

import logobg1 from '../../assets/images/partner-logos/bg/1.jpg';
import logobg2 from '../../assets/images/partner-logos/bg/2.png';
import logobg3 from '../../assets/images/partner-logos/bg/3.jpg';
import logobg4 from '../../assets/images/partner-logos/bg/4.png';
import logobg5 from '../../assets/images/partner-logos/bg/5.jpg';
import logobg6 from '../../assets/images/partner-logos/bg/6.jpg';
import logobg7 from '../../assets/images/partner-logos/bg/7.jpg';
import logobg8 from '../../assets/images/partner-logos/bg/8.png';
import logobg9 from '../../assets/images/partner-logos/bg/9.jpg';
import logobg10 from '../../assets/images/partner-logos/bg/10.jpg';
import logobg11 from '../../assets/images/partner-logos/bg/11.png';
import logobg12 from '../../assets/images/partner-logos/bg/12.png';
import logobg13 from '../../assets/images/partner-logos/bg/13.png';
import logobg14 from '../../assets/images/partner-logos/bg/14.jpg';
import logobg15 from '../../assets/images/partner-logos/bg/15.png';
import logobg16 from '../../assets/images/partner-logos/bg/16.jpg';
import logobg17 from '../../assets/images/partner-logos/bg/17.jpg';
import logobg18 from '../../assets/images/partner-logos/bg/18.jpg';
import logobg19 from '../../assets/images/partner-logos/bg/19.png';
import logobg20 from '../../assets/images/partner-logos/bg/20.png';

/**
* Partners section
* @returns {*}
* @constructor
*/
export default function PartnersSectionSlider() {
  const countryContext = useContext(CountryContext);
  const refSlider = useRef();
  const { currentWidth } = useContext(WindowContext);
  const images = countryContext.country.code !== 'BG' ? [
    logopl1,
    logopl2,
    logopl3,
    logopl4,
    logopl5,
    logopl6,
    logopl7,
    logopl8,
    logopl9,
    logopl10,
    logopl11,
    logopl12,
    logopl13,
    logopl14,
    logopl15,
    logopl16,
    logopl17,
    logopl18,
    logopl19,
    logopl20,
  ] : [
    logobg1,
    logobg2,
    logobg3,
    logobg4,
    logobg5,
    logobg6,
    logobg7,
    logobg8,
    logobg9,
    logobg10,
    logobg11,
    logobg12,
    logobg13,
    logobg14,
    logobg15,
    logobg16,
    logobg17,
    logobg18,
    logobg19,
    logobg20,
  ];

  let slidesToShow = 2;

  if (currentWidth > 560) {
    slidesToShow = 3;
  }

  if (currentWidth > 767) {
    slidesToShow = 4;
  }

  if (currentWidth > 1024) {
    slidesToShow = 5;
  }

  if (currentWidth > 1440) {
    slidesToShow = 7;
  }

  if (currentWidth > 1920) {
    slidesToShow = 10;
  }

  const settingsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow,
    draggable: true,
    speed: 3500,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Slider ref={refSlider} {...settingsSlider}>
      {images.map((image) => (
        <div key={image} className="logo-wrapper">
          <img src={image} alt="" />
        </div>
      ))}
    </Slider>
  );
}
