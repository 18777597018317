import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import WindowContext from '../../react-web-ui/contexts/WindowContext';
import ChosenSet from '../ChosenSet';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';

export default function BookingsTable({ bookings, setSelectedBooking, selectedBooking }) {
  const { t } = useTranslation();
  const [currentBookings, setCurrentBookings] = useState(bookings);
  const { isDesktop } = useContext(WindowContext);
  const myRef = useRef(null);
  const [nameSort, setNameSort] = useState(null);
  const [guestsSort, setGuestsSort] = useState(null);
  const [dateSort, setDateSort] = useState(null);
  const [timeSort, setTimeSort] = useState(null);
  const [specialsSort, setSpecialsSort] = useState(null);
  const [notesSort, setNotesSort] = useState(null);
  const [createdSort, setCreatedSort] = useState(null);
  const [ownerSort, setOwnerSort] = useState(null);

  const scrollToTable = () => myRef.current.scrollIntoView();

  useEffect(() => {
    setCurrentBookings(bookings);
  }, [bookings]);

  useEffect(() => {
    if (!nameSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      const nameA = a.contactName ? a.contactName.toLowerCase() : '';
      const nameB = b.contactName ? b.contactName.toLowerCase() : '';
      if (nameA < nameB) return (nameSort === 'asc') ? -1 : 1;
      if (nameA > nameB) return (nameSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [nameSort]);

  useEffect(() => {
    if (!guestsSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      const guestsA = a.people;
      const guestsB = b.people;
      if (guestsA < guestsB) return (guestsSort === 'asc') ? -1 : 1;
      if (guestsA > guestsB) return (guestsSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [guestsSort]);

  useEffect(() => {
    if (!specialsSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      let nameA = '';
      let nameB = '';

      if (a.specialOffer) {
        nameA = a.specialOffer.type;
      }

      if (b.specialOffer) {
        nameB = b.specialOffer.type;
      }

      if (a.specialEvent) {
        nameA = a.specialEvent.name.toLowerCase();
      }

      if (b.specialEvent) {
        nameB = b.specialEvent.name.toLowerCase();
      }

      if (a.gift) {
        nameA = a.gift.value;
      }

      if (b.gift) {
        nameB = b.gift.value;
      }
      if (nameA < nameB) return (specialsSort === 'asc') ? -1 : 1;
      if (nameA > nameB) return (specialsSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [specialsSort]);

  useEffect(() => {
    if (!dateSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      const dateA = moment.unix(a.dt).utc();
      const dateB = moment.unix(b.dt).utc();
      if (dateA < dateB) return (dateSort === 'asc') ? -1 : 1;
      if (dateA > dateB) return (dateSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [dateSort]);

  useEffect(() => {
    if (!timeSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      const timeA = moment.unix(a.dt).utc().format('HH:mm');
      const timeB = moment.unix(b.dt).utc().format('HH:mm');
      if (timeA < timeB) return (timeSort === 'asc') ? -1 : 1;
      if (timeA > timeB) return (timeSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [timeSort]);

  useEffect(() => {
    if (!notesSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      const guestsA = a.notes ? a.notes.toLowerCase() : '';
      const guestsB = b.notes ? b.notes.toLowerCase() : '';
      if (guestsA < guestsB) return (notesSort === 'asc') ? -1 : 1;
      if (guestsA > guestsB) return (notesSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [notesSort]);

  useEffect(() => {
    if (!createdSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      const guestsA = a.createdAt ? a.createdAt.toLowerCase() : '';
      const guestsB = b.createdAt ? b.createdAt.toLowerCase() : '';
      if (guestsA < guestsB) return (createdSort === 'asc') ? -1 : 1;
      if (guestsA > guestsB) return (createdSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [createdSort]);

  useEffect(() => {
    if (!ownerSort) return;
    const sortedBookings = [...bookings];
    sortedBookings.sort((a, b) => {
      const guestsA = a.owner.firstName ? a.owner.firstName.toLowerCase() : '';
      const guestsB = b.owner.firstName ? b.owner.firstName.toLowerCase() : '';
      if (guestsA < guestsB) return (ownerSort === 'asc') ? -1 : 1;
      if (guestsA > guestsB) return (ownerSort === 'asc') ? 1 : -1;
      return 0;
    });
    setCurrentBookings(sortedBookings);
  }, [ownerSort]);

  return (
    <div className="column-layout">
      <div className="main-column">
        <div className="bookings-table">
          <table>
            <thead>
              <tr>
                <th>
                  <button
                    type="button"
                    className={`sort ${nameSort}`}
                    onClick={() => {
                      setNameSort(nameSort === 'asc' ? 'desc' : 'asc');
                      setGuestsSort(null);
                      setDateSort(null);
                      setTimeSort(null);
                      setSpecialsSort(null);
                      setNotesSort(null);
                      setCreatedSort(null);
                      setOwnerSort(null);
                    }}
                  >
                    {t('pageBookings:names')}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort ${guestsSort}`}
                    onClick={() => {
                      setNameSort(null);
                      setGuestsSort(guestsSort === 'asc' ? 'desc' : 'asc');
                      setDateSort(null);
                      setTimeSort(null);
                      setSpecialsSort(null);
                      setNotesSort(null);
                      setCreatedSort(null);
                      setOwnerSort(null);
                    }}
                  >
                    {t('pageBookings:guests')}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort ${dateSort}`}
                    onClick={() => {
                      setNameSort(null);
                      setGuestsSort(null);
                      setDateSort(dateSort === 'asc' ? 'desc' : 'asc');
                      setTimeSort(null);
                      setSpecialsSort(null);
                      setNotesSort(null);
                      setCreatedSort(null);
                      setOwnerSort(null);
                    }}
                  >
                    {t('pageBookings:date')}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort ${timeSort}`}
                    onClick={() => {
                      setNameSort(null);
                      setGuestsSort(null);
                      setDateSort(null);
                      setTimeSort(timeSort === 'asc' ? 'desc' : 'asc');
                      setSpecialsSort(null);
                      setNotesSort(null);
                      setCreatedSort(null);
                      setOwnerSort(null);
                    }}
                  >
                    {t('pageBookings:time')}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort ${specialsSort}`}
                    onClick={() => {
                      setNameSort(null);
                      setGuestsSort(null);
                      setDateSort(null);
                      setTimeSort(null);
                      setSpecialsSort(specialsSort === 'asc' ? 'desc' : 'asc');
                      setNotesSort(null);
                      setCreatedSort(null);
                      setOwnerSort(null);
                    }}
                  >
                    {t('pageBookings:specials')}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort ${notesSort}`}
                    onClick={() => {
                      setNameSort(null);
                      setGuestsSort(null);
                      setDateSort(null);
                      setTimeSort(null);
                      setSpecialsSort(null);
                      setNotesSort(notesSort === 'asc' ? 'desc' : 'asc');
                      setCreatedSort(null);
                      setOwnerSort(null);
                    }}
                  >
                    {t('pageBookings:notes')}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort ${createdSort}`}
                    onClick={() => {
                      setNameSort(null);
                      setGuestsSort(null);
                      setDateSort(null);
                      setTimeSort(null);
                      setSpecialsSort(null);
                      setNotesSort(null);
                      setCreatedSort(createdSort === 'asc' ? 'desc' : 'asc');
                      setOwnerSort(null);
                    }}
                  >
                    {t('pageBookings:created_at')}
                  </button>
                </th>
                <th>
                  <button
                    type="button"
                    className={`sort ${ownerSort}`}
                    onClick={() => {
                      setNameSort(null);
                      setGuestsSort(null);
                      setDateSort(null);
                      setTimeSort(null);
                      setSpecialsSort(null);
                      setNotesSort(null);
                      setCreatedSort(null);
                      setOwnerSort(ownerSort === 'asc' ? 'desc' : 'asc');
                    }}
                  >
                    {t('pageBookings:owner')}
                  </button>
                </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {currentBookings.length > 0 ? (
                currentBookings.map((booking) => (
                  <tr
                    key={booking.id}
                    className={selectedBooking.id === booking.id ? 'selected' : ''}
                    onClick={() => {
                      setSelectedBooking(booking);
                      if (!isDesktop) {
                        scrollToTable();
                      }
                    }}
                  >
                    <td>{booking.contactName || t(`pageBookings:${booking.type}`)}</td>
                    <td>{booking.people}</td>
                    <td>{moment.unix(booking.dt).utc().format('DD-MM-YYYY')}</td>
                    <td>{moment.unix(booking.dt).utc().format('HH:mm')}</td>
                    <td>
                      {booking.specialOffer || booking.specialEvent || booking.gift ? (
                        <>
                          {booking.specialOffer && (
                            <span className="pill-label">
                              {t(`pageSpecialOffers:${booking.specialOffer.type}`)}
                            </span>
                          )}
                          {booking.specialEvent && (
                            <span className="pill-label">
                              {booking.specialEvent.name}
                            </span>
                          )}
                          {booking.gift && (
                            <span className="pill-label">
                              {`${booking.gift.value} ${booking.gift.currency}`}
                            </span>
                          )}

                        </>
                      ) : ''}
                    </td>
                    <td>{booking.notes}</td>
                    <td>{moment.unix(booking.createdAt).utc().format('DD-MM-YYYY')}</td>
                    <td>{booking.owner.firstName && `${booking.owner.firstName} ${booking.owner.lastName}, ${booking.owner.email}`}</td>
                    <td>
                      <span className="show-more-arrow">
                        <ArrowDown />
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr><td colSpan="9">{t('pageBookings:no_search_results')}</td></tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div ref={myRef} className="right-column relative">
        {selectedBooking && (
          <div className="reservations-table-wrapper">
            <table className="reservations-table">
              <thead>
                <tr>
                  <th colSpan="2">
                    {t('pageBookings:reservation_details')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('pageBookings:name')}</td>
                  <td>{selectedBooking.contactName || t(`pageBookings:${selectedBooking.type}`)}</td>
                </tr>
                <tr>
                  <td>{t('pageBookings:email')}</td>
                  <td>{selectedBooking.contactEmail || t('pageBookings:not_provided')}</td>
                </tr>
                <tr>
                  <td>{t('pageBookings:phone')}</td>
                  <td>{selectedBooking.contactPhone || t('pageBookings:not_provided')}</td>
                </tr>
                <tr>
                  <td>{`${t('pageBookings:guests')}:`}</td>
                  <td>{selectedBooking.people}</td>
                </tr>
                <tr>
                  <td>{`${t('pageBookings:date')}/${t('pageBookings:time')}:`}</td>
                  <td>{moment.unix(selectedBooking.dt).utc().format('DD-MM-YYYY HH:mm')}</td>
                </tr>
                {selectedBooking.tables && (
                  <tr>
                    <td>{t('pageBookings:table')}</td>
                    <td>
                      {selectedBooking.tables.map((table) => (
                        <span key={table.id}>
                          {table.name}
                          {` (${table.salon.name})`}
                        </span>
                      ))}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{t('pageBookings:source')}</td>
                  <td>
                    {selectedBooking.type === 'app' || selectedBooking.type === 'widget' ? (
                      'Dineout'
                    ) : t(`pageBookings:${selectedBooking.type}`)}
                  </td>
                </tr>
                <tr>
                  <td>{t('pageBookings:notes')}</td>
                  <td>{selectedBooking.notes || t('pageBookings:no_special_requests')}</td>
                </tr>
                <tr>
                  <td>{t('pageBookings:status')}</td>
                  <td>{t(`pageBookings:${selectedBooking.status}`)}</td>
                </tr>
                {selectedBooking.specialOffer || selectedBooking.specialEvent ? (
                  <tr>
                    <td>{t('pageBookings:special')}</td>
                    <td>
                      {selectedBooking.specialOffer && (
                        <span>
                          {selectedBooking.specialOffer.percentage && `-${selectedBooking.specialOffer.percentage}%`}
                          {selectedBooking.specialOffer.price && (
                            <>
                              <span style={{ textDecoration: 'line-through' }}>{selectedBooking.specialOffer.price}</span>
                              <span>{selectedBooking.specialOffer.discountedPrice}</span>
                            </>
                          )}
                          {` (${selectedBooking.specialOffer.description})`}
                        </span>
                      )}
                      {selectedBooking.specialEvent && selectedBooking.specialEvent.name}
                    </td>
                  </tr>
                ) : ''}
                {selectedBooking.gift && (
                  <tr>
                    <td>{t('pageBookings:giftcard')}</td>
                    <td>{`${selectedBooking.gift.value} ${selectedBooking.gift.currency} (${selectedBooking.gift.code})`}</td>
                  </tr>
                )}
                {selectedBooking.specialEvent
                  && selectedBooking.specialEvent.chosenSets.length > 0
                  && (
                    <tr>
                      <td>{t('pageBookings:chosen_sets')}</td>
                      <td>
                        {selectedBooking.specialEvent.chosenSets.map((chosenSet) => (
                          <ChosenSet
                            key={chosenSet.name}
                            featureSets={selectedBooking.specialEvent.featureSets}
                            chosenSet={chosenSet}
                            currency={selectedBooking.place.city.country.currency}
                          />
                        ))}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

BookingsTable.propTypes = {
  bookings: PropTypes.oneOfType([PropTypes.array]).isRequired,
  selectedBooking: PropTypes.oneOfType([PropTypes.object]),
  setSelectedBooking: PropTypes.func.isRequired,
};

BookingsTable.defaultProps = {
  selectedBooking: null,
};
