import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/pl';
import { DateRangePicker } from 'react-dates';
import { toast } from 'react-toastify';

import RestaurantContext from '../../contexts/RestaurantContext';
import PageHeading from '../../react-web-ui/components/PageHeading';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RequestService from '../../networking/RequestService';
import WindowContext from '../../react-web-ui/contexts/WindowContext';
import Spinner from '../../react-web-ui/components/Spinner';
import Button from '../../react-web-ui/components/Button';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';

export default function Payments() {
  const { t, i18n } = useTranslation();
  const DEKSTOP_CONTENT_LOADER = 700;
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);
  const restaurantContext = useContext(RestaurantContext);
  const [focused, setFocused] = useState();
  const [start, setStart] = useState(moment());
  const [end, setEnd] = useState(moment().add(1, 'weeks'));
  const [loading, setLoading] = useState(false);
  const [payouts, setPayouts] = useState([]);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  // Change calendar locale
  moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  }, [i18n.language]);

  const datesChangeHandler = ({ startDate, endDate }) => {
    setStart(startDate);
    setEnd(endDate);
  };

  const focusChangeHandler = (focusedInput) => {
    setFocused(focusedInput);
  };

  const handleShowStats = () => {
    if (start === null || end === null) return;
    setLoading(true);
    startListening();

    const params = {
      place_id: restaurantContext.restaurantId,
      user_id: userContext.user.id,
      from_dt: start.startOf('day').unix(),
      to_dt: end.endOf('day').unix(),
    };

    (new RequestService('manager/payout/index'))
      .setParams(params)
      .send()
      .then((response) => {
        setPayouts(response.data);
      })
      .catch((err) => {
        if (err.key) toast.error(t(`apiErrors:${err.key}`));
        else toast.error(t('apiErrors:could_not_get_bookings'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  useEffect(() => {
    if (!userContext.user || !restaurantContext.restaurantId) return;
    handleShowStats();
  }, [t, userContext.user, restaurantContext.restaurantId]);

  return (
    <div className="inner-wrapper">
      <PageHeading
        classes="page-heading"
        title={t('pageBookings:payments')}
        content={(
          <>
            <Link className="btn-link" to="/monetize/terms-giftcards">
              <strong>{t('read_terms_add_conditions')}</strong>
            </Link>
            <form className="bookings-fields">
              <div className="fields-wrapper">
                <div className="pick-date pick-date-input">
                  <strong>{t('pageBookings:period')}</strong>
                  <DateRangePicker
                    isOutsideRange={() => false}
                    firstDayOfWeek={1}
                    numberOfMonths={
                      // eslint-disable-next-line
                      windowContext.currentWidth < DEKSTOP_CONTENT_LOADER
                        ? 1
                        : 2
                    }
                    navPrev={(
                      <button className="calendar-prev" type="button">
                        <ArrowDown />
                      </button>
                    )}
                    navNext={(
                      <button className="calendar-next" type="button">
                        <ArrowDown />
                      </button>
                    )}
                    orientation={
                      windowContext.currentWidth >= DEKSTOP_CONTENT_LOADER
                        ? 'horizontal'
                        : 'vertical'
                    }
                    minimumNights={0}
                    noBorder
                    hideKeyboardShortcutsPanel
                    onDatesChange={datesChangeHandler}
                    onFocusChange={focusChangeHandler}
                    focusedInput={focused}
                    startDate={start}
                    endDate={end}
                    startDateId="your_unique_start_date_id"
                    endDateId="your_unique_end_date_id"
                    startDatePlaceholderText={t('start_date')}
                    endDatePlaceholderText={t('end_date')}
                  />
                </div>
              </div>
              <Button
                text={loading ? (
                  <>
                    <Spinner />
                    {t('loading')}
                  </>
                ) : t('show')}
                classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
                onClick={handleShowStats}
                disabled={loading}
              />
            </form>
          </>
        )}
      />
      <button className="btn-link btn-export" type="button">
        {t('pageBookings:export')}
      </button>
      <div className="payments-table">
        <table>
          <thead>
            <tr>
              <th>
                {`${t('pageBookings:date')}:`}
              </th>
              <th>
                {t('pageBookings:name')}
              </th>
              <th>
                {`${t('pageBookings:guests')}:`}
              </th>
              <th>
                {t('pageBookings:type')}
              </th>
              <th>
                {`${t('pageBookings:gift_card')}:`}
              </th>
              <th>
                {t('pageSubscriptions:total')}
              </th>
            </tr>
          </thead>
          <tbody>
            {payouts.length > 0 ? (
              payouts.map((payout) => (
                <tr key={payout.id}>
                  <td>{moment.unix(payout.dt).utc().format('DD-MM-YYYY')}</td>
                  <td>{payout.contact_name}</td>
                  <td>{payout.people}</td>
                  <td>
                    {payout.gift && !payout.specialEvent && t('pageBookings:gift_card')}
                    {
                      payout.specialEvent
                      && !payout.specialEvent.featureSets
                      && payout.specialEvent.name
                    }
                    {
                      payout.specialEvent
                      && payout.specialEvent.featureSets
                      && t('pageWidget:book_tasting')
                    }
                  </td>
                  <td>{payout.gift && payout.gift.code}</td>
                  <td>{payout.total}</td>
                </tr>
              ))
            ) : (
              <tr><td colSpan="6">{t('pageBookings:no_search_results')}</td></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
