/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tooltip from '../../react-web-ui/components/Tooltip';

function CombinationSuggestions({
  currentCombination,
  savedCombinations,
  onAddClicked,
  doCombinationHighlight,
}) {
  const { t } = useTranslation();

  const [availableCombinations, setAvailableCombinations] = useState([]);

  const doCombination = (elements, combinations = [], result = [combinations]) => {
    for (let i = 0; i < elements.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      result = result.concat(
        doCombination((elements.slice(i + 1)), combinations.concat(elements[i])),
      );
    }
    return result;
  };

  useEffect(() => {
    setAvailableCombinations([]);

    if (currentCombination.tables && currentCombination.tables.length < 1) {
      return;
    }

    const combinedTablesInGroups = doCombination(currentCombination.tables.length > 4
      ? [...currentCombination.tables.slice(
        currentCombination.tables.length - 4, currentCombination.tables.length,
      )]
      : [...currentCombination.tables], []);

    const filteredCombinations = combinedTablesInGroups.filter((group) => {
      const isNotCurrentCombination = currentCombination.tables.length !== group.length;
      const isNotExistingCombination = group.length > 1
          && savedCombinations.filter(
            (savedCombination) => savedCombination.tables.length === group.length
                && savedCombination.tables
                  .filter(
                    (savedTableInThisCombination) => group
                      .map((t) => t.id).indexOf(savedTableInThisCombination) === -1,
                  ).length === 0,
          ).length === 0;

      return isNotCurrentCombination && isNotExistingCombination;
    }).map((group) => ({
      id: null,
      name: group.map((t) => t.name).join(' / '),
      minCapacity: group.reduce((sum, t) => t.minCapacity + sum, 0),
      maxCapacity: group.reduce((sum, t) => t.maxCapacity + sum, 0),
      tables: group,
    }));

    setAvailableCombinations(filteredCombinations);
  }, [currentCombination]);

  return (
    <>
      <h2 className="column-top">
        {`${t('pageCombinations:combination_suggestions_label')}: ${availableCombinations.length}`}
      </h2>
      {availableCombinations.length > 0 && (
        <table className="combinations-list">
          <thead>
            <tr>
              <th>{t('pageCombinations:combination_name')}</th>
              <th>min</th>
              <th>max</th>
            </tr>
          </thead>
          <tbody>
            {availableCombinations.map((combination) => (
              <tr
                onMouseOver={() => doCombinationHighlight(combination)}
                onFocus={() => doCombinationHighlight(combination)}
                onMouseLeave={() => doCombinationHighlight(null)}
                onClick={() => {
                  onAddClicked(
                    combination.name,
                    combination.minCapacity,
                    combination.maxCapacity,
                    combination.tables,
                  );
                  doCombinationHighlight(null);
                }}
                key={combination.tables.map((t) => t.id).join()}
              >
                <td>
                  <Tooltip
                    title={t('pageCombinations:click_to_add')}
                    position="top"
                  >
                    {combination.name}
                  </Tooltip>
                </td>
                <td>
                  {combination.minCapacity}
                </td>
                <td>{combination.maxCapacity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

CombinationSuggestions.propTypes = {
  currentCombination: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    minCapacity: PropTypes.number,
    maxCapacity: PropTypes.number,
    tables: PropTypes.instanceOf(Array),
  }).isRequired,
  savedCombinations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    minCapacity: PropTypes.number,
    maxCapacity: PropTypes.number,
    tables: PropTypes.instanceOf(Array),
  })).isRequired,
  onAddClicked: PropTypes.func.isRequired,
  doCombinationHighlight: PropTypes.func.isRequired,
};

export default CombinationSuggestions;
