import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import { CircularProgressbar } from 'react-circular-progressbar';
import '../../../../node_modules/react-circular-progressbar/dist/styles.css';

/**
 * Review Circle Rating
 * @param title
 * @param rating
 * @returns {*}
 * @constructor
 */
export default function ReviewCircleRating({ title, rating }) {
  const [active, setActive] = useState(true);

  const onChangeVisibility = (isVisible) => {
    if (isVisible) setActive(false);
  };

  return (
    <div className="circle">
      <VisibilitySensor onChange={(e) => onChangeVisibility(e)} active={active}>
        {({ isVisible }) => {
          const value = isVisible ? rating : 0;
          return (
            <CircularProgressbar
              value={value}
              maxValue={5}
              text={rating}
              styles={{
                path: {
                  strokeLinecap: 'butt',
                },
              }}
            />
          );
        }}
      </VisibilitySensor>
      <p>{title}</p>
    </div>
  );
}

ReviewCircleRating.propTypes = {
  /** Title of the circle rating. */
  title: PropTypes.string.isRequired,
  /** Rating inside the circle. */
  rating: PropTypes.number.isRequired,
};
