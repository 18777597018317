import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/pl';
import { DateRangePicker } from 'react-dates';
import { Bar, Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import UserContext from '../../react-web-ui/contexts/UserContext';
import RequestService from '../../networking/RequestService';
import WindowContext from '../../react-web-ui/contexts/WindowContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import Spinner from '../../react-web-ui/components/Spinner';
import PageHeading from '../../react-web-ui/components/PageHeading';
import Button from '../../react-web-ui/components/Button';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';
import Tooltip from '../../react-web-ui/components/Tooltip';
import RestrictedPage from '../RestrictedPage';

export default function Statistics() {
  const { t, i18n } = useTranslation();
  const DEKSTOP_CONTENT_LOADER = 700;
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);
  const restaurantContext = useContext(RestaurantContext);
  const [focused, setFocused] = useState();
  const [start, setStart] = useState(moment().subtract(3, 'months'));
  const [end, setEnd] = useState(moment());
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const [statisticsAdvanced, setStatisticsAdvanced] = useState(null);
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: '.stats-page',
      content: 'The Stats screen of your account provides valuable data for your restaurant and is part of the Operations section.',
      disableBeacon: true,
      placement: 'center',
    },
    {
      target: '.pick-date',
      content: 'You can set the period you’d like to see data for.',
    },
    {
      target: '.tour-stats-bookings',
      content: 'The stats section will show you the total number of reservations that came through Dineout and those you recorded internally, grouped by different criteria.',
    },
    {
      target: '.number-stats-block',
      content: 'This section contains special bookings that required guests to prepay covers or they decided to use a Gift card.',
    },
  ];

  // Change calendar locale
  moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  }, [i18n.language]);

  const datesChangeHandler = ({ startDate, endDate }) => {
    if (startDate !== null && endDate !== null) setError(false);
    setStart(startDate);
    setEnd(endDate);
  };

  const focusChangeHandler = (focusedInput) => {
    setFocused(focusedInput);
  };

  const handleShowStats = () => {
    if (start === null || end === null) {
      setError(true);
      return;
    }
    setLoading(true);

    const params = {
      place_id: restaurantContext.restaurantId,
      user_id: userContext.user.id,
      start_date: start.format('YYYY-MM-DD'),
      end_date: end.format('YYYY-MM-DD'),
    };
    (new RequestService('manager/places/statistics'))
      .setParams(params)
      .send()
      .then((response) => {
        setStatistics(response.data.statistics);
      })
      .catch((err) => {
        if (err.key) toast.error(t(`apiErrors:${err.key}`));
        else toast.error(t('apiErrors:could_not_get_statistics'));
      })
      .finally(() => {
        setLoading(false);
      });

    if (restaurantContext.plan === 'PREMIUM') {
      (new RequestService('manager/statistics/advanced'))
        .setParams(params)
        .send()
        .then((response) => {
          const responseData = response.data;
          const totalTables = responseData.tables.count;
          const allShiftNames = responseData.shifts.map((shift) => shift.name);
          const noDuplicates = allShiftNames.filter(
            (c, index) => allShiftNames.indexOf(c) === index,
          );

          const newStatistics = {};
          const tableTurnoverStats = [];
          const colors = ['#FFAE42', '#7CC52C', '#d64e2f', '#8162f9', '#00C5EE', '#A61C3C', '#ffade1'];

          noDuplicates.forEach((shift, index) => {
            newStatistics[shift] = {
              backgroundColor: colors[index],
              data: [0, 0, 0, 0, 0, 0, 0],
            };
          });

          responseData.bookings.forEach((booking) => {
            const index = booking.day === 0 ? 6 : booking.day - 1;
            newStatistics[booking.shift].data[index] = booking.count / totalTables;
          });

          Object.keys(newStatistics).forEach((key) => {
            tableTurnoverStats.push({
              label: key,
              backgroundColor: newStatistics[key].backgroundColor,
              data: newStatistics[key].data,
            });
          });

          const capacityFilled = responseData.capacity_filled;
          const capacityFillStats = [];
          const hourLabels = Object.keys(capacityFilled.mon);

          Object.keys(capacityFilled).forEach((key, index) => {
            const percentData = Object.values(capacityFilled[key]).map(
              (value) => (value / responseData.tables.max) * 100,
            );

            capacityFillStats.push({
              label: t(`${key}`),
              borderColor: colors[index],
              fill: false,
              data: Object.values(percentData),
            });
          });

          setStatisticsAdvanced({
            tableTurnoverStats,
            capacityFillStats,
            hourLabels,
          });
        })
        .catch((err) => {
          if (err.key) toast.error(t(`apiErrors:${err.key}`));
          else toast.error(t('apiErrors:could_not_get_statistics'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!start || !end || !userContext.user || !restaurantContext.restaurantId) return;
    handleShowStats();
  }, [t, start, end, userContext.user, restaurantContext.restaurantId]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('stats-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('stats-tour-status', 1);
    }
  };

  return (
    <div className="stats-page inner-wrapper">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <PageHeading
        classes="page-heading-menu"
        title={t('pageStatistics:title')}
        content={(
          <div className="pick-date pick-date-input">
            <DateRangePicker
              isOutsideRange={() => false}
              firstDayOfWeek={1}
              numberOfMonths={
                // eslint-disable-next-line
                windowContext.currentWidth < DEKSTOP_CONTENT_LOADER
                  ? 1
                  : 2
              }
              navPrev={(
                <button className="calendar-prev" type="button">
                  <ArrowDown />
                </button>
              )}
              navNext={(
                <button className="calendar-next" type="button">
                  <ArrowDown />
                </button>
              )}
              orientation={
                windowContext.currentWidth >= DEKSTOP_CONTENT_LOADER
                  ? 'horizontal'
                  : 'vertical'
              }
              minimumNights={0}
              noBorder
              hideKeyboardShortcutsPanel
              onDatesChange={datesChangeHandler}
              onFocusChange={focusChangeHandler}
              focusedInput={focused}
              startDate={start}
              endDate={end}
              startDateId="your_unique_start_date_id"
              endDateId="your_unique_end_date_id"
              startDatePlaceholderText={t('start_date')}
              endDatePlaceholderText={t('end_date')}
            />
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('show')}
              classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
              onClick={handleShowStats}
              disabled={loading}
            />
            {error && <p className="error">{t('forms:please_choose_dates')}</p>}
          </div>
        )}
      />
      {statistics ? (
        <div className="tour-stats-bookings">
          <h2 className="section-title underlined"><strong>{t('pageStatistics:basic_statistics')}</strong></h2>
          <h3 className="section-title">{t('pageStatistics:total_number_of_bookings')}</h3>
          <div className="lg-two-cols stats-columns">
            <div className="col">
              <Bar
                data={{
                  labels: Object.keys(statistics.sources),
                  datasets: [{
                    label: t('pageStatistics:by_platform'),
                    backgroundColor: '#FFAE42',
                    data: Object.values(statistics.sources),
                  }],
                }}
              />
            </div>
            <div className="col">
              <Bar
                data={{
                  labels: Object.keys(statistics.party_size),
                  datasets: [{
                    label: t('pageStatistics:by_party_size'),
                    backgroundColor: '#FFAE42',
                    data: Object.values(statistics.party_size),
                  }],
                }}
              />
            </div>
            <div className="col">
              <Bar
                data={{
                  labels: Object.keys(statistics.bookings.week),
                  datasets: [{
                    label: t('pageStatistics:by_weekday'),
                    backgroundColor: '#FFAE42',
                    data: Object.values(statistics.bookings.week),
                  }],
                }}
              />
            </div>
            <div className="col">
              <Bar
                data={{
                  labels: Object.keys(statistics.bookings.day),
                  datasets: [{
                    label: t('pageStatistics:by_day_of_month'),
                    backgroundColor: '#FFAE42',
                    data: Object.values(statistics.bookings.day),
                  }],
                }}
              />
            </div>
            <div className="col">
              <Bar
                data={{
                  labels: Object.keys(statistics.bookings.year),
                  datasets: [{
                    label: t('pageStatistics:by_year'),
                    backgroundColor: '#FFAE42',
                    data: Object.values(statistics.bookings.year),
                  }],
                }}
              />
            </div>
            <div className="col number-stats-block">
              <div className="stats-block tasting-stats">
                <h3>
                  {t('pageStatistics:booking_tasting_menus')}
                </h3>
                <p className="text-tasting">
                  {statistics.tasting_menus}
                </p>
              </div>
              <div className="stats-block event-stats">
                <h3>
                  {t('pageStatistics:booking_events')}
                </h3>
                <p className="text-event">
                  {statistics.events}
                </p>
              </div>
              <div className="stats-block giftcard-stats">
                <h3>
                  {t('pageStatistics:booking_giftcards')}
                </h3>
                <p className="text-giftcard">
                  {statistics.gifts}
                </p>
              </div>
            </div>
          </div>
          <h2 className="section-title underlined"><strong>{t('pageStatistics:advanced_statistics')}</strong></h2>
          {restaurantContext.plan === 'PREMIUM' && statisticsAdvanced ? (
            <div className="lg-two-cols">
              <div className="col">
                <h3 className="section-title">{t('pageStatistics:table_turnover')}</h3>
                <Bar
                  data={{
                    labels: [
                      t('mon'),
                      t('tue'),
                      t('wed'),
                      t('thu'),
                      t('fri'),
                      t('sat'),
                      t('sun'),
                    ],
                    datasets: statisticsAdvanced.tableTurnoverStats,
                  }}
                />
              </div>
              <div className="col">
                <h3 className="section-title">{t('pageStatistics:capacity_fill')}</h3>
                <Line
                  data={{
                    labels: statisticsAdvanced.hourLabels,
                    datasets: statisticsAdvanced.capacityFillStats,
                  }}
                  options={{
                    scales: {
                      yAxes: [{
                        ticks: {
                          min: 0,
                          // max: 100,
                          callback(value) {
                            return `${value}%`;
                          },
                        },
                      }],
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            <RestrictedPage />
          )}
        </div>
      ) : (
        <h2 className="section-title">{t('pageStatistics:choose_dates')}</h2>
      )}
    </div>
  );
}
