import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    passwordEmpty: i18n.t('forms:password_error_empty'),
    repeatPasswordEmpty: i18n.t('forms:repeat_password_error_empty'),
    repeatPasswordDifferent: i18n.t('forms:repeat_password_error_not_same'),
  };

  // New Password
  errors.new_password = [];
  if (!values.new_password || values.new_password.trim() === '') {
    errors.new_password.push(errorTexts.passwordEmpty);
  }

  // Repeat Password
  errors.repeat_new_password = [];
  if (!values.repeat_new_password || values.repeat_new_password.trim() === '') {
    errors.repeat_new_password.push(errorTexts.repeatPasswordEmpty);
  }

  if (values.new_password !== values.repeat_new_password) {
    errors.repeat_new_password.push(errorTexts.repeatPasswordDifferent);
  }

  return errors;
};

export default validate;
