import React, {
  useContext, useRef, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import CountryContext from '../../react-web-ui/contexts/CountryContext';
import heroImg from '../../assets/images/hero-image-5.jpg';
import appPreview from '../../assets/images/app-preview.png';
import appleStore from '../../assets/images/apple-store.png';
import googleStore from '../../assets/images/google-play-button.png';
import ButtonLink from '../../react-web-ui/components/ButtonLink';
import UserContext from '../../react-web-ui/contexts/UserContext';
import qrCodeBg from '../../assets/images/host-landing-bg.svg';
import qrCodePl from '../../assets/images/host-landing-pl.svg';

export default function DineoutHost() {
  const { t } = useTranslation();
  const ref = useRef();
  const countryContext = useContext(CountryContext);
  const [qrCodeSrc, setQrCodeSrc] = useState(UserContext);

  useEffect(() => {
    if (countryContext.country.code === 'BG') {
      setQrCodeSrc(qrCodeBg);
    }
    if (countryContext.country.code === 'PL') {
      setQrCodeSrc(qrCodePl);
    }
  }, [countryContext.country]);

  return (
    <>
      <div className="hero-section app-preview-section">
        <img src={heroImg} alt="" />
        <div className="inner-wrapper">
          <div className="hero-section-flex">
            <div className="hero-section-content">
              <h2 className="text-accent">{t('pageHost:title')}</h2>
              <p>{t('pageHost:your_hub')}</p>
              <ButtonLink
                text={t('pageHost:download_now')}
                classes="btn-accent"
                href="/monetize/dineout-host"
                onClick={() => ref.current.scrollIntoView({ behavior: 'smooth' })}
              />
            </div>
            <img src={appPreview} alt="" className="app-preview" />
          </div>
        </div>
      </div>
      <div className="inner-wrapper">
        <div className="host-features">
          <div className="host-feature shadow-block">
            <h3>{t('pageHost:feature_1_title')}</h3>
            <p>{t('pageHost:feature_1_text')}</p>
          </div>
          <div className="host-feature shadow-block">
            <h3>{t('pageHost:feature_2_title')}</h3>
            <p>{t('pageHost:feature_2_text')}</p>
          </div>
          <div className="host-feature shadow-block">
            <h3>{t('pageHost:feature_3_title')}</h3>
            <p>{t('pageHost:feature_3_text')}</p>
          </div>
          <div className="host-feature shadow-block">
            <h3>{t('pageHost:feature_4_title')}</h3>
            <p>{t('pageHost:feature_4_text')}</p>
          </div>
          <div className="host-feature shadow-block">
            <h3>{t('pageHost:feature_5_title')}</h3>
            <p>{t('pageHost:feature_5_text')}</p>
          </div>
          <div className="host-feature shadow-block">
            <h3>{t('pageHost:feature_6_title')}</h3>
            <p>{t('pageHost:feature_6_text')}</p>
          </div>
        </div>
        <div ref={ref} className="download-apps">
          <h2>{t('pageHost:download_dineout_host')}</h2>
          <div className="mobile-app-stores">
            <div className="links">
              <a href="https://apps.apple.com/us/app/dineout-host/id1527947811">
                <img src={appleStore} alt="" />
                <span>{t('footer:for_ipad')}</span>
              </a>
              <a href="https://apps.apple.com/us/app/dineout-host-lite/id1527950208">
                <img src={appleStore} alt="" />
                <span>{t('footer:for_iphone')}</span>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bsbm.dineout.admin">
                <img src={googleStore} alt="" />
                <span>{t('footer:for_all_devices')}</span>
              </a>
            </div>
            <div className="qr-code">
              <img src={qrCodeSrc} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
