import React from 'react';
import ContentLoader from 'react-content-loader';

export default function RestaurantLoader() {
  return (
    <ContentLoader
      viewBox="0 0 320 385"
      height={385}
      speed={2}
    >
      <rect x="20" cy="50" r="16" width="280" height="200" />
      <rect x="0" y="220" rx="0" ry="0" width="320" height="25" />
      <rect x="0" y="259" rx="0" ry="0" width="320" height="16" />
      <rect x="0" y="283" rx="0" ry="0" width="320" height="16" />
      <rect x="0" y="326" rx="0" ry="0" width="320" height="43" />
    </ContentLoader>
  );
}
