import Thumbor from 'thumbor';
import Provider from './Provider';

const thumborHMAC = '80435335170639';

const URLS = process.env.REACT_APP_THUMBOR_URLS?.split(',') || [
  'https://image9000.dineout.bg',
  'https://image9001.dineout.bg',
  'https://image9002.dineout.bg',
  'https://image9003.dineout.bg',
];

export default class ThumborProvider extends Provider {
  constructor() {
    super();
    this.thumbor = new Thumbor(thumborHMAC, URLS[Math.floor(Math.random() * URLS.length)]);
  }

  /**
   * Return HTTPS url of the image requested for a restaurant in specified size.
   * @param placeId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  restaurant = (placeId, image, width, height) => `${this.thumbor.setImagePath(`places/${placeId}/${image}`).resize(width, height).buildUrl()}`;

  /**
   * Return HTTPS url of the image requested for a chef in specified size.
   * @param placeId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  chef = (placeId, image, width, height) => `${this.thumbor.setImagePath(`chefs/${placeId}/${image}`).resize(width, height).buildUrl()}`;

  /**
   * Return HTTPS url of the image requested for a city in specified size.
   * @param cityId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  city = (cityId, image, width, height) => `${this.thumbor.setImagePath(`cities/${cityId}/${image}`).resize(width, height).buildUrl()}`;

  /**
   * Return HTTPS url of the image requested for an event.
   * @param eventId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  specialEvent = (eventId, image, width, height) => `${this.thumbor.setImagePath(`special-events/${eventId}/${image}`).resize(width, height).buildUrl()}`;

  /**
   * Return HTTPS url of the image requested for a special offer.
   * @param offerId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  specialOffer = (offerId, image, width, height) => `${this.thumbor.setImagePath(`special-offers/${offerId}/${image}`).resize(width, height).buildUrl()}`;

  /**
   * Return HTTPS url of the image requested for a collection.
   * @param collectionId
   * @param image
   * @param width
   * @param height
   * @returns {string}
   */
  collection = (collectionId, image, width, height) => `${this.thumbor.setImagePath(`collections/${collectionId}/${image}`).resize(width, height).buildUrl()}`;
}
