import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormElement from '../../react-web-ui/components/FormElement';

export default function GooglePlaceAutocomplete({
  className,
  limit,
  location,
  setSearchLocation,
  googleId,
  inputId,
  placeholder,
  label,
  errors,
}) {
  const [query, setQuery] = useState('');

  const onQueryChanged = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  /**
   * If searchLocation hook's city is updated & not empty,
   * we are setting city's value as query text.
   */
  useEffect(() => {
    if (location.name) {
      setQuery(location.name);
    }
  }, [location]);

  useEffect(() => {
    if (!googleId || !inputId) return;
    const service = new window.google.maps.places.PlacesService(document.getElementById(inputId));
    // eslint-disable-next-line no-unused-vars
    service.getDetails({ placeId: googleId }, (PlaceResult, PlacesServiceStatus) => {
      setQuery(PlaceResult.name);
    });
  }, [googleId, inputId]);

  /**
   * Get results from Google Maps for current query.
   */
  useEffect(() => {
    if (!window.google || !inputId) return;
    let countryName = '';
    const options = {};
    if (limit.addressComponents) {
      limit.addressComponents.forEach((component) => {
        if (component.types.includes('country')) {
          countryName = component.short_name;
        }
      });
      options.componentRestrictions = { country: countryName };
    }
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(inputId),
      options,
    );
    autocomplete.setFields(['address_components', 'geometry.location', 'name', 'place_id']);
    autocomplete.addListener('place_changed', () => {
      const result = autocomplete.getPlace();
      if (result.address_components?.length) {
        const address = result.address_components[0];
        if (address) {
          setSearchLocation({
            name: result.name,
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
            manual: true,
            placeId: result.place_id,
            addressComponents: result.address_components,
          });
        }
        // window.google.maps.event.clearInstanceListeners(autocomplete);
        // document.querySelectorAll('.pac-container').forEach((e) => e.remove());
      }
    });
    // eslint-disable-next-line
  }, [window.google, inputId, limit]);

  return (
    <FormElement
      className={`location-input ${className}`}
      id={inputId}
      elementType="input"
      placeholder={placeholder}
      label={label}
      value={query}
      changed={onQueryChanged}
      errors={errors}
    />
  );
}

GooglePlaceAutocomplete.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
  setSearchLocation: PropTypes.func,
  className: PropTypes.string,
  googleId: PropTypes.string,
  inputId: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  limit: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.arrayOf(PropTypes.string),
};

GooglePlaceAutocomplete.defaultProps = {
  location: null,
  setSearchLocation: null,
  className: '',
  googleId: '',
  inputId: '',
  placeholder: '',
  label: '',
  limit: {},
  errors: [],
};
