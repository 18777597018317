import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CountryContext from '../../react-web-ui/contexts/CountryContext';
import { eventImage } from '../../react-web-ui/utilities/images';
import SpecialEventWithPlace from '../../networking/resources/SpecialEventWithPlace';
import EventBlock from '../../react-web-ui/components/EventBlock';
import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';

/**
 * Event Block
 * @param event
 * @param big
 * @param buttons
 * @param handleImgUpload
 * @returns {*}
 * @constructor
 */
export default function EventBlockWrapper({
  event,
  big,
  buttons,
  handleImgUpload,
}) {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);

  return (
    <EventBlock
      big={big}
      eventImage={event.thumb ? (
        <>
          <img
            src={eventImage(event.id, event.thumb, 577, 520)}
            alt={event.place.name}
          />
          <label className="add-img edit-img" htmlFor={`edit-button-${event.id}`}>
            <Edit className="icon" />
            <input
              type="file"
              id={`edit-button-${event.id}`}
              hidden
              onChange={(e) => handleImgUpload(event.id, e)}
            />
          </label>
        </>
      ) : (
        <label className="add-img" htmlFor={`upload-button-${event.id}`}>
          {t('forms:add_photo')}
          <input
            type="file"
            id={`upload-button-${event.id}`}
            hidden
            onChange={(e) => handleImgUpload(event.id, e)}
          />
        </label>
      )}
      eventName={event.name}
      date={moment(event.intervals[0].date).format('DD.MM.YYYY')}
      time={event.intervals[0].start === '00:00:00' ? (
        <>{t('all_day')}</>
      ) : (
        <>{moment(event.intervals[0].start, 'HH:mm:ss').format('HH:mm')}</>
      )}
      restaurantName={event.place.name}
      price={`${t('from')} ${event.startingPrice} ${countryContext.country.currency}`}
      description={event.description}
      sets={event.sets}
      buttons={buttons}
    />
  );
}

EventBlockWrapper.propTypes = {
  event: PropTypes.instanceOf(SpecialEventWithPlace).isRequired,
  big: PropTypes.bool,
  buttons: PropTypes.element,
  handleImgUpload: PropTypes.func,
};

EventBlockWrapper.defaultProps = {
  big: false,
  buttons: null,
  handleImgUpload: null,
};
