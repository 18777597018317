import React from 'react';
import PropTypes from 'prop-types';

/**
 * Restaurant info block
 * @param title
 * @param addToList
 * @param rating
 * @param location
 * @param priceRange
 * @param cuisines
 * @param chefName
 * @param workingHours
 * @param dineoutClub
 * @param button
 * @returns {*}
 * @constructor
 */
export default function RestaurantInfo({
  title,
  addToList,
  rating,
  location,
  priceRange,
  cuisines,
  chefName,
  workingHours,
  dineoutClub,
  button,
}) {
  return (
    <div className="restaurant-info-wrapper grid-col-4">
      {title}
      {addToList}
      {rating}
      <div className="restaurant-info">
        {location}
        {priceRange}
        {cuisines}
        {chefName}
        {workingHours}
        {dineoutClub}
      </div>
      {button}
    </div>
  );
}

RestaurantInfo.propTypes = {
  title: PropTypes.element.isRequired,
  addToList: PropTypes.element,
  rating: PropTypes.element,
  location: PropTypes.element,
  priceRange: PropTypes.element,
  cuisines: PropTypes.element,
  chefName: PropTypes.element,
  workingHours: PropTypes.element,
  dineoutClub: PropTypes.element,
  button: PropTypes.element,
};

RestaurantInfo.defaultProps = {
  addToList: null,
  rating: null,
  location: null,
  priceRange: null,
  cuisines: null,
  chefName: null,
  workingHours: null,
  dineoutClub: null,
  button: null,
};
