import React from 'react';
import PropTypes from 'prop-types';

import noAvatar from '../../assets/images/no-avatar.png';

/**
 * Review item
 * @param className
 * @param avatar
 * @param username
 * @param reviewNumber
 * @param date
 * @param reviewText
 * @param reviewTip
 * @param helpful
 * @param translations
 * @returns {*}
 * @constructor
 */
export default function ReviewItem({
  className,
  avatar,
  username,
  reviewNumber,
  date,
  reviewText,
  reviewTip,
  helpful,
  translations,
}) {
  return (
    <div className={`review-item ${className}`}>
      <header className="review-header">
        <div className="user-info">
          <div className="avatar">
            <img
              src={(avatar !== null) ? avatar : noAvatar}
              alt={username}
              onError={(e) => {
                if (e.target.src !== noAvatar) {
                  e.target.src = noAvatar;
                }
              }}
            />
          </div>
          <div className="text">
            <p>{username}</p>
            {reviewNumber > 0 && <span>{`${reviewNumber} ${reviewNumber > 1 ? 'reviews' : 'review'}`}</span>}
          </div>
        </div>
        <span className="date">{date}</span>
      </header>
      <div className="review-text">
        {translations.length > 0 ? (
          <div className="translated-text">
            {translations[0].text}
            {translations[1].text && <p className="tip">{`Tip: ${translations[1].text}`}</p>}
          </div>
        ) : (
          <>
            <>{reviewText}</>
            {reviewTip && <p className="tip">{`Tip: ${reviewTip}`}</p>}
          </>
        )}
        {helpful}
      </div>
    </div>
  );
}

ReviewItem.propTypes = {
  className: PropTypes.string,
  /** Avatar of the user. */
  avatar: PropTypes.string,
  /** Name of the user. */
  username: PropTypes.string.isRequired,
  /** Number of reviews of the user. */
  reviewNumber: PropTypes.number,
  /** Date of the review. */
  date: PropTypes.string.isRequired,
  /** Review text. */
  reviewText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /** Avatar of the user. */
  reviewTip: PropTypes.string,
  helpful: PropTypes.element,
  translations: PropTypes.oneOfType([PropTypes.array]),
};

ReviewItem.defaultProps = {
  className: '',
  avatar: '',
  reviewNumber: 0,
  reviewTip: '',
  helpful: null,
  translations: [],
};
