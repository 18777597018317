export const initialEventSpecificFields = {
  eventNameEn: '',
  eventNameTranslation: '',
  eventDescriptionEn: '',
  eventDescriptionTranslation: '',
};

export const initialMenuItems = {
  itemId: Math.random().toString(36).substr(2, 9),
  itemNameEn: '',
  itemNameTranslation: '',
  itemDescriptionEn: '',
  itemDescriptionTranslation: '',
};

export const initialInputList = {
  menuId: '',
  menuNameEn: '',
  menuNameTranslation: '',
  menuPriceNoWine: '',
  menuPriceWithWine: '',
  menuItems: [JSON.parse(JSON.stringify(initialMenuItems))],
};
