import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import FormElement from '../../react-web-ui/components/FormElement';
import CountryContext from '../../react-web-ui/contexts/CountryContext';

/**
* Add menu item block
* @param category
* @returns {*}
* @constructor
*/
export default function AddMenuItemBlock({
  locale,
  category,
  categories,
  setCategories,
  disabled,
  setChanged,
  changed,
}) {
  const { t, i18n } = useTranslation();
  const [errors, setErrors] = useState({
    newItemPrice: [],
    newItemNameEn: [],
    newItemDescriptionEn: [],
  });
  const [newItem, setNewItem] = useState({
    newItemPrice: '',
    newItemNameEn: '',
    newItemNameTranslation: '',
    newItemDescriptionEn: '',
    newItemDescriptionTranslation: '',
  });
  const [showTranslate, setShowTranslate] = useState(false);
  const countryContext = useContext(CountryContext);

  const handleNewItemChange = (e) => {
    const currentItem = { ...newItem };
    const currentErrors = { ...errors };
    currentItem[e.target.name] = e.target.value;
    if (e.target.value && e.target.value.trim() !== '') {
      currentErrors[e.target.name] = [];
    }
    setErrors(currentErrors);
    setNewItem(currentItem);
  };

  const handleAddNewItem = (categoryId) => {
    const currentCategories = JSON.parse(JSON.stringify(categories));
    const currentErrors = { ...errors };
    let submitCheck = true;
    for (let i = 0; i < currentCategories.length; i += 1) {
      if (categoryId === currentCategories[i].id) {
        currentCategories[i].items.push({
          id: Math.random().toString(36).substr(2, 9),
          name: newItem.newItemNameEn,
          description: newItem.newItemDescriptionEn,
          price: newItem.newItemPrice,
          translations: {
            [locale]: {
              name: newItem.newItemNameTranslation,
              description: newItem.newItemDescriptionTranslation,
            },
          },
        });
        if (!newItem.newItemPrice || newItem.newItemPrice.trim() === '') {
          currentErrors.newItemPrice = ['empty'];
          submitCheck = false;
        }
        if (!newItem.newItemNameEn || newItem.newItemNameEn.trim() === '') {
          currentErrors.newItemNameEn = [t('forms:error_empty')];
          submitCheck = false;
        }
        if (!newItem.newItemNameTranslation || newItem.newItemNameTranslation.trim() === '') {
          currentErrors.newItemNameTranslation = [t('forms:error_empty')];
          submitCheck = false;
        }
        if (submitCheck) {
          newItem.newItemPrice = '';
          newItem.newItemNameEn = '';
          newItem.newItemNameTranslation = '';
          newItem.newItemDescriptionEn = '';
          newItem.newItemDescriptionTranslation = '';
        }
      }
    }
    setErrors(currentErrors);
    if (submitCheck) {
      setCategories(currentCategories);
    }
  };

  const translateNewItem = () => {
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [newItem.newItemNameEn, newItem.newItemDescriptionEn];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [newItem.newItemNameTranslation, newItem.newItemDescriptionTranslation];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          setNewItem({
            ...newItem,
            newItemNameTranslation: translations[0].text,
            newItemDescriptionTranslation: translations[1]?.text,
          });
        } else {
          setNewItem({
            ...newItem,
            newItemNameEn: translations[0].text,
            newItemDescriptionEn: translations[1]?.text,
          });
        }
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  useEffect(() => {
    if (Object.keys(newItem).length > 0 && Object.values(newItem).some((x) => x !== '')) {
      setChanged(true);
    } else if (!changed) {
      setChanged(false);
    }
  }, [newItem]);

  return (
    <div className={`new-menu-item grid ${showTranslate ? 'show-translate' : ''}`}>
      <div className="grid-col-5 invertible-field">
        <FormElement
          id={`new_menu_item_name_en_${category.id}`}
          name="newItemNameEn"
          elementType="input"
          placeholder={t('forms:enter_course_name_en')}
          value={newItem.newItemNameEn || ''}
          errors={errors.newItemNameEn}
          changed={handleNewItemChange}
          disabled={disabled}
          onFocus={() => setShowTranslate(true)}
          onBlur={() => setShowTranslate(false)}
        />
        <FormElement
          id={`new_menu_item_desc_en_${category.id}`}
          name="newItemDescriptionEn"
          elementType="input"
          placeholder={t('forms:enter_course_description_en')}
          value={newItem.newItemDescriptionEn || ''}
          changed={handleNewItemChange}
          disabled={disabled}
          onFocus={() => setShowTranslate(true)}
          onBlur={() => setShowTranslate(false)}
        />
      </div>
      <div className="grid-col-5 invertible-field">
        <FormElement
          id={`new_menu_item_name_translation_${category.id}`}
          name="newItemNameTranslation"
          elementType="input"
          placeholder={t(`forms:enter_course_name_translation_${countryContext.country.name}`)}
          value={newItem.newItemNameTranslation || ''}
          errors={errors && errors.newItemNameTranslation}
          changed={handleNewItemChange}
          disabled={disabled}
          onFocus={() => setShowTranslate(true)}
          onBlur={() => setShowTranslate(false)}
        />
        <FormElement
          id={`new_menu_item_desc_translation_${category.id}`}
          name="newItemDescriptionTranslation"
          elementType="input"
          placeholder={t(`forms:enter_course_description_translation_${countryContext.country.name}`)}
          value={newItem.newItemDescriptionTranslation || ''}
          changed={handleNewItemChange}
          disabled={disabled}
          onFocus={() => setShowTranslate(true)}
          onBlur={() => setShowTranslate(false)}
        />
      </div>
      <div className="price-col">
        <FormElement
          id={`new_menu_item_price_${category.id}`}
          name="newItemPrice"
          elementType="input"
          inputType="number"
          placeholder={t('price')}
          value={newItem.newItemPrice || ''}
          errors={errors && errors.newItemPrice}
          changed={handleNewItemChange}
          disabled={disabled}
        />
      </div>
      <button
        type="button"
        className="add-item-btn"
        onClick={() => handleAddNewItem(category.id)}
      >
        {t('save')}
      </button>
      <button
        type="button"
        className="translate-fields"
        onClick={() => translateNewItem()}
      >
        {' '}
      </button>
    </div>
  );
}

AddMenuItemBlock.propTypes = {
  category: PropTypes.oneOfType([PropTypes.object]).isRequired,
  categories: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setCategories: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setChanged: PropTypes.func,
  changed: PropTypes.bool,
};

AddMenuItemBlock.defaultProps = {
  disabled: false,
  setChanged: null,
  changed: false,
};
