import City from './City';
import Cuisine from './Cuisine';
import Neighbourhood from './Neighbourhood';
import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import ResourceCollectionPlain from './ResourceCollectionPlain';
import Shift from './Shift';

export default class Place extends Resource {
  static collectionKey = 'places';

  constructor(
    id,
    name,
    subtitle,
    city,
    neighbourhood,
    address,
    description,
    slug,
    cuisines,
    priceRange,
    mealDuration,
    dinnerDuration,
    lat,
    lng,
    rateFood,
    rateService,
    rateAmbience,
    rateCount,
    freeTables,
    isFav,
    images,
    thumb,
    distance,
    prebookDuration,
    chefName,
    chefThumb,
    awardsList,
    isPermanentlyClosed,
    isRecommendedForBusinessMeetings,
    isRecommendedForRomanticDate,
    isRecommendedForWithKids,
    isRecommendedForBigGroups,
    acceptsOnlineBookings,
    contactPhoneNumber,
    contactEmail,
    kidsPlace,
    cardPay,
    wifi,
    parking,
    petFriendly,
    subscription,
    acceptsGiftCards,
    hasOutside,
    shifts,
  ) {
    super(id);
    this._name = name;
    this._subtitle = subtitle;
    this._city = city;
    this._neighbourhood = neighbourhood;
    this._address = address;
    this._description = description;
    this._slug = slug;
    this._cuisines = cuisines;
    this._priceRange = priceRange;
    this._mealDuration = mealDuration;
    this._dinnerDuration = dinnerDuration;
    this._lat = lat;
    this._lng = lng;
    this._rateFood = rateFood;
    this._rateService = rateService;
    this._rateAmbience = rateAmbience;
    this._rateCount = rateCount;
    this._freeTables = freeTables;
    this._isFav = isFav;
    this._images = images;
    this._thumb = thumb;
    this._distance = distance;
    this._prebookDuration = prebookDuration;
    this._chefName = chefName;
    this._chefThumb = chefThumb;
    this._awardsList = awardsList;
    this._isPermanentlyClosed = isPermanentlyClosed;
    this._isRecommendedForBusinessMeetings = isRecommendedForBusinessMeetings;
    this._isRecommendedForRomanticDate = isRecommendedForRomanticDate;
    this._isRecommendedForWithKids = isRecommendedForWithKids;
    this._isRecommendedForBigGroups = isRecommendedForBigGroups;
    this._acceptsOnlineBookings = acceptsOnlineBookings;
    this._contactPhoneNumber = contactPhoneNumber;
    this._contactEmail = contactEmail;
    this._kidsPlace = kidsPlace;
    this._cardPay = cardPay;
    this._wifi = wifi;
    this._parking = parking;
    this._petFriendly = petFriendly;
    this._subscription = subscription;
    this._acceptsGiftCards = acceptsGiftCards;
    this._hasOutside = hasOutside;
    this._shifts = shifts;
  }

  get name() {
    return this._name;
  }

  get id() {
    return this._id;
  }

  get subtitle() {
    return this._subtitle;
  }

  get city() {
    return this._city;
  }

  get neighbourhood() {
    return this._neighbourhood;
  }

  get address() {
    return this._address;
  }

  get description() {
    return this._description;
  }

  get slug() {
    return this._slug;
  }

  get cuisines() {
    return this._cuisines;
  }

  get cuisinesText() {
    return this._cuisines.map((cuisine) => cuisine.name).join(', ');
  }

  get priceRange() {
    return this._priceRange;
  }

  get priceRangeText() {
    return `${this._priceRange.min}-${this._priceRange.max}`;
  }

  get mealDuration() {
    return this._mealDuration;
  }

  get dinnerDuration() {
    return this._dinnerDuration;
  }

  get lat() {
    return this._lat;
  }

  get lng() {
    return this._lng;
  }

  get rateFood() {
    return parseFloat(this._rateFood.toFixed(1));
  }

  get rateService() {
    return parseFloat(this._rateService.toFixed(1));
  }

  get rateAmbience() {
    return parseFloat(this._rateAmbience.toFixed(1));
  }

  get rateCount() {
    return this._rateCount;
  }

  get rateStars() {
    return parseFloat(
      ((this._rateAmbience + this._rateService + this._rateFood) / 3).toFixed(
        1,
      ),
    );
  }

  get freeTables() {
    return this._freeTables;
  }

  get isFav() {
    return this._isFav;
  }

  get images() {
    return this._images;
  }

  get thumb() {
    return this._thumb;
  }

  get distance() {
    return this._distance;
  }

  get prebookDuration() {
    return this._prebookDuration;
  }

  get chefName() {
    return this._chefName;
  }

  get chefThumb() {
    return this._chefThumb;
  }

  get awardsList() {
    return this._awardsList;
  }

  get isPermanentlyClosed() {
    return this._isPermanentlyClosed;
  }

  get isRecommendedForBusinessMeetings() {
    return this._isRecommendedForBusinessMeetings;
  }

  get isRecommendedForRomanticDate() {
    return this._isRecommendedForRomanticDate;
  }

  get isRecommendedForWithKids() {
    return this._isRecommendedForWithKids;
  }

  get isRecommendedForBigGroups() {
    return this._isRecommendedForBigGroups;
  }

  get location() {
    return {
      latitude: this._lat,
      longitude: this._lng,
    };
  }

  get acceptsOnlineBookings() {
    return this._acceptsOnlineBookings;
  }

  get contactPhoneNumber() {
    return this._contactPhoneNumber;
  }

  get contactEmail() {
    return this._contactEmail;
  }

  get kidsPlace() {
    return this._kidsPlace;
  }

  get cardPay() {
    return this._cardPay;
  }

  get wifi() {
    return this._wifi;
  }

  get parking() {
    return this._parking;
  }

  get petFriendly() {
    return this._petFriendly;
  }

  get subscription() {
    return this._subscription;
  }

  get acceptsGiftCards() {
    return this._acceptsGiftCards;
  }

  get hasOutside() {
    return this._hasOutside;
  }

  get shifts() {
    return this._shifts;
  }

  static fromAPI(object) {
    return new Place(
      object.id,
      object.name,
      object.subtitle,
      City.fromAPI(object.city),
      Neighbourhood.fromAPI(object.neighbourhood),
      object.address,
      object.description,
      object.slug,
      new ResourceCollection(Cuisine).make(object),
      object.price_range,
      object.meal_duration,
      object.dinner_duration,
      object.lat,
      object.lng,
      object.rate_food,
      object.rate_service,
      object.rate_ambience,
      object.rate_count,
      object.free_tables,
      object.is_fav,
      object.images,
      object.thumb,
      object.distance,
      object.prebook_duration,
      object.chef_name,
      object.chef_thumb,
      object.awards_list,
      object.is_permanently_closed,
      object.is_recommended_for_business_meetings,
      object.is_recommended_for_romantic_date,
      object.is_recommended_for_with_kids,
      object.is_recommended_for_big_groups,
      object.accepts_online_bookings,
      object.contact_phone_number,
      object.contact_email,
      object.kids_place,
      object.card_pay,
      object.wifi,
      object.parking,
      object.pet_friendly,
      object.subscription,
      object.accepts_gift_cards,
      object.has_outside,
      object.shifts ? (new ResourceCollection(Shift)).make(object) : [],
    );
  }

  static plain(object) {
    return {
      id: object.id,
      name: object.name,
      subtitle: object.subtitle,
      city: City.plain(object.city),
      neighbourhood: Neighbourhood.plain(object.neighbourhood),
      address: object.address,
      description: object.description,
      slug: object.slug,
      cuisines: new ResourceCollectionPlain(Cuisine).make(object),
      cuisinesText: object.cuisines.map((cuisine) => cuisine.name).join(', '),
      priceRange: object.price_range,
      mealDuration: object.meal_duration,
      dinnerDuration: object.dinner_duration,
      lat: object.lat,
      lng: object.lng,
      rateFood: object.rate_food,
      rateService: object.rate_service,
      rateAmbience: object.rate_ambience,
      rateCount: object.rate_count,
      freeTables: object.free_tables,
      isFav: object.is_fav,
      images: object.images,
      thumb: object.thumb,
      distance: object.distance,
      prebookDuration: object.prebook_duration,
      chefName: object.chef_name,
      chefThumb: object.chef_thumb,
      awardsList: object.awards_list,
      isPermanentlyClosed: object.is_permanently_closed,
      isRecommendedForBusinessMeetings:
        object.is_recommended_for_business_meetings,
      isRecommendedForRomanticDate: object.is_recommended_for_romantic_date,
      isRecommendedForWithKids: object.is_recommended_for_with_kids,
      isRecommendedForBigGroups: object.is_recommended_for_big_groups,
      acceptsOnlineBookings: object.accepts_online_bookings,
      contactPhoneNumber: object.contact_phone_number,
      contactEmail: object.contact_email,
      kidsPlace: object.kids_place,
      cardPay: object.card_pay,
      wifi: object.wifi,
      parking: object.parking,
      petFriendly: object.pet_friendly,
      priceRangeText: `${object.price_range.min}-${object.price_range.max}`,
      subscription: object.subscription || null,
      acceptsGiftCards: object.accepts_gift_cards,
      hasOutside: object.has_outside,
      shifts: object.shifts,
    };
  }
}
