/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
export const centerImageInCanvas = (chosenBackground, startingWidth, startingHeight, resize) => {
  let imageWidth = chosenBackground.width;
  let imageHeight = chosenBackground.height;
  const aspectRatio = imageWidth / imageHeight;

  imageHeight = startingHeight;

  if (imageHeight * aspectRatio < startingWidth) {
    imageWidth = startingWidth;
    imageHeight = imageWidth / aspectRatio;
  } else {
    imageWidth = imageHeight * aspectRatio;
  }

  if (chosenBackground.height < startingHeight) {
    imageHeight = startingHeight;
    if (imageHeight * aspectRatio < startingWidth) {
      imageWidth = startingWidth;
      imageHeight = imageWidth / aspectRatio;
    } else {
      imageWidth = imageHeight * aspectRatio;
    }
  } else if (chosenBackground.width < startingWidth) {
    imageWidth = startingWidth;
    imageHeight = imageWidth / aspectRatio;
  }

  if (resize > 1) {
    imageHeight *= resize;
    imageWidth *= resize;
  }

  return {
    imageWidth,
    imageHeight,
  };
};

export const drawRectangle = (context, rectangleX, rectangleY, startingWidth, startingHeight) => {
  context.beginPath();
  context.fillStyle = 'rgba(0, 0, 0, 0.5)';
  context.fillRect(rectangleX, rectangleY, startingWidth, startingHeight);
  context.stroke();
  context.beginPath();
  context.lineWidth = '5';
  context.strokeStyle = '#ffae42';
  context.rect(rectangleX, rectangleY, startingWidth, startingHeight);
  context.stroke();
};

export const drawResizingCircles = (
  context, elementPositionX, elementPositionY, elementWidth, elementHeight,
) => {
  context.fillStyle = '#4082A4';
  context.beginPath();
  context.arc(elementPositionX, elementPositionY, 10, 0, 2 * Math.PI);
  context.fill();
  context.beginPath();
  context.arc(elementPositionX + elementWidth, elementPositionY, 10, 0, 2 * Math.PI);
  context.fill();
  context.beginPath();
  context.arc(
    elementPositionX + elementWidth,
    elementPositionY + elementHeight,
    10,
    0,
    2 * Math.PI,
  );
  context.fill();
  context.beginPath();
  context.arc(elementPositionX, elementPositionY + elementHeight, 10, 0, 2 * Math.PI);
  context.fill();
};

export const resizeElement = (
  element,
  dragTopLeft,
  dragTopRight,
  dragBottomLeft,
  dragBottomRight,
  startingElementSize,
  newPositionX,
  newPositionY,
) => {
  const rightPosition = startingElementSize.x + startingElementSize.width;
  const bottomPosition = startingElementSize.y + startingElementSize.height;
  const aspectRatio = startingElementSize.width / startingElementSize.height;

  if (dragTopLeft || dragBottomLeft) {
    if (newPositionX > rightPosition - 40) {
      element.width = 40;
      element.x = rightPosition - 40;
    } else {
      element.width = rightPosition - newPositionX;
      element.x = newPositionX;
    }
  }

  if (dragTopRight || dragBottomRight) {
    if (newPositionX < startingElementSize.x + 40) {
      element.width = 40;
    } else {
      element.width = Math.abs(startingElementSize.x - newPositionX);
    }
  }

  if (dragTopLeft || dragTopRight) {
    if (newPositionY > bottomPosition - 40) {
      element.height = 40;
      element.y = bottomPosition - 40;
    } else {
      element.height = bottomPosition - newPositionY;
      element.y = newPositionY;
    }
    if (element.type === 'chef' || element.type === 'logo') {
      element.width = element.height * aspectRatio;
    }
  }

  if (dragBottomLeft || dragBottomRight) {
    if (newPositionY < startingElementSize.y + 40) {
      element.height = 40;
    } else {
      element.height = Math.abs(startingElementSize.y - newPositionY);
    }
    if (element.type === 'chef' || element.type === 'logo') {
      element.height = element.width / aspectRatio;
    }
  }
};

export const checkCloseEnough = (p1, p2) => Math.abs(p1 - p2) < 10;

export const checkBounds = (
  canvasPositionX,
  canvasPositionY,
  itemX,
  itemWidth,
  itemY,
  itemHeight,
) => (canvasPositionX >= itemX
    && canvasPositionX <= itemWidth
    && canvasPositionY >= itemY
    && canvasPositionY <= itemHeight);

export const printAtWordWrap = (
  context,
  text,
  x,
  y,
  maxWidth,
  lineHeight,
  textDecoration,
  textAlign,
) => {
  maxWidth = maxWidth || 0;

  let alignCorrection = 0;

  const lines = text.split('\n');

  if (textAlign === 'center') {
    alignCorrection = maxWidth / 2;
  }

  if (textAlign === 'right') {
    alignCorrection = maxWidth;
  }

  context.textAlign = textAlign;
  let currentLine = 0;
  let index = 1;

  for (let i = 0; i < lines.length; i += 1) {
    let words = lines[i].split(' ');
    while (words.length > 0 && index <= words.length) {
      const str = words.slice(0, index).join(' ');
      const { width } = context.measureText(str);
      const lineWidth = context.measureText(words.slice(0, index - 1).join(' ')).width;

      if (width > maxWidth) {
        if (index === 1) {
          index = 2;
        }
        context.fillText(words.slice(0, index - 1).join(' '), x + alignCorrection, y + (lineHeight * currentLine));
        if (textDecoration === 'underline') {
          let startingX = x + alignCorrection;
          if (textAlign === 'center') {
            startingX = x + alignCorrection - (lineWidth / 2);
          }
          if (textAlign === 'right') {
            startingX = x + alignCorrection - lineWidth;
          }
          context.fillRect(startingX, y + (lineHeight * currentLine), lineWidth, 2);
        }
        currentLine += 1;
        words = words.splice(index - 1);
        index = 1;
      } else {
        index += 1;
      }
    }
    if (index > 0) {
      const str = words.slice(0, index).join(' ');
      const { width } = context.measureText(str);

      let startingX = x + alignCorrection;
      context.fillText(words.join(' '), startingX, y + (lineHeight * currentLine));
      if (textDecoration === 'underline') {
        if (textAlign === 'center') {
          startingX = x + alignCorrection - (width / 2);
        }
        if (textAlign === 'right') {
          startingX = x + alignCorrection - width;
        }
        context.fillRect(startingX, y + (lineHeight * currentLine), width, 2);
      }
    }
    currentLine += 1;
  }
};

export const getCurrentIcon = (icon, iconCalendar, iconClock, iconCuisine, iconMap) => {
  let currentIcon = iconCalendar;

  switch (icon) {
    case 'calendar':
      currentIcon = iconCalendar;
      break;
    case 'clock':
      currentIcon = iconClock;
      break;
    case 'cuisine':
      currentIcon = iconCuisine;
      break;
    case 'map':
      currentIcon = iconMap;
      break;
    default:
      currentIcon = iconCalendar;
  }
  return currentIcon;
};

export const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

export const setFilter = (context, filter, intensity) => {
  if (filter === 'blur') {
    context.filter = `blur(${intensity / 10}px)`;
  }
  if (filter === 'brightness') {
    context.filter = `brightness(${intensity / 100})`;
  }
  if (filter === 'contrast') {
    context.filter = `contrast(${intensity * 2}%)`;
  }
  if (filter === 'grayscale') {
    context.filter = `grayscale(${intensity}%)`;
  }
  if (filter === 'hue-rotate') {
    context.filter = `hue-rotate(${intensity * 3.6}deg)`;
  }
  if (filter === 'invert') {
    context.filter = `invert(${intensity}%)`;
  }
  if (filter === 'opacity') {
    context.filter = `opacity(${intensity}%)`;
  }
  if (filter === 'sepia') {
    context.filter = `sepia(${intensity}%)`;
  }
  if (filter === 'saturation') {
    context.filter = `saturate(${intensity * 10}%)`;
  }
};

export const sortElements = (elements) => {
  const shapes = elements.filter((el) => el.type === 'shape');
  const chef = elements.filter((el) => el.type === 'chef');
  const logo = elements.filter((el) => el.type === 'logo');
  const text = elements.filter((el) => el.type === 'text');
  const icon = elements.filter((el) => el.type === 'icon-with-text');

  return [
    ...shapes,
    ...chef,
    ...logo,
    ...text,
    ...icon,
  ];
};

export const randomId = () => `_${Math.random().toString(36).substr(2, 9)}`;

export const updateCanvasElements = (canvasElements, selectedElement, valueToChange, newValue) => {
  const currentCanvasElements = [...canvasElements];
  currentCanvasElements.forEach((element) => {
    if (element.id === selectedElement?.id) {
      element[valueToChange] = newValue;
    }
  });
  return currentCanvasElements;
};
