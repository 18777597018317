import React from 'react';
import PropTypes from 'prop-types';

/**
 * Restaurant info block
 * @param categoryName
 * @param selectedMenu
 * @param menuItems
 * @returns {*}
 * @constructor
 */
export default function MenuCategory({ categoryName, selectedMenu, menuItems }) {
  return (
    <>
      <h3>{categoryName}</h3>
      <div className={`menu-items-wrapper ${selectedMenu === categoryName ? 'show' : ''}`}>
        {menuItems}
      </div>
    </>
  );
}

MenuCategory.propTypes = {
  menuItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedMenu: PropTypes.string,
  categoryName: PropTypes.string,
};

MenuCategory.defaultProps = {
  menuItems: {},
  selectedMenu: '',
  categoryName: '',
};
