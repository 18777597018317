import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    nameEmpty: i18n.t('forms:full_name_error_empty'),
    emailEmpty: i18n.t('forms:email_error_empty'),
    emailInvalid: i18n.t('forms:email_error_invalid'),
    phoneEmpty: i18n.t('forms:phone_error_empty'),
    phoneInvalid: i18n.t('forms:phone_error_invalid'),
    errorEmpty: i18n.t('forms:error_empty'),
    vatInvalid: i18n.t('forms:error_invalid_vat'),
  };

  // First Name
  errors.billing_full_name = [];
  if (!values.billing_full_name || values.billing_full_name.trim() === '') {
    errors.billing_full_name.push(errorTexts.nameEmpty);
  }

  // Phone
  errors.phone = [];
  const telRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

  if (!values.phone || values.phone.trim() === '') {
    errors.phone.push(errorTexts.errorEmpty);
  }

  if (values.phone && !telRegex.test(values.phone)) {
    errors.phone.push(errorTexts.phoneInvalid);
  }

  // Email
  errors.billing_email = [];
  if (!values.billing_email || values.billing_email.trim() === '') {
    errors.billing_email.push(errorTexts.emailEmpty);
  }

  if (!/\S+@\S+\.\S+/.test(values.billing_email)) {
    errors.billing_email.push(errorTexts.emailInvalid);
  }

  // billing_country
  errors.billing_country = [];
  if (!values.billing_country || values.billing_country.trim() === '') {
    errors.billing_country.push(errorTexts.errorEmpty);
  }

  // billing_country
  errors.billing_city = [];
  if (!values.billing_city || values.billing_city.trim() === '') {
    errors.billing_city.push(errorTexts.errorEmpty);
  }

  // billing_line1
  errors.billing_line1 = [];
  if (!values.billing_line1 || values.billing_line1.trim() === '') {
    errors.billing_line1.push(errorTexts.errorEmpty);
  }

  // billing_postal_code
  errors.billing_postal_code = [];
  if (!values.billing_postal_code || values.billing_postal_code.trim() === '') {
    errors.billing_postal_code.push(errorTexts.errorEmpty);
  }
  // vat
  errors.billing_vat = [];
  if (!values.billing_vat || values.billing_vat.trim() === '') {
    errors.billing_vat.push(errorTexts.errorEmpty);
  } else if (values.billing_vat === 'invalid') {
    errors.billing_vat.push(errorTexts.vatInvalid);
  }

  return errors;
};

export default validate;
