import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';

/**
* EmployeeFooter
* @returns {*}
* @constructor
*/
export default function EmployeeFooter({
  employee,
  itemsEditing,
  index,
  showSuccess,
  setShowSuccess,
}) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(true);
  const userContext = useContext(UserContext);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const currentSuccess = [...showSuccess];

  useEffect(() => {
    setStatus(employee.isActive);
  }, [employee]);

  const changeSuccess = (bool) => {
    currentSuccess.forEach((current, i) => {
      if (index === i) {
        currentSuccess[i] = bool;
      } else {
        currentSuccess[i] = false;
      }
    });
    setShowSuccess(currentSuccess);
  };

  const handleStatusChange = () => {
    const newStatus = !status;
    setLoadingStatus(true);
    const editStatusParams = {
      user_id: userContext.user.id,
      host_id: employee.id,
    };

    const endpoint = newStatus ? 'activate' : 'deactivate';

    (new RequestService(`manager/hosts/${endpoint}`))
      .setParams(editStatusParams)
      .send()
      .then(() => {
        changeSuccess(true);
        setStatus(newStatus);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t(`apiErrors:could_not_${endpoint}_host`));
      })
      .finally(() => setLoadingStatus(false));
  };

  return (
    <>
      <p>
        <strong>{t('pageEmployees:last_login')}</strong>
        {employee.lastLoginAt ? (
          moment.unix(employee.lastLoginAt).format('YYYY-MM-DD')
        ) : (
          t('never')
        )}
      </p>
      {!itemsEditing[index] && (
        <button
          type="button"
          className={`toggle-status ${status ? 'active' : ''}`}
          onClick={handleStatusChange}
          disabled={loadingStatus}
        >
          <span className="seating-option">{t('pageEmployees:inactive')}</span>
          <span className="seating-option">{t('pageEmployees:active')}</span>
        </button>
      )}
    </>
  );
}

EmployeeFooter.propTypes = {
  employee: PropTypes.oneOfType([PropTypes.object]).isRequired,
  itemsEditing: PropTypes.oneOfType([PropTypes.array]).isRequired,
  index: PropTypes.number.isRequired,
  setShowSuccess: PropTypes.func.isRequired,
  showSuccess: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
