import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import WindowContext from '../../../react-web-ui/contexts/WindowContext';
import MenuItem from '../../../react-web-ui/components/RestaurantMenu/MenuItem';
import RestaurantMenu from '../../../react-web-ui/components/RestaurantMenu';
import MenuCategory from '../../../react-web-ui/components/RestaurantMenu/MenuCategory';
import FormElement from '../../../react-web-ui/components/FormElement';
import { ReactComponent as External } from '../../../assets/images/icons/External.svg';

export default function RestaurantMenuPlaceholder() {
  const { t } = useTranslation();
  const { isDesktop } = useContext(WindowContext);

  return (
    <div className="restaurant-menu restaurant-menu-placeholder">
      <RestaurantMenu
        categorySelect={(
          <>
            {!isDesktop && (
              <FormElement
                id="menu-select"
                elementType="select"
                placeholder="Choose one"
                value="Salads"
                changed={() => {}}
                selectOptions={[
                  {
                    value: 'Salads',
                    text: t('pageMenu:placeholder_category_salad'),
                  },
                  {
                    value: 'Starters',
                    text: t('pageMenu:placeholder_category_starters'),
                  },
                ]}
              />
            )}
          </>
        )}
        menu={(
          <div id="menu-block" className="grid">
            <div className="grid-col-5">
              <MenuCategory
                categoryName={t('pageMenu:placeholder_category_salad')}
                selectedMenu="Salads"
                menuItems={(
                  <>
                    <MenuItem
                      name={t('pageMenu:placeholder_salad_1')}
                      description={t('pageMenu:placeholder_salad_1_desc')}
                      price={5}
                    />
                    <MenuItem
                      name={t('pageMenu:placeholder_salad_2')}
                      description={t('pageMenu:placeholder_salad_2_desc')}
                      price={6}
                    />
                  </>
                )}
              />
            </div>
            <div className="grid-col-5">
              <MenuCategory
                categoryName={t('pageMenu:placeholder_category_starters')}
                menuItems={(
                  <>
                    <MenuItem
                      name={t('pageMenu:placeholder_starter_1')}
                      price={6}
                    />
                    <MenuItem
                      name={t('pageMenu:placeholder_starter_2')}
                      price={10}
                    />
                  </>
                )}
              />
            </div>
          </div>
        )}
        button={(
          <Link to="/manage/menu" className="external-link text-accent">
            <External className="icon" />
            {t('edit')}
          </Link>
        )}
      />
    </div>
  );
}
