import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import useForm from '../../hooks/useForm';
import validate from './validation';
import FormElement from '../FormElement';
import Button from '../Button';
import Checkbox from '../FormElement/Checkbox';
import ForgottenPasswordForm from '../ForgottenPasswordForm';
import RequestService from '../../../networking/RequestService';
import UserContext from '../../contexts/UserContext';
import Spinner from '../Spinner';

/**
 * Login Form
 * @param endpoint
 * @param type
 * @returns {*}
 * @constructor
 */
export default function Login({ endpoint, type }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorText, setErrorText] = useState(false);
  const [forgottenPass, setForgottenPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberChecked, setRememberChecked] = useState(false);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  const userContext = useContext(UserContext);

  const handleLogin = (data) => {
    userContext.setUser(data);
    userContext.setUserId(data.id);
    userContext.setAuthPopup(false);
    history.push('/');
    toast.success(t('success:login_success'));
  };

  function submit() {
    setLoading(true);
    (new RequestService(endpoint))
      .setParams({
        email: values.email,
        password: values.password,
        password_v2: true,
      })
      .send()
      .then((response) => {
        if (type === '') {
          handleLogin(response.data);
        }
        if (type === 'manager') {
          if (response.data.privileges.includes('manager')
            || response.data.privileges.includes('admin_manager')) {
            handleLogin(response.data);
          } else {
            toast.error(t('forms:no_privileges'));
          }
        }
      })
      .catch(() => {
        setErrorText(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <form action="" noValidate>
      {errorText && (
        <p className="error">{t('forms:wrong_email_pass')}</p>
      )}
      <FormElement
        id="email"
        elementType="input"
        inputType="email"
        placeholder={t('forms:email')}
        value={values.email || ''}
        errors={errors.email}
        changed={handleChange}
      />
      <FormElement
        id="password"
        elementType="input"
        inputType="password"
        placeholder={t('forms:password')}
        value={values.password || ''}
        errors={errors.password}
        changed={handleChange}
      />
      <Checkbox
        id="remember"
        text={t('forms:remember')}
        checked={rememberChecked}
        check={() => setRememberChecked(!rememberChecked)}
      />
      <Button
        text={loading ? (
          <>
            <Spinner />
            {t('loading')}
          </>
        ) : t('forms:login')}
        classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
        disabled={loading}
        onClick={handleSubmit}
      />
      <button
        type="button"
        className="btn-link forgotten-pass-btn"
        onClick={() => setForgottenPass(!forgottenPass)}
      >
        {t('forms:forgotten_password')}
      </button>
      {forgottenPass && (
        <ForgottenPasswordForm endpoint="client/users/forgotten" />
      )}
    </form>
  );
}

Login.propTypes = {
  endpoint: PropTypes.string,
  type: PropTypes.string,
};

Login.defaultProps = {
  endpoint: '',
  type: '',
};
