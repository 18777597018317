import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import RequestService from '../../networking/RequestService';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import WindowContext from '../../react-web-ui/contexts/WindowContext';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';
import Spinner from '../../react-web-ui/components/Spinner';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import Tooltip from '../../react-web-ui/components/Tooltip';

export default function Shifts() {
  const { t, i18n } = useTranslation();
  const { isDesktop } = useContext(WindowContext);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [prebookTime, setPrebookTime] = useState();
  const [durationTime, setDurationTime] = useState();
  const [days, setDays] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonStyles, setButtonStyles] = useState({});
  const {
    startListening,
    endListening,
  } = useBeforeUnload();
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'Shifts are the backbone of your restaurant’s operations.',
      disableBeacon: true,
      placement: 'center',
    },
    {
      target: '.fc-day .fc-timegrid-col-frame',
      content: 'Pick the time slot when your restaurant opens and select it in the grid.',
    },
    {
      target: '.fc-timegrid-event-harness',
      content: 'You can now extend it to the desired end time of the shift. You can copy it to other days of the week by dragging and dropping it.',
    },
    {
      target: '.tour-shift-type',
      content: 'Now enter your shift name',
    },
    {
      target: '.tour-time',
      content: 'You can select a prebook time for every different shift this is the minimum time required before a reservation. You can also select individual meal durations for different shifts - this shows how long a booking will be.',
    },
    {
      target: '.tour-shifts',
      content: 'Make sure to cover the whole working hours for your restaurant. And end your last shift at the time you accept last bookings.',
    },
  ];

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('shifts-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (index === 1 && events.length === 0) {
      setButtonStyles({
        pointerEvents: 'none',
        backgroundColor: 'gray',
      });
    }

    if (index === 2 && events.length > 0) {
      setSelectedEvent({
        id: events[0].id,
        start: events[0].start,
        end: events[0].end,
      });
    }

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('shifts-tour-status', 1);
    }
  };

  const editEventTimes = (event) => {
    const newEvents = [...events];
    const startTime = moment(event.start).format('HH:mm');
    let endTime = moment(event.end).format('HH');
    const endMinutes = moment(event.end).format('mm');
    if (moment(event.end).isSame(moment(event.start).add(1, 'day'), 'day')) {
      endTime = 24 + parseInt(moment(event.end).format('HH'), 10);
    }
    for (let i = 0; i < newEvents.length; i += 1) {
      if (newEvents[i].id === event.id) {
        newEvents[i].startTime = startTime;
        newEvents[i].endTime = `${endTime}:${endMinutes}`;
      }
    }
    return newEvents;
  };

  const addNewEvent = (
    start,
    end,
    title,
    prebook = null,
    prebookDay = 0,
    mealDuration = moment().startOf('day').add(2, 'hours'),
  ) => {
    let newEnd = moment(end).format('HH');
    const endMinutes = moment(end).format('mm');
    if (moment(end).isSame(moment(start).add(1, 'day'), 'day')) {
      newEnd = 24 + parseInt(moment(end).format('HH'), 10);
    }

    const weekDay = moment(start).weekday() + 1;
    const newId = Math.random().toString(36).substr(2, 9);
    setSelectedEvent({
      id: newId,
      start,
      end,
    });
    setEvents([
      ...events,
      {
        id: newId,
        title,
        startTime: moment(start).format('HH:mm'),
        endTime: `${newEnd}:${endMinutes}`,
        allDay: false,
        daysOfWeek: [weekDay === 7 ? 0 : weekDay],
        prebook,
        prebookDay,
        mealDuration,
      },
    ]);
    setSelectedValue(title);
    setPrebookTime(prebook);
    setDays(prebookDay);
    setDurationTime(mealDuration);
  };

  moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (!restaurantContext.restaurant) return;
    const convertShifts = [];
    restaurantContext.restaurant.shifts.forEach((shift) => {
      const endHour = moment(shift.endTime, 'HH:mm').format('HH');
      const endMinutes = moment(shift.endTime, 'HH:mm').format('mm');
      let newEnd = endHour;
      if (shift.end < shift.start || shift.end >= 86400) {
        newEnd = 24 + parseInt(endHour, 10);
      }
      if (shift.day < 7) {
        let day = 0;
        let time = shift.prebook;
        if (shift.prebook >= 172800) {
          day = 172800;
          time = shift.prebook - 172800;
        } else if (shift.prebook >= 86400) {
          day = 86400;
          time = shift.prebook - 86400;
        }
        convertShifts.push({
          id: Math.random().toString(36).substr(2, 9),
          daysOfWeek: [shift.day],
          startTime: shift.startTime,
          endTime: `${newEnd}:${endMinutes}`,
          title: shift.name,
          prebook: moment.unix(time).utc(),
          prebookDay: day,
          mealDuration: moment.unix(shift.mealDuration).utc(),
        });
      }
    });
    setEvents(convertShifts);
  }, [t, restaurantContext.restaurant]);

  const handleSaveShifts = () => {
    setLoading(true);
    startListening();
    const shifts = [];
    events.forEach((event) => {
      const endSeconds = moment.duration(event.endTime).asSeconds();
      const startSeconds = moment.duration(event.startTime).asSeconds();
      if (event.daysOfWeek[0] < 7 && endSeconds > startSeconds && endSeconds - startSeconds > 0) {
        shifts.push({
          day: event.daysOfWeek[0],
          start: startSeconds,
          end: endSeconds,
          shift: event.title,
          prebook: moment.duration(moment(event.prebook).format('HH:mm')).asSeconds() + parseInt(event.prebookDay, 10),
          meal_duration: moment.duration(moment(event.mealDuration).format('HH:mm')).asSeconds(),
        });
      }
    });
    (new RequestService('manager/places/shifts'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
        shifts,
      })
      .send()
      .then(() => {
        toast.success(t('forms:success'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_shifts'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  const showDetails = (event) => {
    setSelectedEvent({
      id: event.id,
      start: event.start,
      end: event.end,
    });
    for (let i = 0; i < events.length; i += 1) {
      if (events[i].id === event.id) {
        setPrebookTime(events[i].prebook);
        setDays(events[i].prebookDay);
        setDurationTime(events[i].mealDuration);
      }
    }
    setSelectedValue(event.title);
  };

  function generateOptions(excludedOption) {
    const arr = [];
    const allMinutes = [0, 15, 30, 45];
    for (let i = 0; i < allMinutes.length; i += 1) {
      if (allMinutes[i] >= excludedOption) {
        arr.push(excludedOption);
      }
    }
    return arr;
  }

  function disabledMinutes(h) {
    if (h === 0) { return generateOptions(0); }
    return generateOptions(60);
  }

  return (
    <div className="inner-wrapper">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        hideCloseButton
        hideBackButton
        disableOverlayClose
        disableCloseOnEsc
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
          buttonNext: buttonStyles,
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <div className="sticky-buttons">
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('save')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSaveShifts}
        />
      </div>
      <h1 className="section-title underlined">
        <strong>{t('pageShifts:title')}</strong>
      </h1>
      <div className="tour-shifts column-layout clear-both">
        <div className="main-column shifts-calendar-wrapper">
          <FullCalendar
            height="auto"
            locale={i18n.language}
            firstDay={1}
            events={events}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin]}
            initialView="timeGridWeek"
            headerToolbar={false}
            allDaySlot={false}
            slotMinTime="07:00:00"
            slotMaxTime="31:00:00"
            slotLabelFormat="HH:mm"
            eventTimeFormat="HH:mm"
            dayHeaderFormat={{
              weekday: isDesktop ? 'long' : 'short',
            }}
            editable
            selectable
            select={({ start, end }) => {
              addNewEvent(start, end, t('pageShifts:shift_name'));
              setButtonStyles({});
            }}
            selectOverlap={false}
            selectAllow={({ start, end }) => {
              const startHour = parseInt(moment(start).format('HH'), 10);
              const endHour = parseInt(moment(end).format('HH'), 10);
              if (moment(end).isSame(moment(start).add(1, 'day'), 'day')
                && endHour <= startHour
                && endHour <= 7) return true;
              if (startHour < 7 && endHour > 7) return false;
              return moment(start).isSame(moment(end), 'day');
            }}
            eventResizableFromStart
            eventOverlap={false}
            eventResize={({ event }) => {
              setEvents(editEventTimes(event));
              showDetails(event);
            }}
            eventResizeStart={({ event }) => showDetails(event)}
            eventDrop={({ event, oldEvent }) => {
              if (moment(event.start).weekday() === moment(oldEvent.start).weekday()) {
                setEvents(editEventTimes(event));
              } else {
                const newEvents = [...events];
                let prebook = null;
                let prebookDay = 0;
                let mealDuration = 7200;
                for (let i = 0; i < newEvents.length; i += 1) {
                  if (newEvents[i].id === event.id) {
                    prebook = newEvents[i].prebook
                      ? moment(newEvents[i].prebook)
                      : null;
                    mealDuration = newEvents[i].mealDuration
                      ? moment(newEvents[i].mealDuration)
                      : 7200;
                    prebookDay = newEvents[i].prebookDay;
                  }
                }
                addNewEvent(
                  event.start,
                  event.end,
                  oldEvent.title,
                  prebook,
                  prebookDay,
                  mealDuration,
                );
              }
            }}
            eventContent={(arg) => (
              <>
                <p>{arg.timeText}</p>
                <p>{arg.event.title}</p>
                <button
                  type="button"
                  className="delete-shift"
                  onClick={() => {
                    const newEvents = [...events];
                    const filteredEvents = newEvents.filter(
                      (event) => event.id !== arg.event.id,
                    );
                    setEvents(filteredEvents);
                    setSelectedEvent(null);
                    setSelectedValue('');
                  }}
                >
                  -
                </button>
              </>
            )}
            eventClick={({ event }) => showDetails(event)}
          />
        </div>
        <div className="right-column">
          <div className="shift-details">
            <h2 className="section-sub-title">
              {t('pageShifts:shift_details')}
              {selectedEvent && ` - 
                ${moment(selectedEvent.start).format('dddd')}
                ${moment(selectedEvent.start).format('HH:mm')} - 
                ${moment(selectedEvent.end).format('HH:mm')}
              `}
            </h2>
            {selectedEvent && (
              <>
                <div className="shift-details-content">
                  <div className="tour-shift-type">
                    <FormElement
                      id="shift-type"
                      elementType="input"
                      placeholder={t('pageShifts:shift_name')}
                      label={t('pageShifts:shift_name')}
                      value={selectedValue}
                      errors={!selectedValue ? [t('forms:error_empty')] : []}
                      changed={(event) => {
                        setSelectedValue(event.target.value);
                        const newEvents = [...events];
                        for (let i = 0; i < newEvents.length; i += 1) {
                          if (newEvents[i].id === selectedEvent.id) {
                            newEvents[i].title = event.target.value;
                          }
                        }
                        setEvents(newEvents);
                      }}
                    />
                  </div>
                  <div className="tour-time">
                    <div className="form-group">
                      <label htmlFor="prebook-time">{t('pageShifts:prebook_time')}</label>
                      <div className="day-and-time">
                        <FormElement
                          id="menu-select"
                          elementType="select"
                          placeholder="Choose one"
                          label={t('pageSpecialOffers:days')}
                          value={days}
                          changed={(e) => {
                            const newEvents = [...events];
                            for (let i = 0; i < newEvents.length; i += 1) {
                              if (newEvents[i].id === selectedEvent.id) {
                                newEvents[i].prebookDay = e.target.value;
                              }
                            }
                            setEvents(newEvents);
                            setDays(e.target.value);
                          }}
                          selectOptions={[
                            {
                              value: 0,
                              text: '0',
                            },
                            {
                              value: 86400, // seconds
                              text: '1',
                            },
                            {
                              value: 172800, // seconds
                              text: '2',
                            },
                          ]}
                        />
                        <div className="form-group">
                          <label htmlFor="time">{t('pageShifts:hours')}</label>
                          <TimePicker
                            placeholder={t('pageShifts:prebook_time')}
                            showSecond={false}
                            minuteStep={15}
                            popupClassName="time-picker-custom"
                            defaultOpenValue={moment().startOf('day')}
                            value={prebookTime}
                            onChange={(value) => {
                              const newEvents = [...events];
                              for (let i = 0; i < newEvents.length; i += 1) {
                                if (newEvents[i].id === selectedEvent.id) {
                                  newEvents[i].prebook = value;
                                }
                              }
                              setEvents(newEvents);
                              setPrebookTime(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="meal-time">{t('pageShifts:meal_duration_time')}</label>
                      <TimePicker
                        placeholder={t('pageShifts:meal_duration_time')}
                        showSecond={false}
                        minuteStep={15}
                        popupClassName="time-picker-custom"
                        defaultOpenValue={moment().startOf('day')}
                        value={durationTime}
                        allowEmpty={false}
                        disabledMinutes={disabledMinutes}
                        onChange={(value) => {
                          const newEvents = [...events];
                          for (let i = 0; i < newEvents.length; i += 1) {
                            if (newEvents[i].id === selectedEvent.id) {
                              if (value.format('H') === '0' && value.format('mm') === '00') {
                                newEvents[i].mealDuration = value.add(15, 'minutes');
                              } else {
                                newEvents[i].mealDuration = value;
                              }
                            }
                          }
                          setEvents(newEvents);
                          setDurationTime(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="center-buttons">
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('save')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSaveShifts}
        />
      </div>
    </div>
  );
}
