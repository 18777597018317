import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import RequestService from '../../networking/RequestService';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import FormElement from '../../react-web-ui/components/FormElement';
import useForm from '../../react-web-ui/hooks/useForm';
import Button from '../../react-web-ui/components/Button';
import Spinner from '../../react-web-ui/components/Spinner';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import validate from './validation';
import LocationInput from '../LocationInput';
import useExitPrompt from '../../hooks/useExitPrompt';

export default function ReviewsLinks({
  loading,
  setLoading,
  initialValues,
}) {
  const { t } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [location, setSearchLocation] = useState({});
  const [changed, setChanged] = useExitPrompt(false);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  const {
    handleChange,
    handleSubmit,
    errors,
    values,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
    initialValues,
  );

  function submit() {
    const updateParams = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      facebook_page_link: values.facebook_link,
      tripadvisor_page_link: values.tripadvisor_link,
      google_id: location.placeId,
    };

    setLoading(true);
    startListening();
    (new RequestService('manager/places/change'))
      .setParams(updateParams)
      .send()
      .then((response) => {
        restaurantContext.setRestaurant(response.data);
        toast.success(t('success:review_links_changed'));
        setChanged(false);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_update_links'));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  }

  useEffect(() => {
    if (!values || !initialValues) return;
    if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [values, initialValues]);

  useEffect(() => () => {
    setChanged(false);
  }, []);

  return (
    <>
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      <div className="action-buttons">
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('save')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSubmit}
        />
      </div>
      <h1 className="section-title underlined">
        <strong>{t('pageReviews:title')}</strong>
      </h1>
      <div className="tour-links grid grid-6">
        <LocationInput
          className="grid-col-2"
          location={location}
          setSearchLocation={setSearchLocation}
          googleId={restaurantContext.restaurant?.googleId}
          inputId="google-place-autocomplete"
          placeholder={t('forms:restaurant_name')}
        />
        <FormElement
          className="grid-col-2"
          id="facebook_link"
          elementType="input"
          placeholder={t('pageEmailBranding:provide_facebook_link')}
          value={values.facebook_link || ''}
          errors={errors.facebook_link}
          changed={handleChange}
        />
        <FormElement
          className="grid-col-2"
          id="tripadvisor_link"
          elementType="input"
          placeholder={t('pageEmailBranding:provide_tripadvisor_link')}
          value={values.tripadvisor_link || ''}
          errors={errors.tripadvisor_link}
          changed={handleChange}
        />
      </div>
    </>
  );
}

ReviewsLinks.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
};

ReviewsLinks.defaultProps = {
  loading: false,
  setLoading: null,
  initialValues: {},
};
