import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    checkTerms: i18n.t('forms:check_terms_error'),
    errorEmpty: i18n.t('forms:error_empty'),
    vatInvalid: i18n.t('forms:error_invalid_vat'),
  };

  // Terms
  errors.accept_terms = [];

  if (!values.accept_terms) {
    errors.accept_terms.push(errorTexts.checkTerms);
  }

  // billing_country
  errors.beneficiary = [];
  if (!values.beneficiary || values.beneficiary.trim() === '') {
    errors.beneficiary.push(errorTexts.errorEmpty);
  }

  // iban
  errors.iban = [];
  if (!values.iban || values.iban.trim() === '') {
    errors.iban.push(errorTexts.errorEmpty);
  }

  // swift
  errors.swift = [];
  if (!values.swift || values.swift.trim() === '') {
    errors.swift.push(errorTexts.errorEmpty);
  }

  // billing_country
  errors.billing_company_name = [];
  if (!values.billing_company_name || values.billing_company_name.trim() === '') {
    errors.billing_company_name.push(errorTexts.errorEmpty);
  }
  // billing_country
  errors.billing_country = [];
  if (!values.billing_country || values.billing_country.trim() === '') {
    errors.billing_country.push(errorTexts.errorEmpty);
  }

  // billing_country
  errors.billing_city = [];
  if (!values.billing_city || values.billing_city.trim() === '') {
    errors.billing_city.push(errorTexts.errorEmpty);
  }

  // billing_line1
  errors.billing_line1 = [];
  if (!values.billing_line1 || values.billing_line1.trim() === '') {
    errors.billing_line1.push(errorTexts.errorEmpty);
  }
  // billing_line1
  errors.billing_line2 = [];
  if (!values.billing_line2 || values.billing_line2.trim() === '') {
    errors.billing_line2.push(errorTexts.errorEmpty);
  }

  // vat
  errors.billing_vat = [];
  if (!values.billing_vat || values.billing_vat.trim() === '') {
    errors.billing_vat.push(errorTexts.errorEmpty);
  } else if (values.billing_vat === 'invalid') {
    errors.billing_vat.push(errorTexts.vatInvalid);
  }

  return errors;
};

export default validate;
