import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ElementsContext from '../../../contexts/ElementsContext';
import {
  initialShapeOptions,
} from '../../../utilities/constants';
import { randomId, sortElements, updateCanvasElements } from '../helper';
import { ReactComponent as Delete } from '../../../assets/images/icons/Delete.svg';
import Checkbox from '../../../react-web-ui/components/FormElement/Checkbox';

export default function ShapeElement() {
  const { t } = useTranslation();
  const {
    canvasElements,
    setCanvasElements,
    selectedElement,
    setSelectedElement,
  } = useContext(ElementsContext);

  return (
    <>
      <div className="column-top">
        <h2>{t('pagePostCreator:shape')}</h2>
        <button
          type="button"
          onClick={() => {
            const id = randomId();
            const newElement = {
              ...initialShapeOptions,
              id,
            };
            const sortedElements = sortElements([
              ...canvasElements,
              { ...newElement },
            ]);
            setCanvasElements(sortedElements);
            setSelectedElement(sortedElements.find((element) => element.id === id));
          }}
          className="btn btn-accent btn-add-element"
        >
          {t('pagePostCreator:add_element')}
        </button>
      </div>
      {selectedElement && (
        <div className="details-form">
          <div className="color-form-group">
            <span className="label">{t('pagePostCreator:background_color')}</span>
            <div className="input-wrapper">
              <input
                type="color"
                id="shape-color"
                name="shape-color"
                value={selectedElement.backgroundColor}
                onChange={(e) => {
                  setCanvasElements(updateCanvasElements(
                    canvasElements,
                    selectedElement,
                    'backgroundColor',
                    e.target.value,
                  ));
                }}
              />
              <Checkbox
                id="shape-blur"
                text={t('pagePostCreator:blur')}
                value={`${selectedElement.blur}`}
                checked={selectedElement.blur}
                check={() => {
                  setCanvasElements(updateCanvasElements(
                    canvasElements,
                    selectedElement,
                    'blur',
                    !selectedElement.blur,
                  ));
                }}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              const currentCanvasElements = canvasElements.filter(
                (element) => (element.id !== selectedElement.id),
              );
              let lastIndex = null;
              currentCanvasElements.forEach((element, index) => {
                if (element.type === 'shape') {
                  lastIndex = index;
                }
              });
              setSelectedElement(currentCanvasElements[lastIndex]);
              setCanvasElements(currentCanvasElements);
            }}
            className="btn-link btn-delete-element"
          >
            <Delete className="icon" />
            {t('pagePostCreator:remove_shape_element')}
          </button>
        </div>
      )}
    </>
  );
}
