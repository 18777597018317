import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Canvas from '../../components/PostCreatorComponents/Canvas';
import BackgroundElement from '../../components/PostCreatorComponents/BackgroundElement';
import CanvasElementTypes from '../../components/PostCreatorComponents/CanvasElementTypes';
import { postSizes } from '../../utilities/constants';
import ShapeElement from '../../components/PostCreatorComponents/ShapeElement';
import TextElement from '../../components/PostCreatorComponents/TextElement';
import IconTextElement from '../../components/PostCreatorComponents/IconTextElement';
import ImageElement from '../../components/PostCreatorComponents/ImageElement';
import ElementsContext from '../../contexts/ElementsContext';
import WindowContext from '../../react-web-ui/contexts/WindowContext';
import { ReactComponent as Reposition } from '../../assets/images/icons/Reposition.svg';

export default function PostCreatorPreview() {
  const { t } = useTranslation();
  const { isDesktop } = useContext(WindowContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const postType = query.get('postType');
  const [repositioning, setRepositioning] = useState(false);
  const [elementType, setElementType] = useState('background');
  const [background, setBackground] = useState('');
  const [canvasElements, setCanvasElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [intensity, setIntensity] = useState(50);
  const [filter, setFilter] = useState('none');
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (document.body.style.textAlignLast === undefined) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/context-filter-polyfill@0.2.4/dist/index.min.js';
      script.async = true;
      script.id = 'context-polyfill';
      document.body.appendChild(script);
    }
    return () => {
      if (document.getElementById('context-polyfill')) {
        document.body.removeChild(document.getElementById('context-polyfill'));
        window.location.reload(false);
      }
    };
  }, []);

  useEffect(() => {
    if (canvasElements.length === 0 || selectedElement === null || typeof selectedElement === 'undefined') return;

    setElementType(selectedElement.type);
  }, [selectedElement, canvasElements]);

  return postType ? (
    <ElementsContext.Provider
      value={{
        canvasElements,
        setCanvasElements,
        selectedElement,
        setSelectedElement,
        elementType,
        setElementType,
      }}
    >
      <div className="inner-wrapper">
        {isDesktop ? (
          <>
            <div className="post-creator-form">
              {!repositioning && (
                <div className="left-post-creator-form">
                  <h2 className="column-top">{t('pagePostCreator:elements')}</h2>
                  <div className="preload-font-1">.</div>
                  <div className="preload-font-2">.</div>
                  <div className="preload-font-3">.</div>
                  <CanvasElementTypes />
                </div>
              )}
              <div className={`center-post-creator-form ${repositioning ? 'full-screen' : ''}`}>
                <div className="column-top">
                  <h2>{t('pagePostCreator:post_preview')}</h2>
                  {background && (
                    <button
                      type="button"
                      onClick={() => {
                        setRepositioning(!repositioning);
                        setSelectedElement(null);
                      }}
                      className="btn-link btn-reposition"
                    >
                      {repositioning ? t('pagePostCreator:done') : (
                        <>
                          <Reposition className="icon" />
                          {t('pagePostCreator:reposition_and_scale')}
                        </>
                      )}
                    </button>
                  )}
                </div>
                <Canvas
                  repositioning={repositioning}
                  background={background}
                  width={postSizes[postType].width}
                  height={postSizes[postType].height}
                  intensity={intensity}
                  filter={filter}
                  focus={focus}
                  setFocus={setFocus}
                  onDoubleClick={(e) => {
                    if (repositioning) return;
                    if (e > 0 && selectedElement) {
                      if (selectedElement.type === 'text' || selectedElement.type === 'icon-with-text') {
                        setFocus(Math.random());
                      } else setFocus(false);
                    }
                  }}
                />
              </div>
              {!repositioning && (
                <div className="right-post-creator-form">
                  {elementType === 'background' && (
                    <BackgroundElement
                      background={background}
                      setBackground={setBackground}
                      intensity={intensity}
                      setIntensity={setIntensity}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}
                  {elementType === 'text' && (
                    <TextElement setFocus={setFocus} />
                  )}
                  {elementType === 'icon-with-text' && (
                    <IconTextElement />
                  )}
                  {elementType === 'shape' && (
                    <ShapeElement />
                  )}
                  {(elementType === 'chef' || elementType === 'logo') && (
                    <ImageElement type={elementType} />
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <h2>{t('pagePostCreator:mobile_message')}</h2>
        )}
      </div>
    </ElementsContext.Provider>
  ) : null;
}
