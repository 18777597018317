import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import useForm from '../../react-web-ui/hooks/useForm';
import validate from './validation-phone';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';
import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import Spinner from '../../react-web-ui/components/Spinner';
import CustomPhoneInput from '../../components/CustomPhoneInput';

export default function VerifyPhone() {
  const { t } = useTranslation();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [codeSent, setCodeSent] = useState(false);

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    const params = {
      phone: values.phone,
    };

    setCodeSent(true);

    setLoading(true);
    (new RequestService('users/verification/phone/send-code'))
      .setParams(params)
      .send()
      .then(() => {
        toast.success(t('forms:code_has_been_sent'));
      })
      .catch(() => null)
      .finally(() => {
        setLoading(false);
      });
  }

  const confirmCode = () => {
    const params = {
      phone: values.phone,
      code: values.verify_code,
      user_id: userContext.user.id,
    };

    setLoading(true);
    (new RequestService('users/verification/phone/verify-code'))
      .setParams(params)
      .send()
      .then(() => {
        toast.success(t('forms:your_phone_has_been_verified'));
        userContext.user._phone = values.phone;
        userContext.user._phoneVerifiedAt = true;
        userContext.setUser(userContext.user);
        history.push('/restaurants-listing');
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!userContext.user) return;
    let { phone } = userContext.user;
    if (!userContext.user.phone.includes('+')) {
      phone = `+${phone}`;
    }

    setValues({
      phone,
    });
  }, [userContext.user]);

  useEffect(() => {
    if (!values.phone || codeSent) return;
    const params = {
      phone: values.phone,
    };
    setLoading(true);
    setDisabled(true);
    const timeoutId = setTimeout(() => {
      (new RequestService('users/verification/phone/lookup'))
        .setParams(params)
        .send()
        .then((response) => {
          if (response.status === 200) {
            setDisabled(false);
          }
        })
        .catch(() => setDisabled(true))
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [values.phone, codeSent]);

  return (
    <div className="inner-wrapper">
      <div className="verify-phone-page">
        <h2 className="centered-text section-title">
          <strong>{t('pageSettings:verify_title')}</strong>
        </h2>
        <h3 className="text-accent">{t('pageSettings:verify_subtitle')}</h3>
        {!codeSent ? (
          <>
            <CustomPhoneInput
              values={values}
              setValues={setValues}
              errors={errors.phone}
            />
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('pageSettings:send_sms')}
              classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
              disabled={loading || disabled}
              onClick={handleSubmit}
            />
          </>
        ) : (
          <>
            <div className="phone-wrapper">
              <FormElement
                id="phone"
                elementType="input"
                placeholder={t('forms:phone')}
                label={t('forms:phone')}
                value={values.phone || ''}
                disabled
                changed={handleChange}
              />
              <button
                type="button"
                className="btn-link"
                onClick={() => setCodeSent(false)}
              >
                {t('forms:change')}
              </button>
            </div>
            <FormElement
              id="verify_code"
              elementType="input"
              placeholder={t('forms:verify_code')}
              label={t('forms:verify_code')}
              value={values.verify_code || ''}
              changed={handleChange}
            />
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('pageSettings:confirm_code')}
              classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
              disabled={
                loading
                || disabled
                || values.verify_code === ''
                || !values.verify_code
              }
              onClick={confirmCode}
            />
            <p>
              {t('pageSettings:didnt_recieve_code')}
              {' '}
              <button
                type="button"
                className="btn-link"
                onClick={submit}
              >
                <strong>{t('pageSettings:resend_code')}</strong>
              </button>
            </p>
          </>
        )}
      </div>
    </div>
  );
}
