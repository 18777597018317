import ItemTypes from './ItemTypes';

export const checkBounds = (x, y) => {
  let currentX = Math.ceil(x);
  let currentY = Math.ceil(y);

  if (x < 0) {
    currentX = 0;
  }

  if (y < 0) {
    currentY = 0;
  }

  return {
    currentX,
    currentY,
  };
};

export const getElementOnCanvasSize = (prevWidth, prevHeight) => ({
  width: Math.ceil(prevWidth * 1.5),
  height: Math.ceil(prevHeight * 1.5),
});

export const changeElementSize = (currentSize, newSize, width, height) => {
  let newWidth = width;
  let newHeight = height;

  if (currentSize === 'M') {
    if (newSize === 'S') {
      newWidth = width / 1.5;
      newHeight = height / 1.5;
    }
    if (newSize === 'L') {
      newWidth = width * 1.5;
      newHeight = height * 1.5;
    }
  }

  if (currentSize === 'S') {
    if (newSize === 'M') {
      newWidth = width * 1.5;
      newHeight = height * 1.5;
    }
    if (newSize === 'L') {
      newWidth = width * 2.25;
      newHeight = height * 2.25;
    }
  }

  if (currentSize === 'L') {
    if (newSize === 'M') {
      newWidth = width / 1.5;
      newHeight = height / 1.5;
    }
    if (newSize === 'S') {
      newWidth = width / 2.25;
      newHeight = height / 2.25;
    }
  }

  return {
    width: Math.floor(newWidth),
    height: Math.floor(newHeight),
  };
};

export const findElementSize = (type, width, height, rotation) => {
  let newSize = 'M';
  const rotatedWidth = rotation === 90 ? height : width;
  const rotatedHeight = rotation === 90 ? width : height;

  if (ItemTypes[type].width === rotatedWidth
    || ItemTypes[type].height === rotatedHeight) {
    newSize = 'S';
  }

  if (Math.ceil(ItemTypes[type].width * 1.5) === rotatedWidth
    || Math.ceil(ItemTypes[type].height * 1.5) === rotatedHeight) {
    newSize = 'M';
  }

  if (Math.ceil(ItemTypes[type].width * 2.25) === rotatedWidth
    || Math.ceil(ItemTypes[type].height * 2.25) === rotatedHeight) {
    newSize = 'L';
  }

  return newSize;
};

export const scaledCorrections = (canvasWidth, canvasHeight, minWidth, minHeight, zoom) => {
  const scaledCanvasWidth = canvasWidth / zoom.scale;
  const scaledCanvasHeight = canvasHeight / zoom.scale;
  let marginLeftCorrection = 0;
  let marginTopCorrection = 0;
  if (scaledCanvasWidth < minWidth && zoom.scale < 1) {
    marginLeftCorrection = (scaledCanvasWidth - minWidth) / 10;
  } else if (scaledCanvasWidth < minWidth && zoom.scale > 1) {
    marginLeftCorrection = ((minWidth - scaledCanvasWidth) / 10) * zoom.step;
  } else {
    marginLeftCorrection = 0;
  }
  if (scaledCanvasHeight < minHeight && zoom.scale < 1) {
    marginTopCorrection = (scaledCanvasHeight - minHeight) / 10;
  } else if (scaledCanvasHeight < minHeight && zoom.scale > 1) {
    marginTopCorrection = ((minHeight - scaledCanvasHeight) / 10) * zoom.step;
  } else {
    marginTopCorrection = 0;
  }

  return {
    marginLeftCorrection,
    marginTopCorrection,
  };
};
