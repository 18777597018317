/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import UserContext from '../../react-web-ui/contexts/UserContext';

const PrivateRoute = ({ component, ...rest }) => {
  const userContext = useContext(UserContext);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => (userContext.isLoggedIn ? (
        component
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ))}
    />
  );
};

export default PrivateRoute;
