import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';

import ItemTypes from './ItemTypes';
import FormElement from '../../react-web-ui/components/FormElement';
import Checkbox from '../../react-web-ui/components/FormElement/Checkbox';
import { changeElementSize, findElementSize } from './helpers';
import { ReactComponent as Close } from '../../react-web-ui/assets/images/icons/Close.svg';

function ElementDetails({
  salons,
  element,
  onDeleteClicked,
  setChanged,
}) {
  const { t } = useTranslation();
  const [elementSize, setElementSize] = useState(
    findElementSize(element.type, element.width, element.height, element.rotation),
  );
  const [name, setName] = useState('');
  const [minCapacity, setMinCapacity] = useState(element.minCapacity);
  const [maxCapacity, setMaxCapacity] = useState(element.maxCapacity);
  const [bookable, setBookable] = useState(element.bookable);

  useEffect(() => {
    setName(element.name || '');
    setMinCapacity(element.minCapacity);
    setMaxCapacity(element.maxCapacity);
    setBookable(element.bookable);
    setElementSize(element.size);
  }, [
    element,
    element.name,
    element.minCapacity,
    element.maxCapacity,
    element.bookable,
    element.size,
  ]);

  useEffect(() => {
    if ((name === '' || name === element.name)
      && minCapacity === element.minCapacity
      && maxCapacity === element.maxCapacity
      && bookable === element.bookable) return;
    salons.elements.update({
      ...element,
      name,
      minCapacity,
      maxCapacity,
      bookable,
    });
    setChanged(true);
  }, [
    name,
    minCapacity,
    maxCapacity,
    bookable,
  ]);

  useEffect(() => {
    const currentSize = findElementSize(
      element.type,
      element.width,
      element.height,
      element.rotation,
    );
    if (currentSize === elementSize) return;
    const { width, height } = changeElementSize(
      currentSize,
      elementSize,
      element.width,
      element.height,
    );
    salons.elements.update({
      ...element,
      width,
      height,
      size: findElementSize(element.type, width, height, element.rotation),
    });
    setChanged(true);
  }, [elementSize]);

  return (
    <>
      {(element.type === ItemTypes.SQUARE.name
      || element.type === ItemTypes.CIRCLE.name
      || element.type === ItemTypes.RECTANGLE.name) ? (
        <>
          <h2 className="column-top">{t('pageFloorPlan:table_details')}</h2>
          <div className="details-form">
            <div className="table-sizes">
              <button
                type="button"
                className={elementSize === 'S' ? 'active' : ''}
                onClick={() => setElementSize('S')}
              >
                S
              </button>
              <button
                type="button"
                className={elementSize === 'M' ? 'active' : ''}
                onClick={() => setElementSize('M')}
              >
                M
              </button>
              <button
                type="button"
                className={elementSize === 'L' ? 'active' : ''}
                onClick={() => setElementSize('L')}
              >
                L
              </button>
            </div>
            <FormElement
              id="nameEn0"
              name="nameEn"
              elementType="input"
              value={name || ''}
              changed={(event) => setName(event.target.value)}
              label={t('pageFloorPlan:name')}
              placeholder=""
              errors={name === '' ? [t('forms:error_empty')] : []}
            />
            <div className="lg-two-cols">
              <FormElement
                id="minCapacity"
                name="nameEn"
                className="col"
                elementType="input"
                value={minCapacity}
                changed={(event) => {
                  if (event.target.value > 0) {
                    setMinCapacity(event.target.value);
                  } else {
                    setMinCapacity(1);
                  }
                }}
                label={t('pageFloorPlan:min_capacity')}
                inputType="number"
                placeholder=""
              />
              <FormElement
                id="maxCapacity"
                elementType="input"
                className="col"
                value={maxCapacity}
                changed={(event) => {
                  if (event.target.value > 0) {
                    setMaxCapacity(event.target.value);
                  } else {
                    setMaxCapacity(1);
                  }
                  if (event.target.value > 50) {
                    setMaxCapacity(50);
                  }
                }}
                label={t('pageFloorPlan:max_capacity')}
                inputType="number"
                placeholder=""
              />
            </div>
            <Checkbox
              id="is_bookable"
              text={t('pageFloorPlan:is_bookable')}
              value={`${bookable}`}
              checked={bookable}
              check={() => setBookable(!bookable)}
            />
          </div>
        </>
        ) : (
          <>
            <h2 className="column-top">{t('pageFloorPlan:element_details')}</h2>
            <div className="details-form">
              <FormElement
                id="uneditable"
                elementType="input"
                value={t(`pageFloorPlan:${element.type}`)}
                changed={() => {}}
                label={t('pageFloorPlan:type')}
                placeholder={t('pageFloorPlan:type')}
                disabled
              />
            </div>
          </>
        )}
      <button
        type="button"
        className="delete-element"
        onClick={() => {
          confirmAlert({
            // eslint-disable-next-line react/prop-types
            customUI: ({ onClose }) => (
              <div className="react-confirm-alert-body">
                <h2 className="section-title">{t('pageFloorPlan:are_you_sure_delete')}</h2>
                <button
                  type="button"
                  onClick={() => {
                    onDeleteClicked(element.id);
                    onClose();
                  }}
                >
                  {t('yes')}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                >
                  {t('no')}
                </button>
              </div>
            ),
          });
        }}
      >
        <Close className="icon" />
        {t('delete')}
      </button>
    </>
  );
}

ElementDetails.propTypes = {
  salons: PropTypes.oneOfType([PropTypes.object]).isRequired,
  element: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  setChanged: PropTypes.func.isRequired,
};

export default ElementDetails;
