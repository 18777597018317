import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import InfoItem from '../../../react-web-ui/components/RestaurantInfo/InfoItem';
import FormElement from '../../../react-web-ui/components/FormElement';
import { ReactComponent as Chef } from '../../../react-web-ui/assets/images/icons/Chef.svg';
import { ReactComponent as Edit } from '../../../assets/images/icons/Edit.svg';

export default function ChefNameInfoItem({
  locale,
  currentRestaurantInfo,
  setCurrentRestaurantInfo,
  disabled,
}) {
  const [edit, setEdit] = React.useState(false);
  const [defaultText, setDefaultText] = useState(currentRestaurantInfo._chefName);
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);

  useEffect(() => {
    if (i18n.language === 'en' || i18n.language === 'en-GB') {
      setDefaultText(currentRestaurantInfo._chefName);
    } else {
      setDefaultText(currentRestaurantInfo._translations[locale].chef_name);
    }
  }, [
    i18n.language,
    currentRestaurantInfo._chefName,
    currentRestaurantInfo._translations[locale].chef_name,
  ]);

  return (
    <div className={`editable-info-item ${edit ? 'editing' : ''}`}>
      <InfoItem
        icon={<Chef className="icon" />}
        title={t('pageRestaurantProfile:chef_name')}
        text={edit ? (
          <>
            <div
              className={`
                invertible-fields
                ${(i18n.language === 'bg' || i18n.language === 'pl') ? 'inverted' : ''}
              `}
            >
              <FormElement
                className="invertible-field"
                id="chefNameEn"
                elementType="input"
                placeholder={t('forms:placeholder_default')}
                value={currentRestaurantInfo._chefName}
                changed={(e) => {
                  const copyRestaurantInfo = { ...currentRestaurantInfo };
                  copyRestaurantInfo._chefName = e.target.value;
                  setCurrentRestaurantInfo(copyRestaurantInfo);
                }}
                disabled={disabled}
              />
              <FormElement
                className="invertible-field"
                id="chefNameTranslation"
                elementType="input"
                placeholder={t(`forms:placeholder_translation_${countryContext.country.name}`)}
                value={currentRestaurantInfo._translations[locale].chef_name}
                changed={(e) => {
                  const copyRestaurantInfo = { ...currentRestaurantInfo };
                  copyRestaurantInfo._translations[locale].chef_name = e.target.value;
                  setCurrentRestaurantInfo(copyRestaurantInfo);
                }}
                disabled={disabled}
              />
            </div>
            <button
              className="save-btn"
              type="button"
              onClick={() => setEdit(!edit)}
            >
              {t('save')}
            </button>
          </>
        ) : defaultText || t('pageRestaurantProfile:add_chef_name')}
      />
      <button
        className="edit-btn"
        type="button"
        onClick={() => setEdit(!edit)}
      >
        <Edit className="icon" />
      </button>
    </div>
  );
}

ChefNameInfoItem.propTypes = {
  locale: PropTypes.string,
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
  setCurrentRestaurantInfo: PropTypes.func,
  disabled: PropTypes.bool,
};

ChefNameInfoItem.defaultProps = {
  locale: 'en',
  currentRestaurantInfo: {},
  setCurrentRestaurantInfo: null,
  disabled: false,
};
