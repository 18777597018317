import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/bg';
import 'moment/locale/pl';
import { useTranslation } from 'react-i18next';

import DaySelect from '../DaySelect';
import Calendar from '../Calendar';

/**
* Calendar
* @returns {*}
* @constructor
*/
export default function DateDayPicker({
  selectedDays,
  setSelectedDays,
  setLunchHours,
  setDinnerHours,
  setDisableDays,
  disableDays,
  setStart,
  start,
  setEnd,
  end,
}) {
  const { t, i18n } = useTranslation();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [focused, setFocused] = useState('startDate');
  moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);

  // Change calendar locale
  useEffect(() => {
    setOpenCalendar(false);
    moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
    setTimeout(() => {
      setOpenCalendar(true);
    }, 200);
  }, [i18n.language]);

  useEffect(() => {
    const days = [];
    const names = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];

    for (let day = 0; day < 7; day += 1) {
      days.push({
        index: day,
        selected: true,
        name: names[day],
      });
    }

    const lunch = [];
    for (let hour = 10; hour < 17; hour += 1) {
      for (let minute = 0; minute < 60; minute += 30) {
        lunch.push({
          hour: moment({ hour, minute }).format('HH:mm'),
          selected: false,
        });
      }
    }

    const dinner = [];
    for (let hour = 17; hour < 22; hour += 1) {
      for (let minute = 0; minute < 60; minute += 30) {
        dinner.push({
          hour: moment({ hour, minute }).format('HH:mm'),
          selected: false,
        });
      }
    }

    setSelectedDays(days);
    setLunchHours(lunch);
    setDinnerHours(dinner);
  }, [t, i18n.language, setSelectedDays, setLunchHours, setDinnerHours]);

  useEffect(() => {
    const notSelected = selectedDays.filter(({ selected }) => !selected);
    const disable = notSelected.map(({ index }) => index);
    setDisableDays(disable);
  }, [selectedDays, setDisableDays]);

  const datesChangeHandler = ({ startDate, endDate }) => {
    setStart(startDate);
    setEnd(endDate);
  };

  const focusChangeHandler = (focusedInput) => {
    setFocused(!focusedInput ? 'startDate' : focusedInput);
  };

  return (
    <>
      {openCalendar && (
        <>
          <div className="pick-date">
            <Calendar
              weekStart={1}
              datesChangeHandler={datesChangeHandler}
              focusChangeHandler={focusChangeHandler}
              focused={focused}
              start={start}
              end={end}
              disableDays={disableDays}
            />
          </div>
          <div className="pick-day">
            {selectedDays.length > 0 && (
              <DaySelect
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

DateDayPicker.propTypes = {
  selectedDays: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setSelectedDays: PropTypes.func.isRequired,
  setLunchHours: PropTypes.func.isRequired,
  setDinnerHours: PropTypes.func.isRequired,
  setDisableDays: PropTypes.func.isRequired,
  disableDays: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setStart: PropTypes.func.isRequired,
  start: PropTypes.PropTypes.instanceOf(moment),
  setEnd: PropTypes.func.isRequired,
  end: PropTypes.PropTypes.instanceOf(moment),
};

DateDayPicker.defaultProps = {
  start: null,
  end: null,
};
