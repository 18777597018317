import React, { useContext } from 'react';

import UserContext from '../react-web-ui/contexts/UserContext';
// import RestaurantContext from '../contexts/RestaurantContext';
import Landing from './Landing';
import RestaurantsListing from './RestaurantsListing';
// import Statistics from './Grow/Statistics';
// import Bookings from './Manage/Bookings';

export default function Homepage() {
  const userContext = useContext(UserContext);
  // const restaurantContext = useContext(RestaurantContext);

  if (userContext.isLoggedIn) {
    return <RestaurantsListing />;
  }

  return (
    <Landing />
  );
}
