import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Counter from '../../react-web-ui/components/FormElement/Counter';
import Tooltip from '../../react-web-ui/components/Tooltip';
import BookingFormFields from './BookingFormFields';
import DatePicker from '../DatePicker';
import { ReactComponent as User } from '../../assets/images/icons/User.svg';

export default function BookRestaurantForm({
  currency,
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState(null);
  const [seating, setSeating] = useState(false);

  return (
    <form className="booking-info-block" noValidate>
      <div className="powered-by">
        {t('pageWidget:title')}
      </div>
      <div className="booking-header">
        <DatePicker
          date={date}
          handleDate={(event) => setDate(event)}
          placeholder={t('pageWidget:select_date')}
        />
        <div className="guests-wrapper">
          <User className="icon" />
          <Counter
            count={3}
            clickedMinus={() => {}}
            clickedPlus={() => {}}
          />
        </div>
        <Tooltip
          title={t('pageWidget:seating_tooltip')}
          position="bottom"
        >
          <button
            type="button"
            className={`toggle-seating ${seating ? 'active' : ''}`}
            onClick={() => setSeating(!seating)}
          >
            <span>
              {t('pageWidget:inside')}
            </span>
            <span>
              {t('pageWidget:outside')}
            </span>
          </button>
        </Tooltip>
      </div>
      <div className="hours-wrapper">
        <button type="button" className=""><span className="hour">10:00</span></button>
        <button type="button" className=""><span className="hour">10:30</span></button>
        <button type="button" className=""><span className="hour">11:00</span></button>
        <button type="button" className=""><span className="hour">11:30</span></button>
        <button type="button" className=""><span className="hour">12:00</span></button>
        <button type="button" className=""><span className="hour">12:30</span></button>
        <button type="button" className=""><span className="hour">13:00</span></button>
        <button type="button" className=""><span className="hour">13:30</span></button>
        <button type="button" className=""><span className="hour">14:00</span></button>
        <button type="button" className=""><span className="hour">14:30</span></button>
        <button type="button" className=""><span className="hour">15:00</span></button>
        <button type="button" className=""><span className="hour">15:30</span></button>
        <button type="button" className=""><span className="hour">16:00</span></button>
        <button type="button" className=""><span className="hour">16:30</span></button>
        <button type="button" className=""><span className="hour">17:00</span></button>
        <button type="button" className=""><span className="hour">17:30</span></button>
        <button type="button" className=""><span className="hour">18:00</span></button>
        <button type="button" className=""><span className="hour">18:30</span></button>
        <button type="button" className=""><span className="hour">19:00</span></button>
        <button type="button" className=""><span className="hour">19:30</span></button>
        <button type="button" className=""><span className="hour">20:00</span></button>
        <button type="button" className=""><span className="hour">20:30</span></button>
        <button type="button" className=""><span className="hour">21:00</span></button>
        <button type="button" className=""><span className="hour">21:30</span></button>
      </div>
      <BookingFormFields currency={currency} />
    </form>
  );
}

BookRestaurantForm.propTypes = {
  currency: PropTypes.string.isRequired,
};
