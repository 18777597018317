import React from 'react';
import PropTypes from 'prop-types';

/**
 * Pill type button
 * @param text
 * @param onClick
 * @param className
 * @returns {*}
 * @constructor
 */
export default function PillButton({
  text,
  onClick,
  className,
}) {
  return (
    <button
      className={`pill-btn ${className}`}
      type="button"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

PillButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

PillButton.defaultProps = {
  className: '',
};
