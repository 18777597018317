import LocalProvider from './LocalProvider';
import ThumborProvider from './ThumborProvider';

const PREFERRED_IMAGE_PROVIDER = process.env.REACT_APP_IMAGE_PROVIDER || 'thumbor';

export default class Resolver {
  constructor() {
    switch (PREFERRED_IMAGE_PROVIDER) {
      case 'thumbor':
        this.provider = new ThumborProvider();
        break;
      case 'local':
        this.provider = new LocalProvider();
        break;
      default:
        this.provider = new ThumborProvider();
        break;
    }
  }
}
