import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { USER_ID_KEY, RESTAURANT_ID_KEY, RESTAURANT_PLAN_KEY } from '../utilities/constants';
import ScrollToTop from '../react-web-ui/components/ScrollToTop';
import RequestService from '../networking/RequestService';
import UserContext from '../react-web-ui/contexts/UserContext';
import RestaurantContext from '../contexts/RestaurantContext';
import HeaderWrapper from '../components/HeaderWrapper';
import Footer from '../components/Footer';
import PrivateRoute from '../components/PrivateRoute';
import PrivateRestaurantRoute from '../components/PrivateRestaurantRoute';
import CountryContext from '../react-web-ui/contexts/CountryContext';

import Homepage from '../pages/Homepage';
import RestaurantsListing from '../pages/RestaurantsListing';
import WhatWeServe from '../pages/WhatWeServe';
import WhatYouPay from '../pages/WhatYouPay';
import JoinNow from '../pages/JoinNow';

import Bookings from '../pages/Manage/Bookings';
import BookingNotifications from '../pages/Manage/BookingNotifications';
import Shifts from '../pages/Manage/Shifts';
import Employees from '../pages/Manage/Employees';
import FloorPlan from '../pages/Manage/FloorPlan';
import SeatingAreas from '../pages/Manage/SeatingAreas';
import TableCombinations from '../pages/Manage/TableCombinations';
import Subscriptions from '../pages/Manage/Subscriptions';

import Statistics from '../pages/Grow/Statistics';
import Reviews from '../pages/Grow/Reviews';
import Widget from '../pages/Grow/Widget';
import Menu from '../pages/Grow/Menu';
import PostCreator from '../pages/Grow/PostCreator';
import PostCreatorPreview from '../pages/Grow/PostCreatorPreview';
import RestaurantProfile from '../pages/Grow/RestaurantProfile';
import EmailBranding from '../pages/Grow/EmailBranding';

import SpecialOffersListing from '../pages/Monetize/SpecialOffers/SpecialOffersListing';
import SpecialOfferType from '../pages/Monetize/SpecialOffers/SpecialOfferType';
import AddSpecialOffer from '../pages/Monetize/SpecialOffers/AddSpecialOffer';
import EventsListing from '../pages/Monetize/Events/EventsListing';
import AddEvent from '../pages/Monetize/Events/AddEvent';
import TastingMenusListing from '../pages/Monetize/TastingMenus/TastingMenusListing';
import AddTastingMenu from '../pages/Monetize/TastingMenus/AddTastingMenu';
import GiftCards from '../pages/Monetize/GiftCards';
import DineoutHost from '../pages/Monetize/DineoutHost';
import Payments from '../pages/Monetize/Payments';

import Contact from '../pages/Account/Contact';
import Settings from '../pages/Account/Settings';
import StickyInfo from '../components/StickyInfo';
import VerifyPhone from '../pages/Account/VerifyPhone';
import TermsGiftcards from '../pages/Monetize/TermsGiftcards';
import TermsTastingMenus from '../pages/Monetize/TermsTastingMenus';
import TermsEvents from '../pages/Monetize/TermsEvents';
import VerifyOwnership from '../pages/VerifyOwnership';
import Subscribe from '../pages/Manage/Subscribe';
import Terms from '../pages/Terms';
import Guests from '../pages/Grow/Guests';

function App() {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);
  const [user, setUser] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [restaurantId, setRestaurantId] = useState(localStorage.getItem(RESTAURANT_ID_KEY) || null);
  const [authPopup, setAuthPopup] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem(USER_ID_KEY) || null);
  const [logged, setLogged] = useState(localStorage.getItem(USER_ID_KEY) !== null || false);
  const [plan, setPlan] = useState(localStorage.getItem(RESTAURANT_PLAN_KEY) || 'false');
  const [price, setPrice] = useState();
  // const [period, setPeriod] = useState(localStorage.getItem(RESTAURANT_PERIOD_KEY) || 'false');

  /**
   * When user opens the website, we search for their id in local storage:
   * - if it exists, we compare the id with the API
   *  - if API returns positive result, we "log in" the user
   *  - if API returns negative result, we "delete the key as it's no longer valid the user
   *
   *  - if key doesn't exist, do nothing
   */
  useEffect(() => {
    if (userId) {
      (new RequestService('manager/users/show'))
        .setParams({
          user_id: userId,
        })
        .send()
        .then((response) => {
          setUser(response.data);
          setUserId(response.data.id);
        })
        .catch((error) => {
          localStorage.removeItem(USER_ID_KEY);
        });
    }
  }, [userId]);

  /**
   * When component's state user is updated, we either remove or add their id to local storage.
   */
  useEffect(() => {
    if (user !== null) {
      localStorage.setItem(USER_ID_KEY, user.id);
      setLogged(true);
    }
  }, [user]);

  useEffect(() => {
    if (userId !== null) {
      setLogged(true);
    } else {
      localStorage.removeItem(USER_ID_KEY);
      setLogged(false);
    }
  }, [userId]);

  useEffect(() => {
    if (restaurantId && userId) {
      (new RequestService('manager/places/show'))
        .setParams({
          place_id: restaurantId,
          user_id: userId,
        })
        .send()
        .then((response) => {
          if (response.data.subscriptions.length > 0) {
            const activeSubscription = response.data.subscriptions.find(
              (subscription) => subscription.status === 'active'
                  || (subscription.status === 'trialing'),
            );
            setPlan(activeSubscription?.plan ? activeSubscription.plan : 'false');
          } else {
            setPlan('false');
            localStorage.removeItem(RESTAURANT_PLAN_KEY);
          }
          setRestaurant(response.data);
          setRestaurantId(response.data.id);
        })
        .catch((error) => {
          localStorage.removeItem(RESTAURANT_ID_KEY);
          localStorage.removeItem(RESTAURANT_PLAN_KEY);
        });
    }
  }, [t, restaurantId, userId, plan, price]);

  useEffect(() => {
    if (restaurant !== null) {
      localStorage.setItem(RESTAURANT_ID_KEY, restaurant.id);
      localStorage.setItem(RESTAURANT_PLAN_KEY, plan);
    }
  }, [restaurant]);

  useEffect(() => {
    if (restaurantId === null) {
      localStorage.removeItem(RESTAURANT_ID_KEY);
      localStorage.removeItem(RESTAURANT_PLAN_KEY);
    }
  }, [restaurantId]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        setUserId,
        isLoggedIn: logged,
        authPopup,
        setAuthPopup,
      }}
    >
      <RestaurantContext.Provider
        value={{
          restaurant,
          setRestaurant,
          restaurantId,
          setRestaurantId,
          plan,
          setPlan,
          setPrice,
        }}
      >
        <Router>
          <ToastContainer position="top-center" />
          <ScrollToTop />
          {/* {!logged && (
            <MessengerCustomerChat
              pageId={countryContext.country.code === 'PL' ? '632174407223308' : '863480237061294'}
              appId="368848319968353"
              language={i18n.language}
            />
          )} */}
          <div className="global-wrapper">
            <HeaderWrapper />
            <div className="page-content">
              <StickyInfo />
              <Route path="/" exact>
                <Homepage />
              </Route>
              <Route path="/who-we-help" exact>
                <WhatWeServe />
              </Route>
              <Route path="/what-you-pay" exact>
                <WhatYouPay />
              </Route>
              <Route path="/join" exact>
                <JoinNow />
              </Route>
              {/* <Route path="/terms" exact>
                <Terms />
              </Route> */}
              <PrivateRoute path="/restaurants-listing" exact component={<RestaurantsListing />} />
              <PrivateRestaurantRoute
                path="/verify-ownership"
                exact
                component={<VerifyOwnership />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/bookings"
                exact
                component={<Bookings />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/booking-notifications"
                exact
                component={<BookingNotifications />}
                allow={['PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/seating-areas"
                exact
                component={<SeatingAreas />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/floor-plan"
                exact
                component={<FloorPlan />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/table-combinations"
                exact
                component={<TableCombinations />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/employees"
                exact
                component={<Employees />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/shifts"
                exact
                component={<Shifts />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/menu"
                exact
                component={<Menu />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/restaurant-profile"
                exact
                component={<RestaurantProfile />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/manage/subscriptions"
                exact
                component={<Subscriptions />}
                allow={['FREE', 'PRO', 'PREMIUM', 'ALL']}
              />
              <PrivateRestaurantRoute
                path="/manage/subscribe"
                exact
                component={<Subscribe />}
                allow={['FREE', 'PRO', 'PREMIUM', 'ALL']}
              />

              <PrivateRestaurantRoute
                path="/grow/reviews"
                exact
                component={<Reviews />}
                allow={['PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/grow/widget"
                exact
                component={<Widget />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/grow/email-branding"
                exact
                component={<EmailBranding />}
                allow={['PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/grow/stats"
                exact
                component={<Statistics />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/grow/guests"
                exact
                component={<Guests />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/grow/post-creator"
                exact
                component={<PostCreator />}
                allow={['PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/grow/post-creator-preview"
                exact
                component={<PostCreatorPreview />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />

              <PrivateRestaurantRoute
                path="/monetize/special-offers"
                exact
                component={<SpecialOffersListing />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/special-offers/type"
                exact
                component={<SpecialOfferType />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/special-offers/add"
                exact
                component={<AddSpecialOffer />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/special-offers/edit/:id"
                exact
                component={<AddSpecialOffer editing />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/events"
                exact
                component={<EventsListing />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/events/add"
                exact
                component={<AddEvent />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/events/edit/:id"
                exact
                component={<AddEvent editing />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/events/copy/:id"
                exact
                component={<AddEvent copy />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/tasting-menus"
                exact
                component={<TastingMenusListing />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/tasting-menus/add"
                exact
                component={<AddTastingMenu />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/tasting-menus/edit/:id"
                exact
                component={<AddTastingMenu editing />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/gift-cards"
                exact
                component={<GiftCards />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/payments"
                exact
                component={<Payments />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/terms-giftcards"
                exact
                component={<TermsGiftcards />}
                allow={['FREE', 'PRO', 'PREMIUM']}
              />
              <PrivateRestaurantRoute
                path="/monetize/terms-events"
                exact
                allow={['FREE', 'PRO', 'PREMIUM']}
                component={<TermsEvents />}
              />
              <PrivateRestaurantRoute
                path="/monetize/terms-tasting-menus"
                exact
                allow={['FREE', 'PRO', 'PREMIUM']}
                component={<TermsTastingMenus />}
              />
              <Route path="/monetize/dineout-host" exact>
                <DineoutHost />
              </Route>
              <PrivateRoute path="/account/settings" exact component={<Settings />} />
              <PrivateRoute path="/account/contact-dineout" exact component={<Contact />} />
              <PrivateRoute path="/account/verify-phone" exact component={<VerifyPhone />} />

            </div>
            <Footer />
          </div>
        </Router>
      </RestaurantContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
