import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import JoyRide, { STATUS } from 'react-joyride';

import {
  SPECIAL_EVENT,
  DISCOUNT,
  PROMO_PRICE,
  COMBO_OFFER,
  FREE_ITEM,
  ENTERTAINMENT,
} from '../../../react-web-ui/utilities/constants';
import PageHeading from '../../../react-web-ui/components/PageHeading';
import OfferTypeBlock from '../../../components/OfferTypeBlock';
import { ReactComponent as Star } from '../../../assets/images/icons/Star.svg';
import { ReactComponent as Percent } from '../../../assets/images/icons/Percent.svg';
import { ReactComponent as Discount } from '../../../assets/images/icons/Discount.svg';
import { ReactComponent as Refresh } from '../../../assets/images/icons/Refresh.svg';
import { ReactComponent as Gift } from '../../../assets/images/icons/Gift.svg';
import { ReactComponent as Note } from '../../../assets/images/icons/Note.svg';
import Button from '../../../react-web-ui/components/Button';
import Tooltip from '../../../react-web-ui/components/Tooltip';

export default function SpecialOfferType() {
  const { t } = useTranslation();
  const [currentType, setCurrentType] = useState('');
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: '.discount-page',
      content: 'Take a moment to get to know the different types of offers and choose the one that suits your needs best. They look differently on our platform and require different information.',
      disableBeacon: true,
      placement: 'center',
    },
  ];

  const offerTypes = [
    {
      title: t('pageSpecialOffers:special_menu'),
      text: t('pageSpecialOffers:special_menu_description'),
      type: SPECIAL_EVENT,
      icon: <Star className="icon" />,
    },
    {
      title: t('pageSpecialOffers:discount'),
      text: t('pageSpecialOffers:discount_description'),
      type: DISCOUNT,
      icon: <Percent className="icon" />,
    },
    {
      title: t('pageSpecialOffers:promo_price'),
      text: t('pageSpecialOffers:promo_price_description'),
      type: PROMO_PRICE,
      icon: <Discount className="icon" />,
    },
    {
      title: t('pageSpecialOffers:combo_offer'),
      text: t('pageSpecialOffers:combo_offer_description'),
      type: COMBO_OFFER,
      icon: <Refresh className="icon" />,
    },
    {
      title: t('pageSpecialOffers:free_item'),
      text: t('pageSpecialOffers:free_item_description'),
      type: FREE_ITEM,
      icon: <Gift className="icon" />,
    },
    {
      title: t('pageSpecialOffers:entertainment'),
      text: t('pageSpecialOffers:entertainment_description'),
      type: ENTERTAINMENT,
      icon: <Note className="icon" />,
    },
  ];

  useEffect(() => {
    localStorage.setItem('currentType', JSON.stringify(currentType));
  }, [currentType]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('special-offers-type-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('special-offers-type-tour-status', 1);
    }
  };

  return (
    <div className="inner-wrapper discount-page">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <PageHeading
        classes="page-heading-discount"
        title={t('pageSpecialOffers:select_offer_type')}
        content={(
          <p>{t('pageSpecialOffers:browse_offer_type')}</p>
        )}
      />
      <div>
        {offerTypes.map(({
          title,
          text,
          type,
          icon,
        }) => (
          <OfferTypeBlock
            key={type}
            title={title}
            text={text}
            link={{
              pathname: '/monetize/special-offers/add',
              state: {
                type,
              },
            }}
            onMouseEnter={() => {
              setCurrentType(type);
            }}
            icon={icon}
          />
        ))}
      </div>
    </div>
  );
}
