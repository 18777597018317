import { isCancel } from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import illustration8 from '../assets/images/illustrations/illustration8.svg';
import RestaurantContext from '../contexts/RestaurantContext';
import RequestService from '../networking/RequestService';
import Button from '../react-web-ui/components/Button';
import { ReactComponent as External } from '../assets/images/icons/External.svg';
import { ReactComponent as Reload } from '../assets/images/icons/Reload.svg';
import Spinner from '../react-web-ui/components/Spinner';
import UserContext from '../react-web-ui/contexts/UserContext';

let getRequirementsStatusRequest = null;
let requestVerificationRequest = null;

export default function VerifyOwnership() {
  const { t } = useTranslation('verifyOwnership');

  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);

  const [isReloadingRequirementsStatus, setIsReloadingRequirementsStatus] = useState(false);
  const [requirementsStatus, setRequirementsStatus] = useState({
    is_phone_number_verified: null,
    is_place_profile_setup: null,
    is_gallery_ok: null,
    are_shifts_ok: null,
    are_tables_ok: null,
  });

  const [isReadyForRequesting, setIsReadyForRequesting] = useState(false);
  const [isRequestingVerification, setIsRequestingVerification] = useState(false);

  const reloadRequirementsStatus = () => {
    // Cancel any requests that are still processing
    if (getRequirementsStatusRequest !== null) {
      getRequirementsStatusRequest.source.cancel();
    }
    setIsReloadingRequirementsStatus(true);

    getRequirementsStatusRequest = new RequestService('manager/places/request-verification-checklist')
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      });

    getRequirementsStatusRequest.send()
      .then((response) => setRequirementsStatus(response.data))
      .catch((error) => {
        if (!isCancel(error)) {
          toast.error(t('apiErrors:internal_server_error'));
        }
      }).finally(() => setIsReloadingRequirementsStatus(false));
  };

  const requestVerificationButtonClicked = () => {
    if (requestVerificationRequest !== null) {
      requestVerificationRequest.source.cancel();
    }

    setIsRequestingVerification(true);

    requestVerificationRequest = new RequestService('manager/places/request-verification')
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      });

    requestVerificationRequest.send()
      .then((response) => {
        toast.success(t('success:verification_requested_successfully'));
        restaurantContext.setRestaurant(response.data);
      })
      .catch((error) => {
        if (!isCancel(error)) {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:internal_server_error'));
        }
      })
      .finally(() => setIsRequestingVerification(false));
  };

  /**
   * On page load,
   * check for requirements status after restaurant & user are resolved.
   */
  useEffect(() => {
    if (userContext.user && restaurantContext.restaurantId) {
      reloadRequirementsStatus();
    }
    return () => {
      if (getRequirementsStatusRequest !== null) {
        getRequirementsStatusRequest.source.cancel();
      }
    };
  }, [userContext.user, restaurantContext.restaurantId]);

  /**
   * When requirementStatus updates, check if everything's ok to proceed
   * with requesting ownership verification.
   */
  useEffect(() => {
    if (restaurantContext.restaurant) {
      setIsReadyForRequesting(
        requirementsStatus.is_phone_number_verified
        && requirementsStatus.is_place_profile_setup
        && requirementsStatus.is_gallery_ok
        && requirementsStatus.is_gallery_ok
        && requirementsStatus.are_tables_ok
        && !restaurantContext.restaurant.verificationRequestedAt,
      );
    }
  }, [requirementsStatus, restaurantContext.restaurant]);

  return (
    <div className="inner-wrapper">
      <h2 className="section-title underlined"><strong>Get listed on Dineout</strong></h2>
      <Trans i18nKey="verifyOwnership:leading_paragraph">
        This is the first step to attracting new customers for your restaurant.
        Create a profile so your restaurant can stand out. Make sure your
        profile features appealing photos of your interior and of the
        food you offer.
        Include an up to date menu and brief description that talks
        about your concept who are your regular customers,
        what type of food should customers expect. Set a price range based
        on 3-course meal and don’t forget to list the
        cuisines you offer. You’ll also need to update your working hours and
        have at least 3 tables that can be booked
        online. After we verify your profile you will get a listing on our
        website as well as through our apps, and
        we’ll start promoting it through our channels so you can
        reach new customers and bookings.
      </Trans>
      <div className="verify-section-wrapper">
        <div className="img-wrapper">
          <img src={illustration8} alt="" />
        </div>
        <div className="verify-section">
          <h4 className="section-title">
            <strong>
              {t('checklist_title')}
            </strong>
          </h4>
          <button
            className={`btn-link btn-reload-checklist ${isReloadingRequirementsStatus ? 'disabled-link' : ''}`}
            type="button"
            onClick={() => reloadRequirementsStatus()}
          >
            <Reload className="icon" />
            {t('reload_requirements_link')}
          </button>
          <div className="vertical-progress verify-steps">
            <span className={`step ${requirementsStatus.is_phone_number_verified ? 'active' : ''}`}>
              {t('verify_phone_number_requirement')}
              {(requirementsStatus.is_phone_number_verified === false) && (
                <Link to="/account/verify-phone" className="external-link text-accent" target="_blank">
                  <External className="icon" />
                  {t('verify_phone_number_action')}
                </Link>
              )}
            </span>
            <span className={`step ${requirementsStatus.is_place_profile_setup ? 'active' : ''}`}>
              {t('setup_up_your_profile_requirement')}
              {(requirementsStatus.is_place_profile_setup === false) && (
              <Link to="/manage/restaurant-profile" className="external-link text-accent" target="_blank">
                <External className="icon" />
                {t('setup_up_your_profile_action')}
              </Link>
              )}
            </span>
            <span className={`step ${requirementsStatus.is_gallery_ok ? 'active' : ''}`}>
              {t('gallery_requirement')}
              {(requirementsStatus.is_gallery_ok === false) && (
              <Link to="/manage/restaurant-profile" className="external-link text-accent" target="_blank">
                <External className="icon" />
                {t('gallery_action')}
              </Link>
              )}
            </span>
            <span className={`step ${requirementsStatus.are_shifts_ok ? 'active' : ''}`}>
              {t('shifts_requirement')}
              {(requirementsStatus.are_shifts_ok === false) && (
              <Link to="/manage/shifts" className="external-link text-accent" target="_blank">
                <External className="icon" />
                {t('shifts_action')}
              </Link>
              )}
            </span>
            <span className={`step ${requirementsStatus.are_tables_ok ? 'active' : ''}`}>
              {t('tables_requirement')}
              {(requirementsStatus.are_tables_ok === false) && (
              <Link to="/manage/seating-areas" className="external-link text-accent" target="_blank">
                <External className="icon" />
                {t('tables_action')}
              </Link>
              )}
            </span>
          </div>
          {!restaurantContext.restaurant?.verificationRequestedAt && (
            <Button
              text={isRequestingVerification ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('request_verification_button')}
              classes={`btn-discount ${isRequestingVerification ? 'loading-btn' : ''}`}
              disabled={
                isReloadingRequirementsStatus
                || !isReadyForRequesting
                || isRequestingVerification
              }
              onClick={requestVerificationButtonClicked}
            />
          )}
          {
            restaurantContext.restaurant?.verificationRequestedAt && (
              <>
                <hr />
                <p>
                  {t('place_has_requested_verification_and_its_pending')}
                </p>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}
