import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const stringSort = (array, type, value) => {
  const sortedStrings = [...array];
  sortedStrings.sort((a, b) => {
    const stringA = a[value] ? a[value].toLowerCase() : '';
    const stringB = b[value] ? b[value].toLowerCase() : '';
    if (stringA < stringB) return (type === 'asc') ? -1 : 1;
    if (stringA > stringB) return (type === 'asc') ? 1 : -1;
    return 0;
  });
  return sortedStrings;
};

const dateSort = (array, type, value) => {
  const sortedDates = [...array];
  sortedDates.sort((a, b) => {
    const dateA = a[value] ? moment(a[value]).valueOf() : moment('01.01.1900').valueOf();
    const dateB = b[value] ? moment(b[value]).valueOf() : moment('01.01.1900').valueOf();
    if (dateA < dateB) return (type === 'asc') ? -1 : 1;
    if (dateA > dateB) return (type === 'asc') ? 1 : -1;
    return 0;
  });
  return sortedDates;
};

const numberSort = (array, type, value) => {
  const sortedDates = [...array];
  sortedDates.sort((a, b) => {
    const numberA = a[value];
    const numberB = b[value];
    if (numberA < numberB) return (type === 'asc') ? -1 : 1;
    if (numberA > numberB) return (type === 'asc') ? 1 : -1;
    return 0;
  });
  return sortedDates;
};

export default function GuestsTable({ guests }) {
  const { t } = useTranslation();
  const [currentGuests, setCurrentGuests] = useState(guests);
  const [nameSort, setNameSort] = useState(null);
  const [emailSort, setEmailSort] = useState(null);
  const [phoneSort, setPhoneSort] = useState(null);
  const [firstBookingAtSort, setFirstBookingAtSort] = useState(null);
  const [lastBookingAtSort, setLastBookingAtSort] = useState(null);
  const [birthdaySort, setBirthdaySort] = useState(null);
  const [completedCountSort, setCompletedCountSort] = useState(null);
  const [noShowsCountSort, setNoShowsCountSort] = useState(null);

  const clearSorts = () => {
    setNameSort(null);
    setEmailSort(null);
    setPhoneSort(null);
    setFirstBookingAtSort(null);
    setLastBookingAtSort(null);
    setBirthdaySort(null);
    setCompletedCountSort(null);
    setNoShowsCountSort(null);
  };

  useEffect(() => {
    setCurrentGuests(guests);
  }, [guests]);

  useEffect(() => {
    if (!nameSort) return;
    setCurrentGuests(stringSort(guests, nameSort, 'names'));
  }, [nameSort]);

  useEffect(() => {
    if (!emailSort) return;
    setCurrentGuests(stringSort(guests, emailSort, 'email'));
  }, [emailSort]);

  useEffect(() => {
    if (!phoneSort) return;
    setCurrentGuests(stringSort(guests, phoneSort, 'phone'));
  }, [phoneSort]);

  useEffect(() => {
    if (!firstBookingAtSort) return;
    setCurrentGuests(dateSort(guests, firstBookingAtSort, 'firstBookingAt'));
  }, [firstBookingAtSort]);

  useEffect(() => {
    if (!lastBookingAtSort) return;
    setCurrentGuests(dateSort(guests, lastBookingAtSort, 'lastBookingAt'));
  }, [lastBookingAtSort]);

  useEffect(() => {
    if (!birthdaySort) return;
    setCurrentGuests(dateSort(guests, birthdaySort, 'birthday'));
  }, [birthdaySort]);

  useEffect(() => {
    if (!completedCountSort) return;
    setCurrentGuests(numberSort(guests, completedCountSort, 'completedCount'));
  }, [completedCountSort]);

  useEffect(() => {
    if (!noShowsCountSort) return;
    setCurrentGuests(numberSort(guests, noShowsCountSort, 'noShowsCount'));
  }, [noShowsCountSort]);

  return (
    <div className="bookings-table">
      <table>
        <thead>
          <tr>
            <th>
              <button
                type="button"
                className={`sort ${nameSort}`}
                onClick={() => {
                  clearSorts();
                  setNameSort(nameSort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('pageBookings:names')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className={`sort ${emailSort}`}
                onClick={() => {
                  clearSorts();
                  setEmailSort(emailSort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('forms:email')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className={`sort ${phoneSort}`}
                onClick={() => {
                  clearSorts();
                  setPhoneSort(phoneSort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('forms:phone')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className={`sort ${firstBookingAtSort}`}
                onClick={() => {
                  clearSorts();
                  setFirstBookingAtSort(firstBookingAtSort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('pageGuests:first_booking')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className={`sort ${lastBookingAtSort}`}
                onClick={() => {
                  clearSorts();
                  setLastBookingAtSort(lastBookingAtSort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('pageGuests:latest_booking')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className={`sort ${completedCountSort}`}
                onClick={() => {
                  clearSorts();
                  setCompletedCountSort(completedCountSort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('pageGuests:total_bookings')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className={`sort ${noShowsCountSort}`}
                onClick={() => {
                  clearSorts();
                  setNoShowsCountSort(noShowsCountSort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('pageGuests:total_no_show')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className={`sort ${birthdaySort}`}
                onClick={() => {
                  setBirthdaySort(birthdaySort === 'asc' ? 'desc' : 'asc');
                }}
              >
                {t('pageGuests:birthday')}
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentGuests.length > 0 ? (
            currentGuests.map((guest) => (
              <tr key={guest.id}>
                <td>{guest.names}</td>
                <td>{guest.email || '-'}</td>
                <td>{guest.phone || '-'}</td>
                <td>{guest.firstBookingAt ? moment(guest.firstBookingAt).format('DD.MM.YYYY') : '-'}</td>
                <td>{guest.lastBookingAt ? moment(guest.lastBookingAt).format('DD.MM.YYYY') : '-'}</td>
                <td>{guest.completedCount}</td>
                <td>{guest.noShowsCount}</td>
                <td>{guest.birthday ? moment(guest.birthday).format('DD.MM.YYYY') : '-'}</td>
              </tr>
            ))
          ) : (
            <tr><td colSpan="9">{t('pageBookings:no_search_results')}</td></tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

GuestsTable.propTypes = {
  guests: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
