import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../../networking/RequestService';
import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import EditableText from '../../EditableText';
import AwardItem from './AwardItem';

export default function AwardsBlock({
  locale,
  currentRestaurantInfo,
  setCurrentRestaurantInfo,
  disabled,
  setChanged,
}) {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);
  const [newAwardEn, setNewAwardEn] = useState('');
  const [newAwardTranslation, setNewAwardTranslation] = useState('');
  const [awardsEn, setAwardsEn] = useState([]);
  const [awardsTranslation, setAwardsTranslation] = useState([]);

  const convertList = (list) => {
    let awards = [];
    if (typeof list === 'string' || list instanceof String) {
      awards = list.split(';');
    } else {
      awards = list;
    }
    return awards;
  };

  const checkString = (list, newAward) => {
    let newList = [];
    if (typeof list === 'string' || list instanceof String) {
      newList = [list];
    } else if (list) {
      newList = [...list];
    }
    if (newList) {
      newList.push(newAward);
    } else {
      newList = [newAward];
    }
    return newList;
  };

  useEffect(() => {
    let convertedList = [];
    if (currentRestaurantInfo._awardsList !== null) {
      convertedList = convertList(currentRestaurantInfo._awardsList);
    }
    setAwardsEn(convertedList);
  }, [currentRestaurantInfo]);

  useEffect(() => {
    let convertedList = [];
    if (currentRestaurantInfo._translations[locale].awards_list !== null) {
      convertedList = convertList(currentRestaurantInfo._translations[locale].awards_list);
    }
    setAwardsTranslation(convertedList);
  }, [currentRestaurantInfo]);

  useEffect(() => {
    if (awardsEn.length !== awardsTranslation.length) {
      const newAwardsEn = [...awardsEn];
      const newAwardsTranslation = [...awardsTranslation];
      newAwardsEn.splice(0, newAwardsTranslation.length);
      newAwardsTranslation.push(...newAwardsEn);
      setAwardsTranslation(newAwardsTranslation);
    }
  }, [awardsEn, awardsTranslation]);

  const translateText = () => {
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [newAwardEn];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [newAwardTranslation];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          setNewAwardTranslation(translations[0].text);
        } else {
          setNewAwardEn(translations[0].text);
        }
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  return (
    <div className="awards-block">
      <ul className="benefits">
        {awardsEn
          ? awardsEn.map((award, index) => award && (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${award}${index}`}>
              <AwardItem
                index={index}
                locale={locale}
                en={award}
                translation={awardsTranslation[index]}
                awardsEn={awardsEn}
                awardsTranslation={awardsTranslation}
                setAwardsEn={setAwardsEn}
                setAwardsTranslation={setAwardsTranslation}
                currentRestaurantInfo={currentRestaurantInfo}
                setCurrentRestaurantInfo={setCurrentRestaurantInfo}
                disabled={disabled}
                setChanged={setChanged}
              />
            </li>
          ))
          : null}
        <li>
          <EditableText
            name="newAward"
            valueEn={newAwardEn}
            valueTranslation={newAwardTranslation}
            placeholder={t('pageRestaurantProfile:add_new_award')}
            onChangeEn={(e) => {
              setNewAwardEn(e.target.value);
              if (e.target.value !== '') {
                setChanged(true);
              } else {
                setChanged(false);
              }
            }}
            onChangeTranslation={(e) => {
              setNewAwardTranslation(e.target.value);
              if (e.target.value !== '') {
                setChanged(true);
              } else {
                setChanged(false);
              }
            }}
            onSave={() => {
              const copyRestaurantInfo = { ...currentRestaurantInfo };
              const defaultList = convertList(currentRestaurantInfo._awardsList);
              const translationList = convertList(
                currentRestaurantInfo._translations[locale].awards_list,
              );
              copyRestaurantInfo._awardsList = checkString(defaultList, newAwardEn);
              copyRestaurantInfo._translations[locale].awards_list = checkString(
                translationList, newAwardTranslation,
              );
              setNewAwardEn('');
              setNewAwardTranslation('');
              setCurrentRestaurantInfo(copyRestaurantInfo);
            }}
            disabled={disabled}
            translatable
            translateText={() => translateText()}
          />
        </li>
      </ul>
    </div>
  );
}

AwardsBlock.propTypes = {
  locale: PropTypes.string,
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
  setCurrentRestaurantInfo: PropTypes.func,
  setChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

AwardsBlock.defaultProps = {
  locale: 'en',
  currentRestaurantInfo: {},
  setCurrentRestaurantInfo: null,
  setChanged: null,
  disabled: false,
};
