import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { restaurantImage, chefImage } from '../../react-web-ui/utilities/images';
import SpecialEventWithPlace from '../../networking/resources/SpecialEventWithPlace';
import TastingMenuBlock from '../../react-web-ui/components/TastingMenuBlock';
import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';

/**
 * Tasting Menu Block
 * @param menu
 * @param buttons
 * @returns {*}
 * @constructor
 */
export default function TastingMenuWrapper({
  menu,
  buttons,
  handleChefImgUpload,
}) {
  const { t } = useTranslation();

  return (
    <TastingMenuBlock
      big
      currency={menu.place.city.country.currency}
      restaurantImage={(
        <img
          src={restaurantImage(menu.place.id, menu.place.thumb, 470, 520)}
          alt={menu.place.name}
        />
      )}
      chefImage={menu.place.chefThumb ? (
        <div className="chef-img-wrapper">
          <img src={chefImage(menu.place.id, menu.place.chefThumb, 218, 218)} alt="chef" />
          <label className="add-img" htmlFor="upload-button">
            <Edit className="icon" />
            <input
              type="file"
              id="upload-button"
              hidden
              onChange={handleChefImgUpload}
            />
          </label>
        </div>
      ) : (
        <label className="chef-img-wrapper add-img" htmlFor="upload-button">
          {t('forms:add_photo')}
          <input
            type="file"
            id="upload-button"
            hidden
            onChange={handleChefImgUpload}
          />
        </label>
      )}
      chefName={menu.place.chefName ? (
        <span>
          {menu.place.chefName}
          <Link to="/manage/restaurant-profile">
            <Edit className="icon" />
          </Link>
        </span>
      ) : (
        <Link to="/manage/restaurant-profile">
          {t('pageTastingMenus:add_chef_name')}
        </Link>
      )}
      restaurantName={menu.place.name}
      sets={menu.sets}
      buttons={buttons}
    />
  );
}

TastingMenuWrapper.propTypes = {
  menu: PropTypes.instanceOf(SpecialEventWithPlace).isRequired,
  buttons: PropTypes.element,
  handleChefImgUpload: PropTypes.func,
};

TastingMenuWrapper.defaultProps = {
  buttons: null,
  handleChefImgUpload: null,
};
