/* eslint-disable no-param-reassign */
import React, { useEffect, useContext, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  useLocation, useHistory, Link, Prompt,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import WindowContext from '../../react-web-ui/contexts/WindowContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import Canvas from '../../components/FloorPlan/Canvas';
import useSalons from '../../hooks/useSalons';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';
import Button from '../../react-web-ui/components/Button';
import RequestService from '../../networking/RequestService';
import Spinner from '../../react-web-ui/components/Spinner';
import useExitPrompt from '../../hooks/useExitPrompt';
import { findElementSize } from '../../components/FloorPlan/helpers';
import useCombinations from '../../hooks/useCombinations';
import CombinationDetails from '../../components/TableCombinations/CombinationDetails';
import CombinationSuggestions from '../../components/TableCombinations/CombinationSuggestions';
import ItemTypes from '../../components/FloorPlan/ItemTypes';

export default function TableCombinations() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useExitPrompt(false);
  const [combinationChanged, setCombinationChanged] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [minCanvasSizes, setMinCanvasSizes] = useState({
    width: 0,
    height: 0,
  });
  const [zoom, setZoom] = useState({
    scale: 1,
    step: 0,
  });
  const { isDesktop } = useContext(WindowContext);
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const salons = useSalons(userContext.user, restaurantContext.restaurantId);
  const { hash } = useLocation();
  const history = useHistory();
  const combinations = useCombinations(
    salons.selectedSalon?.id,
    userContext.user,
    restaurantContext.restaurantId,
  );
  const [activeCombination, setActiveCombination] = useState('');

  const getCurrentCombinations = () => (
    {
      id: combinations?.selectedCombination?.id,
      name: salons.selectedSalon?.elements.filter(
          (table) => salons.selectedElements.indexOf(table.id) !== -1
      ).map((selectedTable) => selectedTable.name).join(' / '),
      minCapacity: combinations?.selectedCombination
        ? combinations?.selectedCombination.minCapacity
        : salons.selectedSalon?.elements
          .filter((table) => salons.selectedElements.indexOf(table.id) !== -1)
          .reduce((sum, selectedTable) => selectedTable.minCapacity + sum, 0),
      maxCapacity: combinations?.selectedCombination
        ? combinations?.selectedCombination.maxCapacity
        : salons.selectedSalon?.elements
          .filter((table) => salons.selectedElements.indexOf(table.id) !== -1)
          .reduce((sum, selectedTable) => selectedTable.maxCapacity + sum, 0),
      tables: salons.selectedSalon?.elements.filter(
          (table) => salons.selectedElements.indexOf(table.id) !== -1
      ),
    }
  );

  useEffect(() => {
    document.querySelector('body').classList.add('hide-header');
    return () => {
      document.querySelector('body').classList.remove('hide-header');
    };
  }, []);

  const selectSalonByHash = () => {
    const salonId = hash.replace('#', '');
    const currentSalon = salons.all.filter((salon) => salon.id === salonId)[0];
    currentSalon.elements.forEach((element) => {
      element.size = findElementSize(element.type, element.width, element.height, element.rotation);
    });
    salons.select(currentSalon);
    setOpenSelect(false);
  };

  useEffect(() => {
    if (salons.all.length === 0 || salons.selectedSalon) return;
    selectSalonByHash();
  }, [salons]);

  useEffect(() => {
    if (salons.all.length === 0) return;
    selectSalonByHash();
    salons.selectedElements.forEach((table) => salons.elements.deselect(table));
    combinations.deselect(activeCombination);
    setActiveCombination('');
    setChanged(false);
  }, [hash]);

  const updateFloorPlan = () => {
    setLoading(true);
    if (userContext.user) {
      const requestParams = {
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
        salons: combinations.all.map((salon) => ({
          id: salon.id,
          combinations: combinations.all
            .find((salonWithCombinations) => salonWithCombinations.id === salon.id)?.combinations
              .map((combination) => ({
                id: combination?.id,
                exists: false,
                name: combination.name,
                min_capacity: combination.minCapacity,
                max_capacity: combination.maxCapacity,
                tables: combination.tables.map((table) => table.id),
              })) ?? [],
        })),
      };
      (new RequestService('manager/combinations/save'))
        .setParams(requestParams)
        .send()
        .then((response) => {
          toast.success(t('success:save_combinations_success'));
          combinations.refresh(response.data);
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:could_not_save_combinations'));
        })
        .finally(() => {
          setLoading(false);
          setChanged(false);
        });
    }
  };

  useEffect(() => () => {
    setChanged(false);
  }, []);

  useEffect(() => {
    if (!salons.selectedSalon) return;
    const allX = [];
    const allY = [];
    salons.selectedSalon.elements.forEach((element) => {
      allX.push(element.x + element.width);
      allY.push(element.y + element.height);
    });
    setMinCanvasSizes({
      width: Math.max(...allX),
      height: Math.max(...allY),
    });
  }, [salons.selectedSalon]);

  const findCombinationInSetByTables = (set, items) => set.filter(
    (setItem) => setItem.tables.length === items.length && setItem.tables.filter(
      (setItemTable) => items.map((table) => table.id).indexOf(setItemTable.id) !== -1,
    ).length === items.length,
  );

  useEffect(() => {
    if (salons.selectedElements.length > 1) {
      combinations.current.forEach((combination) => {
        if (combination.tables.length === salons.selectedElements.length) {
          const tableIds = combination.tables.map(({ id }) => id).sort();
          if (JSON.stringify(tableIds) === JSON.stringify(salons.selectedElements.sort())) {
            combinations.select(combination.id);
            setActiveCombination(combination.id);
          }
        }
      });
    } else {
      combinations.deselect(activeCombination);
      setActiveCombination('');
    }
  }, [salons.selectedElements]);

  return (
    <div className="full-width-inner-wrapper">
      <Prompt
        when={changed}
        message={t('changes_may_not_be_saved')}
      />
      <div className="floorplan-top">
        <div className="selected-salon-wrapper">
          <div className="selected-salon">
            <h2>{salons.selectedSalon && salons.selectedSalon.name}</h2>
            <button
              type="button"
              className={`arrow ${openSelect ? 'rotate' : ''}`}
              onClick={() => setOpenSelect(!openSelect)}
            >
              <ArrowDown />
            </button>
          </div>
          {openSelect && (
            <div className="other-salons">
              <ul>
                {salons.all.filter((salon) => salon.id !== hash.replace('#', '')).map((salon) => (
                  <li key={salon.id}>
                    <Link to={`/manage/table-combinations#${salon.id}`}>
                      {salon.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="buttons">
          <button
            type="button"
            className="btn-link btn-discard"
            onClick={() => history.push('/manage/seating-areas')}
          >
            {changed ? t('discard') : t('go_back')}
          </button>
          {isDesktop && (
            <Button
              text={loading ? (
                <>
                  <Spinner />
                  {t('loading')}
                </>
              ) : t('save_changes')}
              classes={`btn-discount ${loading ? 'loading-btn' : ''}`}
              disabled={loading || !changed}
              onClick={() => {
                confirmAlert({
                  // eslint-disable-next-line react/prop-types
                  customUI: ({ onClose }) => (
                    <div className="react-confirm-alert-body">
                      <h2 className="section-title">{t('pageCombinations:are_you_sure_update_combinations')}</h2>
                      <button
                        type="button"
                        onClick={() => {
                          updateFloorPlan();
                          onClose();
                        }}
                      >
                        {t('yes')}
                      </button>
                      <button
                        type="button"
                        onClick={onClose}
                      >
                        {t('no')}
                      </button>
                    </div>
                  ),
                });
              }}
            />
          )}
        </div>
      </div>
      {salons.selectedSalon ? (
        <>
          {isDesktop ? (
            <DndProvider backend={HTML5Backend}>
              <div className="floorplan-content">
                <div className="left-floorplan-column">
                  <h2 className="column-top">
                    {t('pageCombinations:existing_combinations_label')}
                  </h2>
                  {combinations.current.length > 0 ? (
                    <table className="combinations-list">
                      <thead>
                        <tr>
                          <th>{t('pageCombinations:combination_name')}</th>
                          <th>min</th>
                          <th>max</th>
                        </tr>
                      </thead>
                      <tbody>
                        {combinations.current.map((combination) => (
                          <tr
                            key={combination.id}
                            onMouseOver={() => combinations.highlight(combination)}
                            onFocus={() => combinations.highlight(combination)}
                            onMouseLeave={() => combinations.highlight(null)}
                            onClick={() => {
                              const currentSelectedTables = combinations.current.find(
                                (current) => current.id === combination.id,
                              ).tables;
                              if (combinationChanged) {
                                confirmAlert({
                                  // eslint-disable-next-line react/prop-types
                                  customUI: ({ onClose }) => (
                                    <div className="react-confirm-alert-body">
                                      <h2 className="section-title">{t('pageCombinations:are_you_sure_change_combination')}</h2>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          combinations.select(combination.id);
                                          salons.selectedElements.forEach(
                                            (table) => salons.elements.deselect(table),
                                          );
                                          currentSelectedTables.forEach(
                                            (table) => salons.elements.select(table.id),
                                          );
                                          setActiveCombination(combination.id);
                                          combinations.highlight(null);
                                          setCombinationChanged(false);
                                          onClose();
                                        }}
                                      >
                                        {t('yes')}
                                      </button>
                                      <button
                                        type="button"
                                        onClick={onClose}
                                      >
                                        {t('no')}
                                      </button>
                                    </div>
                                  ),
                                });
                              } else {
                                combinations.select(combination.id);
                                salons.selectedElements.forEach(
                                  (table) => salons.elements.deselect(table),
                                );
                                currentSelectedTables.forEach(
                                  (table) => salons.elements.select(table.id),
                                );
                                setActiveCombination(combination.id);
                                combinations.highlight(null);
                              }
                            }}
                            className={activeCombination === combination.id ? 'active' : ''}
                          >
                            <td>{combination.name}</td>
                            <td>{combination.minCapacity}</td>
                            <td>{combination.maxCapacity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="column-top">
                      {t('pageCombinations:no_combinations')}
                    </p>
                  )}
                </div>
                <div className="center-floorplan-column">
                  <div className="column-top">
                    <span />
                    <span className="zoom-options">
                      {t('pageFloorPlan:zoom')}
                      <span className="zoom-scale">
                        {zoom.scale}
                      </span>
                      <button
                        type="button"
                        onClick={() => setZoom({
                          scale: parseFloat((zoom.scale - 0.2).toFixed(2)),
                          step: zoom.step - 1,
                        })}
                        className="zoom-out"
                        disabled={zoom.scale === 0.2}
                      >
                        -
                      </button>
                      <button
                        type="button"
                        onClick={() => setZoom({
                          scale: parseFloat((zoom.scale + 0.2).toFixed(2)),
                          step: zoom.step + 1,
                        })}
                        className="zoom-in"
                        disabled={zoom.scale === 2}
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <Canvas
                    salons={salons}
                    elements={salons.selectedSalon.elements}
                    setChanged={setChanged}
                    zoom={zoom}
                    minCanvasSizes={minCanvasSizes}
                    staticElements
                    onClick={(elementId) => {
                      const selectedElement = salons.selectedSalon.elements.find((element) => (
                        element.id === elementId
                      ));
                      if (selectedElement.type === ItemTypes.CIRCLE.name
                        || selectedElement.type === ItemTypes.SQUARE.name
                        || selectedElement.type === ItemTypes.RECTANGLE.name) {
                        if (salons.selectedElements.find((id) => id === elementId)) {
                          return salons.elements.deselect(elementId);
                        }
                        combinations.deselect(activeCombination);
                        setActiveCombination('');
                        return salons.elements.select(elementId);
                      }
                      return null;
                    }}
                    highlight={combinations.highlightedCombination}
                  />
                </div>
                <div className="right-floorplan-column">
                  {salons.selectedElements.length > 0 && (
                    <CombinationDetails
                      combination={getCurrentCombinations()}
                      activeCombination={activeCombination}
                      setCombinationChanged={setCombinationChanged}
                      onSaveClicked={({
                        id, name, minCapacity, maxCapacity, tables,
                      }) => {
                        const existingCombination = findCombinationInSetByTables(
                          combinations.current,
                          tables,
                        );
                        if (existingCombination.length === 1) {
                          combinations.update({
                            id: existingCombination[0].id,
                            name,
                            minCapacity,
                            maxCapacity,
                            tables,
                          });

                          combinations.deselect(id);
                        } else {
                          combinations.add({
                            name,
                            minCapacity,
                            maxCapacity,
                            tables,
                          });
                        }

                        salons.selectedElements.forEach((table) => salons.elements.deselect(table));
                        setChanged(true);
                        setCombinationChanged(false);
                        combinations.deselect(activeCombination);
                        setActiveCombination('');
                      }}
                      onDeleteClicked={(id) => {
                        combinations.delete(id);
                        combinations.deselect(id);
                        salons.selectedElements.forEach((table) => salons.elements.deselect(table));
                        setChanged(true);
                        setCombinationChanged(false);
                        combinations.deselect(activeCombination);
                        setActiveCombination('');
                      }}
                    />
                  )}
                  {salons.selectedElements.length > 2 && (
                    <CombinationSuggestions
                      currentCombination={getCurrentCombinations()}
                      savedCombinations={
                        combinations.all
                          .find(({ id }) => (id === salons.selectedSalon.id))
                          ?.combinations.map((combination) => ({
                            id: combination?.id,
                            exists: false,
                            name: combination.name,
                            min_capacity: combination.minCapacity,
                            max_capacity: combination.maxCapacity,
                            tables: combination.tables.map((table) => table.id),
                          }))
                      }
                      onAddClicked={(name, minCapacity, maxCapacity, tables) => {
                        const existingCombination = findCombinationInSetByTables(
                          combinations.current,
                          tables,
                        );
                        if (existingCombination.length === 1) {
                          combinations.deselect(existingCombination[0].id);
                        } else {
                          combinations.add({
                            name,
                            minCapacity,
                            maxCapacity,
                            tables,
                          });
                        }
                        setChanged(true);
                      }}
                      doCombinationHighlight={(combination) => {
                        combinations.highlight(combination);
                      }}
                    />
                  )}
                </div>
              </div>
            </DndProvider>
          ) : (
            <h2>{t('pageFloorPlan:mobile_message')}</h2>
          )}
        </>
      ) : t('loading')}
    </div>
  );
}
