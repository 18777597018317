import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import RestaurantContext from '../../contexts/RestaurantContext';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import BookRestaurantForm from '../../components/BookRestaurantForm';
import Checkbox from '../../react-web-ui/components/FormElement/Checkbox';
import FormElement from '../../react-web-ui/components/FormElement';
import ServicesSection from '../../components/ServicesSection';
import MenuSection from '../../components/MenuSection';
import ReviewsSection from '../../components/ReviewsSection';
import { ReactComponent as PlusOnly } from '../../assets/images/icons/PlusOnly.svg';
import Button from '../../react-web-ui/components/Button';
import Tooltip from '../../react-web-ui/components/Tooltip';

export default function Widget() {
  const { t, i18n } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const countryContext = useContext(CountryContext);
  const [services, setServices] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [menu, setMenu] = useState(false);
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'Your widget will help you combine booking from all sources in one location and use your restaurant’s capacity to the maximum.',
      disableBeacon: true,
      placement: 'center',
    },
    {
      target: '.tour-settings',
      content: 'Besides the booking functionality, you can add Services - special events, and tasting menus; Ratings and Reviews, and your A la carte menu. They will appear alongside the booking functionality.',
    },
    {
      target: '.tour-install',
      content: 'Follow the instructions provided to add the widget on your website.',
    },
  ];

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('widget-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('widget-tour-status', 1);
    }
  };

  return (
    <div className="inner-wrapper">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <h1 className="section-title underlined">
        <strong>{t('pageWidget:dineout_widget')}</strong>
      </h1>
      <div className="tour-settings">
        <h3>{t('pageWidget:settings')}</h3>
        <div className="options-inline">
          <Checkbox
            id="services"
            text={t('pageWidget:include_services')}
            checked={services}
            check={() => setServices(!services)}
          />
          <Checkbox
            id="reviews"
            text={t('pageWidget:include_reviews')}
            checked={reviews}
            check={() => setReviews(!reviews)}
          />
          <Checkbox
            id="menu"
            text={t('pageWidget:include_menu')}
            checked={menu}
            check={() => setMenu(!menu)}
          />
        </div>
      </div>
      <h3>{t('pageWidget:preview')}</h3>
      <div className={services || reviews || menu ? 'lg-two-cols' : ''}>
        <div className="col">
          {restaurantContext.restaurant && (
            <BookRestaurantForm
              currency={restaurantContext.restaurant.neighbourhood?.city?.country.currency}
            />
          )}
        </div>
        {services || reviews || menu ? (
          <div className="col">
            {services && (
              <ServicesSection
                restaurantId={restaurantContext.restaurantId}
                name={restaurantContext.restaurant?.name}
                slug={restaurantContext.restaurant?.slug}
              />
            )}
            {reviews && (
              <ReviewsSection
                restaurantId={restaurantContext.restaurantId}
                name={restaurantContext.restaurant?.name}
                restaurantInfo={restaurantContext.restaurant}
              />
            )}
            {menu && (
              <MenuSection
                restaurantId={restaurantContext.restaurantId}
                name={restaurantContext.restaurant?.name}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="tour-install">
        <h3>{t('pageWidget:how_to_install')}</h3>
        <p>{t('pageWidget:include_script')}</p>
        <div className="copy-input">
          <FormElement
            id="widget-script"
            elementType="input"
            placeholder="Script"
            value={`<script src="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.js"></script>`}
            icon={<PlusOnly className="icon" />}
            changed={() => {}}
          />
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(`<script src="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.js"></script>`);
              toast.success(t('success:copied'));
            }}
          >
            {t('pageWidget:copy')}
          </button>
        </div>
        <p>{t('pageWidget:include_css')}</p>
        <div className="copy-input">
          <FormElement
            id="widget-css"
            elementType="input"
            placeholder="Script"
            value={`<link href="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.css">`}
            icon={<PlusOnly className="icon" />}
            changed={() => {}}
          />
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(`<link href="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.css">`);
              toast.success(t('success:copied'));
            }}
          >
            {t('pageWidget:copy')}
          </button>
        </div>
        <p>{t('pageWidget:include_widget')}</p>
        <div className="copy-input">
          <FormElement
            id="widget-div"
            elementType="input"
            placeholder={t('forms:first_name')}
            value={`<div class="dineout-widget" data-lang="${i18n.language}" data-type="app" data-id="${restaurantContext.restaurantId}" ${services ? 'data-services="true"' : ''} ${reviews ? 'data-reviews="true"' : ''} ${menu ? 'data-menu="true"' : ''} data-os="referral-name.com"></div>`}
            icon={<PlusOnly className="icon" />}
            changed={() => {}}
          />
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(`<div class="dineout-widget" data-lang="${i18n.language}" data-type="app" data-id="${restaurantContext.restaurantId}" ${services ? 'data-services="true"' : ''} ${reviews ? 'data-reviews="true"' : ''} ${menu ? 'data-menu="true"' : ''} data-os="referral-name.com"></div>`);
              toast.success(t('success:copied'));
            }}
          >
            {t('pageWidget:copy')}
          </button>
        </div>
      </div>
    </div>
  );
}
