import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import ButtonLink from '../../react-web-ui/components/ButtonLink';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import appleStore from '../../assets/images/apple-store.png';
import googleStore from '../../assets/images/google-play-button.png';
import illustration5 from '../../assets/images/illustrations/illustration5.svg';
import qrCodeBg from '../../assets/images/host-landing-bg.svg';
import qrCodePl from '../../assets/images/host-landing-pl.svg';

const packageJson = require('../../../package.json');

/**
 * Main Footer component
 * @returns {*}
 * @constructor
 */
const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const countryContext = useContext(CountryContext);
  const userContext = useContext(UserContext);
  const [qrCodeSrc, setQrCodeSrc] = useState(UserContext);

  useEffect(() => {
    if (countryContext.country.code === 'BG') {
      setQrCodeSrc(qrCodeBg);
    }
    if (countryContext.country.code === 'PL') {
      setQrCodeSrc(qrCodePl);
    }
  }, [countryContext.country]);

  return (
    <>
      {!userContext.isLoggedIn
        && (location.pathname !== '/join')
        && (
          <ButtonLink
            text={t('navigation:nav_join_now')}
            classes="btn-accent"
            href="/join"
            target="_blank"
          />
        )}
      <footer className="page-footer">
        {userContext.isLoggedIn && (
          <div className="footer-top">
            <div className="inner-wrapper">
              <div className="grid">
                <div className="grid-col-3">
                  <h3>{t('footer:quick_access')}</h3>
                  <ul>
                    <li>
                      <NavLink to="/manage/bookings">{t('navigation:nav_bookings')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/manage/menu">{t('navigation:nav_menu')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/manage/restaurant-profile">{t('navigation:nav_restaurant_profile')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/monetize/special-offers">{t('navigation:nav_special_offers')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/manage/employees">{t('navigation:nav_subscriptions')}</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="grid-col-4">
                  <h3>{t('footer:manage_your_business')}</h3>
                  <p>
                    {t('footer:manage_your_business_text')}
                  </p>
                  <div className="mobile-app-stores">
                    <div className="links">
                      <a href="https://apps.apple.com/us/app/dineout-host/id1527947811">
                        <img src={appleStore} alt="" />
                        <span>{t('footer:for_ipad')}</span>
                      </a>
                      <a href="https://apps.apple.com/us/app/dineout-host-lite/id1527950208">
                        <img src={appleStore} alt="" />
                        <span>{t('footer:for_iphone')}</span>
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.bsbm.dineout.admin">
                        <img src={googleStore} alt="" />
                        <span>{t('footer:for_all_devices')}</span>
                      </a>
                    </div>
                    <div className="qr-code">
                      <img src={qrCodeSrc} alt="" />
                    </div>
                  </div>
                </div>
                <div className="grid-col-3">
                  <img className="footer-illustration" src={illustration5} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer-bottom">
          <div className="inner-wrapper">
            <div className="footer-bottom-content">
              <ul>
                <li>
                  <a href={`https://dineout.${countryContext.country.code.toLowerCase()}/about-us`}>
                    {t('footer:about_us')}
                  </a>
                </li>
                <li>
                  <NavLink to="/what-you-pay">{t('navigation:what_you_pay')}</NavLink>
                </li>
                <li>
                  <a href={`https://dineout.${countryContext.country.code.toLowerCase()}/terms-of-service`}>
                    {t('footer:terms_of_use')}
                  </a>
                </li>
                {countryContext.country.code.toLowerCase() === 'pl' && (
                  <li>
                    <a href={`https://dineout.${countryContext.country.code.toLowerCase()}/privacy-policy`}>
                      {t('footer:privacy_policy')}
                    </a>
                  </li>
                )}
              </ul>
              <p>
                {`v.${packageJson.version}`}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
