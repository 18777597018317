import React from 'react';
import PropTypes from 'prop-types';

/**
 * Modal
 * @param title
 * @param children
 * @param className
 * @param setShowPopup
 * @returns {*}
 * @constructor
 */
export default function Modal({
  title,
  children,
  className,
  setShowPopup,
}) {
  const handleClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowPopup(false);
    }
  };

  return (
    <div
      className={`modal ${className}`}
      role="button"
      tabIndex="0"
      onClick={handleClick}
      onKeyUp={handleClick}
    >
      <div className="modal-inner">
        <h2 className="section-title">{title}</h2>
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  setShowPopup: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  title: '',
  className: '',
};
