import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import PlaceManager from '../../networking/resources/PlaceManager';
import ButtonLink from '../../react-web-ui/components/ButtonLink';
import RestaurantBlock from '../../react-web-ui/components/RestaurantBlock';
import { ReactComponent as Clock } from '../../react-web-ui/assets/images/icons/Clock.svg';

/**
* Add discouunt block
* @param restaurantInfo
* @returns {*}
* @constructor
*/
export default function AddSpecialOfferBlock({ restaurantInfo }) {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <RestaurantBlock
      className="add-special-offer-block"
      imageBlock={(
        <div className="img-wrapper"><span className="no-img" /></div>
      )}
      title={(
        <>
          <span className="validity">
            <Clock className="icon" />
            {t('pageSpecialOffers:validity')}
          </span>
          {t('pageSpecialOffers:your_special_offer_title')}
        </>
      )}
      cuisines={restaurantInfo?.name}
      button={(
        <ButtonLink
          text={t('pageSpecialOffers:add_offer')}
          classes="btn-accent"
          href={`${location.pathname}/type`}
        />
      )}
    />
  );
}

AddSpecialOfferBlock.propTypes = {
  restaurantInfo: PropTypes.instanceOf(PlaceManager).isRequired,
};
