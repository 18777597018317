import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RestaurantTitle from '../../../react-web-ui/components/RestaurantInfo/RestaurantTitle';
import EditableText from '../../EditableText';

export default function RestaurantTitleWrapper({
  locale,
  currentRestaurantInfo,
  setCurrentRestaurantInfo,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <RestaurantTitle
      title={(
        <EditableText
          name="restaurant_name"
          placeholder={t('pageRestaurantProfile:add_name')}
          valueEn={currentRestaurantInfo._name}
          valueTranslation={currentRestaurantInfo._translations[locale].name}
          onChangeEn={(e) => {
            const copyRestaurantInfo = { ...currentRestaurantInfo };
            copyRestaurantInfo._name = e.target.value;
            setCurrentRestaurantInfo(copyRestaurantInfo);
          }}
          onChangeTranslation={(e) => {
            const copyRestaurantInfo = { ...currentRestaurantInfo };
            copyRestaurantInfo._translations[locale].name = e.target.value;
            setCurrentRestaurantInfo(copyRestaurantInfo);
          }}
          disabled={disabled}
        />
      )}
      subtitle={(
        <EditableText
          name="restaurant_subtitle"
          placeholder={t('pageRestaurantProfile:add_subtitle')}
          valueEn={currentRestaurantInfo._subtitle}
          valueTranslation={currentRestaurantInfo._translations[locale].subtitle}
          onChangeEn={(e) => {
            const copyRestaurantInfo = { ...currentRestaurantInfo };
            copyRestaurantInfo._subtitle = e.target.value;
            setCurrentRestaurantInfo(copyRestaurantInfo);
          }}
          onChangeTranslation={(e) => {
            const copyRestaurantInfo = { ...currentRestaurantInfo };
            copyRestaurantInfo._translations[locale].subtitle = e.target.value;
            setCurrentRestaurantInfo(copyRestaurantInfo);
          }}
          disabled={disabled}
        />
      )}
    />
  );
}

RestaurantTitleWrapper.propTypes = {
  locale: PropTypes.string,
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
  setCurrentRestaurantInfo: PropTypes.func,
  disabled: PropTypes.bool,
};

RestaurantTitleWrapper.defaultProps = {
  locale: 'en',
  currentRestaurantInfo: {},
  setCurrentRestaurantInfo: null,
  disabled: false,
};
