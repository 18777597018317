import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import SubscriptionInfo from '../../components/SubscriptionInfo/SubscriptionInfo';
import useFeatures from '../../hooks/useFeatures';
import { ReactComponent as Tick } from '../../assets/images/icons/Tick.svg';
import { ReactComponent as Plus } from '../../assets/images/icons/PlusOnly.svg';
import Loader from '../../assets/images/loader.gif';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Subscribe() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get('plan');
  const price = searchParams.get('price');
  const setupIntent = searchParams.get('setup_intent');
  const setupIntentClientSecret = searchParams.get('setup_intent_client_secret');
  const { pricingBlocks } = useFeatures();

  return (
    <div className="inner-wrapper">
      <h2 className="section-title">
        <strong>{t('pageSubscriptions:processing')}</strong>
      </h2>
      <div className="grid bigger-gap">
        <div className="grid-col-4">
          <div className="pricing-block vertical">
            <span className="ribbon-above" />
            <div className="shadow-block">
              <header className="pricing-header">
                <h3>{plan}</h3>
              </header>
              <div className="pricing-content">
                {pricingBlocks.map((block, index) => block.value === plan && (
                  <React.Fragment key={block.value}>
                    {block.value === 'PRO' && (
                      <div className="pricing-option">
                        <Tick className="icon" />
                        <p>
                          {t('pageSubscriptions:all')}
                          {' '}
                          <strong className="text-accent">LITE</strong>
                          {' '}
                          {t('pageSubscriptions:features')}
                        </p>
                      </div>
                    )}
                    {block.value === 'PREMIUM' && (
                      <div className="pricing-option">
                        <Tick className="icon" />
                        <p>
                          {t('pageSubscriptions:all')}
                          {' '}
                          <strong className="text-accent">PRO</strong>
                          {' '}
                          {t('pageSubscriptions:features')}
                        </p>
                      </div>
                    )}
                    {block.features.map((feature) => (
                      <div key={feature} className="pricing-option">
                        {block.value === 'FREE' ? (
                          <Tick className="icon" />
                        ) : (
                          <Plus className="icon" />
                        )}
                        <p>{feature}</p>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="grid-col-4">
          <div className="loader-wrapper"><img src={Loader} alt="" /></div>
          {setupIntentClientSecret && (
            <Elements
              stripe={stripePromise}
              locale={i18n.language}
              options={{
                clientSecret: setupIntentClientSecret,
              }}
            >
              <SubscriptionInfo
                plan={plan}
                price={price}
                setupIntent={setupIntent}
                setupIntentClientSecret={setupIntentClientSecret}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
}
