import i18n from 'i18next';

function isValidURL(string) {
  // eslint-disable-next-line no-useless-escape
  const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null);
}

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    urlInvalid: i18n.t('forms:url_invalid'),
  };

  // fb link
  errors.facebook_link = [];
  if (values.facebook_link && !isValidURL(values.facebook_link)) {
    errors.facebook_link.push(errorTexts.urlInvalid);
  }

  // tripadvisor link
  errors.tripadvisor_link = [];
  if (values.tripadvisor_link && !isValidURL(values.tripadvisor_link)) {
    errors.tripadvisor_link.push(errorTexts.urlInvalid);
  }

  return errors;
};

export default validate;
