import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import { initialInputList } from '../../pages/Monetize/Events/constants';
import EventMenuItems from '../EventMenuItems';
import FormElement from '../../react-web-ui/components/FormElement';
import Button from '../../react-web-ui/components/Button';
import CountryContext from '../../react-web-ui/contexts/CountryContext';

/**
* Event menu section
* @param inputList
* @param setInputList
* @param errors
* @param setErrors
* @returns {*}
* @constructor
*/
export default function EventMenuSection({
  inputList,
  setInputList,
  errors,
  setErrors,
  events,
  sectionTitle,
}) {
  const { t, i18n } = useTranslation();
  const countryContext = useContext(CountryContext);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = JSON.parse(JSON.stringify(inputList));
    const currentErrors = JSON.parse(JSON.stringify(errors));
    list[index][name] = value;
    if (value && value.trim() !== '' && currentErrors[index]) {
      currentErrors[index][name] = [];
    }
    setInputList(list);
    setErrors(currentErrors);
  };

  const handleAddMenuClick = () => {
    setInputList([...inputList, JSON.parse(JSON.stringify(initialInputList))]);
  };

  const translateText = (englishParam, englishValue, otherParam, otherValue, index) => {
    const list = JSON.parse(JSON.stringify(inputList));
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [englishValue];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [otherValue];
      params.target_language_code = 'en';
    }

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          list[index][otherParam] = translations[0].text;
        } else {
          list[index][englishParam] = translations[0].text;
        }
        setInputList(list);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  return (
    <div className="section">
      <p className="label">{sectionTitle }</p>
      {inputList && inputList.map(({
        menuNameEn,
        menuNameTranslation,
        menuPriceNoWine,
        menuPriceWithWine,
      }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="grid-group" key={index}>
          <div
            className={`tour-menu-options
            clear-both
            invertible-grid 
            ${(i18n.language === 'bg' || i18n.language === 'pl') ? 'inverted' : ''}
            `}
          >
            <div className="tour-menu-name grid">
              <div className="grid-col-5">
                <FormElement
                  id={`menuNameEn${index}`}
                  name="menuNameEn"
                  elementType="input"
                  placeholder={t('forms:enter_menu_name_en')}
                  value={menuNameEn}
                  errors={errors[index] && errors[index].menuNameEn}
                  changed={(e) => handleInputChange(e, index)}
                />
              </div>
              <div className="grid-col-5">
                <FormElement
                  id={`menuNameTranslation${index}`}
                  name="menuNameTranslation"
                  elementType="input"
                  placeholder={t(`forms:enter_menu_name_translation_${countryContext.country.name}`)}
                  value={menuNameTranslation}
                  errors={errors[index] && errors[index].menuNameTranslation}
                  changed={(e) => handleInputChange(e, index)}
                />
              </div>
              <button
                type="button"
                className="translate-fields"
                onClick={() => translateText('menuNameEn', menuNameEn, 'menuNameTranslation', menuNameTranslation, index)}
              >
                {' '}
              </button>
            </div>
            <EventMenuItems
              inputList={inputList}
              setInputList={setInputList}
              index={index}
              errors={errors}
              setErrors={setErrors}
              events={events}
            />
          </div>
          <div className="grid">
            <div className="tour-price grid-col-5">
              <FormElement
                id={`menuPriceNoWine${index}`}
                name="menuPriceNoWine"
                elementType="input"
                inputType="number"
                placeholder={t('forms:enter_price_without_drinks')}
                value={menuPriceNoWine}
                errors={errors[index] && errors[index].menuPriceNoWine}
                changed={(e) => handleInputChange(e, index)}
              />
            </div>
            <div className="tour-drinks grid-col-5">
              <FormElement
                id={`menuPriceWithWine${index}`}
                name="menuPriceWithWine"
                elementType="input"
                inputType="number"
                placeholder={t('forms:enter_price_with_drinks')}
                value={menuPriceWithWine || ''}
                changed={(e) => handleInputChange(e, index)}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="tour-add-menu">
        <Button
          text={t('add_another_option')}
          classes=" btn-accent btn-outline add-another-btn"
          onClick={handleAddMenuClick}
        />
      </div>
    </div>
  );
}

EventMenuSection.propTypes = {
  inputList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setInputList: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setErrors: PropTypes.func.isRequired,
  events: PropTypes.bool,
  sectionTitle: PropTypes.string,
};

EventMenuSection.defaultProps = {
  events: false,
  sectionTitle: '',
};
