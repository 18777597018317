import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/pl';
import { DayPickerSingleDateController } from 'react-dates';
import { useTranslation } from 'react-i18next';

import useOutsideClick from '../../react-web-ui/hooks/useOutsideClick';
import { ReactComponent as Calendar } from '../../react-web-ui/assets/images/icons/Calendar.svg';
import { ReactComponent as ArrowDown } from '../../react-web-ui/assets/images/icons/ArrowDown.svg';

/**
 * Date picker
 * @param date
 * @param start
 * @param end
 * @param placeholder
 * @param handleDate
 * @returns {*}
 * @constructor
 */
export default function DatePicker({
  date,
  start,
  end,
  placeholder,
  handleDate,
}) {
  const ref = useRef();
  const { i18n } = useTranslation();
  const [openCalendar, setOpenCalendar] = useState(false);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  // useOutsideClick(ref, () => {
  //   if (openCalendar) {
  //     setOpenCalendar(false);
  //   }
  // });

  // console.log(openCalendar);

  return (
    <div
      className="date-picker"
      role="button"
      tabIndex="0"
      onClick={() => {
        setOpenCalendar(!openCalendar);
      }}
      onKeyPress={() => {
        setOpenCalendar(!openCalendar);
      }}
    >
      <Calendar className="icon" />
      <div className={`date-result ${openCalendar ? 'is-open' : ''}`}>
        {date ? moment(date._d).format('DD.MM.YYYY') : placeholder}
      </div>
      {openCalendar && (
        <div ref={ref} className="pick-date">
          <DayPickerSingleDateController
            onDateChange={(event) => {
              handleDate(event);
              setOpenCalendar(false);
            }}
            date={date}
            isOutsideRange={
              (day) => day.isBefore(moment().startOf('day'))
                || day.isBefore(start)
                || day.isAfter(end)
            }
            numberOfMonths={1}
            navPrev={(
              <button className="calendar-prev" type="button">
                <ArrowDown />
              </button>
            )}
            navNext={(
              <button className="calendar-next" type="button">
                <ArrowDown />
              </button>
            )}
            noBorder
            hideKeyboardShortcutsPanel
          />
        </div>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  /**
  * Default date
  */
  date: PropTypes.instanceOf(moment),
  /**
  * Function on date click
  */
  handleDate: PropTypes.func.isRequired,
  /**
  * placeholder
  */
  placeholder: PropTypes.string,
  start: PropTypes.instanceOf(moment),
  end: PropTypes.instanceOf(moment),
};

DatePicker.defaultProps = {
  placeholder: '',
  start: null,
  end: null,
  date: null,
};
