import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import RequestService from '../../../networking/RequestService';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import PageHeading from '../../../react-web-ui/components/PageHeading';
import ButtonLink from '../../../react-web-ui/components/ButtonLink';
import AddSpecialOfferBlock from '../../../components/AddSpecialOfferBlock';
import SpecialOfferBlock from '../../../components/SpecialOfferBlock';
import Button from '../../../react-web-ui/components/Button';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import Tooltip from '../../../react-web-ui/components/Tooltip';

export default function SpecialOffersListing() {
  const { t } = useTranslation();
  const location = useLocation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [requestDone, setRequestDone] = useState(false);
  const [specialOffers, setSpecialOffers] = useState([]);
  const [specialOffersActive, setSpecialOffersActive] = useState([]);
  const [specialOffersDraft, setSpecialOffersDraft] = useState([]);
  const [steps, setSteps] = useState([]);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (!userContext.user || !restaurantContext.restaurantId) return;
    (new RequestService('manager/special-offers/get'))
      .setParams({
        user_id: userContext.user.id,
        place_id: restaurantContext.restaurantId,
      })
      .send()
      .then((response) => {
        setRequestDone(true);
        setSpecialOffers(response.data);
        setSpecialOffersActive(response.data.filter((item) => item.status === 'Active'));
        setSpecialOffersDraft(response.data.filter((item) => item.status === 'Draft'));
        let newSteps = [];
        if (response.data.length > 0) {
          newSteps = [
            {
              target: '.tour-all-restaurants',
              content: 'All your special offers appear here, grouped by status - draft and active. You have to add a photo which is automatically saved to drafts. You can also edit or delete the offers. You need to unpublish active offers before editing or deleting them. Once you publish an offer it is visible to all customers.',
              disableBeacon: true,
            },
          ];
          if (response.data.filter((item) => item.status === 'Active').length > 0) {
            newSteps = [
              {
                target: '.tour-unpublish',
                content: 'In order to edit or delete an offer you need to unpublish it. If there are bookings for the offer you need to cancel them and notify the guests before being able to unpublish the offer.',
                disableBeacon: true,
              },
            ];
          }
        }
        if (newSteps.length > 0) {
          setSteps(newSteps);
        } else {
          setSteps([
            {
              target: '.page-heading-discount',
              content: 'The Special offers section provides a wide variety of instruments to attract new guests. Start by adding a new offer.',
              disableBeacon: true,
            },
          ]);
        }
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_offers'));
      });
  }, [t, restaurantContext.restaurantId, userContext.user, loading]);

  const handleRequest = (endpoint, offerId) => {
    setLoading(true);
    startListening();
    (new RequestService(`manager/special-offers/${endpoint}`))
      .setParams({
        user_id: userContext.user.id,
        special_offer_id: offerId,
      })
      .send()
      .then(() => toast.success(t(`success:${endpoint}_offer_success`)))
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t(`apiErrors:could_not_${endpoint}_offer`));
      })
      .finally(() => {
        setLoading(false);
        endListening();
      });
  };

  const handleDelete = (offerId) => {
    handleRequest('remove', offerId);
  };

  const handlePublish = (offerId) => {
    handleRequest('publish', offerId);
  };

  const handleUnpublish = (offerId) => {
    handleRequest('unpublish', offerId);
  };

  const handleImgUpload = (offerId, e) => {
    setLoading(true);
    startListening();
    if (e.target.files.length) {
      (new RequestService('manager/special-offers/thumb'))
        .forFileUpload()
        .setParams({
          special_offer_id: offerId,
          user_id: userContext.user.id,
          thumb: e.target.files[0],
        })
        .send()
        .then(() => toast.success(t('success:offer_thumb_upload_success')))
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:could_not_upload_image'));
        })
        .finally(() => {
          setLoading(false);
          endListening();
        });
    }
  };

  // start tour if first time visit
  useEffect(() => {
    let tourStatus = 0;

    if (specialOffers.length === 0) {
      tourStatus = localStorage.getItem('special-offers-tour-status') ? localStorage.getItem('special-offers-tour-status') : 0;
    } else {
      tourStatus = localStorage.getItem('special-offers-second-tour-status') ? localStorage.getItem('special-offers-second-tour-status') : 0;
    }
    if (specialOffersActive.length > 0) {
      tourStatus = localStorage.getItem('special-offers-third-tour-status') ? localStorage.getItem('special-offers-third-tour-status') : 0;
    }

    if (tourStatus === 0) {
      setRun(true);
    }
  }, [specialOffers, specialOffersDraft, specialOffersActive]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      if (specialOffers.length === 0) {
        localStorage.setItem('special-offers-tour-status', 1);
      }
      if (specialOffersDraft.length > 0) {
        localStorage.setItem('special-offers-second-tour-status', 1);
      }
      if (specialOffersActive.length > 0) {
        localStorage.setItem('special-offers-third-tour-status', 1);
        localStorage.setItem('special-offers-second-tour-status', 1);
        localStorage.setItem('special-offers-tour-status', 1);
      }
    }
  };

  return (
    <div className="inner-wrapper discount-page">
      {requestDone && (
        <JoyRide
          steps={steps}
          continuous
          showSkipButton
          showProgress
          disableScrolling
          run={run}
          locale={{
            back: t('walktrough:back'),
            close: t('walktrough:close'),
            last: t('walktrough:last'),
            next: t('walktrough:next'),
            skip: t('walktrough:skip'),
          }}
          styles={{
            options: {
              primaryColor: '#ffae42',
            },
          }}
          callback={handleJoyrideCallback}
        />
      )}
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => {
              setSteps([
                {
                  target: '.page-heading-discount',
                  content: 'The Special offers section provides a wide variety of instruments to attract new guests. Start by adding a new offer.',
                  disableBeacon: true,
                },
                {
                  target: '.tour-all-restaurants',
                  content: 'All your special offers appear here, grouped by status - draft and active. You have to add a photo which is automatically saved to drafts. You can also edit or delete the offers. You need to unpublish active offers before editing or deleting them. Once you publish an offer it is visible to all customers.',
                  disableBeacon: true,
                },
                {
                  target: '.tour-unpublish',
                  content: 'In order to edit or delete an offer you need to unpublish it. If there are bookings for the offer you need to cancel them and notify the guests before being able to unpublish the offer.',
                  disableBeacon: true,
                },
              ]);
              setRun(true);
            }}
          />
        </Tooltip>
      </div>
      <PageHeading
        classes="page-heading-discount"
        title={t('pageSpecialOffers:title')}
        content={(
          <ButtonLink
            href={`${location.pathname}/type`}
            text={t('pageSpecialOffers:add_offer')}
            classes="btn-accent btn-outline"
          />
        )}
      />
      {specialOffers.length === 0 && restaurantContext.restaurant && (
        <div className="restaurant-list-grid">
          <AddSpecialOfferBlock restaurantInfo={restaurantContext.restaurant} />
        </div>
      )}
      <div className={`${specialOffersActive.length > 0 || specialOffersDraft.length > 0 ? 'tour-all-restaurants' : ''}`}>
        {specialOffersActive.length > 0 && (
          <>
            <h2 className="section-title underlined">
              <strong>{t('pageSpecialOffers:active_offers')}</strong>
            </h2>
            <div className="restaurant-list-grid">
              {specialOffersActive.map((offer) => (
                <SpecialOfferBlock
                  key={offer.id}
                  restaurantInfo={restaurantContext.restaurant}
                  offer={offer}
                  handleImgUpload={handleImgUpload}
                  buttons={(
                    <div className="multiple-buttons">
                      <Button
                        text={t('unpublish')}
                        classes="tour-unpublish btn-accent btn-outline right"
                        onClick={
                          () => handleUnpublish(offer.id)
                        }
                        disabled={loading}
                      />
                    </div>
                  )}

                />
              ))}
            </div>
          </>
        )}
        {specialOffersDraft.length > 0 && (
          <>
            <h2 className="section-title underlined">
              <strong>{t('pageSpecialOffers:draft_offers')}</strong>
            </h2>
            <div className="restaurant-list-grid">
              {specialOffersDraft.map((offer) => (
                <SpecialOfferBlock
                  key={offer.id}
                  restaurantInfo={restaurantContext.restaurant}
                  offer={offer}
                  handleImgUpload={handleImgUpload}
                  buttons={(
                    <div className="multiple-buttons">
                      <Button
                        text={t('publish')}
                        classes="btn-accent"
                        onClick={() => {
                          confirmAlert({
                            // eslint-disable-next-line react/prop-types
                            customUI: ({ onClose }) => (
                              <div className="react-confirm-alert-body discount-page">
                                <h2 className="section-title">{t('pageSpecialOffers:are_you_sure_publish')}</h2>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handlePublish(offer.id);
                                    onClose();
                                  }}
                                >
                                  {t('yes')}
                                </button>
                                <button
                                  type="button"
                                  onClick={onClose}
                                >
                                  {t('no')}
                                </button>
                              </div>
                            ),
                          });
                        }}
                        disabled={loading}
                      />
                      <ButtonLink
                        href={{
                          pathname: `${location.pathname}/edit/${offer.id}`,
                          state: {
                            type: offer.type,
                          },
                        }}
                        text={t('edit')}
                        classes="btn-accent right"
                        disabled={loading}
                      />
                    </div>
                  )}
                  removeBtn={(
                    <button
                      type="button"
                      className="btn-link btn-delete-offer"
                      onClick={() => {
                        confirmAlert({
                          // eslint-disable-next-line react/prop-types
                          customUI: ({ onClose }) => (
                            <div className="react-confirm-alert-body discount-page">
                              <h2 className="section-title">{t('pageSpecialOffers:are_you_sure_delete')}</h2>
                              <button
                                type="button"
                                onClick={() => {
                                  handleDelete(offer.id);
                                  onClose();
                                }}
                              >
                                {t('yes')}
                              </button>
                              <button
                                type="button"
                                onClick={onClose}
                              >
                                {t('no')}
                              </button>
                            </div>
                          ),
                        });
                      }}
                      disabled={loading}
                    >
                      {t('delete')}
                    </button>
                  )}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
