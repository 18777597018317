import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import JoyRide, { STATUS } from 'react-joyride';
import { Link } from 'react-router-dom';

import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import ShadowBlock from '../../components/ShadowBlock';
import AddNewShadowBlock from '../../components/AddNewShadowBlock';
import Button from '../../react-web-ui/components/Button';
import Tooltip from '../../react-web-ui/components/Tooltip';
import AddSalonForm from '../../components/AddSalonForm';
import { ReactComponent as External } from '../../assets/images/icons/External.svg';
import EditSalonForm from '../../components/EditSalonForm';
import ItemTypes from '../../components/FloorPlan/ItemTypes';
import RequestService from '../../networking/RequestService';

export default function SeatingAreas() {
  const { t } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [itemsEditing, setItemsEditing] = useState([]);
  const [showSuccess, setShowSuccess] = useState([]);
  const [showAdd, setShowAdd] = useState(true);
  const [loading, setLoading] = useState(false);
  const [run, setRun] = useState(false);
  const [adding, setAdding] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [salons, setSalons] = useState([]);

  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'Manage your seating areas here.',
      disableBeacon: true,
      placement: 'center',
    },
  ];

  useEffect(() => {
    if (!userContext.user || !restaurantContext.restaurantId) return;
    (new RequestService('manager/floorplan/get'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setSalons(response.data);
        const editing = [];
        response.data.forEach(() => {
          editing.push(false);
        });
        setItemsEditing(editing);
        setRefresh(false);
      })
      .catch(() => null);
  }, [userContext.user, restaurantContext.restaurantId, refresh]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('seating-areas-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('seating-areas-tour-status', 1);
    }
  };

  // console.log(salons);

  return (
    <div className="inner-wrapper">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <h1 className="section-title underlined">
        <strong>{t('pageSeatingAreas:title')}</strong>
      </h1>
      <div className="shadow-grid">
        {salons.map((salon, index) => {
          let max = 0;
          salon.elements.forEach((table) => {
            if (table.maxCapacity) max += table.maxCapacity;
          });
          return (
            <ShadowBlock
              key={salon.id}
              item={salon}
              name={itemsEditing[index] ? '' : (
                <>
                  <span>{salon.name}</span>
                  <div className="salon-statuses">
                    {salon.active ? (
                      <small className="seating-status">{t('pageEmployees:active')}</small>
                    ) : (
                      <small className="seating-status inactive">{t('pageEmployees:inactive')}</small>
                    )}
                    {salon.outside ? (
                      <small className="seating-status outside">{t('pageWidget:outside')}</small>
                    ) : (
                      <small className="seating-status inside">{t('pageWidget:inside')}</small>
                    )}
                  </div>
                </>
              )}
              subtitle={!itemsEditing[index] ? (
                <>
                  <span className="additional-info">
                    <strong>
                      {salon.elements.filter((element) => (
                        element.type === ItemTypes.SQUARE.name
                        || element.type === ItemTypes.CIRCLE.name
                        || element.type === ItemTypes.RECTANGLE.name
                      )).length}
                    </strong>
                    {' '}
                    {t('pageSeatingAreas:tables')}
                  </span>
                  <span className="additional-info">
                    {t('pageSeatingAreas:max_capacity')}
                    {' '}
                    <strong>{max}</strong>
                  </span>
                </>
              ) : ''}
              loading={loading}
              setLoading={setLoading}
              setShowAdd={setShowAdd}
              index={index}
              itemsEditing={itemsEditing}
              setItemsEditing={setItemsEditing}
              successMessage={t('pageSeatingAreas:salon_updated')}
              showSuccess={showSuccess}
              setShowSuccess={setShowSuccess}
              footer={!itemsEditing[index] ? (
                <>
                  <Link to={`/manage/floor-plan#${salon.id}`} className="external-link text-accent">
                    <External className="icon" />
                    {t('pageSeatingAreas:edit_floorplan')}
                  </Link>
                  <Link to={`/manage/table-combinations#${salon.id}`} className="external-link text-accent">
                    <External className="icon" />
                    {t('pageSeatingAreas:edit_table_combinations')}
                  </Link>
                </>
              ) : (
                <></>
              )}
              editingForm={(
                <EditSalonForm
                  salon={salon}
                  index={index}
                  itemsEditing={itemsEditing}
                  setItemsEditing={setItemsEditing}
                  setLoading={setLoading}
                  setShowAdd={setShowAdd}
                  setRefresh={setRefresh}
                />
              )}
            />
          );
        })}
        {showAdd && (
          <AddNewShadowBlock
            setLoading={setLoading}
            title={t('pageSeatingAreas:new_salon')}
            adding={adding}
            setAdding={setAdding}
            fields={(
              <AddSalonForm
                setLoading={setLoading}
                setAdding={setAdding}
                setRefresh={setRefresh}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}
