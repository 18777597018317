import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'react-multi-select-component';
import { toast } from 'react-toastify';

import CountryContext from '../../../react-web-ui/contexts/CountryContext';
import InfoItem from '../../../react-web-ui/components/RestaurantInfo/InfoItem';
import RequestService from '../../../networking/RequestService';
import { ReactComponent as Cuisine } from '../../../react-web-ui/assets/images/icons/Cuisine.svg';
import { ReactComponent as Edit } from '../../../assets/images/icons/Edit.svg';

export default function CuisinesInfoItem({
  currentRestaurantInfo,
  setCurrentRestaurantInfo,
  disabled,
}) {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);
  const [edit, setEdit] = React.useState(false);
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const cuisinesArray = [];

  useEffect(() => {
    (new RequestService('client/cuisines/all'))
      .setParams({
        country_id: countryContext.country.id,
      })
      .send()
      .then((response) => {
        setCuisines(response.data);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_cuisines'));
      });
  }, [t, countryContext.country.id]);

  useEffect(() => {
    if (!currentRestaurantInfo._cuisines) return;
    const initialCuisines = [];
    cuisines.forEach((cuisine) => {
      currentRestaurantInfo._cuisines.forEach((item) => {
        if (cuisine._id === item._id) {
          initialCuisines.push({
            label: cuisine._name,
            value: cuisine._id,
          });
        }
      });
    });
    setSelectedCuisines(initialCuisines);
  }, [cuisines]);

  useEffect(() => {
    if (selectedCuisines.length === 0) return;
    const copyRestaurantInfo = { ...currentRestaurantInfo };
    const newCuisines = [];
    selectedCuisines.forEach((cuisine) => {
      newCuisines.push({
        _id: cuisine.value,
        _name: cuisine.label,
        _count: null,
      });
    });
    copyRestaurantInfo._cuisines = newCuisines;
    setCurrentRestaurantInfo(copyRestaurantInfo);
  }, [selectedCuisines]);

  for (let i = 0; i < cuisines.length; i += 1) {
    cuisinesArray.push({
      label: cuisines[i]._name,
      value: cuisines[i]._id,
    });
  }

  return (
    <div className={`tour-cuisines editable-info-item ${edit ? 'editing' : ''}`}>
      <InfoItem
        icon={<Cuisine className="icon" />}
        title={t('pageRestaurantProfile:cuisines')}
        text={edit ? (
          <>
            <div className="form-group">
              <MultiSelect
                id="cuisines-select"
                options={cuisinesArray}
                value={selectedCuisines}
                onChange={setSelectedCuisines}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: t('forms:select'),
                  allItemsAreSelected: t('forms:all_items_selected'),
                  selectAll: t('forms:select_all'),
                  search: t('forms:searh'),
                }}
                disabled={disabled}
              />
            </div>
            <button
              className="save-btn"
              type="button"
              onClick={() => setEdit(!edit)}
            >
              {t('save')}
            </button>
          </>
        ) : (currentRestaurantInfo._cuisines && currentRestaurantInfo._cuisines.map((cuisine) => cuisine._name).join(', ')) || 'Choose cuisines'}
      />
      <button
        className="edit-btn"
        type="button"
        onClick={() => setEdit(!edit)}
      >
        <Edit className="icon" />
      </button>
    </div>
  );
}

CuisinesInfoItem.propTypes = {
  currentRestaurantInfo: PropTypes.oneOfType([PropTypes.object]),
  setCurrentRestaurantInfo: PropTypes.func,
  disabled: PropTypes.bool,
};

CuisinesInfoItem.defaultProps = {
  currentRestaurantInfo: {},
  setCurrentRestaurantInfo: null,
  disabled: false,
};
