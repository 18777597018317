import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RestrictedPage from '../../pages/RestrictedPage';

const PrivateRestaurantRoute = ({ component, allow, ...rest }) => {
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => (restaurantContext.restaurantId ? (
        <>
          {allow.includes('ALL') ? (
            component
          ) : (
            <>
              {userContext.isLoggedIn && restaurantContext.plan !== 'false' ? (
                <>
                  {allow.includes(restaurantContext.plan) ? (
                    component
                  ) : (
                    <RestrictedPage />
                  )}
                </>
              ) : (
                <Redirect to={{ pathname: '/manage/subscriptions' }} />
              )}
            </>
          )}
        </>
      ) : (
        // eslint-disable-next-line react/prop-types
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ))}
    />
  );
};

PrivateRestaurantRoute.propTypes = {
  component: PropTypes.element.isRequired,
  allow: PropTypes.oneOfType([PropTypes.array]),
};

PrivateRestaurantRoute.defaultProps = {
  allow: ['FREE'],
};

export default PrivateRestaurantRoute;
