import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RestaurantContext from '../../contexts/RestaurantContext';
import useForm from '../../react-web-ui/hooks/useForm';
import validate from './validation';
import FormElement from '../../react-web-ui/components/FormElement';
import SaveOrDiscard from '../SaveOrDiscard';

/**
* AddEmployeeForm
* @returns {*}
* @constructor
*/
export default function AddSalonForm({
  setLoading,
  setAdding,
  setRefresh,
}) {
  const { t } = useTranslation();
  const restaurantContext = React.useContext(RestaurantContext);
  const userContext = React.useContext(UserContext);
  const [isOutside, setIsOutside] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const {
    handleChange,
    handleSubmit,
    setValues,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    setLoading(true);
    const params = {
      place_id: restaurantContext.restaurantId,
      user_id: userContext.user.id,
      default_name: values.salon_name,
      active: isActive,
      outside: isOutside,
    };
    setAdding(false);
    (new RequestService('manager/salons/create'))
      .setParams(params)
      .send()
      .then(() => {
        toast.success(t('success:salon_created'));
        setAdding(false);
        setRefresh(true);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_create_salon'));
      })
      .finally(() => {
        setLoading(false);
        setValues({});
      });
  }

  return (
    <>
      <SaveOrDiscard
        handleSubmit={handleSubmit}
        check={Object.keys(values).length > 0 && Object.values(values).some((x) => x !== '')}
        onClick={() => {
          setAdding(false);
          setValues({});
        }}
      />
      <div className="lg-two-cols">
        <FormElement
          className="col"
          id="salon_name"
          elementType="input"
          placeholder={t('pageFloorPlan:name')}
          value={values.salon_name || ''}
          errors={errors.salon_name}
          changed={handleChange}
        />
      </div>
      <footer>
        <button
          type="button"
          className={`toggle-status ${isOutside ? 'active' : ''}`}
          onClick={() => setIsOutside(!isOutside)}
        >
          <span className="seating-option">{t('pageWidget:inside')}</span>
          <span className="seating-option">{t('pageWidget:outside')}</span>
        </button>
        <button
          type="button"
          className={`toggle-status ${isActive ? 'active' : ''}`}
          onClick={() => setIsActive(!isActive)}
        >
          <span className="seating-option">{t('pageEmployees:inactive')}</span>
          <span className="seating-option">{t('pageEmployees:active')}</span>
        </button>
      </footer>
    </>
  );
}

AddSalonForm.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setAdding: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};
