import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RestaurantContext from '../../contexts/RestaurantContext';
import RequestService from '../../networking/RequestService';
import Place from '../../networking/resources/Place';
import ReviewCircleRating from '../../react-web-ui/components/ReviewCircleRating';
import Rating from '../../react-web-ui/components/Rating';
import Reviews from './Reviews';

export default function ReviewsSection({
  restaurantId,
  name,
  restaurantInfo,
}) {
  const { t } = useTranslation();
  const [restaurantReviews, setRestaurantReviews] = useState([]);
  const restaurantContext = useContext(RestaurantContext);

  useEffect(() => {
    if (restaurantId) {
      (new RequestService('client/reviews/place'))
        .setParams({
          place_id: restaurantId,
          sort: 'submitted',
        })
        .send()
        .then((response) => {
          setRestaurantReviews(response.data);
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:could_not_get_reviews'));
        });
    }
  }, [restaurantId]);

  return (
    <section className="section">
      <h2 className="section-title">
        {`${t('pageWidget:section_rating_title')} `}
        <strong>{name}</strong>
      </h2>
      <p>{t('pageReviews:total_rating')}</p>
      {restaurantContext.restaurant && (
        <div>
          <Rating
            stars={restaurantContext.restaurant.rateStars}
          />
          <small>{`(${restaurantContext.restaurant.rateCount} ${t('pageReviews:ratings')})`}</small>
        </div>
      )}
      {restaurantContext.restaurant && (
        <div className="circles-wrapper">
          <ReviewCircleRating
            title={t('pageReviews:food')}
            rating={restaurantContext.restaurant.rateFood}
          />
          <ReviewCircleRating
            title={t('pageReviews:service')}
            rating={restaurantContext.restaurant.rateService}
          />
          <ReviewCircleRating
            title={t('pageReviews:ambience')}
            rating={restaurantContext.restaurant.rateAmbience}
          />
        </div>
      )}
      <Reviews restaurantReviews={restaurantReviews} slug={restaurantInfo.slug} />
    </section>
  );
}

ReviewsSection.propTypes = {
  restaurantId: PropTypes.string,
  name: PropTypes.string,
  restaurantInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Place)]),
};

ReviewsSection.defaultProps = {
  restaurantId: '',
  name: '',
  restaurantInfo: {},
};
