/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JoyRide, { STATUS } from 'react-joyride';
import { toast } from 'react-toastify';
import MultiSelect from 'react-multi-select-component';
import axios from 'axios';

import RequestService from '../networking/RequestService';
import CountryContext from '../react-web-ui/contexts/CountryContext';
import UserContext from '../react-web-ui/contexts/UserContext';
import RestaurantContext from '../contexts/RestaurantContext';
import Restaurant from '../components/Restaurant';
import Button from '../react-web-ui/components/Button';
import Tooltip from '../react-web-ui/components/Tooltip';
import RestaurantBlock from '../react-web-ui/components/RestaurantBlock';
import Rating from '../react-web-ui/components/Rating';
import { ReactComponent as Plus } from '../assets/images/icons/Plus.svg';
import FormElement from '../react-web-ui/components/FormElement';
import useForm from '../react-web-ui/hooks/useForm';
import validate from './restaurant-validation';
import LocationInput from '../components/LocationInput';
import CollectionLoader from './CollectionLoader';
import RestaurantLoader from './CollectionLoader/types/RestaurantLoader';

export default function RestaurantsListing() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);
  const [restaurants, setRestaurants] = useState([]);
  const [error, setError] = useState(false);
  const countryContext = useContext(CountryContext);
  const [run, setRun] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showPostInput, setShowPostInput] = useState(false);
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [city, setCity] = useState({});
  const [address, setAddress] = useState({});
  const [fullCity, setFullCity] = useState({});
  const [fullAddress, setFullAddress] = useState({});
  const [restaurantLoader, setRestaurantLoader] = useState(
    <CollectionLoader
      contentLoader={<RestaurantLoader />}
      numberOfItems={6}
      containerClasses="restaurant-list-grid"
      itemClasses="content-loader"
    />,
  );

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  // tour steps
  const steps = [
    {
      target: '.tour-restaurants-list',
      content: 'If you manage multiple restaurants, this is the screen that lets you choose which one to manage. Select the desired restaurant by clicking the MANAGE button.',
      disableBeacon: true,
      placement: 'center',
    },
  ];

  let contactEmail = 'info@dineout.bg/info';

  if (countryContext.country.name === 'Poland') {
    contactEmail = 'info@dineout.pl/info';
  }

  function submit() {
    const addParams = {
      user_id: userContext.user.id,
      place_name: values.restaurant_name,
      place_address: fullAddress.name,
      cuisine_ids: selectedCuisines.map((cuisine) => cuisine.value),
      place_lat: fullAddress.lat,
      place_lng: fullAddress.lng,
      neighbourhood_name: values.restaurant_neighborhood || fullAddress.neighborhood,
      city_name: fullCity.name,
      city_lat: fullCity.lat,
      city_lng: fullCity.lng,
      city_timezone: fullCity.timezone,
      country_name: fullAddress.countryName,
      country_code: fullAddress.countryCode,
      postal_code: values.restaurant_post_code || fullAddress.postCode,
    };

    (new RequestService('manager/places/store'))
      .setParams(addParams)
      .send()
      .then((response) => {
        toast.success(t('success:new_place_added_success'));
        restaurantContext.setRestaurant(response.data);
        restaurantContext.setRestaurantId(response.data.id);
        setOpenCreate(false);
        setValues({});
      })
      .catch((err) => {
        if (err.key) toast.error(t(`apiErrors:${err.key}`));
        else toast.error(t('apiErrors:could_not_add_place'));
      });
  }

  useEffect(() => {
    (new RequestService('client/cuisines/all'))
      .setParams({
        country_id: countryContext.country.id,
      })
      .send()
      .then((response) => {
        const cuisinesArray = [];
        for (let i = 0; i < response.data.length; i += 1) {
          cuisinesArray.push({
            label: response.data[i].name,
            value: response.data[i].id,
          });
        }
        setCuisines(cuisinesArray);
      })
      .catch((err) => {
        if (err.key) toast.error(t(`apiErrors:${err.key}`));
        else toast.error(t('apiErrors:could_not_get_cuisines'));
      });
  }, [t, countryContext.country.id]);

  useEffect(() => {
    if (!userContext.user) return;

    (new RequestService('manager/places/index'))
      .setParams({
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        setRestaurants(response.data);
        setError(false);
      })
      .catch(() => setError(true))
      .finally(() => setRestaurantLoader(false));
  }, [t, userContext.user, restaurantContext]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('switch-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      cuisines_select: selectedCuisines.join(' '),
    });
  }, [selectedCuisines]);

  useEffect(() => {
    if (!city.name) return;

    const endpoint = `https://maps.googleapis.com/maps/api/timezone/json?location=${city.lat}, ${city.lng}&timestamp=1627994283&key=AIzaSyBAVuL5Sxx8fSQLi5gy4lRtZrEuQy_KjuA`;

    axios.get(endpoint)
      .then((res) => {
        setFullCity({
          ...city,
          timezone: res.data.timeZoneId,
        });
      });

    setValues({
      ...values,
      restaurant_city: city.name,
    });
  }, [city]);

  useEffect(() => {
    if (!address.name) return;
    let neighborhood = '';
    let countryName = '';
    let countryCode = '';
    let postCode = '';

    address.addressComponents.forEach((component) => {
      if (component.types.includes('neighborhood')) {
        neighborhood = component.long_name;
      }
      if (component.types.includes('country')) {
        countryName = component.long_name;
        countryCode = component.short_name;
      }
      if (component.types.includes('postal_code')) {
        postCode = component.long_name;
      }
    });

    if (neighborhood === '') {
      setShowInput(true);
    }

    if (postCode === '') {
      setShowPostInput(true);
    }

    setFullAddress({
      ...address,
      neighborhood,
      countryName,
      countryCode,
      postCode,
    });
    setValues({
      ...values,
      restaurant_address: address.name,
      restaurant_neighborhood: neighborhood,
      restaurant_post_code: postCode,
    });
  }, [address]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('switch-tour-status', 1);
    }
  };

  return (
    <div className="inner-wrapper">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <div className="tour-restaurants-list">
        <h2 className="section-title underlined">
          {restaurants.length > 0 ? (
            <strong>{t('pageRestaurantsListing:title')}</strong>
          ) : (
            <strong>{t('pageRestaurantsListing:get_started')}</strong>
          )}
        </h2>
        {restaurants.length === 0 && restaurantLoader}
        {error && (
          <h3 className="section-sub-title">
            <strong>
              {t('pageRestaurantsListing:no_restaurants')}
              <br />
              {t('pageRestaurantsListing:contact_dineout')}
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </strong>
          </h3>
        )}
        <div className="restaurant-list-grid">
          {userContext.user && userContext.user.isPrimary && (
            <>
              {openCreate ? (
                <RestaurantBlock
                  className={openCreate ? 'open-create' : ''}
                  imageBlock={(
                    <form action="#" noValidate autoComplete="off">
                      <FormElement
                        id="restaurant_name"
                        elementType="input"
                        placeholder={t('forms:restaurant_name')}
                        label={t('forms:restaurant_name')}
                        value={values.restaurant_name || ''}
                        errors={errors.restaurant_name}
                        changed={handleChange}
                      />
                      <div className="form-group">
                        <label htmlFor="cuisines-select">{t('pageRestaurantProfile:cuisines')}</label>
                        <MultiSelect
                          id="cuisines_select"
                          options={cuisines}
                          value={selectedCuisines}
                          onChange={setSelectedCuisines}
                          labelledBy={t('pageRestaurantsListing:select_cuisines')}
                          overrideStrings={{
                            selectSomeItems: t('pageRestaurantsListing:select_cuisines'),
                            allItemsAreSelected: t('forms:all_items_selected'),
                            selectAll: t('forms:select_all'),
                            search: t('forms:searh'),
                          }}
                        />
                        {errors.cuisines_select && errors.cuisines_select.map((e) => <p key={e} className="error">{e}</p>)}
                      </div>
                      <LocationInput
                        location={city}
                        setSearchLocation={setCity}
                        inputId="restaurant_city"
                        placeholder={t('forms:restaurant_city')}
                        label={t('forms:restaurant_city')}
                        errors={errors.restaurant_city}
                      />
                      <LocationInput
                        limit={city}
                        location={address}
                        setSearchLocation={setAddress}
                        inputId="restaurant_address"
                        placeholder={t('pageRestaurantProfile:location')}
                        label={t('pageRestaurantProfile:location')}
                        errors={errors.restaurant_address}
                      />
                      {showInput && (
                        <FormElement
                          id="restaurant_neighborhood"
                          elementType="input"
                          placeholder={t('forms:restaurant_neighborhood')}
                          label={t('forms:restaurant_neighborhood')}
                          value={values.restaurant_neighborhood || ''}
                          errors={errors.restaurant_neighborhood}
                          changed={handleChange}
                        />
                      )}
                      {showPostInput && (
                        <FormElement
                          id="restaurant_post_code"
                          elementType="input"
                          placeholder={t('forms:billing_postal_code')}
                          label={t('forms:billing_postal_code')}
                          value={values.restaurant_post_code || ''}
                          errors={errors.restaurant_post_code}
                          changed={handleChange}
                        />
                      )}
                    </form>
                  )}
                  button={(
                    <Button
                      classes="btn btn-accent"
                      text={t('forms:create')}
                      onClick={handleSubmit}
                    />
                  )}
                />
              ) : (
                <RestaurantBlock
                  onClick={() => setOpenCreate(true)}
                  className="clickable"
                  imageBlock={(
                    <Plus className="icon icon-plus" />
                  )}
                  title={t('pageRestaurantsListing:your_name')}
                  address={t('pageRestaurantProfile:location')}
                  cuisines={t('pageRestaurantProfile:cuisines')}
                  rating={(
                    <Rating stars={5} />
                  )}
                  button={(
                    <span className="btn btn-accent">
                      {t('forms:create')}
                    </span>
                  )}
                />
              )}
            </>
          )}
          {restaurants.length > 0 && restaurants.map((restaurant) => (
            <Restaurant
              key={restaurant.id}
              restaurantInfo={restaurant}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
