/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RestaurantContext from '../../contexts/RestaurantContext';

export default function SubscriptionInfo({
  plan,
  price,
  setupIntent,
  setupIntentClientSecret,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const stripe = useStripe();
  const [errorText, setErrorText] = useState(null);
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const restaurantContext = useContext(RestaurantContext);

  const handleSubscription = () => {
    const params = {
      user_id: userContext.user.id,
      place_id: restaurantContext.restaurantId,
      plan,
      price,
    };
    setLoading(true);

    (new RequestService('manager/subscription/subscribe'))
      .setParams(params)
      .send()
      .then(() => {
        restaurantContext.setPlan(plan);
        restaurantContext.setPrice(price);
        toast.success(t('success:subscribe_success'));
        history.push('/manage/restaurant-profile');
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_subscribe'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!stripe || !setupIntentClientSecret || !userContext.user) return;
    stripe.retrieveSetupIntent(setupIntentClientSecret)
      .then((result) => {
        // eslint-disable-next-line default-case
        switch (result.setupIntent.status) {
          case 'succeeded': {
            handleSubscription();
            break;
          }
          case 'requires_payment_method': {
            // console.log('Failed to process payment details. Please try another payment method.');
            break;
          }
        }
        if (result.error) setErrorText(result.error.message);
      });
  }, [stripe, setupIntentClientSecret, userContext.user]);

  return (
    <>
      {loading && (
        <h3 className="text-center">{t('pageSubscriptions:confirming_payment')}</h3>
      )}
      {errorText && (
        <h3 className="text-center">{errorText}</h3>
      )}
    </>
  );
}

SubscriptionInfo.propTypes = {
  setupIntent: PropTypes.string.isRequired,
  setupIntentClientSecret: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};
