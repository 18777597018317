import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormElement from '../../react-web-ui/components/FormElement';

export default function PercentOrPriceForm({
  values,
  errors,
  handleChange,
  status,
  setStatus,
}) {
  const { t } = useTranslation();

  const handleStatusChange = () => {
    const newStatus = !status;
    setStatus(newStatus);
  };

  return (
    <div className="tour-percent grid align-center">
      <button
        type="button"
        className={`toggle-status ${status ? 'active' : ''}`}
        onClick={handleStatusChange}
      >
        <span className="seating-option">%</span>
        <span className="seating-option">{t('price')}</span>
      </button>
      {status ? (
        <>
          <FormElement
            className="grid-col-2"
            id="standard_price"
            elementType="input"
            inputType="number"
            placeholder={t('pageSpecialOffers:standard_price')}
            value={values.standard_price}
            errors={errors.standard_price}
            changed={handleChange}
          />
          <FormElement
            className="grid-col-2"
            id="discount_price"
            elementType="input"
            inputType="number"
            placeholder={t('pageSpecialOffers:discount_price')}
            value={values.discount_price}
            errors={errors.discount_price}
            changed={handleChange}
          />
        </>
      ) : (
        <FormElement
          className="grid-col-2"
          id="percent"
          elementType="input"
          inputType="number"
          placeholder={t('pageSpecialOffers:percent')}
          value={values.percent}
          errors={errors.percent}
          changed={handleChange}
        />
      )}
    </div>
  );
}

PercentOrPriceForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleChange: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
};
