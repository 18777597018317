import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { restaurantImage, chefImage } from '../../react-web-ui/utilities/images';
import Place from '../../networking/resources/Place';
import ButtonLink from '../../react-web-ui/components/ButtonLink';
import TastingMenuBlock from '../../react-web-ui/components/TastingMenuBlock';
import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';
import PlaceManager from '../../networking/resources/PlaceManager';

/**
* Add tasting menu block
* @param restaurantInfo
* @param handleChefImgUpload
* @returns {*}
* @constructor
*/
export default function AddTastingMenuBlock({ restaurantInfo, handleChefImgUpload }) {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <TastingMenuBlock
      big
      currency=""
      restaurantImage={(
        <img
          src={restaurantImage(restaurantInfo?.id, restaurantInfo?.thumb, 470, 520)}
          alt={restaurantInfo?.name}
        />
      )}
      chefImage={restaurantInfo?.chefThumb ? (
        <div className="chef-img-wrapper">
          <img src={chefImage(restaurantInfo?.id, restaurantInfo?.chefThumb, 218, 218)} alt="chef" />
          <label className="add-img" htmlFor="upload-button">
            <Edit className="icon" />
            <input
              type="file"
              id="upload-button"
              hidden
              onChange={handleChefImgUpload}
            />
          </label>
        </div>
      ) : (
        <label className="chef-img-wrapper add-img" htmlFor="upload-button">
          {t('forms:add_photo')}
          <input
            type="file"
            id="upload-button"
            hidden
            onChange={handleChefImgUpload}
          />
        </label>
      )}
      chefName={restaurantInfo?.chefName ? (
        <span>
          {restaurantInfo?.chefName}
          <Link to="/communication/profile">
            <Edit className="icon" />
          </Link>
        </span>
      ) : (
        <Link to="/communication/profile">
          {t('pageTastingMenus:add_chef_name')}
        </Link>
      )}
      restaurantName={restaurantInfo?.name}
      buttons={(
        <ButtonLink
          href={`${location.pathname}/add`}
          text={t('pageTastingMenus:add_a_tasting_menu')}
          classes="btn-accent"
        />
      )}
    />
  );
}

AddTastingMenuBlock.propTypes = {
  restaurantInfo: PropTypes.oneOfType([
    PropTypes.instanceOf(Place), PropTypes.instanceOf(PlaceManager),
  ]).isRequired,
  handleChefImgUpload: PropTypes.func,
};

AddTastingMenuBlock.defaultProps = {
  handleChefImgUpload: null,
};
