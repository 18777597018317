import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RestaurantContext from '../../../contexts/RestaurantContext';
import ElementsContext from '../../../contexts/ElementsContext';
import {
  initialImageOptions,
} from '../../../utilities/constants';
import { randomId, sortElements, updateCanvasElements } from '../helper';
import { ReactComponent as Delete } from '../../../assets/images/icons/Delete.svg';
import Checkbox from '../../../react-web-ui/components/FormElement/Checkbox';
import { chefImage, restaurantImage } from '../../../react-web-ui/utilities/images';

export default function ImageElement({ type }) {
  const { t } = useTranslation();
  const {
    canvasElements,
    setCanvasElements,
    selectedElement,
    setSelectedElement,
  } = useContext(ElementsContext);
  const restaurantContext = useContext(RestaurantContext);
  const [imageToUse, setImageToUse] = useState();

  const addImageToCanvas = (img) => {
    const id = randomId();
    const newElement = {
      ...initialImageOptions,
      type,
      img,
      borderColor: '#000000',
      border: false,
      borderRadius: type === 'chef',
      id,
    };
    const sortedElements = sortElements([
      ...canvasElements,
      { ...newElement },
    ]);
    setCanvasElements(sortedElements);
    setSelectedElement(sortedElements.find((element) => element.id === id));
  };

  useEffect(() => {
    setImageToUse();
    if (type === 'chef') {
      if (restaurantContext.restaurant?.chefThumb) {
        setImageToUse(
          chefImage(
            restaurantContext.restaurantId,
            restaurantContext.restaurant?.chefThumb,
            218,
            218,
          ),
        );
      }
    } else if (restaurantContext.restaurant?.logo) {
      setImageToUse(
        restaurantImage(
          restaurantContext.restaurantId,
          restaurantContext.restaurant?.logo,
          300,
          200,
        ),
      );
    }
  }, [restaurantContext.restaurant, type]);

  return (
    <>
      <div className="column-top">
        <h2>
          {t(`pagePostCreator:${type}`)}
        </h2>
        {imageToUse && !canvasElements.find((element) => element.type === type) && (
          <button
            type="button"
            onClick={() => {
              addImageToCanvas(imageToUse);
            }}
            className="btn btn-accent btn-add-element"
          >
            {t('pagePostCreator:add_element')}
          </button>
        )}
      </div>
      {!canvasElements.find((element) => element.type === type) && (
        <div className="details-form">
          {imageToUse ? (
            <p>
              {t('pagePostCreator:upload_image_for_post')}
            </p>
          ) : (
            <p>
              {t('pagePostCreator:you_have_no_images')}
            </p>
          )}
          <label className="upload-file btn btn-accent" htmlFor="avatar-image">
            {t('pagePostCreator:upload_image')}
            <input
              type="file"
              id="avatar-image"
              accept="image/*"
              onChange={(event) => {
                addImageToCanvas(URL.createObjectURL(event.target.files[0]));
              }}
            />
          </label>
        </div>
      )}
      {selectedElement && type === 'chef' && (
        <div className="details-form">
          <div className="color-form-group">
            <span className="label">{t('pagePostCreator:border_color')}</span>
            <div className="input-wrapper">
              <input
                type="color"
                id="border-color"
                name="border-color"
                value={selectedElement?.borderColor}
                onChange={(e) => {
                  setCanvasElements(updateCanvasElements(
                    canvasElements,
                    selectedElement,
                    'borderColor',
                    e.target.value,
                  ));
                }}
              />
              <Checkbox
                id="chef-border"
                text={t('pagePostCreator:border')}
                value={`${selectedElement?.border}`}
                checked={selectedElement?.border}
                check={() => {
                  setCanvasElements(updateCanvasElements(
                    canvasElements,
                    selectedElement,
                    'border',
                    !selectedElement.border,
                  ));
                }}
              />
            </div>
          </div>
        </div>
      )}
      {canvasElements.find((element) => element.type === type) && (
        <button
          type="button"
          onClick={() => {
            const currentCanvasElements = canvasElements.filter(
              (element) => (element.type !== type),
            );
            setSelectedElement(null);
            setCanvasElements(currentCanvasElements);
          }}
          className="btn-link btn-delete-element"
        >
          <Delete className="icon" />
          {t(`pagePostCreator:remove_${type}_element`)}
        </button>
      )}
    </>
  );
}

ImageElement.propTypes = {
  type: PropTypes.string.isRequired,
};
