import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Tooltip
 * @param title
 * @param position
 * @param children
 * @param className
 * @returns {*}
 * @constructor
 */
export default function Tooltip({
  title,
  position,
  children,
  className,
  alwaysShow,
}) {
  const ref = useRef();
  const [show, setShow] = useState(false);

  return (
    <div
      className={`tooltip-wrapper ${className}`}
      ref={ref}
      role="button"
      tabIndex="0"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {(alwaysShow || show) && (
        <div ref={ref} className={`tooltip-content ${position}`}>
          <p>{title}</p>
        </div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  className: PropTypes.string,
  alwaysShow: PropTypes.bool,
};

Tooltip.defaultProps = {
  position: 'right',
  className: '',
  alwaysShow: false,
};
