import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import WindowContext from '../../react-web-ui/contexts/WindowContext';
import PageHeading from '../../react-web-ui/components/PageHeading';
import noAvatar from '../../react-web-ui/assets/images/no-avatar.png';
import facebookIcon from '../../assets/images/facebook-icon.png';
import instagramIcon from '../../assets/images/instagram-icon.png';
import like from '../../assets/images/thumb.png';
import comment from '../../assets/images/comment.png';
import share from '../../assets/images/share.png';
import instagramShare from '../../assets/images/instagram-share.png';
import instagramComment from '../../assets/images/instagram-comment.png';
import heart from '../../assets/images/heart.png';
import bookmark from '../../assets/images/bookmark.png';
import facebookImage from '../../assets/images/facebook-img.jpg';
import instagramImage from '../../assets/images/instagram-img.jpg';
import RestaurantContext from '../../contexts/RestaurantContext';

export default function PostCreator() {
  const { t } = useTranslation();
  const { isDesktop } = useContext(WindowContext);
  const restaurantContext = useContext(RestaurantContext);

  return (
    <div className="inner-wrapper tasting-page">
      <PageHeading
        classes="page-heading-post-creator"
        title={t('pagePostCreator:title')}
        content={(
          <></>
        )}
      />
      {isDesktop ? (
        <>
          <h2 className="section-title underlined">
            <strong>{t('pagePostCreator:choose_size')}</strong>
          </h2>
          <div className="post-sizes">
            <Link
              className="facebook-post"
              to={{
                pathname: '/grow/post-creator-preview',
                search: '?postType=facebookPost',
              }}
            >
              <header className="post-header">
                <div className="post-restaurant-info">
                  <div className="avatar">
                    <img
                      src={noAvatar}
                      alt={t('pagePostCreator:facebook_title', { restaurant: restaurantContext.restaurant?.name })}
                    />
                  </div>
                  <div className="text">
                    <p>{t('pagePostCreator:facebook_title', { restaurant: restaurantContext.restaurant?.name })}</p>
                    <span>{t('pagePostCreator:facebook_date')}</span>
                  </div>
                </div>
                <img className="facebook-logo" src={facebookIcon} alt=" " />
              </header>
              <div className="post-text">
                <p>{t('pagePostCreator:facebook_text')}</p>
                <img src={facebookImage} alt="" />
              </div>
              <footer>
                <span>
                  <img src={like} alt=" " />
                  {t('pagePostCreator:like')}
                </span>
                <span>
                  <img src={comment} alt=" " />
                  {t('pagePostCreator:comment')}
                </span>
                <span>
                  <img src={share} alt=" " />
                  {t('pagePostCreator:share')}
                </span>
              </footer>
            </Link>
            <Link
              className="instagram-post"
              to={{
                pathname: '/grow/post-creator-preview',
                search: '?postType=instagramPost',
              }}
            >
              <header className="post-header">
                <div className="post-restaurant-info">
                  <div className="avatar">
                    <img
                      src={noAvatar}
                      alt={restaurantContext.restaurant?.name}
                    />
                  </div>
                  <div className="text">
                    <p>{restaurantContext.restaurant?.name}</p>
                    <span>{restaurantContext.restaurant?.city?.name}</span>
                  </div>
                </div>
                <img className="instagram-logo" src={instagramIcon} alt=" " />
              </header>
              <div className="post-text">
                <img src={instagramImage} alt="" />
              </div>
              <footer>
                <span>
                  <img src={heart} alt=" " />
                </span>
                <span>
                  <img src={instagramComment} alt=" " />
                </span>
                <span>
                  <img src={instagramShare} alt=" " />
                </span>
                <span className="bookmark">
                  <img src={bookmark} alt=" " />
                </span>
              </footer>
            </Link>
            <Link
              className="instagram-story"
              to={{
                pathname: '/grow/post-creator-preview',
                search: '?postType=instagramStory',
              }}
            >
              <header className="post-header">
                <div className="post-restaurant-info">
                  <div className="avatar">
                    <img
                      src={noAvatar}
                      alt={restaurantContext.restaurant?.name}
                    />
                  </div>
                  <div className="text">
                    <p>{restaurantContext.restaurant?.name}</p>
                  </div>
                </div>
              </header>
            </Link>
          </div>
        </>
      ) : (
        <h2>{t('pagePostCreator:mobile_message')}</h2>
      )}
    </div>
  );
}
