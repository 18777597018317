/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import RestaurantContext from '../../contexts/RestaurantContext';
import UserContext from '../../react-web-ui/contexts/UserContext';
import RequestService from '../../networking/RequestService';
import { restaurantImage } from '../../react-web-ui/utilities/images';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import { ReactComponent as Logo } from '../../react-web-ui/assets/images/logo.svg';
import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';
import EmailLinks from '../../components/EmailLinks';
import Button from '../../react-web-ui/components/Button';
import Tooltip from '../../react-web-ui/components/Tooltip';

export default function EmailBranding() {
  const { t } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [initialFormValues, setInitialFormValues] = useState({});
  const {
    startListening,
    endListening,
  } = useBeforeUnload();
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: 'body',
      content: 'This is the email that you can send as confirmation to bookings you manually add to the Dineout system. So make it yours.',
      disableBeacon: true,
      placement: 'center',
    },
    {
      target: '.tour-links',
      content: 'Add links to your restaurants’ social profiles.',
    },
    {
      target: '.tour-image',
      content: 'Upload your logo.',
    },
    {
      target: '.tour-profile',
      content: 'You can change your restaurant’s profile picture in the Restaurant Profile section.',
    },
  ];

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('email-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('email-tour-status', 1);
    }
  };

  useEffect(() => {
    if (restaurantContext.restaurant !== null) {
      setInitialFormValues({
        facebook_link: restaurantContext.restaurant.facebookPageLink,
        youtube_link: restaurantContext.restaurant.youtubeChannelLink,
        instagram_link: restaurantContext.restaurant.instagramProfileLink,
      });
    } else {
      setInitialFormValues({});
    }
  }, [restaurantContext.restaurant]);

  const handleImageChange = (e) => {
    startListening();
    if (e.target.files.length) {
      (new RequestService('manager/places/logo'))
        .forFileUpload()
        .setParams({
          user_id: userContext.user.id,
          place_id: restaurantContext.restaurantId,
          logo: e.target.files[0],
        })
        .send()
        .then((response) => {
          restaurantContext.setRestaurant(response.data);
          toast.success(t('success:logo_upload_success'));
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
          else toast.error(t('apiErrors:could_not_upload_image'));
        })
        .finally(() => {
          endListening();
        });
    }
  };

  return (
    <div className="inner-wrapper">
      <JoyRide
        steps={steps}
        continuous
        showSkipButton
        spotlightClicks
        showProgress
        disableScrolling
        run={run}
        locale={{
          back: t('walktrough:back'),
          close: t('walktrough:close'),
          last: t('walktrough:last'),
          next: t('walktrough:next'),
          skip: t('walktrough:skip'),
        }}
        styles={{
          options: {
            primaryColor: '#ffae42',
          },
        }}
        callback={handleJoyrideCallback}
      />
      <div className="show-tips">
        <Tooltip
          title="show walktrough for current page"
          position="left"
        >
          <Button
            text="?"
            classes="btn-show-tips"
            onClick={() => setRun(true)}
          />
        </Tooltip>
      </div>
      <EmailLinks initialValues={initialFormValues} />
      <div className="email-container">
        <table style={{
          padding: 0, margin: 0, fontFamily: 'Helvetica, sans-serif', fontSize: '16px', backgroundColor: '#f1f3f4', width: '100%', color: '#000',
        }}
        >
          <tbody>
            <tr>
              <td>
                <table
                  align="center"
                  style={{
                    maxWidth: '734px', width: '100%', margin: '0 auto', borderCollapse: 'collapse',
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{
                        paddingBottom: 0, textAlign: 'center', borderTop: '9px solid #f1f3f4', borderBottom: '9px solid #f1f3f4', borderRight: '34px solid #f1f3f4', borderLeft: '34px solid #f1f3f4',
                      }}
                      >
                        <a href="/" style={{ color: '#939393', fontSize: '12px' }}>{t('pageEmailBranding:automated_email')}</a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: 0 }}>
                        <table style={{
                          textAlign: 'center', width: '100%', backgroundColor: '#ffffff', borderTop: '18px solid #fff', borderLeft: '28px solid #fff', borderRight: '28px solid #fff',
                        }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: 'center', paddingBottom: '14px', borderBottom: '2px solid #f1f1f1' }}>
                                {restaurantContext.restaurant?.logo ? (
                                  <div className="tour-image img-wrapper email-logo">
                                    <img
                                      src={
                                        restaurantImage(
                                          restaurantContext.restaurantId,
                                          restaurantContext.restaurant?.logo,
                                          300,
                                          200,
                                        )
                                      }
                                      alt={restaurantContext.restaurant?.name}
                                    />
                                    <label className="edit-img" htmlFor="edit-logo-button">
                                      <Edit className="icon" />
                                      <input
                                        type="file"
                                        id="edit-logo-button"
                                        hidden
                                        onChange={handleImageChange}
                                      />
                                    </label>
                                  </div>
                                ) : (
                                  <label className="tour-image add-logo-btn" htmlFor="upload-logo-button">
                                    {t('pageEmailBranding:your_logo_here')}
                                    <input
                                      type="file"
                                      id="upload-logo-button"
                                      hidden
                                      onChange={handleImageChange}
                                    />
                                  </label>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: 'left', borderTop: '7px solid #fff', borderBottom: '13px solid #FFF' }}>
                                <h1 style={{ fontWeight: 'normal', margin: 0 }}>{t('pageEmailBranding:booking_confirmed')}</h1>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: 0, backgroundColor: '#f2f3f5', borderTop: '5px solid #ffae43' }}>
                        <table style={{
                          width: '100%', borderTop: '14px solid #f2f3f5', borderLeft: '28px solid #f2f3f5', borderRight: '28px solid #f2f3f5', borderBottom: '26px solid #f2f3f5',
                        }}
                        >
                          <tbody>
                            <tr>
                              <td>
                                <p style={{ marginTop: 0 }}>
                                  {t('pageEmailBranding:where')}
                                  {' '}
                                  <br />
                                  <span style={{ fontSize: '20px' }}>
                                    <strong>{restaurantContext.restaurant?.name}</strong>
                                    {` (${t('pageWidget:outside')})`}
                                    <br />
                                    {t('pageEmailBranding:for_people')}
                                  </span>
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                  {t('pageEmailBranding:when')}
                                  {' '}
                                  <br />
                                  <span style={{ fontSize: '20px' }}>
                                    <strong>14.12.2020</strong>
                                    {' '}
                                    {t('pageEmailBranding:at')}
                                    <strong>18:00</strong>
                                  </span>
                                </p>
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                <div style={{ height: '90px' }}>
                                  <div className="tour-profile">
                                    {restaurantContext.restaurant?.thumb && (
                                      <img
                                        style={{ width: '279px', height: '170px' }}
                                        src={
                                          restaurantImage(
                                            restaurantContext.restaurantId,
                                            restaurantContext.restaurant?.thumb,
                                            279,
                                            170,
                                          )
                                        }
                                        alt="Restaurant thumb"
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          backgroundColor: '#fff', paddingTop: 0, paddingBottom: 0, borderTop: '32px solid #fff',
                        }}
                      >
                        <div />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: 0 }}>
                        <table style={{
                          width: '100%', background: '#fff', lineHeight: '1.38', borderLeft: '28px solid #fff', borderRight: '28px solid #fff',
                        }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ paddingBottom: '16px', borderBottom: '1px solid #f1f1f1' }}>
                                <p style={{ marginBottom: '21px', marginTop: 0 }}><strong>{t('pageEmailBranding:hi')}</strong></p>
                                <p style={{ marginBottom: '21px' }}>{t('pageEmailBranding:booking_now_confirmed')}</p>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ paddingBottom: '16px', borderBottom: '1px solid #f1f1f1' }}>
                                <h2 style={{ fontSize: '32px', fontWeight: 400, marginBottom: 0 }}>{t('pageEmailBranding:need_to_cancel')}</h2>
                                <p>
                                  <Trans i18nKey="pageEmailBranding:need_to_cancel_text">
                                    You can change the date and time of your booking right from our system. Use
                                    {' '}
                                    <a href="/" className="text-accent">this link</a>
                                    {' '}
                                    to cancel it altogether.
                                  </Trans>
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ paddingBottom: '14px' }}>
                                <p>
                                  <strong>{t('pageEmailBranding:looking_forward')}</strong>
                                  <br />
                                  {restaurantContext.restaurant?.name}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          align="center"
                          style={{
                            width: '330px', textAlign: 'center', margin: '0 auto', borderTop: '16px solid #f1f3f4', borderBottom: '35px solid #f1f3f4',
                          }}
                        >
                          <tbody>
                            <tr>
                              <td style={{ padding: '0 20px' }}>
                                <a href="/" style={{ color: '#000', textDecoration: 'none' }}>
                                  <strong style={{ textDecoration: 'none' }}>{restaurantContext.restaurant?.contactPhoneNumber}</strong>
                                </a>
                              </td>
                              <td style={{ padding: '0 20px', borderLeft: '2px solid #939393' }}>
                                <a href="/" style={{ color: '#000', textDecoration: 'none' }}>
                                  <strong style={{ textDecoration: 'none' }}>
                                    {restaurantContext.restaurant?.contactEmail}
                                  </strong>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" style={{ borderTop: '26px solid #f1f3f4', borderBottom: '26px solid #f1f3f4' }}>
                                <table style={{ width: '200px', margin: '0 auto' }}>
                                  <tbody>
                                    <tr>
                                      <td><a href="/"><img src="https://i.imgur.com/BpernLt.png" alt="facebook" /></a></td>
                                      <td style={{ padding: '0 10px' }}><a href="/"><img src="https://i.imgur.com/21OGrcY.png" alt="youtube" /></a></td>
                                      <td><a href="/"><img src="https://i.imgur.com/keAkzx8.png" alt="instagram" /></a></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="2" style={{ fontSize: '13px' }}>
                                {t('pageEmailBranding:powered_by')}
                                <br />
                                <a href="/">
                                  <Logo />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
