import React from 'react';
import PropTypes from 'prop-types';

/**
 * Restaurant title
 * @param title
 * @param subtitle
 * @returns {*}
 * @constructor
 */
export default function RestaurantTitle({ title, subtitle }) {
  return (
    <h1 className="restaurant-title">
      <strong>
        {title}
      </strong>
      {subtitle ? '—' : ''}
      {subtitle}
    </h1>
  );
}

RestaurantTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

RestaurantTitle.defaultProps = {
  subtitle: '',
};
