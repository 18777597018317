import React, { useContext, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { toast } from 'react-toastify';
import JoyRide, { STATUS } from 'react-joyride';

import RequestService from '../../../networking/RequestService';
import RestaurantContext from '../../../contexts/RestaurantContext';
import UserContext from '../../../react-web-ui/contexts/UserContext';
import PageHeading from '../../../react-web-ui/components/PageHeading';
import ButtonLink from '../../../react-web-ui/components/ButtonLink';
import AddEventBlock from '../../../components/AddEventBlock';
import EventBlock from '../../../components/EventBlock';
import Button from '../../../react-web-ui/components/Button';
import useSpecialEvents from '../../../hooks/useSpecialEvents';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import Tooltip from '../../../react-web-ui/components/Tooltip';
import TermsEvents from '../TermsEvents';

export default function EventsListing() {
  const { t } = useTranslation();
  const location = useLocation();
  const restaurantContext = useContext(RestaurantContext);
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsActive, setEventsActive] = useState([]);
  const [eventsDraft, setEventsDraft] = useState([]);
  const [eventsPastEven, setEventsPastEven] = useState([]);
  const [eventsPastOdd, setEventsPastOdd] = useState([]);
  const [run, setRun] = useState(false);
  // tour steps
  const steps = [
    {
      target: '.page-heading-event',
      content: 'Culinary events are a great way to attract extra income for your restaurant and Dineout have made adding and promoting them easy. Start by adding your first event.',
      disableBeacon: true,
    },
  ];

  const {
    handlePublish,
    handleUnpublish,
    handleDelete,
  } = useSpecialEvents(setLoading);
  const {
    startListening,
    endListening,
  } = useBeforeUnload();

  useEffect(() => {
    if (!restaurantContext.restaurantId || !userContext.user || loading) return;
    setLoadingEvents(true);
    (new RequestService('manager/special-events/get'))
      .setParams({
        place_id: restaurantContext.restaurantId,
        user_id: userContext.user.id,
      })
      .send()
      .then((response) => {
        const responseData = response.data.filter((data) => !data.featureSets);
        setEvents(responseData);
        const allActive = responseData.filter((item) => item.status === 'Active');
        const currentDay = moment().startOf('day');
        const pastEvents = allActive.filter(
          ({ intervals }) => moment(intervals[intervals.length - 1].date).isBefore(currentDay),
        );
        const activeEvents = allActive.filter(
          ({ intervals }) => moment(intervals[intervals.length - 1].date).isAfter(currentDay)
            || moment(intervals[intervals.length - 1].date).isSame(currentDay, 'day'),
        );
        setEventsActive(activeEvents);
        setEventsPastEven(pastEvents.filter((event, index) => index % 2));
        setEventsPastOdd(pastEvents.filter((event, index) => !(index % 2)));
        setEventsDraft(responseData.filter((item) => item.status === 'Draft'));
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_get_events'));
      })
      .finally(() => setLoadingEvents(false));
  }, [t, restaurantContext.restaurantId, userContext.user, loading]);

  // start tour if first time visit
  useEffect(() => {
    const tourStatus = localStorage.getItem('events-tour-status');
    if (!tourStatus) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      localStorage.setItem('events-tour-status', 1);
    }
  };

  const handleImgUpload = (eventId, e) => {
    setLoading(true);
    startListening();
    if (e.target.files.length) {
      (new RequestService('manager/special-events/thumb'))
        .forFileUpload()
        .setParams({
          special_event_id: eventId,
          user_id: userContext.user.id,
          thumb: e.target.files[0],
        })
        .send()
        .then(() => {
          setLoading(false);
          endListening();
        })
        .catch((error) => {
          if (error.key) toast.error(t(`apiErrors:${error.key}`));
        })
        .finally(() => {
          setLoading(false);
          endListening();
        });
    }
  };

  if (restaurantContext.restaurant) {
    if (!restaurantContext.restaurant.areDiningEventsEnabled) {
      return (
        <TermsEvents />
      );
    }

    return (
      <div className="inner-wrapper event-page">
        <JoyRide
          steps={steps}
          continuous
          showSkipButton
          showProgress
          disableScrolling
          run={run}
          locale={{
            back: t('walktrough:back'),
            close: t('walktrough:close'),
            last: t('walktrough:last'),
            next: t('walktrough:next'),
            skip: t('walktrough:skip'),
          }}
          styles={{
            options: {
              primaryColor: '#ffae42',
            },
          }}
          callback={handleJoyrideCallback}
        />
        <div className="show-tips">
          <Tooltip
            title="show walktrough for current page"
            position="left"
          >
            <Button
              text="?"
              classes="btn-show-tips"
              onClick={() => setRun(true)}
            />
          </Tooltip>
        </div>
        <PageHeading
          classes="page-heading-event"
          title={t('pageEvents:title')}
          content={(
            <>
              <Link className="btn-link" to="/monetize/terms-events">
                <strong>{t('read_terms_add_conditions')}</strong>
              </Link>
              <ButtonLink
                href={`${location.pathname}/add`}
                text={t('pageEvents:add_an_event')}
                classes="btn-accent btn-outline"
              />
            </>
          )}
        />
        {events.length === 0 && (
          <div className="events-list-grid event-no-img">
            <AddEventBlock restaurantName={restaurantContext.restaurant?.name} />
          </div>
        )}
        {eventsActive.length > 0 && (
          <>
            <h2 className="section-title underlined">
              <strong>{t('pageEvents:active_events')}</strong>
            </h2>
            <div className="events-list-grid">
              {eventsActive.map((eventActive) => (
                <EventBlock
                  big
                  key={eventActive.id}
                  event={eventActive}
                  handleImgUpload={handleImgUpload}
                  buttons={(
                    <div className="multiple-buttons">
                      <Button
                        text={t('unpublish')}
                        classes="btn-accent btn-outline right"
                        onClick={
                          () => handleUnpublish(eventActive.id, userContext.user.id, t('pageEvents:active_bookings'))
                        }
                        disabled={loading || loadingEvents}
                      />
                    </div>
                  )}
                />
              ))}
            </div>
          </>
        )}
        {eventsDraft.length > 0 && (
          <>
            <h2 className="section-title underlined">
              <strong>{t('pageEvents:draft_events')}</strong>
            </h2>
            <div className="events-list-grid">
              {eventsDraft.map((eventDraft) => (
                <EventBlock
                  big
                  key={eventDraft.id}
                  event={eventDraft}
                  handleImgUpload={handleImgUpload}
                  buttons={(
                    <div className="multiple-buttons">
                      <Button
                        text={t('publish')}
                        classes="btn-accent"
                        onClick={() => {
                          confirmAlert({
                            // eslint-disable-next-line react/prop-types
                            customUI: ({ onClose }) => (
                              <div className="react-confirm-alert-body event-page">
                                <h2 className="section-title">{t('pageEvents:are_you_sure_publish')}</h2>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handlePublish(eventDraft.id, userContext.user.id);
                                    onClose();
                                  }}
                                >
                                  {t('yes')}
                                </button>
                                <button
                                  type="button"
                                  onClick={onClose}
                                >
                                  {t('no')}
                                </button>
                              </div>
                            ),
                          });
                        }}
                        disabled={loading || loadingEvents}
                      />
                      <ButtonLink
                        href={`${location.pathname}/edit/${eventDraft.id}`}
                        text={t('edit')}
                        classes="btn-accent"
                        disabled={loading || loadingEvents}
                      />
                      <Button
                        text={t('delete')}
                        classes="btn-accent btn-outline right"
                        onClick={() => {
                          confirmAlert({
                            // eslint-disable-next-line react/prop-types
                            customUI: ({ onClose }) => (
                              <div className="react-confirm-alert-body event-page">
                                <h2 className="section-title">{t('pageEvents:are_you_sure_delete')}</h2>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDelete(eventDraft.id, userContext.user.id);
                                    onClose();
                                  }}
                                >
                                  {t('yes')}
                                </button>
                                <button
                                  type="button"
                                  onClick={onClose}
                                >
                                  {t('no')}
                                </button>
                              </div>
                            ),
                          });
                        }}
                        disabled={loading || loadingEvents}
                      />
                    </div>
                  )}
                />
              ))}
            </div>
          </>
        )}
        {eventsPastOdd.length > 0 && (
          <>
            <h2 className="section-title underlined">
              <strong>{t('pageEvents:past_events')}</strong>
            </h2>
            <div className="events-list-grid past-events-grid">
              <div className="lg-two-cols">
                <div className="col">
                  {eventsPastOdd.map((eventPast) => (
                    <EventBlock
                      key={eventPast.id}
                      event={eventPast}
                      handleImgUpload={handleImgUpload}
                      buttons={(
                        <ButtonLink
                          href={`${location.pathname}/copy/${eventPast.id}`}
                          text={t('copy')}
                          classes="btn-accent"
                        />
                      )}
                    />
                  ))}
                </div>
                <div className="col">
                  {eventsPastEven.map((eventPast) => (
                    <EventBlock
                      key={eventPast.id}
                      event={eventPast}
                      handleImgUpload={handleImgUpload}
                      buttons={(
                        <ButtonLink
                          href={`${location.pathname}/copy/${eventPast.id}`}
                          text={t('copy')}
                          classes="btn-accent"
                        />
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  return ('');
}
