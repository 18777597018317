import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';

import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';
import { ReactComponent as CloseIcon } from '../../react-web-ui/assets/images/icons/Close.svg';

/**
* SaveOrDiscard
* @returns {*}
* @constructor
*/
export default function SaveOrDiscard({
  check,
  handleSubmit,
  onClick,
}) {
  const { t } = useTranslation();

  return (
    <>
      <button
        className="save-shadow-btn"
        type="button"
        onClick={handleSubmit}
        disabled={!check}
      >
        <Edit className="icon" />
        {t('save')}
      </button>
      <button
        className="discard-shadow-btn"
        type="button"
        onClick={() => {
          if (check) {
            confirmAlert({
              // eslint-disable-next-line react/prop-types
              customUI: ({ onClose }) => (
                <div className="react-confirm-alert-body">
                  <h2 className="section-title">{t('pageEmployees:confirm_discard')}</h2>
                  <button
                    type="button"
                    onClick={() => {
                      onClick();
                      onClose();
                    }}
                  >
                    {t('yes')}
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                  >
                    {t('no')}
                  </button>
                </div>
              ),
            });
          } else {
            onClick();
          }
        }}
      >
        <CloseIcon className="icon" />
      </button>
    </>
  );
}

SaveOrDiscard.propTypes = {
  check: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
