import { useState, useEffect, useCallback } from 'react';

const useCanvasImage = (width, height, repositioning, background, canvasContainerRef) => {
  const [resize, setResize] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [canDrag, setCanDrag] = useState(false);
  const [startingCanvasSizes, setStartingCanvasSizes] = useState({
    width: 0,
    height: 0,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [newImagePosition, setNewImagePosition] = useState({
    x: null,
    y: null,
  });
  const [imagePosition, setImagePosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [cursorPositionImage, setCursorPositionImage] = useState({
    x: 0,
    y: 0,
  });
  const [rectanglePosition, setRectanglePosition] = useState({
    x: 0,
    y: 0,
  });
  const [scaledPositionX, setScaledPositionX] = useState(0);
  const [scaledPositionY, setScaledPositionY] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(width);
  const [currentHeight, setCurrentHeight] = useState(height);
  const [widthScaleCorrection, setWidthScaleCorrection] = useState(0);
  const [heightScaleCorrection, setHeightScaleCorrection] = useState(0);

  const resetOptions = () => {
    setResize(1);
    setDragging(false);
    setCanDrag(false);
    setNewImagePosition({
      x: null,
      y: null,
    });
    setImagePosition({
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    });
    setCursorPositionImage({
      x: 0,
      y: 0,
    });
    setRectanglePosition({
      x: 0,
      y: 0,
    });
  };

  useEffect(() => {
    resetOptions();
    const chosenBackground = new Image();
    chosenBackground.src = background;
    chosenBackground.crossOrigin = '*';
    chosenBackground.onload = () => {
      setSelectedImage(chosenBackground);
    };
    setStartingCanvasSizes({
      width: canvasContainerRef.current.offsetWidth,
      height: canvasContainerRef.current.offsetHeight,
    });
  }, [background, canvasContainerRef]);

  useEffect(() => {
    if (!repositioning) return;

    let thisCurrentWidth = canvasContainerRef.current.offsetWidth;
    let thisCurrentHeight = canvasContainerRef.current.offsetHeight;
    if (canvasContainerRef.current.offsetWidth > width) {
      thisCurrentWidth = width;
      thisCurrentHeight = height;
    }

    const rectangleX = (thisCurrentWidth - startingCanvasSizes.width) / 2;
    const rectangleY = (thisCurrentHeight - startingCanvasSizes.height) / 2;
    setCurrentWidth(thisCurrentWidth);
    setCurrentHeight(thisCurrentHeight);
    setRectanglePosition({
      x: rectangleX,
      y: rectangleY,
    });
  }, [
    repositioning,
    width,
    height,
    startingCanvasSizes.width,
    startingCanvasSizes.height,
    canvasContainerRef,
  ]);

  useEffect(() => {
    setWidthScaleCorrection(startingCanvasSizes.width / width);
    setHeightScaleCorrection(startingCanvasSizes.height / height);
  }, [width, height, startingCanvasSizes.width, startingCanvasSizes.height]);

  const getImageStartingPosition = useCallback((imageWidth, imageHeight) => {
    let startingPostitionX = Math.ceil((currentWidth - imageWidth) / 2);
    let startingPostitionY = Math.ceil((currentHeight - imageHeight) / 2);
    if (newImagePosition.x) {
      if (repositioning) {
        startingPostitionX = newImagePosition.x - cursorPositionImage.x;
        if (startingPostitionX >= rectanglePosition.x) {
          startingPostitionX = rectanglePosition.x;
        }
        if (startingPostitionX + imageWidth < rectanglePosition.x + startingCanvasSizes.width) {
          startingPostitionX = rectanglePosition.x + startingCanvasSizes.width - imageWidth;
        }
        setScaledPositionX(startingPostitionX);
      } else {
        startingPostitionX = (scaledPositionX - rectanglePosition.x) / widthScaleCorrection;
      }
    }
    if (newImagePosition.y) {
      if (repositioning) {
        startingPostitionY = newImagePosition.y - cursorPositionImage.y;
        if (startingPostitionY > rectanglePosition.y) {
          startingPostitionY = rectanglePosition.y;
        }
        if (startingPostitionY + imageHeight < rectanglePosition.y + startingCanvasSizes.height) {
          startingPostitionY = rectanglePosition.y + startingCanvasSizes.height - imageHeight;
        }
        setScaledPositionY(startingPostitionY);
      }
      if (!repositioning) {
        startingPostitionY = (scaledPositionY - rectanglePosition.y) / heightScaleCorrection;
      }
    }

    setImagePosition({
      top: startingPostitionY,
      left: startingPostitionX,
      width: imageWidth,
      height: imageHeight,
    });

    return {
      startingPostitionX,
      startingPostitionY,
    };
  }, [
    repositioning,
    currentWidth,
    currentHeight,
    cursorPositionImage.x,
    cursorPositionImage.y,
    newImagePosition.x,
    newImagePosition.y,
    scaledPositionX,
    scaledPositionY,
    heightScaleCorrection,
    widthScaleCorrection,
    rectanglePosition.x,
    rectanglePosition.y,
    startingCanvasSizes.width,
    startingCanvasSizes.height,
  ]);

  return {
    resize,
    setResize,
    dragging,
    setDragging,
    canDrag,
    setCanDrag,
    startingCanvasSizes,
    selectedImage,
    setNewImagePosition,
    imagePosition,
    setCursorPositionImage,
    rectanglePosition,
    widthScaleCorrection,
    heightScaleCorrection,
    getImageStartingPosition,
  };
};

export default useCanvasImage;
