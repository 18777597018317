import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useForm from '../../hooks/useForm';
import validate from './validation';
import FormElement from '../FormElement';
import Button from '../Button';
import RequestService from '../../../networking/RequestService';
import Spinner from '../Spinner';

/**
 * Forgotten password Form
 * @returns {*}
 * @constructor
 */
export default function ForgottenPasswordForm({ endpoint }) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    setError(false);
    setLoading(true);
    (new RequestService(endpoint))
      .setParams({
        email: values.reset_email,
      })
      .send()
      .then(() => {
        setError(false);
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <div className="forgotten-pass-form">
        <FormElement
          id="reset_email"
          elementType="email"
          placeholder={t('forms:email')}
          value={values.reset_email || ''}
          errors={errors.reset_email}
          changed={handleChange}
        />
        <Button
          text={loading ? (
            <>
              <Spinner />
              {t('loading')}
            </>
          ) : t('send')}
          classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
          disabled={loading}
          onClick={handleSubmit}
        />
      </div>
      {success && (
        <p className="success">{t('forms:link_sent')}</p>
      )}
      {error && (
        <p className="error">{t('forms:user_not_found')}</p>
      )}
    </>
  );
}

ForgottenPasswordForm.propTypes = {
  endpoint: PropTypes.string,
};

ForgottenPasswordForm.defaultProps = {
  endpoint: '',
};
