/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import FormElement from '../../react-web-ui/components/FormElement';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import { ReactComponent as CloseIcon } from '../../react-web-ui/assets/images/icons/Close.svg';
import Button from '../../react-web-ui/components/Button';

/**
* Event menu items
* @param restaurantInfo
* @returns {*}
* @constructor
*/
export default function EventMenuItems({
  index,
  errors,
  setErrors,
  events,
  inputList,
  setInputList,
}) {
  const { t, i18n } = useTranslation();
  const ref = useRef();
  const countryContext = useContext(CountryContext);
  const [items, setItems] = useState(inputList[index].menuItems);

  const handleSubInputChange = (e, i) => {
    const { name, value } = e.target;
    const list = JSON.parse(JSON.stringify(inputList));
    const currentItems = JSON.parse(JSON.stringify(items));
    const currentErrors = JSON.parse(JSON.stringify(errors));
    currentItems[i][name] = value;
    if (value && value.trim() !== '' && currentErrors[index]) {
      if (currentErrors[index].menuItems[i]) {
        currentErrors[index].menuItems[i][name] = [];
      }
    }
    list[index].menuItems = currentItems;
    setItems(currentItems);
    setInputList(list);
    setErrors(currentErrors);
  };

  const handleAddItemClick = () => {
    const currentInputList = [...inputList];

    currentInputList[index].menuItems = items;
    currentInputList[index].menuItems.push({
      itemId: Math.random().toString(36).substr(2, 9),
      itemNameEn: '',
      itemNameTranslation: '',
      itemDescriptionEn: '',
      itemDescriptionTranslation: '',
    });

    setInputList(currentInputList);
  };

  const handleDelete = (i) => {
    const list = JSON.parse(JSON.stringify(inputList));
    const currentItems = [...items];
    currentItems.splice(i, 1);
    list[index].menuItems = currentItems;
    setItems(currentItems);
    setInputList(list);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    const currentInputList = [...inputList];
    currentInputList[index].menuItems = itemsNew;
    setInputList(currentInputList);
    setItems(itemsNew);
  };

  useEffect(() => {
    setItems(inputList[index].menuItems);
  }, [inputList[index].menuItems]);

  const translateNewItem = (ind) => {
    const params = {
      source_language_code: i18n.language,
    };

    if (i18n.language === 'en') {
      params.texts = [items[ind].itemNameEn, items[ind].itemDescriptionEn];
      params.target_language_code = countryContext.country.code.toLowerCase();
    } else {
      params.texts = [items[ind].itemNameTranslation, items[ind].itemDescriptionTranslation];
      params.target_language_code = 'en';
    }

    const list = JSON.parse(JSON.stringify(inputList));
    const currentItems = JSON.parse(JSON.stringify(items));

    (new RequestService('proxy/translations/translate'))
      .setParams(params)
      .send()
      .then((response) => {
        const { translations } = response.data;
        if (i18n.language === 'en') {
          currentItems[ind].itemNameTranslation = translations[0].text;
          currentItems[ind].itemDescriptionTranslation = translations[1]?.text;
        } else {
          currentItems[ind].itemNameEn = translations[0].text;
          currentItems[ind].itemDescriptionEn = translations[1]?.text;
        }
        list[index].menuItems = currentItems;
        setItems(currentItems);
        setInputList(list);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_translate'));
      });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="tour-menu-fields menu-items-section"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map(({
                itemId,
                itemNameEn,
                itemNameTranslation,
                itemDescriptionEn,
                itemDescriptionTranslation,
              }, i) => (
                items.length === 1 ? (
                  <div key={itemId} className="menu-item-group">
                    <div className="grid">
                      <div className="grid-col-5">
                        <FormElement
                          id={`itemNameEn${index}${i}`}
                          name="itemNameEn"
                          elementType="input"
                          placeholder={t('forms:enter_course_name_en')}
                          value={itemNameEn}
                          errors={
                            errors[index]
                            && errors[index].menuItems[i]
                            && errors[index].menuItems[i].itemNameEn
                          }
                          changed={(e) => handleSubInputChange(e, i)}
                        />
                      </div>
                      <div className="grid-col-5">
                        <FormElement
                          id={`itemNameTranslation${index}${i}`}
                          name="itemNameTranslation"
                          elementType="input"
                          placeholder={t(`forms:enter_course_name_translation_${countryContext.country.name}`)}
                          value={itemNameTranslation}
                          errors={
                            errors[index]
                            && errors[index].menuItems[i]
                            && errors[index].menuItems[i].itemNameTranslation
                          }
                          changed={(e) => handleSubInputChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="grid">
                      <div className="grid-col-5">
                        <FormElement
                          id={`itemDescriptionEn${index}${i}`}
                          name="itemDescriptionEn"
                          elementType="input"
                          placeholder={t('forms:enter_course_description_en')}
                          value={itemDescriptionEn || ''}
                          changed={(e) => handleSubInputChange(e, i)}
                        />
                      </div>
                      <div className="grid-col-5">
                        <FormElement
                          id={`itemDescriptionTranslation${index}${i}`}
                          name="itemDescriptionTranslation"
                          elementType="input"
                          placeholder={t(`forms:enter_course_description_translation_${countryContext.country.name}`)}
                          value={itemDescriptionTranslation || ''}
                          changed={(e) => handleSubInputChange(e, i)}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="translate-fields"
                      onClick={() => translateNewItem(i)}
                    >
                      {' '}
                    </button>
                  </div>
                ) : (
                  <Draggable key={itemId} draggableId={itemId} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className={`menu-item-group draggable-inputs ${snapshot.isDragging ? 'dragged' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="grid">
                          <div className="grid-col-5">
                            <FormElement
                              id={`itemNameEn${index}${i}`}
                              name="itemNameEn"
                              elementType="input"
                              placeholder={t('forms:enter_course_name_en')}
                              value={itemNameEn}
                              errors={
                                errors[index]
                                && errors[index].menuItems[i]
                                && errors[index].menuItems[i].itemNameEn
                              }
                              changed={(e) => handleSubInputChange(e, i)}
                            />
                          </div>
                          <div className="grid-col-5">
                            <FormElement
                              id={`itemNameTranslation${index}${i}`}
                              name="itemNameTranslation"
                              elementType="input"
                              placeholder={t(`forms:enter_course_name_translation_${countryContext.country.name}`)}
                              value={itemNameTranslation}
                              errors={
                                errors[index]
                                && errors[index].menuItems[i]
                                && errors[index].menuItems[i].itemNameTranslation
                              }
                              changed={(e) => handleSubInputChange(e, i)}
                            />
                          </div>
                        </div>
                        <div className="grid">
                          <div className="grid-col-5">
                            <FormElement
                              id={`itemDescriptionEn${index}${i}`}
                              name="itemDescriptionEn"
                              elementType="input"
                              placeholder={t('forms:enter_course_description_en')}
                              value={itemDescriptionEn || ''}
                              changed={(e) => handleSubInputChange(e, i)}
                            />
                          </div>
                          <div className="grid-col-5">
                            <FormElement
                              id={`itemDescriptionTranslation${index}${i}`}
                              name="itemDescriptionTranslation"
                              elementType="input"
                              placeholder={t(`forms:enter_course_description_translation_${countryContext.country.name}`)}
                              value={itemDescriptionTranslation || ''}
                              changed={(e) => handleSubInputChange(e, i)}
                            />
                          </div>
                        </div>
                        <button
                          ref={ref}
                          className="delete-btn"
                          type="button"
                          onClick={() => {
                            confirmAlert({
                              // eslint-disable-next-line react/prop-types
                              customUI: ({ onClose }) => (
                                <div className={`react-confirm-alert-body ${events ? 'event-page' : 'tasting-page'}`}>
                                  <h2 className="section-title">{t('pageMenu:are_you_sure_delete')}</h2>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleDelete(i);
                                      onClose();
                                    }}
                                  >
                                    {t('yes')}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={onClose}
                                  >
                                    {t('no')}
                                  </button>
                                </div>
                              ),
                            });
                          }}
                        >
                          <CloseIcon className="icon" />
                        </button>
                        <button
                          type="button"
                          className="translate-fields"
                          onClick={() => translateNewItem(i)}
                        >
                          {' '}
                        </button>
                      </div>
                    )}
                  </Draggable>
                )
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        text={t('add_another_course')}
        classes="tour-add-course btn-accent btn-outline add-another-btn"
        onClick={() => handleAddItemClick(index)}
      />
    </>
  );
}

EventMenuItems.propTypes = {
  index: PropTypes.number.isRequired,
  errors: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setErrors: PropTypes.func.isRequired,
  events: PropTypes.bool,
  inputList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setInputList: PropTypes.func.isRequired,
};

EventMenuItems.defaultProps = {
  events: false,
};
