import React from 'react';

/**
 * Spinner
 * @returns {*}
 * @constructor
 */
export default function Spinner() {
  return (
    <div className="loader">Loading</div>
  );
}
