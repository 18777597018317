import Resolver from './Resolver';

const resolver = new Resolver();

export const restaurantImage = resolver.provider.restaurant;
export const chefImage = resolver.provider.chef;
export const cityImage = resolver.provider.city;
export const eventImage = resolver.provider.specialEvent;
export const specialOfferImg = resolver.provider.specialOffer;
export const collectionImg = resolver.provider.collection;
