import React, { useEffect } from 'react';
import RequestService from '../networking/RequestService';

let getFloorPlanRequest = null;

const ACTION_FETCH_SALONS_START = 'FETCH_SALONS_START';
const ACTION_FETCH_SALONS_FAIL = 'FETCH_SALONS_FAIL';
const ACTION_FETCH_SALONS_SUCCESS = 'FETCH_SALONS_SUCCESS';

const ACTION_SELECT_SALON = 'SELECT_SALON';

const ACTION_ADD_SALON = 'ADD_SALON';
const ACTION_EDIT_SALON = 'EDIT_SALON';
const ACTION_DELETE_SALON = 'DELETE_SALON';

const ACTION_SELECT_ELEMENT = 'SELECT_ELEMENT';
const ACTION_DESELECT_ELEMENT = 'DESELECT_ELEMENT';

const ACTION_CREATE_ELEMENT = 'CREATE_ELEMENT';
const ACTION_EDIT_ELEMENT = 'EDIT_ELEMENT';
const ACTION_DELETE_ELEMENT = 'DELETE_ELEMENT';

const INITIAL_STATE = {
  all: [],
  // Single Salon Object
  selectedSalon: null,
  salonsAreFetching: true,
  salonsFetchError: null,
  selectedElements: [],
  highlightedElements: [],
};

const salonsReducer = (state, action) => {
  let updatedSalon = null;
  switch (action.type) {
    case ACTION_FETCH_SALONS_START:
      return {
        ...state,
        salonsAreFetching: true,
        salonsFetchError: null,
      };
    case ACTION_FETCH_SALONS_FAIL:
      return {
        ...state,
        salonsAreFetching: false,
        salonsFetchError: 'Failed to load salons & elements.',
      };
    case ACTION_FETCH_SALONS_SUCCESS:
      return {
        ...INITIAL_STATE,
        salonsAreFetching: false,
        salonsFetchError: null,
        all: action.salons ?? [],
        selectedSalon: null,
      };
    case ACTION_SELECT_SALON:
      return {
        ...state,
        selectedSalon: action.salon,
        elements: action.salon.elements,
      };

    case ACTION_EDIT_SALON:
      return {
        ...state,
        all: state.all.map((salon) => ({
          ...salon.id !== action.salon.id ? salon : action.salon,
        })),
      };

    case ACTION_ADD_SALON:
      return {
        ...state,
        all: state.all.concat(action.salon),
        selectedSalon: action.salon,
        selectedElements: [],
      };

    case ACTION_DELETE_SALON:
      return {
        ...state,
        all: state.all.filter((salon) => salon.id !== action.salon.id),
      };
    case ACTION_EDIT_ELEMENT:
      updatedSalon = {
        ...state.selectedSalon,
        elements: [action.element]
          .concat(state.selectedSalon.elements.filter(
            (element) => (element.id !== action.element.id),
          )),
      };
      return {
        ...state,
        all: state.all.map((salon) => {
          // Here we assume that you can only edit elements,
          // in the currently selected Salon;
          if (salon.id === state.selectedSalon.id) {
            return updatedSalon;
          }
          return salon;
        }),
        selectedSalon: updatedSalon,
        // Select currently edited element
        selectedElements: [action.element.id],
      };
    case ACTION_SELECT_ELEMENT:
      return {
        ...state,
        selectedElements: [action.elementId].concat(state.selectedElements),
      };
    case ACTION_DESELECT_ELEMENT:
      return {
        ...state,
        selectedElements: state.selectedElements.filter(
          (elementId) => elementId !== action.elementId,
        ),
      };
    case ACTION_CREATE_ELEMENT:
      // Here we assume that you can only add elements,
      // in the currently selected Salon;
      updatedSalon = {
        ...state.selectedSalon,
        elements: [action.element].concat(state.selectedSalon.elements),
      };
      return {
        ...state,
        all: state.all.map((salon) => {
          if (salon.id === state.selectedSalon.id) {
            return updatedSalon;
          }
          return salon;
        }),
        selectedElements: [action.element.id],
        selectedSalon: updatedSalon,
      };

    case ACTION_DELETE_ELEMENT:
      // Here we assume that you can only delete elements,
      // in the currently selected Salon;
      updatedSalon = {
        ...state.selectedSalon,
        elements: state.selectedSalon.elements.filter((element) => element.id !== action.elementId),
      };
      return {
        ...state,
        all: state.all.map((salon) => {
          const elements = salon.elements.filter((element) => element.id !== action.elementId);
          return {
            ...salon,
            elements,
          };
        }),
        selectedSalon: updatedSalon,
        selectedElements: state.selectedElements.filter(
          (selectedelementId) => selectedelementId !== action.elementId,
        ),
      };
    default:
      throw new Error(`Unsupported action: ${action.type}`);
  }
};

const useSalons = (user, restaurantId) => {
  const [
    {
      all,
      selectedSalon,
      selectedElements,
      highlightedElements,
      salonsAreFetching,
      salonsFetchError,
    }, dispatch] = React.useReducer(
    salonsReducer,
    INITIAL_STATE,
    () => INITIAL_STATE,
  );

  useEffect(() => {
    if (user && restaurantId) {
      if (getFloorPlanRequest !== null) {
        getFloorPlanRequest.source.cancel();
      }

      dispatch({
        type: ACTION_FETCH_SALONS_START,
      });

      getFloorPlanRequest = (new RequestService('manager/floorplan/get'))
        .setParams({
          place_id: restaurantId,
          user_id: user.id,
        });

      getFloorPlanRequest
        .send()
        .then((response) => {
          dispatch({
            type: ACTION_FETCH_SALONS_SUCCESS,
            salons: response.data.map((salon) => salon.plain()),
          });
        })
        .catch(() => {
          dispatch({
            type: ACTION_FETCH_SALONS_FAIL,
          });
        });
    }
    return () => {
      if (getFloorPlanRequest !== null) {
        getFloorPlanRequest.source.cancel();
      }
    };
  }, [user, restaurantId]);

  return {
    all,
    selectedSalon,
    salonsAreFetching,
    salonsFetchError,
    selectedElements,
    highlightedElements,
    refresh: (salons) => dispatch({
      type: ACTION_FETCH_SALONS_SUCCESS,
      salons: salons.map((salon) => salon.plain()),
    }),
    select: (salon) => dispatch({ type: ACTION_SELECT_SALON, salon }),
    update: (salon) => dispatch({ type: ACTION_EDIT_SALON, salon }),
    add: (salon) => dispatch({ type: ACTION_ADD_SALON, salon }),
    elements: {
      create: (element) => dispatch({
        type: ACTION_CREATE_ELEMENT,
        element,
      }),
      update: (element) => dispatch({
        type: ACTION_EDIT_ELEMENT,
        element,
      }),
      select: (elementId) => dispatch({
        type: ACTION_SELECT_ELEMENT,
        elementId,
      }),
      deselect: (elementId) => dispatch({
        type: ACTION_DESELECT_ELEMENT,
        elementId,
      }),
      remove: (elementId) => dispatch({
        type: ACTION_DELETE_ELEMENT,
        elementId,
      }),
    },
  };
};

export default useSalons;
