import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useForm = (callback, validate, initialValues = {}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSent, setIsSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (JSON.stringify(initialValues) !== '{}'
      && JSON.stringify(values) !== JSON.stringify(initialValues)) {
      setValues({ ...initialValues });
    }
    // eslint-disable-next-line
  }, [initialValues]);

  useEffect(() => {
    if (Object.values(errors).every((error) => !error.length) && isSubmitting) {
      callback();
      setIsSubmitting(false);
    }
  }, [errors, isSubmitting, callback]);

  useEffect(() => {
    if (isSent) setErrors(validate(values));
  }, [t, isSent, values, validate]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
    setIsSent(true);
  };

  const handleChange = (event) => {
    event.persist();
    setIsSubmitting(false);
    let { value } = event.target;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    const newValues = { ...values, [event.target.name]: value };
    if (isSent) setErrors(validate(newValues));
    setValues(newValues);
  };

  return {
    handleChange,
    handleSubmit,
    setValues,
    setIsSubmitting,
    values,
    errors,
  };
};

export default useForm;
