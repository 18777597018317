import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import RequestService from '../../networking/RequestService';
import UserContext from '../../react-web-ui/contexts/UserContext';
import useForm from '../../react-web-ui/hooks/useForm';
import validate from './validation';
import FormElement from '../../react-web-ui/components/FormElement';
import SaveOrDiscard from '../SaveOrDiscard';

/**
* EditSalonForm
* @returns {*}
* @constructor
*/
export default function EditSalonForm({
  salon,
  index,
  itemsEditing,
  setItemsEditing,
  setLoading,
  setShowAdd,
  setRefresh,
}) {
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);
  const [isOutside, setIsOutside] = useState(salon.outside);
  const [isActive, setIsActive] = useState(salon.active);
  const currentEditing = [...itemsEditing];

  const {
    handleChange,
    handleSubmit,
    setValues,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  useEffect(() => {
    setValues({
      salon_name: salon.name,
    });
  }, []);

  const changeEditing = (bool) => {
    currentEditing.forEach((current, i) => {
      if (index === i) {
        currentEditing[i] = bool;
      } else {
        currentEditing[i] = false;
      }
    });
    setItemsEditing(currentEditing);
  };

  function submit() {
    setLoading(true);
    const params = {
      user_id: userContext.user.id,
      salon_id: salon.id,
      default_name: values.salon_name,
      active: isActive,
      outside: isOutside,
    };
    (new RequestService('manager/salons/edit'))
      .setParams(params)
      .send()
      .then(() => {
        toast.success(t('success:salon_successfully_edited'));
        setRefresh(true);
      })
      .catch((error) => {
        if (error.key) toast.error(t(`apiErrors:${error.key}`));
        else toast.error(t('apiErrors:could_not_edit_salon'));
      })
      .finally(() => {
        setLoading(false);
        changeEditing(false);
      });
  }

  return (
    <>
      <SaveOrDiscard
        handleSubmit={() => {
          handleSubmit();
          setShowAdd(true);
        }}
        check={(
          (Object.keys(values).length > 0 && Object.values(values).some((x) => x !== salon.name))
          || isOutside !== salon.outside
          || isActive !== salon.active
        )}
        onClick={() => {
          changeEditing(false);
          setShowAdd(true);
        }}
      />
      <div className="lg-two-cols">
        <FormElement
          className="col"
          id="salon_name"
          elementType="input"
          placeholder={t('pageFloorPlan:name')}
          value={values.salon_name || ''}
          errors={errors.salon_name}
          changed={handleChange}
        />
      </div>
      <footer>
        <button
          type="button"
          className={`toggle-status ${isOutside ? 'active' : ''}`}
          onClick={() => setIsOutside(!isOutside)}
        >
          <span className="seating-option">{t('pageWidget:inside')}</span>
          <span className="seating-option">{t('pageWidget:outside')}</span>
        </button>
        <button
          type="button"
          className={`toggle-status ${isActive ? 'active' : ''}`}
          onClick={() => setIsActive(!isActive)}
        >
          <span className="seating-option">{t('pageEmployees:inactive')}</span>
          <span className="seating-option">{t('pageEmployees:active')}</span>
        </button>
      </footer>
    </>
  );
}

EditSalonForm.propTypes = {
  salon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  itemsEditing: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setItemsEditing: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setShowAdd: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};
