import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import useForm from '../react-web-ui/hooks/useForm';
import validate from './validation';
import FormElement from '../react-web-ui/components/FormElement';
import Button from '../react-web-ui/components/Button';
import Checkbox from '../react-web-ui/components/FormElement/Checkbox';
import RequestService from '../networking/RequestService';
import UserContext from '../react-web-ui/contexts/UserContext';
import CountryContext from '../react-web-ui/contexts/CountryContext';
import Spinner from '../react-web-ui/components/Spinner';
import heroImg from '../assets/images/hero-image-4.jpg';
import CustomPhoneInput from '../components/CustomPhoneInput';

export default function JoinNow() {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorText, setErrorText] = useState(false);
  const userContext = useContext(UserContext);
  const countryContext = useContext(CountryContext);
  const [loading, setLoading] = useState(false);

  const {
    handleChange,
    handleSubmit,
    setValues,
    values,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    const params = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      password_v2: true,
      phone: values.phone,
      is_subscribed: values.accept_promo_newsletter || false,
      country_preference: countryContext.country.id,
      source: values.learn_select || '',
      position: values.position || '',
    };

    setLoading(true);
    (new RequestService('manager/users/signup'))
      .setParams(params)
      .send()
      .then((response) => {
        toast.success(t('success:register_success'));
        userContext.setUser(response.data);
        history.push('/restaurants-listing');
      })
      .catch(() => setErrorText(true))
      .finally(() => {
        setLoading(false);
      });
  }

  if (userContext.isLoggedIn) {
    history.push('/');
  }

  return (
    <>
      <div className="hero-section small">
        <img src={heroImg} alt="" />
      </div>

      <div className="inner-wrapper">
        <div className="grid">
          <div className="grid-col-5 progress-section">
            <h2 className="section-title">
              <strong>
                {t('selfOnboarding:steps_away')}
              </strong>
            </h2>
            <div className="vertical-progress">
              <span className="step active">
                {t('selfOnboarding:create_account_and_add_restaurant')}
                <small>{t('selfOnboarding:youre_here')}</small>
              </span>
              <span className="step">
                {t('selfOnboarding:select_subscription')}
              </span>
              <span className="step">
                {t('selfOnboarding:set_up')}
              </span>
              <span className="step">
                {t('selfOnboarding:start_using')}
              </span>
            </div>
          </div>
          <div className="grid-col-5">
            <h2 className="section-title"><strong>{t('selfOnboarding:create_account')}</strong></h2>
            {!userContext.isLoggedIn && (
              <button
                type="button"
                className="btn-link open-login"
                onClick={() => userContext.setAuthPopup(true)}
              >
                {t('selfOnboarding:have_an_account')}
              </button>
            )}
            <form action="" noValidate className="join-form">
              {errorText && (
                <p className="error">{t('forms:email_taken')}</p>
              )}
              <FormElement
                id="first_name"
                elementType="input"
                placeholder={t('forms:first_name')}
                value={values.first_name || ''}
                errors={errors.first_name}
                label={t('forms:first_name')}
                changed={handleChange}
              />
              <FormElement
                id="last_name"
                elementType="input"
                placeholder={t('forms:last_name')}
                value={values.last_name || ''}
                errors={errors.last_name}
                changed={handleChange}
                label={t('forms:last_name')}
              />
              <CustomPhoneInput
                values={values}
                setValues={setValues}
                errors={errors.phone}
              />
              <FormElement
                id="email"
                elementType="input"
                inputType="email"
                placeholder={t('forms:email')}
                value={values.email || ''}
                errors={errors.email}
                changed={handleChange}
                label={t('forms:email')}
              />
              <FormElement
                id="password"
                elementType="input"
                inputType="password"
                placeholder={t('forms:password')}
                value={values.password || ''}
                errors={errors.password}
                changed={handleChange}
                label={t('forms:password')}
              />
              <FormElement
                id="repeat_password"
                elementType="input"
                inputType="password"
                placeholder={t('forms:repeat_password')}
                value={values.repeat_password || ''}
                errors={errors.repeat_password}
                changed={handleChange}
                label={t('forms:repeat_password')}
              />
              <FormElement
                id="places_number"
                elementType="input"
                inputType="number"
                placeholder={t('selfOnboarding:places_operate_label')}
                value={values.places_number || ''}
                errors={errors.places_number}
                changed={handleChange}
                label={t('selfOnboarding:places_operate_label')}
              />
              <FormElement
                id="position"
                elementType="input"
                placeholder={t('forms:position')}
                value={values.position || ''}
                errors={errors.position}
                changed={handleChange}
                label={t('forms:position')}
              />
              <FormElement
                id="learn_select"
                elementType="select"
                placeholder="Select"
                value={values.learn_select || ''}
                errors={errors.learn_select}
                changed={handleChange}
                label={t('selfOnboarding:how_did_you_learn')}
                selectOptions={[
                  {
                    value: '',
                    text: t('forms:select'),
                  },
                  {
                    value: 'Google Ad',
                    text: t('selfOnboarding:google_ad'),
                  },
                  {
                    value: 'Facebook',
                    text: t('selfOnboarding:facebook'),
                  },
                  {
                    value: 'Recommended by a peer',
                    text: t('selfOnboarding:recommended'),
                  },
                  {
                    value: 'Google Search',
                    text: t('selfOnboarding:google_search'),
                  },
                  {
                    value: 'Outdoor campaign',
                    text: t('selfOnboarding:outdoor_campaign'),
                  },
                  {
                    value: 'Other',
                    text: t('selfOnboarding:other'),
                  },
                ]}
              />
              <Checkbox
                id="accept_terms_register"
                text={(
                  <span>
                    {t('forms:read_and_accept')}
                    <a
                      href={`https://dineout.${countryContext.country.code.toLowerCase()}/terms-of-service`}
                      onClick={() => userContext.setAuthPopup(false)}
                    >
                      {t('forms:terms_and_conditions')}
                    </a>
                  </span>
                )}
                value={`${values.accept_terms_register}`}
                checked={values.accept_terms_register}
                check={handleChange}
                errors={errors.accept_terms_register}
              />
              <Checkbox
                id="accept_promo_newsletter"
                text={(
                  <span>
                    {t('selfOnboarding:accept_promo')}
                  </span>
                )}
                value={`${values.accept_promo_newsletter}`}
                checked={values.accept_promo_newsletter}
                check={handleChange}
                errors={errors.accept_promo_newsletter}
              />
              <Button
                text={loading ? (
                  <>
                    <Spinner />
                    {t('loading')}
                  </>
                ) : t('selfOnboarding:sign_up')}
                classes={`btn-accent ${loading ? 'loading-btn' : ''}`}
                disabled={loading}
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
