import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Edit } from '../../assets/images/icons/Edit.svg';

/**
* ShadowBlock
* @param employee
* @returns {*}
* @constructor
*/
export default function ShadowBlock({
  name,
  subtitle,
  index,
  itemsEditing,
  setShowAdd,
  setItemsEditing,
  successMessage,
  showSuccess,
  footer,
  editingForm,
}) {
  const { t } = useTranslation();
  const currentEditing = [...itemsEditing];

  const changeEditing = (bool) => {
    currentEditing.forEach((current, i) => {
      if (index === i) {
        currentEditing[i] = bool;
      } else {
        currentEditing[i] = false;
        // setValues({});
      }
    });
    setItemsEditing(currentEditing);
  };

  return (
    <div className="shadow-block">
      {showSuccess[index] && (
        <span className="success-ribbon">
          {successMessage}
        </span>
      )}
      {!itemsEditing[index] && (
        <button
          className="edit-shadow-btn"
          type="button"
          onClick={() => {
            changeEditing(true);
            setShowAdd(false);
          }}
        >
          <Edit className="icon" />
          {t('edit')}
        </button>
      )}
      <header>
        <h3 className="name">
          {name}
        </h3>
        <p>{subtitle}</p>
      </header>
      {itemsEditing[index] && editingForm}
      <footer>
        {footer}
      </footer>
    </div>
  );
}

ShadowBlock.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  setShowAdd: PropTypes.func.isRequired,
  setItemsEditing: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  itemsEditing: PropTypes.oneOfType([PropTypes.array]).isRequired,
  successMessage: PropTypes.string.isRequired,
  footer: PropTypes.element.isRequired,
  editingForm: PropTypes.element.isRequired,
  showSuccess: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
