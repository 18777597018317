import React, { useEffect } from 'react';
import RequestService from '../networking/RequestService';
import { randomId } from '../utilities/constants';

let getCombinationsRequest = null;

const ACTION_FETCH_COMBINATIONS_START = 'FETCH_COMBINATIONS_START';
const ACTION_FETCH_COMBINATIONS_FAIL = 'FETCH_COMBINATIONS_FAIL';
const ACTION_FETCH_COMBINATIONS_SUCCESS = 'FETCH_COMBINATIONS_SUCCESS';

const ACTION_SELECT_COMBINATION = 'SELECT_COMBINATION';
const ACTION_DESELECT_COMBINATION = 'DESELECT_COMBINATION';

const ACTION_HIGHLIGHT_COMBINATION = 'HIGHLIGHT_COMBINATION';

const ACTION_ADD_COMBINATION = 'ADD_COMBINATION';
const ACTION_EDIT_COMBINATION = 'EDIT_COMBINATION';
const ACTION_DELETE_COMBINATION = 'DELETE_COMBINATION';

const INITIAL_STATE = {
  all: [],
  selectedCombination: null,
  highlightedCombination: null,
  expandCombinationDetails: false,
  combinationsAreFetching: true,
  combinationsFetchError: null,
};

const combinationsReducer = (state, action) => {
  switch (action.type) {
    case ACTION_FETCH_COMBINATIONS_START:
      return {
        ...state,
        combinationsAreFetching: true,
        combinationsFetchError: null,
      };
    case ACTION_FETCH_COMBINATIONS_FAIL:
      return {
        ...state,
        combinationsAreFetching: false,
        combinationsFetchError: 'Failed to load combinations.',
      };
    case ACTION_FETCH_COMBINATIONS_SUCCESS:
      return {
        ...INITIAL_STATE,
        combinationsAreFetching: false,
        combinationsFetchError: null,
        all: action.combinations ?? [],
      };
    case ACTION_SELECT_COMBINATION:
      return {
        ...state,
        expandCombinationDetails: true,
        selectedCombination: state.all.find((salon) => salon.id === action.salonId)
          .combinations.find((combination) => combination.id === action.combinationId),
      };
    case ACTION_DESELECT_COMBINATION:
      return {
        ...state,
        selectedCombination: null,
      };
    case ACTION_HIGHLIGHT_COMBINATION:
    {
      return {
        ...state,
        highlightedCombination: action.combination,
      };
    }
    case ACTION_EDIT_COMBINATION:
      return {
        ...state,
        selectedCombination: null,
        expandCombinationDetails: false,
        all: state.all.map((salon) => (salon.id !== action.salonId ? salon : {
          ...salon,
          combinations: salon.combinations.map((combination) => {
            if (combination.id === action.combination.id) {
              return action.combination;
            }

            return combination;
          }),
        })),
      };

    case ACTION_ADD_COMBINATION:
      return {
        ...state,
        expandCombinationDetails: false,
        all: state.all.map((salon) => {
          if (salon.id === action.salonId) {
            return {
              id: salon.id,
              combinations: [
                ...salon.combinations,
                {
                  id: randomId(),
                  exists: false,
                  name: action.combination.name,
                  minCapacity: action.combination.minCapacity,
                  maxCapacity: action.combination.maxCapacity,
                  tables: action.combination.tables,
                },
              ],
            };
          }

          return salon;
        }),
      };

    case ACTION_DELETE_COMBINATION:
      return {
        ...state,
        expandCombinationDetails: false,
        all: state.all.map((salon) => {
          if (salon.id === action.salonId) {
            return {
              ...salon,
              combinations: salon.combinations
                .filter((combination) => combination.id !== action.combinationId),
            };
          }

          return salon;
        }),
      };
    default:
      throw new Error(`Unsupported action: ${action.type}`);
  }
};

const useCombinations = (salonId, user, restaurantId) => {
  const [
    {
      all,
      expandCombinationDetails,
      selectedCombination,
      highlightedCombination,
      combinationsAreFetching,
      combinationsFetchError,
    }, dispatch] = React.useReducer(
    combinationsReducer,
    INITIAL_STATE,
    () => INITIAL_STATE,
  );

  useEffect(() => {
    if (user && restaurantId) {
      if (getCombinationsRequest !== null) {
        getCombinationsRequest.source.cancel();
      }

      dispatch({
        type: ACTION_FETCH_COMBINATIONS_START,
      });

      getCombinationsRequest = (new RequestService('manager/combinations/get'))
        .setParams({
          place_id: restaurantId,
          user_id: user.id,
        });

      getCombinationsRequest
        .send()
        .then((response) => {
          dispatch({
            type: ACTION_FETCH_COMBINATIONS_SUCCESS,
            combinations: response.data.map((combination) => combination.plain()),
          });
        })
        .catch(() => {
          dispatch({
            type: ACTION_FETCH_COMBINATIONS_FAIL,
          });
        });
    }
    return () => {
      if (getCombinationsRequest !== null) {
        getCombinationsRequest.source.cancel();
      }
    };
  }, [user, restaurantId]);

  return {
    all,
    current: all.find((salon) => salon.id === salonId)?.combinations ?? [],
    expandCombinationDetails,
    selectedCombination,
    highlightedCombination,
    combinationsAreFetching,
    combinationsFetchError,
    refresh: (combinations) => dispatch({
      type: ACTION_FETCH_COMBINATIONS_SUCCESS,
      combinations: combinations.map((salon) => salon.plain()),
    }),
    select: (combinationId) => dispatch({
      type: ACTION_SELECT_COMBINATION,
      combinationId,
      salonId,
    }),
    deselect: (combinationId) => dispatch({
      type: ACTION_DESELECT_COMBINATION,
      combinationId,
      salonId,
    }),
    highlight: (combination) => dispatch({
      type: ACTION_HIGHLIGHT_COMBINATION,
      combination,
    }),
    delete: (combinationId) => dispatch({
      type: ACTION_DELETE_COMBINATION,
      combinationId,
      salonId,
    }),
    update: (combination) => dispatch({
      type: ACTION_EDIT_COMBINATION,
      combination,
      salonId,
    }),
    add: (combination) => dispatch({
      type: ACTION_ADD_COMBINATION,
      combination,
      salonId,
    }),
  };
};

export default useCombinations;
