import moment from 'moment';

const enumerateDaysBetweenDates = (startDate, endDate, disableDays) => {
  const dates = [];
  const currDate = moment(startDate).subtract(1, 'days').startOf('day');
  const lastDate = moment(endDate).startOf('day');
  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    if (!disableDays.some((item) => currDate.weekday() === item)) {
      dates.push(currDate.clone().format('YYYY-MM-DD'));
    }
  }

  return dates;
};

export default enumerateDaysBetweenDates;
