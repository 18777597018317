import React from 'react';
import PropTypes from 'prop-types';

/**
 * Restaurant Images Gallery
 * @param images
 * @returns {*}
 * @constructor
 */
export default function RestaurantImages({ images }) {
  if (!images) return null;

  return (
    <>
      {images}
    </>
  );
}

RestaurantImages.propTypes = {
  images: PropTypes.element.isRequired,
};
