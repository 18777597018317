/* eslint-disable max-len */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TermsGiftcards from './TermsGiftcards';
import illustration6 from '../../assets/images/illustrations/illustration6.svg';
import RestaurantContext from '../../contexts/RestaurantContext';
import PageHeading from '../../react-web-ui/components/PageHeading';

export default function GiftCards() {
  const { t, i18n } = useTranslation();
  const restaurantContext = useContext(RestaurantContext);

  if (restaurantContext.restaurant) {
    if (!restaurantContext.restaurant.acceptsGiftCards) {
      return (
        <TermsGiftcards />
      );
    }

    return (
      <div className="inner-wrapper">
        <PageHeading
          classes="page-heading"
          title={t('navigation:nav_gift_cards')}
          content={(
            <>
              <Link className="btn-link" to="/monetize/terms-giftcards">
                <strong>{t('read_terms_add_conditions')}</strong>
              </Link>
              <div className="terms-heading">
                {i18n.language === 'en' && (
                  <div className="text-wrapper">
                    <p>At Dineout we sell our own gift cards which are widely popular among HRs of businesses of varying sizes and types. Our team works with partnering restaurants to create monthly promotions so that users of gift cards have additional reasons to use them at a specific place. Through our corporate network every month we reach thousands of avid restaurant goers and promote your restaurant among them for free.</p>
                    <p>We strongly encourage you to benefit from accepting the Dineout Gift Cards.</p>
                  </div>
                )}
                {i18n.language === 'pl' && (
                  <div className="text-wrapper">
                    <p>W Dineout sprzedajemy nasze własne karty podarunkowe, które cieszą się dużą popularnością wśród HR-owców firm różnej wielkości i typu. Nasz zespół współpracuje z partnerskimi restauracjami tworząc comiesięczne promocje, tak aby posiadacze kart podarunkowych mieli dodatkowe powody do wykorzystania ich w polecanym przez nas miejscu. Poprzez naszą sieć korporacyjną co miesiąc docieramy do tysięcy zapalonych bywalców restauracji i promujemy wśród nich Państwa restaurację zupełnie za darmo.</p>
                    <p>Gorąco zachęcamy do akceptacji i honorowania naszych Kart Podarunkowych Dineout w waszej restauracji.</p>
                  </div>
                )}
                {i18n.language === 'bg' && (
                  <div className="text-wrapper">
                    <p>Dineout предлага собствени ваучери за подарък, които имат огромна популярност сред HR-и на компании от различни сектори. Екипът ни непрестанно разработва промоции с партньори, приемащи ваучери за подарък, за да могат именно техните обекти да бъдат предпочетени от потребителите. Без допълнително заплащане, ежемесечно достигате до широката ни мрежа от корпоративни клиенти.</p>
                    <p>Горещо препоръчваме на бизнеса Ви да започне да печели, приемайки Dineout ваучерите за подарък.</p>
                  </div>
                )}
                <div className="img-wrapper giftcards-img">
                  <img src={illustration6} alt="" />
                </div>
              </div>
            </>
          )}
        />
      </div>
    );
  }
  return ('');
}
