import React from 'react';
import PropTypes from 'prop-types';

export default function MenuNav({ mainLinks, secondaryLinks, navOpen }) {
  return (
    <div className={`mobile-menu ${navOpen ? 'open' : ''}`}>
      <ul className="main-nav">
        {mainLinks}
      </ul>
      <ul className="secondary-nav">
        {secondaryLinks}
      </ul>
    </div>
  );
}

MenuNav.propTypes = {
  mainLinks: PropTypes.element,
  secondaryLinks: PropTypes.element,
  navOpen: PropTypes.bool,
};

MenuNav.defaultProps = {
  mainLinks: null,
  secondaryLinks: null,
  navOpen: false,
};
