import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Create links styled as buttons.
 * @param text
 * @param href
 * @param classes
 * @param icon
 * @param onClick
 * @param target
 * @param disabled
 * @returns {*}
 * @constructor
 */
export default function ButtonLink({
  text,
  href,
  classes,
  icon = null,
  onClick,
  target,
  disabled,
}) {
  return (
    <Link
      target={target}
      to={href}
      className={`btn ${classes}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {text}
    </Link>
  );
}

ButtonLink.propTypes = {
  /** Text of the button. */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** Location this link leads to. */
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** Style of the link.
   * Supports: "btn-accent", "btn-dark", "btn-outline". */
  classes: PropTypes.string.isRequired,
  /** The svg icon component */
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
  target: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonLink.defaultProps = {
  icon: null,
  onClick: null,
  target: '',
  disabled: false,
};
