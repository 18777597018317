import i18n from 'i18next';

const validate = (values) => {
  const errors = {};
  const errorTexts = {
    nameEmpty: i18n.t('forms:first_name_error_empty'),
    lastNameEmpty: i18n.t('forms:last_name_error_empty'),
    emailEmpty: i18n.t('forms:email_error_empty'),
    emailInvalid: i18n.t('forms:email_error_invalid'),
    passwordEmpty: i18n.t('forms:password_error_empty'),
    repeatPasswordEmpty: i18n.t('forms:repeat_password_error_empty'),
    repeatPasswordDifferent: i18n.t('forms:repeat_password_error_not_same'),
  };

  // Name
  errors.first_name = [];
  if (!values.first_name || values.first_name.trim() === '') {
    errors.first_name.push(errorTexts.nameEmpty);
  }
  errors.last_name = [];
  if (!values.last_name || values.last_name.trim() === '') {
    errors.last_name.push(errorTexts.lastNameEmpty);
  }

  // Email
  errors.email = [];
  if (!values.email || values.email.trim() === '') {
    errors.email.push(errorTexts.emailEmpty);
  }

  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email.push(errorTexts.emailInvalid);
  }

  // New Password
  errors.new_password = [];
  if (!values.new_password || values.new_password.trim() === '') {
    errors.new_password.push(errorTexts.passwordEmpty);
  }

  // Repeat Password
  errors.repeat_new_password = [];
  if (!values.repeat_new_password || values.repeat_new_password.trim() === '') {
    errors.repeat_new_password.push(errorTexts.repeatPasswordEmpty);
  }

  if (values.new_password !== values.repeat_new_password) {
    errors.repeat_new_password.push(errorTexts.repeatPasswordDifferent);
  }

  return errors;
};

export default validate;
