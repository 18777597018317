/* eslint-disable default-case */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import apiConfig from '../api.config';
import i18n from '../i18n';
import Banner from './resources/Banner';
import Block from './resources/Block';
import Booking from './resources/Booking';
import BookingManager from './resources/BookingManager';
import City from './resources/City';
import Collection from './resources/Collection';
import Country from './resources/Country';
import Cuisine from './resources/Cuisine';
import DiscountsPlace from './resources/DiscountsPlace';
import Employee from './resources/Employee';
import Gift from './resources/Gift';
import Guest from './resources/Guest';
import MenuCategory from './resources/MenuCategory';
import MenuCategoryWithTranslations from './resources/MenuCategoryWithTranslations';
import Place from './resources/Place';
import PlaceManager from './resources/PlaceManager';
import PlaceWithDiscounts from './resources/PlaceWithDiscounts';
import PlaceWithTranslations from './resources/PlaceWithTranslations';
import PlaceWorkingHours from './resources/PlaceWorkingHours';
import RequestFailedException from './resources/RequestFailedException';
import Resolution from './resources/Resolution';
import ResourceCollection from './resources/ResourceCollection';
import Review from './resources/Review';
import Salon from './resources/Salon';
import SalonWithElements from './resources/SalonWithElements';
import SalonWithTableCombinations from './resources/SalonWithTableCombinations';
import Shift from './resources/Shift';
import Slot from './resources/Slot';
import SpecialEvent from './resources/SpecialEvent';
import SpecialEventWithPlace from './resources/SpecialEventWithPlace';
import SpecialOffer from './resources/SpecialOffer';
import SpecialOfferClient from './resources/SpecialOfferClient';
import Statement from './resources/Statement';
import Suggestion from './resources/Suggestion';
import Table from './resources/Table';
import ThirdPartyReview from './resources/ThirdPartyReview';
import User from './resources/User';
import UserManager from './resources/UserManager';

const FORM_DATA_CONTENT_TYPE = 'multipart/form-data';
const JSON_CONTENT_TYPE = 'application/json';
/**
 * Wrapper of axios made for requesting resources from Dineout API.
 */
export default class RequestService {
  INTERNAL_CODE_OK = 200;

  CONTENT_TYPE = JSON_CONTENT_TYPE;

  /**
   * Accepts the endpoint of the API being requested.
   * @param endpoint
   */
  constructor(endpoint) {
    this.endpoint = endpoint;

    // Create instance of axios
    this.axios = axios.create({
      baseURL: apiConfig.apiUrl,
      headers: {
        'X-Authorization': apiConfig.apiKey,
      },
      transformResponse: [
        (response) => {
          const responseObject = JSON.parse(response);
          if (responseObject.code === this.INTERNAL_CODE_OK) {
            return this.resourcify(responseObject.data);
          }
          const { key, details } = this.errorify(
            responseObject.code,
            responseObject.data,
          );
          throw new RequestFailedException(key, details);
        },
      ],
    });

    // create cancel tokens,
    // for stopping requests that are obsolete
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();

    // Mandatory request body,
    // Supports both server (native) & client sides

    this.body = {
      id: 1,
      params: {
        os: apiConfig.apiPlatform,
        locale: i18n.language,
      },
    };
  }

  forFileUpload() {
    this.CONTENT_TYPE = FORM_DATA_CONTENT_TYPE;
    return this;
  }

  /**
   * Return resource object based on endpoint and response data.
   * @param response
   * @returns {City|ResourceCollection|Country|*}
   */
  resourcify(response) {
    switch (this.endpoint) {
      case 'client/countries/all':
        return new ResourceCollection(Country).make(response);
      case 'client/countries/details':
        return Country.fromAPI(response);
      case 'client/banners/get':
        return Banner.fromAPI(response);
      case 'client/cities/all':
        return new ResourceCollection(City).make(response);
      case 'client/cuisines/all':
      case 'client/cuisines/subscribe':
        return new ResourceCollection(Cuisine).make(response);
      case 'client/cities/details':
        return City.fromAPI(response);
      case 'client/places/search':
      case 'client/places/favorites':
      case 'client/places/similar':
      case 'host/places/all':
        return new ResourceCollection(Place).make(response);
      case 'manager/places/index':
        return new ResourceCollection(PlaceManager).make(response);
      case 'host/places/get':
        return Place.fromAPI(response);
      case 'manager/places/show':
      case 'manager/places/notifications':
      case 'manager/places/request-verification':
      case 'manager/places/enable-gift-cards':
      case 'manager/places/enable-tasting-menus':
      case 'manager/places/enable-dining-events':
      case 'manager/places/chef':
      case 'manager/places/logo':
      case 'manager/gallery/remove':
      case 'manager/gallery/images':
      case 'manager/gallery/sort':
      case 'manager/gallery/thumb':
      case 'manager/places/change':
      case 'manager/guests/settings':
        return PlaceManager.fromAPI(response);
      case 'client/places/salons':
        return new ResourceCollection(Salon).make(response);
      case 'client/places/slots':
        return new ResourceCollection(Slot).make(response);
      case 'client/places/tables':
      case 'host/places/tables':
        return new ResourceCollection(Table).make(response);
      case 'client/places/details':
      case 'client/favorites/add':
      case 'client/favorites/remove':
        return Place.fromAPI(response);
      case 'manager/places/details':
        return PlaceWithTranslations.fromAPI(response);
      case 'client/places/working':
        return PlaceWorkingHours.fromAPI(response);
      case 'client/menu/get':
      case 'client/menu/recommended':
        return new ResourceCollection(MenuCategory).make(response);
      case 'manager/menu/get':
      case 'manager/menu/edit':
        return new ResourceCollection(MenuCategoryWithTranslations).make(
          response,
        );
      case 'client/reviews/place-tips':
      case 'client/reviews/place':
        return new ResourceCollection(Review).make(response);
      case 'manager/reviews/third-party':
        return new ResourceCollection(ThirdPartyReview).make(response);
      case 'client/reviews/pending':
      case 'client/reviews/upvote':
      case 'client/reviews/submit':
        return Review.fromAPI(response);
      case 'client/discounts/city':
        return new ResourceCollection(PlaceWithDiscounts).make(response);
      case 'client/discounts/place':
        return new ResourceCollection(DiscountsPlace).make(response);
      case 'client/bookings/get':
      case 'client/bookings/active':
      case 'client/bookings/completed':
      case 'host/bookings/all':
        return new ResourceCollection(Booking).make(response);
      case 'guest/bookings/details':
      case 'client/bookings/details':
      case 'host/bookings/details':
      case 'guest/bookings/create':
      case 'client/bookings/create':
      case 'guest/bookings/cancel':
      case 'client/bookings/cancel':
      case 'client/bookings/join':
      case 'client/bookings/leave':
      case 'client/bookings/rate':
      case 'host/bookings/internal':
      case 'host/bookings/walkin':
        return Booking.fromAPI(response);
      case 'manager/payout/index':
        return new ResourceCollection(BookingManager).make(response);
      case 'host/bookings/edit':
        return Booking.fromAPI(response);
      case 'host/bookings/status':
      case 'host/bookings/seen':
        return Booking.fromAPI(response);
      case 'client/users/details':
      case 'host/users/details':
      case 'client/users/signup':
      case 'client/users/signin':
      case 'client/users/facebook':
      case 'client/users/google':
      case 'client/users/apple':
      case 'client/users/edit':
      case 'client/users/password':
      case 'host/users/signin':
        return User.fromAPI(response);
      case 'manager/users/signup':
      case 'manager/users/show':
      case 'manager/users/signin':
      case 'manager/users/update':
        return UserManager.fromAPI(response);
      case 'manager/hosts/index':
        return new ResourceCollection(Employee).make(response);
      case 'client/users/statements':
        return new ResourceCollection(Statement).make(response);
      case 'client/gifts/activate':
        return Gift.fromAPI(response);
      case 'client/gifts/activated':
        return new ResourceCollection(Gift).make(response);
      case 'client/places/autocomplete':
      case 'client/places/suggestions':
      case 'client/countries/suggestions':
        return new ResourceCollection(Suggestion).make(response);
      case 'client/special-events/tasting-menus':
      case 'client/special-events/place':
      case 'client/special-events/events':
      case 'manager/special-events/get':
        return new ResourceCollection(SpecialEventWithPlace).make(response);
      case 'client/special-events/single':
        return SpecialEventWithPlace.fromAPI(response);
      case 'client/places/shifts':
      case 'manager/places/shifts':
        return new ResourceCollection(Shift).make(response);
      case 'host/guests/index':
        return new ResourceCollection(Guest).make(response);
      case 'host/guests/create':
      case 'host/guests/details':
        return Guest.fromAPI(response);
      case 'host/blocks/all':
        return new ResourceCollection(Block).make(response);
      case 'host/blocks/block':
        return Block.fromAPI(response);
      case 'manager/special-events/single':
      case 'manager/special-events/add':
      case 'manager/special-events/edit':
        return SpecialEvent.fromAPI(response);
      case 'manager/special-offers/get':
        return new ResourceCollection(SpecialOffer).make(response);
      case 'client/special-offers/get':
      case 'client/special-offers/place':
        return new ResourceCollection(SpecialOfferClient).make(response);
      case 'manager/special-offers/single':
      case 'manager/special-offers/add':
      case 'manager/special-offers/edit':
        return SpecialOffer.fromAPI(response);
      case 'client/special-offers/single':
        return SpecialOfferClient.fromAPI(response);
      case 'client/cities/collections':
        return new ResourceCollection(Collection).make(response);
      case 'host/salons/floorplan':
        return new ResourceCollection(Salon).make(response);
      case 'host/floorplan/get':
      case 'manager/floorplan/get':
      case 'manager/floorplan/save':
        return new ResourceCollection(SalonWithElements).make(response);
      case 'manager/combinations/save':
      case 'manager/combinations/get':
        return new ResourceCollection(SalonWithTableCombinations).make(
          response,
        );
      case 'host/bookings/resolutions':
        return new ResourceCollection(Resolution).make(response);
      default:
        return response;
    }
  }

  errorify(code, data) {
    /**
     * @TODO: Details to be a string explaining more about the error, if available.
     * Eg: which fields are missing
     * @type {null}
     */
    let key = null;
    let details = null;
    switch (code) {
      case 100:
        key = 'invalid_request';
        details = data;
        break;
      case 101:
        key = 'dt_is_in_the_past';
        break;
      case 102:
        key = 'unauthorized_access';
        break;

      case 199:
        key = 'internal_server_error';
        break;

      case 300:
        key = 'table_not_found';
        break;
      case 301:
        key = 'block_not_created';
        break;
      case 302:
        key = 'block_not_removed';
        break;

      case 400:
        key = 'salon_not_found';
        break;

      case 500:
        key = 'place_not_found';
        break;
      case 501:
        key = 'place_already_in_favorites';
        break;
      case 502:
        key = 'place_not_in_favorites';
        break;

      case 600:
        key = 'user_not_found';
        break;
      case 601:
        key = 'email_already_taken';
        break;
      case 602:
        key = 'user_not_allowed';
        break;
      case 603:
        key = 'email_invalid';
        break;
      case 604:
        key = 'user_with_mail_not_found';
        break;
      case 605:
        key = 'invalid_link_or_expired';
        break;
      case 606:
        key = 'user_logged_with_facebook';
        break;
      case 607:
        key = 'user_logged_with_google';
        break;
      case 608:
        key = 'old_password_not_matching';
        break;
      case 609:
        key = 'country_preference_not_set';
        break;
      case 610:
        key = 'not_enough_points_to_exchange';
        break;
      case 611:
        key = 'signin_with_apple_error';
        break;

      case 700:
        key = 'city_not_found';
        break;

      case 750:
        key = 'gift_not_found';
        break;
      case 751:
        key = 'gift_with_cardinality_already_used';
        break;
      case 752:
        key = 'gift_is_expired';
        break;
      case 753:
        key = 'campaign_not_found';
        break;

      case 800:
        key = 'booking_not_found';
        break;
      case 801:
        key = 'too_many_people';
        break;
      case 802:
        key = 'table_not_available';
        break;
      case 803:
        key = 'invalid_status';
        break;
      case 804:
        key = 'booking_already_rated';
        break;
      case 806:
        key = 'not_enough_points';
        break;
      case 807:
        key = 'booking_is_not_cancellable';
        break;
      case 808:
        key = 'booking_max_guests_reached';
        break;

      case 860:
        key = 'guest_not_created';
        break;
      case 861:
        key = 'guest_not_deleted';
        break;

      case 862:
        key = 'guest_limit_reached';
        break;

      case 900:
        key = 'event_cannot_be_deleted';
        break;

      case 950:
        key = 'discount_cannot_be_deleted';
        break;

      case 1001:
        key = 'special_offer_not_found';
        break;

      case 1050:
        key = 'dining_event_features_not_enabled';
        break;
      case 1051:
        key = 'tasting_menu_features_not_enabled';
        break;

      case 1100:
        key = 'active_booking_in_shift';
        break;

      case 1200:
        key = 'no_active_subscriptions';
        break;
      case 1201:
        key = 'user_on_free_subscription';
        break;
      case 1203:
        key = 'subscription_payment_incomplete';
        break;
      case 1204:
        key = 'subscription_cannot_be_updated';
        break;

      case 1205:
        key = 'request_verification_failed';
        break;

      case 1250:
        key = 'twilio_invalid_response';
        break;
      case 1251:
        key = 'twilio_verification_canceled';
        break;
      case 1252:
        key = 'twilio_invalid_code';
        break;
      case 1253:
        key = 'twilio_invalid_phone';
        break;
    }
    return {
      key,
      details,
    };
  }

  /**
   * Params required for the endpoint request to work.
   * @param data
   * @returns {RequestService}
   */
  setParams(data) {
    this.body.params = {
      ...this.body.params,
      ...data,
    };
    return this;
  }

  /**
   * Converts an TWO-LEVEL object to FormData.
   * @returns {FormData}
   * @private
   */
  convertBodyToFormData() {
    const formData = new FormData();
    Object.keys(this.body).forEach((key) => {
      if ({}.hasOwnProperty.call(this.body, key)) {
        if (typeof this.body[key] === 'object') {
          Object.keys(this.body[key]).forEach((subKey) => {
            if ({}.hasOwnProperty.call(this.body[key], subKey)) {
              if (Array.isArray(this.body[key][subKey])) {
                this.body[key][subKey].forEach((item) => {
                  formData.append(`${key}[${subKey}][]`, item);
                });
              } else {
                formData.append(`${key}[${subKey}]`, this.body[key][subKey]);
              }
            }
          });
        } else {
          formData.append(key, this.body[key]);
        }
      }
    });

    return formData;
  }

  _prepareBody() {
    if (this.CONTENT_TYPE === FORM_DATA_CONTENT_TYPE) {
      this.body = this.convertBodyToFormData();
    }
  }

  /**
   * Sending the request and returning the promise that axios has.
   * @returns {never}
   */
  send() {
    if (!this.endpoint) {
      throw Error('Request has no endpoint.');
    }

    if (!this.body) {
      throw Error('Request has no parameters.');
    }

    this._prepareBody();

    /**
     * @TODO: Handle invalid requests to the API that do not generate RESPONSE CODE !== 200
     */
    return this.axios.post(this.endpoint, this.body, {
      cancelToken: this.source.token,
      headers: {
        ...this.axios.headers,
        'Content-Type': this.CONTENT_TYPE,
      },
    });
  }
}
