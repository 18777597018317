import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ElementsContext from '../../../contexts/ElementsContext';
import { initialTextOptions } from '../../../utilities/constants';
import { randomId, sortElements, updateCanvasElements } from '../helper';
import FormElement from '../../../react-web-ui/components/FormElement';
import { ReactComponent as Delete } from '../../../assets/images/icons/Delete.svg';
import { ReactComponent as Left } from '../../../assets/images/icons/Left.svg';
import { ReactComponent as Center } from '../../../assets/images/icons/Center.svg';
import { ReactComponent as Right } from '../../../assets/images/icons/Right.svg';

export default function TextElement({ setFocus }) {
  const { t } = useTranslation();
  const {
    canvasElements,
    setCanvasElements,
    selectedElement,
    setSelectedElement,
  } = useContext(ElementsContext);

  return (
    <>
      <div className="column-top">
        <h2>{t('pagePostCreator:text')}</h2>
        <button
          type="button"
          onClick={() => {
            const id = randomId();
            const newElement = {
              ...initialTextOptions,
              id,
            };
            const sortedElements = sortElements([
              ...canvasElements,
              { ...newElement },
            ]);
            setCanvasElements(sortedElements);
            setSelectedElement(sortedElements.find((element) => element.id === id));
          }}
          className="btn btn-accent btn-add-element"
        >
          {t('pagePostCreator:add_element')}
        </button>
      </div>
      {selectedElement && (
        <div className="details-form">
          <FormElement
            id="font-select"
            elementType="select"
            placeholder={t('pagePostCreator:font')}
            label={t('pagePostCreator:font')}
            value={selectedElement?.fontFamily}
            changed={(e) => {
              setCanvasElements(updateCanvasElements(
                canvasElements,
                selectedElement,
                'fontFamily',
                e.target.value,
              ));
            }}
            selectOptions={[
              {
                value: 'Montserrat',
                text: 'Montserrat',
              },
              {
                value: 'El Messiri',
                text: 'El Messiri',
              },
              {
                value: 'Jost',
                text: 'Jost',
              },
              {
                value: 'Lobster',
                text: 'Lobster',
              },
            ]}
          />
          <div className="toggle-buttons">
            <button
              type="button"
              className={`bold-option ${selectedElement?.fontWeight === 'bold' ? 'active' : ''}`}
              onClick={() => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'fontWeight',
                  selectedElement.fontWeight === 'bold' ? 'normal' : 'bold',
                ));
              }}
            >
              B
            </button>
            <button
              type="button"
              className={`italic-option ${selectedElement?.fontStyle === 'italic' ? 'active' : ''}`}
              onClick={() => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'fontStyle',
                  selectedElement.fontStyle === 'italic' ? 'normal' : 'italic',
                ));
              }}
            >
              I
            </button>
            <button
              type="button"
              className={`underline-option ${selectedElement?.textDecoration === 'underline' ? 'active' : ''}`}
              onClick={() => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'textDecoration',
                  selectedElement.textDecoration === 'underline' ? 'none' : 'underline',
                ));
              }}
            >
              U
            </button>
          </div>
          <div className="lg-two-cols">
            <FormElement
              className="col"
              id="text-size"
              elementType="input"
              inputType="number"
              placeholder={t('pagePostCreator:font_size')}
              label={t('pagePostCreator:font_size')}
              value={selectedElement?.fontSize}
              changed={(e) => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'fontSize',
                  e.target.value,
                ));
              }}
            />
            <FormElement
              className="col"
              id="line-height"
              elementType="input"
              inputType="number"
              placeholder={t('pagePostCreator:line_height')}
              label={t('pagePostCreator:line_height')}
              value={selectedElement?.lineHeight}
              changed={(e) => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'lineHeight',
                  e.target.value,
                ));
              }}
            />
          </div>
          <div className="toggle-buttons">
            <button
              type="button"
              className={`${selectedElement?.align === 'left' ? 'active' : ''}`}
              onClick={() => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'align',
                  'left',
                ));
              }}
            >
              <Left className="icon" />
            </button>
            <button
              type="button"
              className={`${selectedElement?.align === 'center' ? 'active' : ''}`}
              onClick={() => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'align',
                  'center',
                ));
              }}
            >
              <Center className="icon" />
            </button>
            <button
              type="button"
              className={`${selectedElement?.align === 'right' ? 'active' : ''}`}
              onClick={() => {
                setCanvasElements(updateCanvasElements(
                  canvasElements,
                  selectedElement,
                  'align',
                  'right',
                ));
              }}
            >
              <Right className="icon" />
            </button>
          </div>
          <div className="color-form-group">
            <span className="label">{t('pagePostCreator:text_color')}</span>
            <div className="input-wrapper">
              <input
                type="color"
                id="text-color"
                name="text-color"
                value={selectedElement?.color}
                onChange={(e) => {
                  setCanvasElements(updateCanvasElements(
                    canvasElements,
                    selectedElement,
                    'color',
                    e.target.value,
                  ));
                }}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              const currentCanvasElements = canvasElements.filter(
                (element) => (element.id !== selectedElement.id),
              );
              let lastIndex = null;
              currentCanvasElements.forEach((element, index) => {
                if (element.type === 'text') {
                  lastIndex = index;
                }
              });
              setSelectedElement(currentCanvasElements[lastIndex]);
              setCanvasElements(currentCanvasElements);
              setFocus(false);
            }}
            className="btn-link btn-delete-element"
          >
            <Delete className="icon" />
            {t('pagePostCreator:remove_text_element')}
          </button>
        </div>
      )}
    </>
  );
}

TextElement.propTypes = {
  setFocus: PropTypes.func.isRequired,
};
